import { Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import QueryField from '../../aegeon/query/QueryField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import DeviceGeneralInformationDetailsContent from './DeviceGeneralInformationDetailsContent';

const DeviceGeneralInformationDetails = (props) => {
  const { data, isLoading } = useQueryContext(props);
  const { record, setRecord } = useContext(RecordContext);
  const isDeviceContract = ["enedisMeter", "grdfMeter"].includes(record?.deviceType);

  useEffect(() => {
    if (data && !isLoading) {
      setRecord(data)
    }
    else {
      setRecord(null)
    }
  }, [data, isLoading]);

  return (
    record && isDeviceContract ? (
      <QueryField query_name="getDeviceContract">
        <DeviceGeneralInformationDetailsContent />
      </QueryField>
    ) : record &&
    <DeviceGeneralInformationDetailsContent />
  )
};

const DeviceGeneralInformationDetailsWithContext = (props) => {
  const TitleCallback = (props) => {
    const t = useTranslate();

    const record = useRecordContext(props);
    if (!record) {
      return null;
    }

    const quality = record?.fusionedEnergyQuality
    const fluid = record?.airtable?.fluid_type ? t('devices.general_information.fluids.' + record?.airtable?.fluid_type) : "N/A";

    return (
      <Typography variant="h4">{fluid} - {record.name} - {t('devices.general_information.quality')} {quality}</Typography>
    )

  }
  return (
    <DetailsShow titleCallback={TitleCallback} resource="devices" lessMargin={true}>
      <QueryField query_name="getDevice">
        <DeviceGeneralInformationDetails {...props} />
      </QueryField>
    </DetailsShow>
  )
}

export default DeviceGeneralInformationDetailsWithContext;

