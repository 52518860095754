import { Resource } from "./components/aegeon-rbac/Resource";
import { CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";

import SearchPageResult from "./components/search/SearchPageResult";
import DevelopmentPageList from "./components/aegeon/cards/DevelopmentPageList";

import DeviceIcon from "./assets/DeviceIcon.js";
import SiteIcon from "./assets/SiteIcon.js";
import FunctionalEntityIcon from "./assets/FunctionalEntityIcon";
import PortfoliosIcon from "./assets/PortfoliosIcon";
import UsersIcon from "./assets/UsersIcon.js";

import SitesList from "./components/sites/SitesList";
import SitesShow from "./components/sites/SitesShow";
import SiteGeneralInformationDetails from "./components/sites/CardsDetails/SiteGeneralInformation/SiteGeneralInformationDetails";
import SiteMissingInformationDetails from "./components/sites/CardsDetails/SiteMissingInformation/SiteMissingInformationDetails";
import SiteCompositionDetails from "./components/sites/CardsDetails/SiteComposition/SiteCompositionDetails";
import TableSitePageDetails from "./components/sites/CardsDetails/TableSitePageDetails";
import SiteEnergeticPerformanceDetails from "./components/sites/CardsDetails/SiteEnergeticPerformanceDetails";
import SiteAnalysisPageDetails from "./components/sites/CardsDetails/SiteAnalysisPageDetails";
import SiteHeatmapDetails from "./components/sites/CardsDetails/SiteHeatmapDetails";
import SiteHistogramDetails from "./components/sites/CardsDetails/SiteHistogramDetails";

import DeviceShow from "./components/devices/DeviceShow";
import DevicesList from "./components/devices/DevicesList";
import QualityPageDetails from "./components/devices/CardsDetails/QualityCardDetails";

// import { CustomRoutes } from "react-admin";

import DeviceGeneralInformationDetailsWithContext from "./components/devices/CardsDetails/DeviceGeneralInformationDetails";
import QualityCardDetailsWithContext from "./components/devices/CardsDetails/QualityCardDetails";
import HeatmapPageDetails from "./components/devices/CardsDetails/HeatmapPageDetails";
import HorizontalBarPageDetailsWithContext from "./components/devices/CardsDetails/HorizontalBarPageDetails";
import LinePageDetails from "./components/devices/CardsDetails/LinePageDetails";
import MonotonePageDetails from "./components/devices/CardsDetails/MonotonePageDetails";
import AnalysisPageDetailsWithContext from "./components/devices/CardsDetails/AnalysisPageDetails";
import TablePageDetailsWithContext from "./components/devices/CardsDetails/TablePageDetails";

import UsersShow from "./components/users/UsersShow.js";
import UsersList from "./components/users/UsersList.js";
import SyncPage from "./components/users/Synchronize/SyncPage";
import UsersShowDetails from "./components/users/UsersShowDetails.jsx";
import SyncPagePreview from "./components/users/Synchronize/SyncPagePreview";

import SubDevicesImportPage from "./components/devices/SubDevicesImport/SubDevicesImportPage.js";

export const availableResources = {
  portfolios: <Resource name="portfolios" list={<DevelopmentPageList resource="portfolios" />} icon={PortfoliosIcon} />,
  sites: (
    <Resource name="sites" list={SitesList} show={SitesShow} icon={SiteIcon}>
      <Route path=":id/show/general_information" element={<SiteGeneralInformationDetails />} />
      <Route path=":id/show/missing_information" element={<SiteMissingInformationDetails />} />
      <Route path=":id/show/energetic_performance" element={<SiteEnergeticPerformanceDetails />} />
      <Route path=":id/show/composition" element={<SiteCompositionDetails />} />
      <Route path=":id/show/analyse" element={<SiteAnalysisPageDetails />} />
      <Route path=":id/show/heatmap" element={<SiteHeatmapDetails />} />
      <Route path=":id/show/histogram" element={<SiteHistogramDetails />} />
      <Route path=":id/show/table" element={<TableSitePageDetails />} />
    </Resource>
  ),
  functional_entities: (
    <Resource
      name="functional_entities"
      list={<DevelopmentPageList resource="functional_entities" />}
      icon={FunctionalEntityIcon}
    />
  ),
  devices: (
    <Resource name="devices" list={DevicesList} show={DeviceShow} icon={DeviceIcon}>
      <Route path=":id/show/general_information" element={<DeviceGeneralInformationDetailsWithContext />} />
      <Route path=":id/show/quality" element={<QualityCardDetailsWithContext />} />
      <Route path=":id/show/comparison" element={<HorizontalBarPageDetailsWithContext />} />
      <Route path=":id/show/dataTable" element={<TablePageDetailsWithContext />} />
      <Route path=":id/show/analyse" element={<AnalysisPageDetailsWithContext />} />
      <Route path=":id/show/heatmap" element={<HeatmapPageDetails />} />
      <Route path=":id/show/monotone" element={<MonotonePageDetails />} />
      <Route path=":id/show/power" element={<LinePageDetails />} />
      <Route path="/import_subdevices" element={<SubDevicesImportPage />} />
    </Resource>
  ),
  users: (
    <Resource name="users" list={UsersList} show={UsersShow} icon={UsersIcon}>
      <Route path="/synchronize" element={<SyncPage />} />
      <Route path="/synchronize/preview" element={<SyncPagePreview />} />
      <Route path="/create" element={<UsersShowDetails create={true}/>} />
    </Resource>
  ),
  search: (
    <CustomRoutes>
      <Route path="/search" element={<SearchPageResult />} />
    </CustomRoutes>
  ),
};
