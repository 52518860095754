import { ResourceContextProvider, useRecordContext } from "react-admin";
import useTimeseriesController from "./useTimeseriesController";

import TimeseriesContextProvider from "./TimeseriesContextProvider";

const TimeseriesField = (props) => {
    const {
        children,
        source,
        filter = {},
        aggregation
    } = props;

    const propertyName = source;
    const record = useRecordContext(props);

    const controllerProps = useTimeseriesController(record, { record, propertyName, filter, aggregation });

    return (
        <ResourceContextProvider value={record}>
            <TimeseriesContextProvider value={controllerProps}>
                {children}
            </TimeseriesContextProvider>
        </ResourceContextProvider>
    )
}

export default TimeseriesField;
