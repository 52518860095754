import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceTypeColdIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m49.14,59.75l-7.82,2.1c-.53.14-.85.69-.71,1.22.12.45.52.74.96.74.09,0,.17-.01.26-.03l8.39-2.25,12.12,7-12.12,7-8.39-2.25c-.53-.14-1.08.17-1.22.71-.14.53.17,1.08.71,1.22l7.82,2.1-2.1,7.82c-.14.53.17,1.08.71,1.22.09.02.17.03.26.03.44,0,.85-.29.96-.74l2.25-8.39,12.12-7v14l-6.14,6.14c-.39.39-.39,1.02,0,1.41s1.02.39,1.41,0l5.73-5.73,5.73,5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02,0-1.41l-6.14-6.14v-14l12.12,7,2.25,8.39c.12.45.52.74.96.74.09,0,.17-.01.26-.03.53-.14.85-.69.71-1.22l-2.1-7.82,7.82-2.1c.53-.14.85-.69.71-1.22-.14-.53-.69-.85-1.22-.71l-8.39,2.25-12.12-7,12.12-7,8.39,2.25c.09.02.17.03.26.03.44,0,.85-.29.96-.74.14-.53-.17-1.08-.71-1.22l-7.82-2.1,2.1-7.82c.14-.53-.17-1.08-.71-1.22-.53-.14-1.08.17-1.22.71l-2.25,8.39-12.12,7v-14l6.14-6.14c.39-.39.39-1.02,0-1.41s-1.02-.39-1.41,0l-5.73,5.73-5.73-5.73c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l6.14,6.14v14l-12.12-7-2.25-8.39c-.14-.53-.69-.85-1.22-.71-.53.14-.85.69-.71,1.22l2.1,7.82Z"/>
      <path d="m64,37.73c4.61,0,8.54-2.88,10.12-6.93,17.03,4.49,29.63,20.02,29.63,38.44,0,1.66,1.34,3,3,3s3-1.34,3-3c0-21.56-14.99-39.67-35.1-44.49-.98-4.99-5.38-8.75-10.66-8.75-6,0-10.86,4.86-10.86,10.86s4.86,10.86,10.86,10.86Z"/>
      <path d="m25.64,79.7c-1.84-6.72-1.89-13.84-.04-20.75,2.75-10.26,9.33-18.83,18.52-24.14,1.43-.83,1.93-2.66,1.1-4.1-.83-1.43-2.66-1.93-4.1-1.1-10.58,6.11-18.16,15.98-21.32,27.78-2.22,8.29-2.07,16.84.33,24.87-3.82,3.35-4.87,9.03-2.24,13.59,3,5.2,9.64,6.98,14.84,3.98,5.2-3,6.98-9.64,3.98-14.84-2.31-4-6.77-5.96-11.07-5.29Z"/>
      <path d="m106.13,81.02c-5.2-3-11.84-1.22-14.84,3.98-2.31,3.99-1.78,8.84.94,12.23-12.4,12.51-32.16,15.65-48.11,6.44-1.43-.83-3.27-.34-4.1,1.1-.83,1.43-.34,3.27,1.1,4.1,7.19,4.15,15.04,6.12,22.8,6.12,12.39,0,24.52-5.05,33.26-14.28,4.81,1.65,10.28-.27,12.92-4.85,3-5.2,1.22-11.84-3.98-14.84Z"/>
    </SvgIcon>
  );
};

export default DeviceTypeColdIcon;