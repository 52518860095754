import { useEffect, useMemo } from 'react';

import { useInfiniteQuery, useQueryClient } from 'react-query';
import useQueryDataProvider from "./useQueryDataProvider";

const useQueryInfiniteProvider = (resource, props = {}, options) => {
    const {
        query_name,
        id,
        params
    } = props;

    const getQuery = ({ pageParam }) => {
        return queryDataProvider
            .getQuery(query_name, id, { ...params, nextToken: pageParam })
            .then(({ data }) => ({
                data
            }));
    };

    const queryDataProvider = useQueryDataProvider();
    const queryClient = useQueryClient();
    let result = useInfiniteQuery({
        queryKey: [query_name, { query_name, id }, params],
        queryFn: getQuery,
        getNextPageParam: (lastPage, pages) => lastPage?.data?.nextToken,
        ...options,
        staleTime: Infinity,
        CacheTime: Infinity,
        keepPreviousData: true
    });

    useEffect(() => {
        if (result.hasNextPage === true && result.isFetchingNextPage === false) {
            result.fetchNextPage()
        };

        if (result.hasNextPage === false && result.isFetchingNextPage === false) {
            // transform data to a  single array of objects
            let data = {};
            if (result.data.pages.length <= 1) {
                data = result.data.pages[0].data;
            }
            else if (result.data.pages.length > 1) {
                data.scannedCount = result.data.pages.reduce((acc, page) => acc + page.data.scannedCount, 0);
                data.items = result.data.pages.reduce((acc, page) => [...acc, ...page.data.items], []);
            }
            queryClient.setQueryData([query_name, { query_name, id }, params], data);
        }
    }, [result.hasNextPage, result.isFetchingNextPage]);


    if (process.env.NODE_ENV === 'development') {
        console.log("useQuery", result.data)
    }

    return useMemo(
        () =>
            result.data
                ? {
                    ...result,
                    data: result.data,
                }
                : result,
        [result]
    )
};

export default useQueryInfiniteProvider;
