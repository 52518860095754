import React from 'react';
import globalUseStyles from '../../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import CustomTable from '../../../Table/CustomTable';
import { convert_array_string_to_string, convert_list_to_contacts } from "../../../aegeon/helper";

const SiteGeneralInformationDetailsContentRightBottom = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const record = useRecordContext();

  const technical_contact_list = convert_list_to_contacts(record?.airtable?.technical_contact)
  const technical_assistant_contact_list = convert_list_to_contacts(record?.airtable?.technical_assistant_contact)
  const billing_contact_list = convert_list_to_contacts(record?.airtable?.billing_contact)
  const asset_manager_contact_list = convert_list_to_contacts(record?.airtable?.asset_manager_contact)
  const rental_manager_contact_list = convert_list_to_contacts(record?.airtable?.rental_manager_contact)
  const property_manager_contact_list = convert_list_to_contacts(record?.airtable?.property_manager_contact)
  const property_manager_agency_list = convert_list_to_contacts(record?.airtable?.property_manager_agency)
  const maintainers_intervenants_contact_list = convert_list_to_contacts(record?.airtable?.maintainers_intervenants_contact)

  const hours = (() => {
    if (record?.airtable?.opening_hours !== null && record?.airtable?.closing_hours !== null) {
      let start = record?.airtable?.opening_hours
      let end = record?.airtable?.closing_hours
      let value = `${start} - ${end}`
      return value
    }
    else return null
  })()

  // The rows tooltips are translated in the CustomTable component
  const rows = [
    { name: t('sites.general_information.opening_hours'), value: hours, tooltip: 'sites.general_information.opening_hours_no_data', tooltip_with_value: !record?.airtable?.validated_client_hours },
    { name: t('sites.general_information.working_days'), value: convert_array_string_to_string(record?.airtable?.working_days), tooltip: 'sites.general_information.working_days_no_data', tooltip_with_value: !record?.airtable?.validated_client_working_days },
    { name: t('sites.general_information.current_typology'), value: record?.airtable?.current_typology, tooltip: 'sites.general_information.current_typology_no_data' },
    { name: t('sites.general_information.technical_contact_name'), value: technical_contact_list, tooltip: 'sites.general_information.contact_no_data' },
    { name: t('sites.general_information.technical_assistant_contact_name'), value: technical_assistant_contact_list, tooltip: 'sites.general_information.contact_no_data', hide_if_null: true },
    { name: t('sites.general_information.billing_contact_name'), value: billing_contact_list, tooltip: 'sites.general_information.contact_no_data' },
    { name: t('sites.general_information.asset_manager_contact_name'), value: asset_manager_contact_list, tooltip: 'sites.general_information.contact_no_data' },
    { name: t('sites.general_information.rental_manager_contact_name'), value: rental_manager_contact_list, tooltip: 'sites.general_information.contact_no_data' },
    { name: t('sites.general_information.maintainers_intervenants_contact_name'), value: maintainers_intervenants_contact_list, tooltip: 'sites.general_information.contact_no_data' },
    { name: t('sites.general_information.property_manager_contact_name'), value: property_manager_contact_list, tooltip: 'sites.general_information.contact_no_data' },
    { name: t('sites.general_information.property_manager_agency_name'), value: property_manager_agency_list, tooltip: 'sites.general_information.property_manager_agency_name_no_data' },
  ]

  return (
    <Card className={globalClasses.DetailCard}>
      <Typography display="block" variant="h8">
        <span><b>{t('sites.general_information.right_bottom_panel.title')}</b></span>
      </Typography>
      <div className={globalClasses.DetailCardTableSubtitle} />
      <CustomTable rows={rows} defaultTooltip={"sites.no_data_tooltip"} />
    </Card>
  );
};

export default SiteGeneralInformationDetailsContentRightBottom;
