import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from 'react';

import ChartLoading from '../../../aegeon/charts/ChartLoading';
import { useQueryContext } from '../../../aegeon/query/useQueryContext';
import SiteGeneralInformationDetailsContentLeft from './SiteGeneralInformationDetailsContentLeft';
import SiteGeneralInformationDetailsContentLeftBottom from './SiteGeneralInformationDetailsContentLeftBottom';
import SiteGeneralInformationDetailsContentRight from './SiteGeneralInformationDetailsContentRight';
import SiteGeneralInformationDetailsContentRightBottom from './SiteGeneralInformationDetailsContentRightBottom';


const SiteGeneralInformationDetailsContent = (props) => {
  const {
    isLoading,
  } = useQueryContext(props);

  if (isLoading) {
    return (
      <ChartLoading />
    );
  }

  return (<div>
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <SiteGeneralInformationDetailsContentLeft />
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <SiteGeneralInformationDetailsContentRight />
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <SiteGeneralInformationDetailsContentLeftBottom />
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <SiteGeneralInformationDetailsContentRightBottom />
      </Grid2>
    </Grid2>
  </div>);
};

export default SiteGeneralInformationDetailsContent;

