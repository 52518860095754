
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const BackButtonIcon = (props) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Vector" d="M0.376695 7.09187C-0.125565 7.59413 -0.125565 8.4098 0.376695 8.91206L6.80563 15.341C7.30789 15.8433 8.12356 15.8433 8.62582 15.341C9.12808 14.8387 9.12808 14.0231 8.62582 13.5208L4.38674 9.28574H16.7142C17.4254 9.28574 18 8.71116 18 7.99996C18 7.28876 17.4254 6.71417 16.7142 6.71417H4.39076L8.6218 2.47911C9.12406 1.97685 9.12406 1.16118 8.6218 0.658922C8.11954 0.156662 7.30387 0.156662 6.80161 0.658922L0.372677 7.08785L0.376695 7.09187Z" fill="#71B9A1"/>
    </svg>
    );
}

export default BackButtonIcon;