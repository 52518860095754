const translateSearchFr = {
  search: {
    placeholder: "Chercher un numéro de site, de compteur...",
    noResults: "Aucun résultat",
    searchPage: {
      title: "Résultat de la recherche",
    }
  },
};

export default translateSearchFr;
