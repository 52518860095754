import moment from 'moment'
import DeviceTypeIcon from '../devices/CustomComponents/DeviceTypeIcon';


const convertQualitiesIntoRows = (reporting_start_date, reporting_end_date, deviceQualities, list_of_pdl, t) => {
  let rows = {
    "energetic":[],
    "water":[],
  }
  deviceQualities && Object.keys(deviceQualities).forEach((deviceQualityId) => {
    let current_date = reporting_start_date.clone()
    let device = list_of_pdl?.find((pdl) => pdl?.id === deviceQualityId)
    let timeseries_start_date = deviceQualities[deviceQualityId]?.timeseries_start_date
    timeseries_start_date = moment(timeseries_start_date, "YYYY-MM-DDTHH:mm:ss.SSSZ")
    let timeseries_end_date = deviceQualities[deviceQualityId]?.timeseries_end_date
    timeseries_end_date = moment(timeseries_end_date, "YYYY-MM-DDTHH:mm:ss.SSSZ")
    timeseries_end_date = timeseries_end_date.startOf('month')
    let missing_values = deviceQualities[deviceQualityId]?.missing_values
    let missing_months = missing_values?.map((missing_value) =>
      moment(missing_value, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/YYYY")
    )
    missing_months = [...new Set(missing_months)]
    let pdl_missing_months = []
    while (current_date.isBefore(reporting_end_date)) {
      if (timeseries_start_date > current_date || timeseries_end_date < current_date || missing_months.includes(current_date.format("MM/YYYY"))) {
        pdl_missing_months.push(current_date.format("MM/YYYY"))
      }
      current_date.add(1, 'month')
    }
    let row = {
      name: device?.name,
      icon: <DeviceTypeIcon deviceType={device?.deviceType} />,
      icon_tooltip: t("devices.deviceTypes." + device?.deviceType),
      values: [pdl_missing_months.join(', '), ''],
      path: "/devices/" + device.id + "/show",
      tooltip_link: "sites.composition.device_tooltip_link"
    }
    if (device?.deviceType === "waterMeter") {
      rows["water"].push(row)
    }
    else {
      rows["energetic"].push(row)
    }
  })

  rows["energetic"].sort((a, b) => {
    if (a.icon_tooltip < b.icon_tooltip) { return 1 }
    if (a.icon_tooltip > b.icon_tooltip) { return -1 }
    return 0
  }
  )
  
  return rows
}

const calculScore = (both_rows) => {
  let score = {}
  Object.keys(both_rows)?.forEach((key) => {
    let rows = both_rows[key]
    let total = rows.length * 12
    let missing = 0
    rows.forEach((row) => {
      missing += row.values[0] ? row.values[0].split(', ').length : 0
    })
    score[key] = {
      missing: missing,
      total: total
    }
  })
  return score
}

const calculNotation = (score) => {
  let percentage = {}
  Object.keys(score).forEach((key) => {
    if (!score[key]?.missing || !score[key]?.total) {
      percentage[key] = 0
    }
    else {
      percentage[key] = Math.round((score[key].missing / score[key].total) * 1000) / 10
    }
  })
  return percentage
}

const calculReportingDates = (start_date) => {
  // we display the current reporting period 6 months after the end of the period
  if (!start_date) {
    start_date = "01/01"
  }
  let today = moment()
  let reporting_end_date = moment(start_date, "DD/MM").set({ year: today.year()})
  if (today.isAfter(reporting_end_date.clone().add(6, 'month'))) {
    reporting_end_date = reporting_end_date.clone().add(1, 'year')
  }
  else if (today.isBefore(reporting_end_date.clone().add(-6, 'month'))) {
    reporting_end_date = reporting_end_date.clone().add(-1, 'year')
  }
  let reporting_start_date = reporting_end_date.clone().add(-1, 'year')
  return {
    reporting_start_date: reporting_start_date,
    reporting_end_date: reporting_end_date
  }
}

export { convertQualitiesIntoRows, calculScore, calculNotation, calculReportingDates }






