import React from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CardWrapper from "../../aegeon/cards/CardWrapper";
import SiteGeneralInformation from '../../sites/Cards/SiteGeneralInformation';

const CardGeneralInformation = () => {
  const globalClasses = globalUseStyles();
  return (
    <CardWrapper resource="sites" type="general_information" >
      <div className={globalClasses.generalInformation}>
        <SiteGeneralInformation />
      </div>
    </CardWrapper>
  )
};

export default CardGeneralInformation;

