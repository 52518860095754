import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import TableDetailsComponent from '../../sites/CardsDetails/TableDetailsComponent';
import { useQueryContext } from '../query/useQueryContext';


const TableDJComponent = (props) => {
  const moment = require('moment');
  const t = useTranslate();
  const { data, isLoading } = useQueryContext(props);
  const type = props.type

  const convertData = (data) => {
    let convertedData = {}
    if (data && data.timeseries && data.timeseries.length > 0) {
      data.timeseries.map((item) => {
        let year = moment(item.time).format("YYYY")
        let month = moment(item.time).month() + 1
        if (!convertedData[year]) {
          convertedData[year] = {}
        }
        convertedData[year][month] = item.value
      })
    }
    return convertedData
  }

  useEffect(() => {
    props.setIsLoading && props.setIsLoading(isLoading)
  }, [isLoading]);

  return (
    <TableDetailsComponent
      setDataSourcesCallback={props.setDataSourcesCallback}
      groupedSerie={convertData(data)}
      unit={""}
      isLoading={isLoading}
      title={t('sites.table.' + type + '.title')}
      source={t('sites.table.' + type + '.title')}
      disableMissingDataTooltip={true}
      disabledBoldLastValue={true}
      disabledTotal={true}
      tooltip_text={t('sites.table.' + type + '.tooltip')}
      bottomSource={t('sites.table.' + type + '.source')}
    />
  );
}

export default TableDJComponent;