import React, { useState } from "react";

import CardWrapper from "../../aegeon/cards/CardWrapper";
import ChartHeatmap from '../../aegeon/charts/ChartHeatmap';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import HeatmapDataReceiver from '../CustomComponents/HeatmapDataReceiver';
const CardChartHeatmap = (props) => {
  const { filter, source, enabled } = props;

  const [data, setData] = useState(null);
  const [unit, setUnit] = useState(null);
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const addHeatmapData = (childData, unit) => {
    if (!data) {
      setData(childData);
      setUnit(unit);
      let min = Math.min(...childData.map((item) => item[2]));
      let max = Math.max(...childData.map((item) => item[2]));
      setMinMax({ min, max });
    }
  };

  if (!enabled) return null;

  return (

    <CardWrapper resource="devices" type="heatmap" >
      <TimeseriesField source={source} filter={filter} aggregation={{ period: "HOUR", operation: "IN_KWH" }}>
        <HeatmapDataReceiver setHeatmapData={(childData, unit) => addHeatmapData(childData, unit)} groupBy="day_of_week" />
        {data ?
          <ChartHeatmap data={data} minMax={minMax} type="day_of_week" unit={unit} />
          :
          <ChartLoading />
        }
      </TimeseriesField>
    </CardWrapper>
  )
};

export default CardChartHeatmap;