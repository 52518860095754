import { useContext, useMemo } from 'react';

import QueryDataProviderContext from './QueryDataProviderContext';

const useQueryDataProvider = () => {
    const queryDataProvider = useContext(QueryDataProviderContext);

    // const logoutIfAccessDenied = useLogoutIfAccessDenied();

    const queryDataProviderProxy = useMemo(() => {
        return new Proxy(queryDataProvider, {
            get: (target, name) => {
                if (typeof name === 'symbol' || name === 'then') {
                    return;
                }
                return (...args) => {
                    const type = name.toString();

                    if (typeof queryDataProvider[type] !== 'function') {
                        throw new Error(
                            `Unknown queryDataProvider function: ${type}`
                        );
                    }

                    try {
                        return queryDataProvider[type]
                            .apply(queryDataProvider, args)
                            .then(response => {
                                if (process.env.NODE_ENV !== 'production') {
                                    // console.log(response);
                                    // console.log(type);
                                    // validateResponseFormat(response, type);
                                }
                                return response;
                            })
                            .catch(error => {
                                if (process.env.NODE_ENV !== 'production') {
                                    console.error(error);
                                }
                                return Promise.resolve(false);
                            });
                    } catch (e) {
                        if (process.env.NODE_ENV !== 'production') {
                            console.error(e);
                        }
                        throw new Error(
                            'The queryDataProvider threw an error. It should return a rejected Promise instead.'
                        );
                    }
                };
            },
        });
        // }, [queryDataProvider, logoutIfAccessDenied]);
    }, [queryDataProvider]);

    return queryDataProviderProxy;
};

export default useQueryDataProvider;