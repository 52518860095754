import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Switch,
  TextField
} from "@mui/material";
import Typography from '@mui/material/Typography';
import { capitalize } from "lodash";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import { useTranslate } from "react-admin";
import { utils, writeFile } from 'xlsx';
import globalUseStyles from "../../../styles/globalCustomStyles";

import { faFileExcel } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SyncSourceMain = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const [syncAll, setSyncAll] = useState(false);

  const SourceAirtableFields = () => {
    return (
      <>
        <TextField
          id="user"
          required
          label={t("users.configuration.user")}
          variant="outlined"
          className={globalClasses.UserField}
        />
        <FormControl variant="outlined" required className={globalClasses.UserField}>
          <InputLabel htmlFor="key">{t("users.configuration.key")}</InputLabel>
          <OutlinedInput id="key" type="password" label="Password" />
        </FormControl>
      </>
    );
  };

  const onUploadFile = (e) => {
    props.onUploadFile(e);
  }

  const onUploadExampleFile = () => {
    let data = ['username', 'email', 'role', 'enabled', 'perimeterSitesCodes', 'perimeterDevicesCodes', 'timeConstraintPerimeterDevices'];
    let ws = utils.aoa_to_sheet([data]);
    let wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'example');
    writeFile(wb, 'example.xlsx');
  }

  const SourceExcelFields = () => {
    return (
      <div className={globalClasses.syncExcel}>
        <div className={globalClasses.dropzoneContainer}>
          <DropzoneArea
            onChange={onUploadFile}
            dropzoneText={t("users.configuration.dropzone_text")}
            showPreviews={true}
            showPreviewsInDropzone={false}
            useChipsForPreview
            previewText=""
            showAlerts={false}
            clearOnUnmount={false}
            filesLimit={1}
            acceptedFiles={[".csv", ".xlsx"]}
          />
        </div>
        <div className={globalClasses.syncLineContainer}>
          <Typography>{t("users.configuration.download_exemple_file")}:</Typography>
          <IconButton onClick={onUploadExampleFile}>
            <FontAwesomeIcon icon={faFileExcel} color="#71B9A1" style={{ paddingRight: "10px", height: "40px" }} />
          </IconButton>
        </div>
        <div className={globalClasses.syncLineContainer}>
          <Typography >{t("users.configuration.synchronize_all")}:</Typography>
          <FormControlLabel
            control={<Switch defaultChecked={syncAll} />}
            disabled
          />
        </div>
      </div >
    );
  };

  const possibleSources = [
    // { id: "airtable", component: <SourceAirtableFields /> },
    { id: "excel", component: <SourceExcelFields /> }
  ]

  const [source, setSource] = useState(possibleSources[0].id);


  return (
    <div className={globalClasses.UserGeneralInformation}>
      <TextField
        id="source"
        required
        label={t("users.configuration.source")}
        variant="outlined"
        select
        onChange={(e) => setSource(e.target.value)}
        value={source}
        className={globalClasses.UserField}>
        {possibleSources.map((source) => (
          <MenuItem key={source.id} value={source.id}>
            {capitalize(source.id)}
          </MenuItem>
        ))}
      </TextField>
      {possibleSources.filter((component) => component.id === source)[0].component}
    </div>
  );
};

export default SyncSourceMain;
