import React, { useState } from "react";

import CardWrapper from "../../aegeon/cards/CardWrapper";
import ChartHeatmap from "../../aegeon/charts/ChartHeatmap";
import ChartLoading from "../../aegeon/charts/ChartLoading";
import QueryInfiniteField from "../../aegeon/query/QueryInfiniteField";
import HeatmapDataReceiver from '../CustomComponents/HeatmapDataReceiver';

const CardHeatmap = (props) => {
  const moment = require('moment');
  const startDate = moment().subtract(1, 'months').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).subtract(2, 'days');

  const [data, setData] = useState(null);
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const addHeatmapData = (childData) => {
    if (!data) {
      setData(childData);
      let min = Math.min(...childData.map((item) => item[2]));
      let max = Math.max(...childData.map((item) => item[2]));
      setMinMax({ min, max });
    }
  };

  return (
    <CardWrapper resource="sites" type="heatmap" >
      <QueryInfiniteField query_name="getSiteAggElecTimeseries" params={{ time: { between: [startDate, endDate] } }}>
        <>
          <HeatmapDataReceiver setHeatmapData={(childData) => addHeatmapData(childData)} groupBy="day_of_week" />
          {data ?
            <ChartHeatmap data={data} minMax={minMax} type="day_of_week" unit="kWh" />
            :
            <ChartLoading />
          }
        </>
      </QueryInfiniteField>
    </CardWrapper>
  )
};

export default CardHeatmap;

