import { createContext } from 'react';

/**
 * Context to store choices and functions to retrieve them.
 *
 * Use the useChoicesContext() hook to read the context.
 */
export const TimeseriesContext = createContext( undefined );

// export type TimeseriesContextValue = {
//     data;
//     error;
//     filter;
//     filterValues;
//     hasNextPage;
//     hasPreviousPage;
//     hideFilter: (filterName) => void;
//     isFetching;
//     isLoading;
//     page;
//     perPage;
//     refetch: (() => void);
//     resource;
//     setFilters: (
//         filters,
//         displayedFilters,
//         debounce?
//     ) => void;
//     setPage: (page) => void;
//     setPerPage: (page) => void;
//     setSort: (sort) => void;
//     showFilter: (filterName, defaultValue) => void;
//     sort ;
//     source;
//     total;
//     isFromReference;
// };
