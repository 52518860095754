import React, { useContext, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import QueryField from '../../aegeon/query/QueryField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import HorizontalBarCardDetails from './HorizontalBarCardDetails';

const HorizontalBarPageDetails = (props) => {
  const { data, isLoading } = useQueryContext(props);
  const { record, setRecord } = useContext(RecordContext);

  useEffect(() => {
    if (data && !isLoading) {
      setRecord(data)
    }
    else {
      setRecord(null)
    }
  }, [data, isLoading]);

  return (
    record &&
    <HorizontalBarCardDetails />
  )
};

const HorizontalBarPageDetailsWithContext = (props) => {
  const t = useTranslate();
  return (
    <DetailsShow title={t('devices.comparison.title')} resource="devices" lessMargin={true}>
      <QueryField query_name='getDevice'>
        <HorizontalBarPageDetails {...props} />
      </QueryField>
    </DetailsShow>
  )
}

export default HorizontalBarPageDetailsWithContext;

