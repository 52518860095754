import React from 'react';
import { useRecordContext } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CardWrapper from '../../aegeon/cards/CardWrapper';

import QueryField from "../../aegeon/query/QueryField";
import DeviceGeneralInformation from "./DeviceGeneralInformation";

const CardGeneralInformation = () => {
  const globalClasses = globalUseStyles();
  const record = useRecordContext();
  const isDeviceContract = ["enedisMeter", "grdfMeter"].includes(record?.deviceType);

  const DeviceComponent = () => {
    return (
      <div className={globalClasses.generalInformation}>
        <DeviceGeneralInformation />
      </div>
    )
  }

  return (
    <CardWrapper resource="devices" type="general_information" >
      {isDeviceContract ? (
        <QueryField query_name='getDeviceContract' >
          <DeviceComponent />
        </QueryField>
      ) :
        <DeviceComponent />
      }
    </CardWrapper>
  )
};

export default CardGeneralInformation;

