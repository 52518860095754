// Libs
import { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import useQueryProvider from '../../aegeon/query/useQueryProvider';
import useSubscriptionProvider from '../../aegeon/subscription/useSubscriptionProvider';

import moment from "moment-timezone";


const GetTimeseriesAsync = (props) => {
  // Send the data to the API via a callback function
  // Function to export data to excel
  const {
    onStreamCallback,
    name,
    aggregation,
    startDate,
    endDate,
    exportSchema,
    chunked = false,
  } = props;

  const record = useRecordContext();

  // Configure callbacks for the export process
  // Control the export process
  const [token, setToken] = useState(undefined);
  const [accChunk, setAccChunk] = useState({ timeseries: [], status: "IDLE", number: 0 });

  // The function to handle the reception of data
  const onStreamedData = (provider, value) => {

    let localChunk = accChunk;

    const status = value.data.onGetDevicePropertyTimeseriesAsyncResult.status;
    let timeseries = value.data.onGetDevicePropertyTimeseriesAsyncResult.timeseries;
    const chunkCurrent = value.data.onGetDevicePropertyTimeseriesAsyncResult.chunkCurrent;
    const chunkTotal = value.data.onGetDevicePropertyTimeseriesAsyncResult.chunkTotal;
    // XXX: Handle Paris Timezone
    timeseries = timeseries.map((element) => {
      // Convert the UTC time  to Paris Timezone
      const europeParisDate = moment.utc(element.time).tz('Europe/Paris').format();
      return { ...element, time: europeParisDate };
    });

    // Append the new data to the existing data
    localChunk.timeseries.push.apply(accChunk.timeseries, timeseries);
    // Increase the number of chunks
    localChunk.number = accChunk.number + 1;
    // Update the status
    localChunk.status = accChunk.number === chunkTotal ? "COMPLETE" : "PENDING";

    console.log("receiving data... " + timeseries.length + " " + status + " " + localChunk.number + " " + chunkCurrent + " " + chunkTotal);

    // Close the provider if the chunked is true and the status is complete
    if (localChunk.number === chunkTotal) {
      // setToken(undefined);
      provider.close();
      // This command triggers a new call to the API, but it is not necessary
      // dataProvider.remove();
    }

    setAccChunk(localChunk);

    if (chunked === false && localChunk.status !== "COMPLETE") {
      return;
    }

    const data = {
      serie: properties.name,
      unit: properties.unit,
      status: accChunk.status,
      data: accChunk.timeseries.map((element) => {
        return {
          time: element.time,
          value: element.value,
          nature: element.nature,
          sampling_rate_in_min: element.sampling_rate_in_min
        }
      })
    }

    // Send the data to the parent component
    onStreamCallback(data);
  };

  // This is sent to the parent component to handle the data
  useEffect(() => {
    console.log("==> Sending data to the parent component");
  }, [accChunk]);

  // ****************************************************************************************************
  // Prepare All Calls to the Export API
  // ****************************************************************************************************
  // This shoul come from upper component
  // Test if name aggregation and export schema are corect and present
  // in the record properties
  let properties = {
    id: record.properties.find((element) => element.name === name)?.id,
    unit: record.properties.find((element) => element.name === name)?.unit,
    name: name,
    aggregation: aggregation,
    exportSchema: exportSchema
  };

  // This data provider is used to get the token and star
  let dataProvider = useQueryProvider(
    'devices',
    {
      query_name: "getDevicePropertyTimeseriesAsync",
      id: record.id,
      params: {
        deviceId: record.id,
        propertyId: properties.id,
        timestamp: { between: [startDate, endDate] },
        aggregation: properties.aggregation,
      }
    },
    {
      enabled: token === undefined,
      staleTime: 0
    }
  );


  // Configure the the subscriber, but enabled it only when the token is available
  let subscriptionProvider = useSubscriptionProvider(
    {
      subscriptionName: "onGetDevicePropertyTimeseriesAsyncResult",
      queryParams: { token: token !== undefined ? token : "" }
    },
    onStreamedData,
    {
      enabled: false
    }
  );

  // Handle The first part of the export process - Get the token to start the export and subscribe the call
  useEffect(() => {
    // if (token === undefined && dataProvider.status !== "loading") {

    //   console.log("=REFETCH====================");
    //   console.log(dataProvider);
    //   console.log("=REFETCH====================");
    //   dataProvider.refetch();
    // }

    switch (dataProvider.status) {
      case "success":
        setToken(dataProvider.data?.token);
        break;
      default:


        break;
    }
  }, [dataProvider]);

  // This effect is used to subscribe to the streaming when a token is available
  useEffect(() => {
    if (token) {
      //
      subscriptionProvider.subscribe(
        "onGetDevicePropertyTimeseriesAsyncResult",
        {
          token: token
        },
        onStreamedData
      );
    }
  }, [token]);


  return (<></>);
};


export default GetTimeseriesAsync;
