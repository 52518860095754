import React from "react";
import DevelopmentImageSvg from "./DevelopmentImage.svg";

const DevelopmentImage = (props) => {
    return (
        <img alt="developmentPage" src={DevelopmentImageSvg} className={props.className}/>
  );
};

export default DevelopmentImage;

