import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from 'react';
import { allowedShowComponents, useRolePermissions } from "../../aegeon-rbac/AuthRolePermissions";
import ChartLoading from '../../aegeon/charts/ChartLoading';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import DeviceGeneralInformationDetailsContentBottomSubDevices from './DeviceGeneralInformationDetailsContentBottomSubDevices';
import DeviceGeneralInformationDetailsContentLeft from './DeviceGeneralInformationDetailsContentLeft';
import DeviceGeneralInformationDetailsContentLeftBottom from './DeviceGeneralInformationDetailsContentLeftBottom';
import DeviceGeneralInformationDetailsContentRight from './DeviceGeneralInformationDetailsContentRight';

const DeviceGeneralInformationDetails = (props) => {
  const {
    isLoading,
  } = useQueryContext(props);
  const { isLoading: isLoadingPermissions, permissions } = useRolePermissions();


  if (isLoading === true) {
    return (
      <ChartLoading />
    );
  }

  const permissionControlledComponents = {
    resource: "devices.general_information",
    elements: {
      show: {
        subdevices: <DeviceGeneralInformationDetailsContentBottomSubDevices />
      }
    }
  };

  return (<div>
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <DeviceGeneralInformationDetailsContentLeft />
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <DeviceGeneralInformationDetailsContentRight />
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={12}>
        <DeviceGeneralInformationDetailsContentLeftBottom />
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={12}>
        {!isLoadingPermissions && allowedShowComponents(permissions, permissionControlledComponents)}
      </Grid2>
    </Grid2>
  </div>);
};

export default DeviceGeneralInformationDetails;

