import useGetTimeseriesProvider from "./useGetTimeseriesProvider";

const moment = require('moment');

const defaultFilter = { startDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), endDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(7, 'days') };
// const defaultAggregate = {};

const useTimeseriesController = (resource, params = {}) => {
    const {
        record,
        propertyName,
        filter = defaultFilter,
        aggregation
    } = params;

    const deviceId = resource.id;
    //
    const property = resource.properties.filter(p => p?.name === propertyName);

    const propertyId = property[0]?.id;

    const {
        timeseries,
        unit,
        error,
        // refetch,
        isFetching,
        isLoading } = useGetTimeseriesProvider(resource,
            {
                deviceId,
                propertyId,
                filter,
                aggregation
            },
            {
                staleTime: Infinity,
                cacheTime: Infinity,
            });

    return {
        resource: resource,
        timeseries: timeseries,
        unit: unit,
        error: error,
        isFetching: isFetching,
        isLoading: isLoading,
        // refetch
    };
}

export default useTimeseriesController;
