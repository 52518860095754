import PropTypes from 'prop-types';
import * as React from 'react';
import globalUseStyles from '../styles/globalCustomStyles';
import CustomAppBar from './CustomAppBar';
import CustomSidebar from './CustomSidebar';

const CustomLayout = ({ children }) => {
    const globalClasses = globalUseStyles();
    const [open, setOpen] = React.useState(window.innerWidth < 1050 ? false : false);

    return (
        <div id="layout-root" className={globalClasses.layoutRoot}>
            <div className={globalClasses.layoutAppFrame}>
                <div className={globalClasses.layoutContentWithSidebar}>
                    <div className={globalClasses.layoutSidebar}>
                        <CustomSidebar setOpen={setOpen} />
                    </div>
                </div>

                <div className={globalClasses.layoutContent} style={{ marginLeft: open ? "400px" : "112px" }}>
                    <div className={globalClasses.layoutContentWithAppBar}>
                        <CustomAppBar />
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

CustomLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default CustomLayout;