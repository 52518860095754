import algoliasearch from 'algoliasearch/lite';
import React, { useState, useEffect } from 'react';
import { Auth } from "aws-amplify";
import {
  InstantSearch,
  Configure,
  Hits,
  SearchBox,
  Panel,
  RefinementList,
  Pagination,
  Highlight,
  connectHits,
  connectSearchBox,
} from 'react-instantsearch-dom';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useTranslate } from 'react-admin';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography } from "@mui/material";
import globalUseStyles from '../../styles/globalCustomStyles';
import BackButton from "../Buttons/BackButton";
import SearchResultCard from './SearchResultCard';
import { useGetList } from "react-admin";
import { filter } from "lodash";

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
const DEBOUNCE_TIME = 400;

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

const createURL = state => `?${qs.stringify(state)}`;
const searchStateToUrl = searchState =>
  searchState ? createURL(searchState) : '';
const urlToSearchState = ({ search }) => qs.parse(search.slice(1));


function SearchPageResult(props) {
  const { data: devices } = useGetList(
    'devices'
  );

  const { data: sites } = useGetList(
    'sites'
  );

  const [instantSearchIndexName, setInstantSearchIndexName] = useState("");
  let location = window.location;
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  let navigate = useNavigate();
  const debouncedSetStateRef = useRef(null);
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [availableResources, setAvailableResources] = useState([]);

  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(updatedSearchState));
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  // Get the user group
  useEffect(() => {
    const fetchUserGroup = async () => {
      let index = await Auth.currentAuthenticatedUser().then(
        (data) => data.signInUserSession.accessToken.payload["cognito:groups"]
      );
      index = index.filter((i) => !i.startsWith("role_"));
      setInstantSearchIndexName(index[0]);
    };
    fetchUserGroup().catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  useEffect(() => {
    const availableResources = filter(devices, (device) => device.id !== null).concat(filter(sites, (site) => site.id !== null));
    setAvailableResources(availableResources);
  }, [devices, sites]);

  const Hits = ({ hits }) => (
    <Grid2
      className={globalClasses.grid}
      container
      spacing={4}
    >
      {hits.map(hit => (
        <Grid2 item="true" small={12} medium={6} big={4} >
          <SearchResultCard hit={hit} />
        </Grid2>
      ))}
    </Grid2>
  );


  const CustomHits = connectHits(({ hits }) => (
    <Hits hits={hits} />
  ));

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={instantSearchIndexName}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <SearchBox
        translations={{
          placeholder: t("search.placeholder"),
        }}
      />
      <div className={globalClasses.pageCard} style={{ marginTop: "1.2em", transform: "translateY(-50px)" }}>
        <BackButton />
        <div className={globalClasses.pageTitle} >
          <Typography variant="h4">{t('search.searchPage.title')}</Typography>
        </div>
        <div className={globalClasses.pagination}>
          <Pagination />
        </div>
        <div className={globalClasses.pageSubTitle} >
          <Typography variant="h5" >{""}</Typography>
        </div>
        <div className={globalClasses.pageContent} style={{ width: "100%" }}>
          <div>
            <div className={globalClasses.grid}>
              <Configure hitsPerPage={9} filters={`${availableResources.map((resource) => `objectID:${resource.id}`).join(" OR ")}`} />
              <div>
                <div>
                  <CustomHits />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </InstantSearch>
  );
}

export default SearchPageResult;
