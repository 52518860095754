import { IconButton, Typography } from "@mui/material";
import React from 'react';
import { Link, useTranslate } from 'react-admin';
import { useLocation, useNavigate } from "react-router-dom";
import BackButtonIcon from '../../assets/BackButtonIcon';
import globalUseStyles from "../../styles/globalCustomStyles";

const BackButton = (props) => {
    let navigate = useNavigate();
    const location = useLocation()
    const globalClasses = globalUseStyles();
    const t = useTranslate();
    const record = props.record;
    const item_name = record?.name;

    const getBreadCrumbWithLinks = () => {
        let path = location.pathname.split("/")
        let resource_name = path[1]
        let breadCrumb = []

        breadCrumb.push({ name: t(`resources.home.name`), link: "/" })
        breadCrumb.push({ name: t(`resources.${resource_name}.name`), link: `/${resource_name}` })
        if (path.length > 3) {
            breadCrumb.push({ name: t(`${resource_name}.title`) + (item_name ? ` ${item_name}` : ""), link: `/${resource_name}/${path[2]}/show` })
            for (let i = 4; i < path.length; i++) {
                let path_name = t(`${resource_name}.${path[i]}.title`)
                if (path_name !== `${resource_name}.${path[i]}.title`) {
                    breadCrumb.push({ name: path_name, link: `/${resource_name}/${path[2]}/${path[i]}` })
                }
            }
        }
        else if (path.length > 2) {
            breadCrumb.push({ name: t(`${resource_name}.${path[path.length - 1]}.title`), link: `/${resource_name}/${path[2]}` })
        }

        return breadCrumb
    }


    const breadCrumbs = getBreadCrumbWithLinks()

    const goBack = () => {
        if (location.pathname.split("/").length > 3) {
            if (location.pathname.split("/").slice(-1)[0] === "show") {
                let newPath = location.pathname.split("/").slice(0, -2).join("/")
                navigate(newPath)
            }
            else {
                let newPath = location.pathname.split("/").slice(0, -1).join("/")
                navigate(newPath)
            }
        }
        else if (location.pathname.split("/").length === 3) {
            let newPath = location.pathname.split("/").slice(0, -1).join("/")
            navigate(newPath)
        }
        else {
            navigate("/")
        }
    }


    return (
        <div className={globalClasses.BackButtonCustom}>
            <IconButton onClick={() => goBack()} className={globalClasses.BackButton}>
                <BackButtonIcon />
            </IconButton>
            {breadCrumbs.map((crumb, index) => {
                return (
                    <div key={index}>
                        <Link to={crumb.link} className={globalClasses.BackButtonLink}>
                            <Typography variant="h9" className={globalClasses.BackButtonTitle}>
                                {crumb.name}
                            </Typography>
                        </Link>
                        {index !== breadCrumbs.length - 1 && <span>&nbsp;{"›"}&nbsp;</span>}
                    </div>

                )
            }
            )}
        </div>
    );
};

export default BackButton;