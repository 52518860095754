import { faFloppyDisk, faPenToSquare, faTrash, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';

import { DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid';

const QualityDataGrid = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const { onProcessRowUpdate, onProcessRowDelete } = props;
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState(props.rows);


  const handleDeleteClick = (id) => () => {
    const row = rows.find((row) => row.id === id);
    onProcessRowDelete(row);
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const actionsColumns = ({ id }) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    const isOutdated = rows.find((row) => row.id === id).outdated === true;

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faFloppyDisk} />}
          label="Save"
          sx={{
            color: 'primary.main',
          }}
          onClick={handleSaveClick(id)}
        />,
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faXmark} />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          color="inherit"
        />,
      ];
    }
    else if (isOutdated) {
      return [
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faPenToSquare} />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faTrash} />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    }

    return [
      <GridActionsCellItem
        icon={<FontAwesomeIcon icon={faPenToSquare} />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id)}
        color="inherit"
      />
    ];
  };

  const available_columns = [
    {
      field: 'startDate',
      headerName: t('devices.quality.stats.datagrid.start_date'),
      type: "date",
      width: 120
    },
    {
      field: 'endDate',
      headerName: t('devices.quality.stats.datagrid.end_date'),
      type: "date",
      width: 120
    },
    {
      field: 'type',
      headerName: t('devices.quality.stats.datagrid.type'),
      type: "string",
      width: 180,
    },
    {
      field: 'reviewed',
      headerName: t('devices.quality.stats.datagrid.reviewed'),
      editable: true,
      type: "boolean",
      width: 180,
    },
    {
      field: 'reasoning',
      headerName: t('devices.quality.stats.datagrid.reasoning'),
      editable: true,
      type: "string",
      flex: 0.3,
      minWidth: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('devices.quality.stats.datagrid.actions'),
      width: 100,
      cellClassName: 'actions',
      getActions: actionsColumns
    },
  ];

  let columns = props.columns.map((column) => {
    return available_columns.find((available_column) => available_column.field === column);
  }
  );

  // Add Actions
  columns.push(available_columns[available_columns.length - 1]);
  // remove undefied columns
  columns = columns.filter((column) => column !== undefined);

  columns.forEach((column) => {
    if (column.field === "type") {
      column.valueFormatter = (params) => {
        return t('devices.quality.stats.types.' + params.value);
      }
    }
  });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      editMode='row'
      rowModesModel={rowModesModel}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={(updatedRow, originalRow) => onProcessRowUpdate(updatedRow, originalRow)}
      onRowModesModelChange={handleRowModesModelChange}
      getRowClassName={(params) => globalClasses[`dataGridQualityStats${params.row.outdated ? 'Outdated' : params.row.reviewed ? 'Reasoned' : 'Actual'}`]}
    />
  )
};

export default QualityDataGrid;
