import React from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CardWrapper from '../../aegeon/cards/CardWrapper';
import SiteComposition from '../../sites/Cards/SiteComposition';

const CardComposition = () => {
  const globalClasses = globalUseStyles();
  return (
    <CardWrapper resource="sites" type="composition">
      <SiteComposition />
    </CardWrapper>
  )
};

export default CardComposition;

