import React from 'react';

import { useState } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CardWrapper from "../../aegeon/cards/CardWrapper";
import QueryInfiniteField from "../../aegeon/query/QueryInfiniteField";
import ChartSiteTable from './ChartSiteTable';

export default function CardTable(props) {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const [subtitle, setSubtitle] = useState("");

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitle(t("sites.table.subtitle") + childData);
    }
  };

  const moment = require('moment');
  const startDate = moment().set({ year: 2019, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  return (
    <CardWrapper resource="sites" type="table" subtitleCallBack={subtitle} >
      <QueryInfiniteField query_name="getSiteTimeseries" params={{ time: { between: [startDate, endDate] } }}>
        <ChartSiteTable displayButton={false} groupBy="trimester" className={globalClasses.CardTable} border={false} setSubtitleCallback={handleSetSubtitleCallback} />
      </QueryInfiniteField>
    </CardWrapper>
  );
}