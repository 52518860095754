import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-admin';
import ExclamationTooltipIcon from '../../assets/ExclamationTooltipIcon';
import InfoTooltipIcon from '../../assets/InfoTooltipIcon';
import globalUseStyles from '../../styles/globalCustomStyles';
import CustomTooltip from '../aegeon/CustomTooltip';


const CustomTableRow = (props) => {
    const classes = globalUseStyles();
    const t = useTranslate();
    const row = props.row;
    const index = props.index;
    const leftSizeBigger = props.leftSizeBigger;
    const defaultTooltip = props.defaultTooltip ? t(props.defaultTooltip) : "";

    const is_value = row.value !== null && row.value !== undefined && row.value !== ""

    const tableRowStyle = `${classes.tableRow}`
    const cellStyleName = `${classes.tableCell} ${classes.tableCellLeft} ${leftSizeBigger ? classes.tableCellBigger : classes.tableCellEqualSize} ${(row.tooltip_with_value && row.tooltip) || !is_value ? classes.tableNoData : ''}`
    const cellStyleValue = `${classes.tableCell} ${classes.tableCellRight} ${(row.tooltip_with_value && row.tooltip) || !is_value ? classes.tableNoData : ''}`

    const value = (row.value && typeof row.value === 'string' && row.value.includes('\n') ? row.value.split('\n').map((item, key) => {
        return <span key={key} className={classes.tableCellMultipleLines}>{item}<br /></span>
    }) : row.value)


    const CellRight = () => {
        return (
            (row.tooltip_with_value && row.tooltip) || (!is_value && !row.hide_if_null) ?
                <TableCell className={cellStyleValue}>
                    <div className={classes.tableCellNoData}>
                        <div className={classes.tableCellOneLine}>
                            {row.value}
                        </div>
                        <CustomTooltip title={(row.tooltip && t(row.tooltip) !== row.tooltip) ? t(row.tooltip) : defaultTooltip} icon={<ExclamationTooltipIcon />} />
                    </div>
                </TableCell>
                :
                <TableCell className={cellStyleValue}>
                    <div className={classes.tableCellWithIcon}>
                        <div className={classes.tableCellOneLine}>
                            {value}
                        </div>
                        {(row.path) &&
                            <CustomTooltip title={t(row.tooltip_link)} clickable={true} path={row.path} externalLink={row.externalLink} icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} className={classes.tableCellIcon} />} />}
                        {(row.tooltip_info) &&
                            <CustomTooltip title={(row.tooltip_info && t(row.tooltip_info) !== row.tooltip_info) ? t(row.tooltip_info) : ""} icon={<InfoTooltipIcon />} />
                        }
                    </div>
                </TableCell>
        )
    }

    return (
        (row.hide_if_null && !row.value) ?
            <></> :
            <TableRow key={index} className={tableRowStyle}>
                <TableCell className={cellStyleName}>{row.name}</TableCell>
                <CellRight />
            </TableRow>
    )
};

export default CustomTableRow;