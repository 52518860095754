import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from 'react';

import SiteCompositionDetailsContentDevices from './SiteCompositionDetailsContentDevices';
import SiteCompositionDetailsContentFunctionalEntities from './SiteCompositionDetailsContentFunctionalEntities';

const SiteCompositionDetails = (props) => {

    return (
        <Grid2 container spacing={2}>
            <Grid2 item="true" small={12} medium={12} big={12}>
                <SiteCompositionDetailsContentFunctionalEntities />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={12}>
                <SiteCompositionDetailsContentDevices />
            </Grid2>
        </Grid2>)
}

export default SiteCompositionDetails
