// import { API, GRAPHQL_AUTH_MODE, graphqlOperation } from "aws-amplify/api";
import { API, GRAPHQL_AUTH_MODE, graphqlOperation } from "@aws-amplify/api";
const defaultOptions = {
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  enableAdminQueries: false,
};

class SubscriptionDataProvider {
  static queries;
  static mutations;
  static subscriptions;
  static models;

  static authMode;
  static enableAdminQueries;

  constructor(operations, options) {
    this.queries = operations.queries;
    this.mutations = operations.mutations;
    this.subscriptions = operations.subscriptions;

    this.authMode = options?.authMode || defaultOptions.authMode;
  };

  subscribe = async (subscriptionn_name, params, callback) => {

    const subscription_query = this.getSubscriptionByName(subscriptionn_name);

    // Subscribe to creation of Todo
    const subscrition = API.graphql(
      graphqlOperation(subscription_query, params)
    ).subscribe({
      next: ({ provider, value }) => callback(provider, value),
      error: (error) => console.warn(error)
    });

    return subscrition
  };

  getSubscriptionByName(subscriptionName) {
    if (this.queries[subscriptionName]) {
      return this.queries[subscriptionName];
    }

    if (this.mutations[subscriptionName]) {
      return this.mutations[subscriptionName];
    }

    if (this.subscriptions[subscriptionName]) {
      return this.subscriptions[subscriptionName];
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`Could not find query ${subscriptionName}`);
    }

    throw new Error("Data provider error");
  }

  getSubscriptionName(operation, resource) {
    const pluralOperations = ["list"];
    if (pluralOperations.includes(operation)) {
      return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1)}`;
    }
    // else singular operations ["create", "delete", "get", "update"]
    return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1, -1)}`;
  }

  getQueryNameMany(operation, resource, target) {
    const subscriptionName = this.getSubscriptionName(operation, resource);

    return `${subscriptionName}By${target.charAt(0).toUpperCase() + target.slice(1, -2)}Id`;
  }

  async graphql(query, variables) {
    const queryResult = await API.graphql({ query, variables, authMode: this.authMode });

    if (queryResult.errors || !queryResult.data) {
      throw new Error("Data provider error");
    }

    return queryResult.data;
  }
}


export default SubscriptionDataProvider;