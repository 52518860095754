// Lib
import { default as React } from "react";

// Components
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ErrorImage from "../../../assets/ErrorImage";

const texts = {
    title_1: "Oups !",
    title_2: "Il y a eu une erreur.",
    paragraph: "Nous faisons tout notre possible pour résoudre ce problème. Veuillez réessayer plus tard.",
    button: "Retour à l'accueil",
}

var styles = {
    developmentPage: {
        marginLeft: "30px",
        height: "calc(100vh - 180px)",
        position: "relative",
    },
    developmentText: {
        position: "absolute",
        top: "calc(50% - 180px)",
        left: "0",
        zIndex: "1",
        "@media (max-width: 1700px)": {
            maxWidth: "350px",
        },
        "@media (max-width: 1300px)": {
            position: "relative",
        },
    },
    developmentTitle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px",
    },
    developmentParagraph: {
        paddingTop: "27px",
        paddingBottom: "27px",
        lineHeight: "19.5px",
    },
    developmentButton: {
        '& .MuiTypography-root': {
            fontSize: "18px",
        },
        height: "40px",
        backgroundColor: "#71B9A1",
        borderRadius: "32px",
        color: "white",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        padding: "16px 32px",
    },
    developmentImage: {
        position: "absolute",
        top: "calc(50% - 214px)",
        right: "0",
        "@media (max-width: 1300px)": {
            position: "relative",
        },
    },
    developmentImg: {
        "@media (max-width: 1700px)": {
            marginTop: "76px",
            width: "600px",
        },
        "@media (max-width: 1400px)": {
            width: "450px",
        },
        "@media (max-width: 1300px)": {
            width: "350px",
        },
    },
    h3: {
        textAlign: "left",
        margin: "0",
        fontWeight: "600",
        fontSize: "20px",
        fontFamily: 'Montserrat',
        color: "white",
    },
    h6: {
        fontWeight: "400",
        fontSize: "16px",
        fontFamily: 'Montserrat',
    },
    h10: {
        fontWeight: "600",
        fontSize: "70px",
        fontFamily: 'Montserrat',
        color: "#5CFFBD",
        lineHeight: "80px",
    },
    h11: {
        fontWeight: "600",
        fontSize: "40px",
        fontFamily: 'Montserrat',
        color: "#1B232A",
        lineHeight: "49px",
    },
}

const ErrorPage = () => {
    const handleClick = () => {
        window.location.href = "/";
    }
    return (
        <div style={styles.developmentPage}>
            <div style={styles.developmentText}>
                <div style={styles.developmentTitle}>
                    <Typography style={styles.h10}>{texts.title_1}</Typography>
                    <Typography style={styles.h11}>{texts.title_2}</Typography>
                </div>
                <div style={styles.developmentParagraph}>
                    <Typography style={styles.h6}>{texts.paragraph}</Typography>
                </div>
                <IconButton onClick={handleClick} style={styles.developmentButton}>
                    <Typography style={styles.h3}>{texts.button}</Typography>
                </IconButton>
            </div>
            <div style={styles.developmentImage}>
                <ErrorImage style={styles.developmentImg} />
            </div>
        </div>
    );
};

export default ErrorPage;