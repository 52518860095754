import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../styles/globalCustomStyles';

const BottomButton = (props) => {
    const globalClasses = globalUseStyles();
    const t = useTranslate();

    const label = props.label;
    const tooltip_label = props.tooltip_label;
    const button_classname = props.className ? props.className : globalClasses.customButton;

    const handleClick = () => {
        props.onClick(label);
    }

    return (
        <Tooltip title={tooltip_label} classes={{ popper: globalClasses.tooltip }} placement="right">
            {props.disabled ?
                <div className={globalClasses.tooltip}>
                    <IconButton disabled className={button_classname}>
                        <Typography variant="h3" color="white !important">{label}</Typography>
                    </IconButton>
                </div>
                :
                <IconButton onClick={handleClick} className={button_classname}>
                    <Typography variant="h3" color="white !important">{label}</Typography>
                </IconButton>
            }
        </Tooltip>
    )
}

export default BottomButton;
