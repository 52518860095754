import React from 'react';
import globalUseStyles from '../../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import CustomTable from '../../../Table/CustomTable';


const SiteCompositionDetailsContentFunctionalEntities = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const record = useRecordContext();

  const functional_entities_data = record?.airtable?.functional_entities ? record?.airtable?.functional_entities : [];
  let functional_entities_data_filtered = functional_entities_data.filter((ef) => (
    ef.tertiary === "OUI")
  )
  let surface_total = functional_entities_data_filtered.reduce((a, b) => a + (b['surface'] || 0), 0);

  let activities_weights = {
    "BUREAUX": 0,
    "ACTIVITE": 1,
    "COMMERCE": 2,
  }

  functional_entities_data_filtered.sort((a, b) => {
    let activity_a = activities_weights[a.type] ? activities_weights[a.type] : 3;
    let activity_b = activities_weights[b.type] ? activities_weights[b.type] : 3;
    if (activity_a < activity_b) {
      return 1;
    }
    if (activity_a > activity_b) {
      return -1;
    }
    let tenant_a = a.tenant_name ? a.tenant_name : a.tenant_code;
    let tenant_b = b.tenant_name ? b.tenant_name : b.tenant_code;
    if (tenant_a < tenant_b) {
      return -1;
    }
    if (tenant_a > tenant_b) {
      return 1;
    }
    return 0;
  })

  const rows = [{
    values: [
      t('sites.composition.ef_tenant_code'),
      t('sites.composition.ef_tenant'),
      t('sites.composition.ef_surface') + " (Total : " + surface_total + ")",
      t('sites.composition.ef_lots'),
      // t('sites.composition.ef_start_date'),
      t('sites.composition.ef_activity'),
      ''
    ]
  }]
  functional_entities_data_filtered.forEach((ef) => {
    let lots = ef.lots?.split(',').join(',\n')
    rows.push({
      values: [
        ef.tenant_code,
        ef.tenant_name ? ef.tenant_name : ef.tenant_code,
        ef.surface ? ef.surface.toFixed(2).replace(/\.?0+$/, "") : '',
        lots,
        ef.type,
        ''
      ],
    })
  })

  return (
    <Card className={globalClasses.DetailCard}>
      <Typography display="block" variant="h8">{t('sites.composition.functional_entities_title') + " (" + functional_entities_data_filtered.length + ")"}</Typography>
      <div className={globalClasses.DetailCardTableSubtitle} />
      {rows.length === 1 ? <></> :
        <CustomTable list={true} rows={rows} defaultTooltip={"sites.no_data_tooltip"} />}
    </Card>
  );
};

export default SiteCompositionDetailsContentFunctionalEntities;