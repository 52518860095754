import React from 'react';
import globalUseStyles from '../../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../../assets/InfoTooltipIcon';
import CustomTable from '../../../Table/CustomTable';
import CustomTooltip from '../../../aegeon/CustomTooltip';
import ChartLoading from '../../../aegeon/charts/ChartLoading';
import { convert_array_to_string, filter_and_sort_devices } from '../../../aegeon/helper';
import DeviceTypeIcon from '../../../devices/CustomComponents/DeviceTypeIcon';

const SiteCompositionDetailsContentDevices = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const record = useRecordContext();
  const siteId = record?.id;

  const { data, isLoading } = useGetList(
    'devices',
    {
      filter: {
        siteIds: { contains: siteId },
      },
    }
  );


  if (isLoading === true) {
    return (
      <ChartLoading />
    );
  }

  let devices_filtered = filter_and_sort_devices(data);

  const rows = [{ name: t('sites.composition.device_code'), values: [t('sites.composition.device_destination'), t('sites.composition.device_quality'), t('sites.composition.device_usage'), t("sites.composition.device_tenants"), t("sites.composition.device_tenth"), ''] }]
  devices_filtered.forEach((device) => {
    let destination = device.airtable?.destination ? device.airtable?.destination : "inconnue";
    let quality = device.fusionedEnergyQuality ? device.fusionedEnergyQuality : "";
    let usage = device.airtable?.usage ? convert_array_to_string(device.airtable?.usage) : "";
    let tenants = device.airtable?.functional_entities ? device.airtable?.functional_entities.map(ef => ef.tenant_name) : [];

    let site_tenth = device.airtable?.building_tenths?.find(tenth => tenth.site_code === record?.airtable?.code)?.tenth;

    // Drop null values in tenants
    tenants = tenants.filter((tenant) => tenant);
    tenants = [...new Set(tenants)].join(', ');
    rows.push({
      name: device.name,
      icon: <DeviceTypeIcon deviceType={device.deviceType} />,
      icon_tooltip: t("devices.deviceTypes." + device.deviceType),
      values: [destination, quality, usage, tenants, site_tenth, ''],
      path: "/devices/" + device.id + "/show",
      tooltip_link: "sites.composition.device_tooltip_link"
    })
  })

  return (
    <Card className={globalClasses.DetailCard}>
      <div className={globalClasses.DetailCardTableTitle}>
        <Typography display="block" variant="h8">{t('sites.composition.devices_title') + " (" + devices_filtered.length + ")"}</Typography>
        <CustomTooltip title={t('sites.composition.devices_tooltip')} icon={<InfoTooltipIcon />} />
      </div>
      <div className={globalClasses.DetailCardTableSubtitle} />
      <CustomTable list={true} rows={rows} defaultTooltip={"sites.no_data_tooltip"} />
    </Card>
  );
};

export default SiteCompositionDetailsContentDevices;