import React, { useContext } from 'react';
import { RecordContext } from '../../../App';
import globalUseStyles from '../../../styles/globalCustomStyles';
import QueryField from '../../aegeon/query/QueryField';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import TableSubdevicesComponent from './TableSubdevicesComponent';

const TableSubdevicesCard = (props) => {
  const moment = require('moment');
  const globalClasses = globalUseStyles();
  const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const startDate10years = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const startDateOneYear = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter_all_data = { startDate: startDate10years, endDate: today };

  const source = {
    source: 'billed_energy',
    filter: filter_all_data,
    aggregation: { period: 'MONTH', operation: 'SUM' },

  };
  const { record } = useContext(RecordContext);

  return (
    <TimeseriesField source={source.source} filter={source.filter} aggregation={source.aggregation}>
      <QueryField query_name="getDeviceSubdevicesTimeseries" params={{ id: record?.id, time: { between: [startDateOneYear, today] } }}>
        <TableSubdevicesComponent setDataSourcesCallback={props.setDataSourcesCallback} record={record} source={source} />
      </QueryField>
    </TimeseriesField>
  )
};

export default TableSubdevicesCard;