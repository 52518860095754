import { Typography } from '@mui/material';
import { useNotify } from 'ra-core';
import React, { useCallback, useContext } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import useMutationProvider from '../../aegeon/mutation/useMutationProvider';
import { useQueryContext } from '../../aegeon/query/useQueryContext';


import QualityDataGrid from './QualityDataGrid';
function transformMissingValues(params) {
  var hash = require('object-hash');

  // Test if params is a list or a dict
  if (typeof params === 'object') {
    // If params is a array

    if (Array.isArray(params)) {

      if (params.length === 0) {
        return ["", []];
      }

      params.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });

      // Create an array of objects with startDate, endDate, uncounted, text as keys
      const rows = params.reduce((acc, curr, index) => {
        const date = new Date(curr);
        if (index === 0) {
          acc = [];
          let item = {
            startDate: date,
            endDate: date,
            type: 'missing_value'

          };
          const id = hash(item);
          item.id = id;
          item.reviewed = false;
          item.reasoning = '';
          acc.push(item);

        } else {
          const last = acc[acc.length - 1];
          const diff = date - last.endDate;
          if (diff === 86400000) {
            last.endDate = date;
            const id = hash({ startDate: last.startDate, endDate: last.endDate, type: last.type })
            last.id = id;
          } else {
            let item = {
              startDate: date,
              endDate: date,
              type: 'missing_value'
            };
            const id = hash(item);
            item.id = id;
            item.reviewed = false;
            item.reasoning = '';
            acc.push(item);
          }
        }
        return acc;
      }, 0);

      return [Object.keys(rows[0]), rows];
    }
  }
  return ["", []];
}

function transformOutliers(params) {
  var hash = require('object-hash');
  // Test if params is a list or a dict
  if (typeof params === 'object') {
    // If params is a array

    if (Array.isArray(params)) {

      if (params.length === 0) {
        return ["", []];
      }

      params.sort((a, b) => {
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);
        return dateA - dateB;
      });

      // Create an array of objects with startDate, endDate, uncounted, text as keys
      const rows = params.reduce(function (acc, curr, index) {
        const date = new Date(curr.timestamp);
        if (index === 0) {
          acc = [];
          let item = {
            startDate: date,
            endDate: date,
            value: curr.value,
            type: 'outlier'
          };
          const id = hash(item);
          item.id = id;
          item.reviewed = false;
          item.reasoning = '';
          acc.push(item);
        } else {
          const last = acc[acc.length - 1];
          const diff = date - last.endDate;
          if (diff === 86400000) {
            last.endDate = date;
            last.value = curr.value + last.value;
            const id = hash({ startDate: last.startDate, endDate: last.endDate, value: last.value, type: last.type })
            last.id = id;
          } else {
            let item = {
              startDate: date,
              endDate: date,
              value: curr.value,
              type: 'outlier'
            };
            const id = hash(item);
            item.id = id;
            item.reviewed = false;
            item.reasoning = '';
            acc.push(item);
          }
        }
        return acc;
      }, 0);
      return [Object.keys(rows[0]), rows];
    }
  }
  return ["", []];
}

const QualityCardDetailsStats = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const notify = useNotify();
  const { record } = useContext(RecordContext);
  const { isLoading, data } = useQueryContext(props);

  const property = record?.properties?.find((property) => property.name === 'fusioned_energy');
  const missingValues = property?.quality?.missing_values;
  const outliers = property?.quality?.outliers;
  const [missingValuesColumns, missingValuesRows] = transformMissingValues(missingValues);
  const [outliersColumns, outliersRows] = transformOutliers(outliers);
  const onSuccessCallback = (data, variables, context) => {
    console.log(data);
    console.log(variables);
    console.log(context);
    notify(t("devices.quality.stats.notify.success"), { type: "success" });
    return variables.input;
  };
  const mutation = useMutationProvider('updateDeviceQualityReasoning', { id: record?.id, params: { input: {} } }, undefined, onSuccessCallback)
  const delete_mutation = useMutationProvider('deleteDeviceQualityReasoning', { id: record?.id, params: { input: {} } }, undefined, onSuccessCallback)

  let Rows = [...missingValuesRows, ...outliersRows];
  const Columns = [...new Set([...missingValuesColumns, ...outliersColumns])];

  const useProcessRowUpdate = useCallback(
    async (newRow, oldRow) => {
      if (newRow.reviewed === oldRow.reviewed && newRow.reasoning === oldRow.reasoning) {
        return oldRow;
      }
      const input = {
        id: record?.id,
        reasoningType: newRow.type,
        reasoningId: newRow.id,
        reasoningText: newRow.reasoning,
        reviewed: newRow.reviewed,
        startDate: newRow.startDate,
        endDate: newRow.endDate
      };
      mutation.mutate({ mutation_name: "updateDeviceQualityReasoning", id: record?.id, input: input });
      return newRow;
    }, [mutation]);

  const useProcessRowDelete =
    (row) => {
      console.log(row);
      const input = {
        id: record?.id,
        reasoningType: row.type,
        reasoningId: row.id,
      };
      delete_mutation.mutate({ mutation_name: "deleteDeviceQualityReasoning", id: record?.id, input: input });
      return row;
    };

  if (isLoading) {
    return (<div>
      <ChartLoading />
    </div>);
  }

  Rows = Rows.map((row) => {
    const previous = data?.items?.find((reasoning) => {
      return reasoning.reasoningId === row.id;
    });
    if (previous) {
      row.reviewed = previous.reviewed;
      row.reasoning = previous.reasoningText;
      return row;
    }
    return row;
  });

  data.items.forEach((row) => {
    const previous = Rows.find((reasoning) => {
      return reasoning.id === row.reasoningId;
    });
    if (previous) {
      row.reviewed = previous.reviewed;
      row.reasoning = previous.reasoningText;
    }
    else {
      row.outdated = true;
      row.startDate = new Date(row.startDate);
      row.endDate = new Date(row.endDate);
      row.type = row.reasoningType;
      row.id = row.reasoningId;
      row.reasoning = row.reasoningText;
      Rows.push(row);
    }
  });


  if (Rows.length === 0) {
    return (
      <div style={{ marginTop: "20px" }}>
        <Typography variant="body1">
          {t('devices.quality.stats.no_reasoning')}
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <div className={globalClasses.DetailCardSecondTitle}>
        <Typography variant="h8">
          {t('devices.quality.stats.reasoning_quality')}
        </Typography>
      </div>
      <div className={globalClasses.QualityDataGrid}>
        <QualityDataGrid
          columns={Columns}
          rows={Rows}
          onProcessRowUpdate={useProcessRowUpdate}
          onProcessRowDelete={useProcessRowDelete} />
      </div>
    </div>
  );
};

export default QualityCardDetailsStats;
