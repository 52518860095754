import React from 'react';

import "./styles.css";

const ChartLoading = () => {
  return (
    <div className="container">
      <div className="dots"></div>
    </div>
  )
};

export default ChartLoading; 
