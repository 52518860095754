import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from 'react';

import { useGetList, useRecordContext } from 'react-admin';
import SiteMissingInformationDetailsContentLeft from './SiteMissingInformationDetailsContentLeft';
import SiteMissingInformationDetailsContentLeftBottom from './SiteMissingInformationDetailsContentLeftBottom';
import SiteMissingInformationDetailsContentRight from './SiteMissingInformationDetailsContentRight';
import SiteMissingInformationDetailsContentRightBottom from './SiteMissingInformationDetailsContentRightBottom';

const SiteMissingInformationDetailsContent = (props) => {
  const record = useRecordContext();
  const siteId = record?.id;
  const { data } = useGetList(
    'devices',
    {
      filter: {
        siteIds: { contains: siteId },
      },
    }
  );

  return (<div>
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <SiteMissingInformationDetailsContentLeft />
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <SiteMissingInformationDetailsContentRight devices={data} />
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <SiteMissingInformationDetailsContentLeftBottom devices={data} />
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6}>
        <SiteMissingInformationDetailsContentRightBottom devices={data} />
      </Grid2>
    </Grid2>
  </div>);
};

export default SiteMissingInformationDetailsContent;

