import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceTypeFuelIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m70,76.98c0-7.67-2.92-12.8-5.22-18.31-.38-.9-1.16-.89-1.53.03-2.21,5.57-5.26,10.6-5.26,18.28,0,6.92,3.31,11,6,11.02,2.69-.02,6-4.1,6-11.02Z"/>
      <path d="m94.25,69.19c1.66,0,3-1.34,3-3s-1.34-3-3-3h0V25.86h0c1.66,0,3-1.34,3-3s-1.34-3-3-3h-5.16v-2.92h-12.96v2.92h-42.38c-1.66,0-3,1.34-3,3s1.34,3,3,3v37.33c-1.66,0-3,1.34-3,3s1.34,3,3,3v36.83c-1.54.13-2.75,1.4-2.75,2.97,0,1.66,1.34,3,3,3h60c1.66,0,3-1.34,3-3,0-1.57-1.21-2.85-2.75-2.97v-36.83h0Zm-46.25,3.7c0-13.3,8.12-22.03,14.02-31.68.97-1.59,3.07-1.62,4.07-.05,6.12,9.55,13.91,18.43,13.91,31.74,0,12-8.84,19.07-16,19.11-7.16-.04-16-7.11-16-19.11Z"/>
    </SvgIcon>
  );
};

export default DeviceTypeFuelIcon;