import React, { useContext } from 'react';

import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CardWrapper from "../../aegeon/cards/CardWrapper";
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import ChartDeviceTable from './ChartDeviceTable';

export default function CardTable(props) {
  const globalClasses = globalUseStyles();
  const { source, filter } = props;
  const { record } = useContext(RecordContext);
  const unit = record?.properties?.find((property) => property?.name === source)?.unit
  const t = useTranslate();

  const [subtitle, setSubtitle] = useState("");

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitle(t("devices.dataTable.subtitle") + childData);
    }
  };

  return (
    <TimeseriesField source={source} filter={filter} aggregation={{ period: "MONTH", operation: "SUM" }}>
      <CardWrapper resource="devices" type="dataTable" subtitleCallBack={subtitle}>
        <ChartDeviceTable unit={unit} displayButton={false} groupBy="trimester" className={globalClasses.CardTable} border={false} setSubtitleCallback={handleSetSubtitleCallback} />
      </CardWrapper>
    </TimeseriesField>
  );
}