import { Card, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { allowedClickComponents, allowedShowComponents, useRolePermissions } from "../../aegeon-rbac/AuthRolePermissions";
import ChartActivePower from '../../aegeon/charts/ChartActivePower';
import ChartIndex from '../../aegeon/charts/ChartIndex';
import CustomTooltip from '../../aegeon/CustomTooltip';
import QueryField from '../../aegeon/query/QueryField';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import ChooseDateButton from '../../Buttons/ChooseDateButton';

const LineCardDetails = ({ setDataCallback }) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const moment = require('moment');

  const startDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(9, 'days');
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter = { startDate: startDate, endDate: endDate };

  const [powerFilter, setPowerFilter] = useState(filter);

  const startDateOneYear = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'year');
  const filterOneYear = { startDate: startDateOneYear, endDate: endDate };

  const { isLoading, permissions } = useRolePermissions();

  const [dataSources, setDataSources] = useState({});
  const setDataSourcesCallback = (childData, source) => {
    if (childData && !dataSources[source]) {
      setDataSources({ ...dataSources, [source]: childData });
    }
    else if (childData && dataSources[source] && source === "active_power" && (childData.length !== dataSources[source].length || childData?.[1]?.[0] !== dataSources[source]?.[1]?.[0])) {
      // Update the data if the timeseries has changed
      setDataSources({ ...dataSources, [source]: childData });
    }
  };

  const changeFilterDates = (dateRange) => {
    const filter = { startDate: dateRange[0], endDate: dateRange[1] };
    setPowerFilter(filter);
  }

  const resetFilterDates = () => {
    setPowerFilter(filter);
  }

  useEffect(() => {
    setDataCallback(dataSources);
  }, [dataSources, setDataCallback]);

  const ChartComponent = ({ type, line, stacked, monthAgg, conversionButton }) => {
    return (
      <Grid2 item="true" small={12} medium={12} big={6} key={type}>
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t(`devices.${type}.title`)}</Typography>
            {<CustomTooltip title={t(`devices.${type}.tooltip_text`)} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t(`devices.${type}.subtitle`)}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <TimeseriesField source={type === "index_substracted" ? "index" : type} filter={filterOneYear}>
              <ChartIndex
                line={line}
                stacked={stacked}
                type={type}
                setDataCallback={setDataSourcesCallback}
                conversionButton={conversionButton}
                monthAgg={monthAgg}
              />
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
    )
  }

  const permissionControlledComponentsCharts = {
    resource: "devices",
    elements: {
      show: {
        "active_power.index": <ChartComponent type="index" line={true} stacked={true} />,
        "active_power.index_substracted": <ChartComponent type="index_substracted" line={true} stacked={true} conversionButton={true} monthAgg={true} />,
        "active_power.energy": <ChartComponent type="energy" line={true} stacked={false} conversionButton={true} monthAgg={true} />,
      }
    }
  };

  const permissionControlledButtons = {
    resource: "devices",
    elements: {
      click: {
        "active_power.date_picker": <ChooseDateButton onDateChange={changeFilterDates} reset={resetFilterDates} maxDaysRange={30} startDate={powerFilter.startDate} endDate={powerFilter.endDate} />
      }
    }
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={12} medium={12} big={12} key="1">
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.power.detailledTitle')}</Typography>
            {<CustomTooltip title={t('devices.power.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.power.subtitle')}
          </div>
          <div style={{ width: "100%", height: "550px" }} >
            {allowedClickComponents(permissions, permissionControlledButtons)}
            <TimeseriesField source={"active_power"} filter={powerFilter}>
              <QueryField query_name='getDeviceContract' >
                <ChartActivePower
                  isWeekendsHighlight={true}
                  isNightsHighlight={true}
                  isDetailedCard={true}
                  setLineDataCallback={setDataSourcesCallback}
                  isZoom={true}
                  isButtonBefore={true}
                />
              </QueryField>
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
      {!isLoading && allowedShowComponents(permissions, permissionControlledComponentsCharts)}
    </Grid2>
  )
};

export default LineCardDetails;

