import { Menu as MenuReactAdmin } from "react-admin";
import { useRolePermissions } from "./AuthRolePermissions";


const Menu = (props) => {
    const { isLoading, permissions } = useRolePermissions();

    if (isLoading) {
        return null;
    }

    return (
        <MenuReactAdmin {...props} >
            {props.children}
        </MenuReactAdmin>
    )
}

export default Menu;