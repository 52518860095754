import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import { useTimeseriesContext } from '../timeseries/useTimeseriesContext';
import ChartLoading from './ChartLoading';
import ChartNoData from './ChartNoData';
const ChartProfile = (props) => {

  const {
    timeseries,
    isLoading,
  } = useTimeseriesContext(props);

  const {
    setSerieCallback,
    isZoom
  } = props;

  const { record } = useContext(RecordContext);

  const [options, setOptions] = useState(null);
  const t = useTranslate();

  const one_day = 24 * 60 * 60 * 1000;
  const two_hours = 2 * 60 * 60 * 1000;

  // Nota : the ticks on the xAxis are not always on the 00:00:00 because they are randomly chosen

  const groupTimeseries = (timeseries, group) => {
    if (!timeseries || timeseries.length === 0) return [];
    let groupedSerie = [];
    let previous_item = {};

    let timeseriesSorted = timeseries.sort((a, b) => {
      return new Date(a.time) - new Date(b.time);
    })

    timeseriesSorted?.forEach((element, index) => {
      const time = new Date(element.time).getTime();
      if (index === 0) {
        previous_item = Object.assign({}, element);
        previous_item.value = 1;
        previous_item.start_date = time;
        previous_item.end_date = time + one_day;
      }
      else {
        if (Math.abs(time - new Date(previous_item.end_date).getTime()) < two_hours && element[group] === previous_item[group]) {
          previous_item.value = previous_item.value + 1;
          previous_item.end_date = time + one_day;
        } else {
          groupedSerie.push(previous_item);
          previous_item = Object.assign({}, element);
          previous_item.value = 1;
          previous_item.end_date = time + one_day;
          previous_item.start_date = time;
        }
      }
    })
    groupedSerie.push(previous_item);
    return groupedSerie;
  }

  var types = record?.deviceType === "grdfMeter" ? {
    "serie_aegeon": { name: `${t("devices.sources.serie_aegeon")}`, color: "#044742" },
    "grdf_billed_energy": { name: `${t("devices.sources.grdf_billed_energy")}`, color: "#4ecca2" },
    "grdf_informative_energy": { name: `${t("devices.sources.grdf_informative_energy")}`, color: "#6c74d8" },
    "airtable_manual_bills": { name: `${t("devices.sources.manual_bills")}`, color: "#d6ad56" },
    "third_party_energy": { name: `${t("devices.sources.third_party_energy")}`, color: "#5cd1ff" },
    "third_party_active_power_in_kwh": { name: `${t("devices.sources.third_party_active_power_in_kwh")}`, color: "#5da4d9" },
    "default": { name: "Non défini", color: "#d9d9d9" },
  } :
    {
      "serie_aegeon": { name: `${t("devices.sources.serie_aegeon")}`, color: "#044742" },
      "enedis_billed_energy": { name: `${t("devices.sources.enedis_billed_energy")}`, color: "#4ecca2" },
      "enedis_active_power_day_in_kwh": { name: `${t("devices.sources.enedis_active_power_day_in_kwh")}`, color: "#6c74d8" },
      "airtable_manual_bills": { name: `${t("devices.sources.manual_bills")}`, color: "#d6ad56" },
      "third_party_energy": { name: `${t("devices.sources.third_party_energy")}`, color: "#5cd1ff" },
      "third_party_active_power_in_kwh": { name: `${t("devices.sources.third_party_active_power_in_kwh")}`, color: "#5da4d9" },
      "default": { name: "Non défini", color: "#d9d9d9" },
    };

  const corresponding_qualities = {
    "serie_aegeon": "fusioned_energy",
    "enedis_billed_energy": "billed_energy",
    "enedis_active_power_day_in_kwh": "active_power",
    "airtable_manual_bills": "manual_bills",
    "third_party_energy": "third_party_energy",
    "third_party_active_power_in_kwh": "third_party_active_power_in_kwh",
    "grdf_billed_energy": "billed_energy",
    "grdf_informative_energy": "informative_energy",
  };

  const filter_types_by_existing_sources = (types, groupedSerie) => {
    let filtered_types = {}
    Object.keys(types).forEach((key, index) => {
      if (groupedSerie.some(item => item.source === key || key === "serie_aegeon" || key === "default")) {
        filtered_types[key] = types[key];
      }
    });
    return filtered_types;
  }

  let transparency = ["CC", "AA", "88"];

  const add_serie_aegeon_to_data = (data, groupedSerieByReason, filtered_types) => {
    groupedSerieByReason.forEach((element, index) => {
      let source = element.source;
      var typeItem = source in filtered_types ? filtered_types[source] : types['default'];
      let name = typeItem.name;
      let reason = element.reason;
      let color = typeItem.color;
      if (source === "enedis_active_power_day_in_kwh") {
        if (reason.includes("start_power_failure") || reason.includes("end_power_failure")) {
          color = color + transparency[2];
        }
        else if (reason.includes("manual")) {
          color = color + transparency[1];
        }
        else if (reason.includes("estime")) {
          color = color + transparency[0];
        }

        let reasons = reason.split("__");
        let reasons_name = [];
        for (let i = 1; i < reasons.length; i++) {
          reasons_name.push(t(`devices.reasons.${reasons[i]}`));
        }
        name = `${name} (${reasons_name.join(" & ")})`;
      }
      data.push({
        name: name,
        value: [0, element.start_date, element.end_date, element.end_date - element.start_date],
        itemStyle: {
          color: color
        }
      });
    })
    return data;
  }

  const find_missing_values_intervals = (key, property) => {
    let missing_value_intervals = [];
    // Add missing values
    if (property?.quality?.missing_values?.length > 0) {
      let missing_value_interval = {
        start: new Date(property?.quality?.missing_values[0]).getTime(),
        end: (key === "airtable_manual_bills" || key === "third_party_energy" || key === "third_party_active_power_in_kwh") ? new Date(property?.quality?.missing_values[0]).setMonth(new Date(property?.quality?.missing_values[0]).getMonth() + 1) : new Date(property?.quality?.missing_values[0]).getTime() + one_day
      };
      property?.quality?.missing_values?.forEach((missing_value, index) => {
        if (index > 0) {
          let missing_value_time = new Date(missing_value).getTime();
          let missing_value_end = new Date(missing_value).getTime() + one_day;
          if (key === "airtable_manual_bills" || key === "third_party_energy" || key === "third_party_active_power_in_kwh") {
            missing_value_end = new Date(missing_value).setMonth(new Date(missing_value).getMonth() + 1);
          }
          if (Math.abs(missing_value_time - missing_value_interval.end) < two_hours) {
            missing_value_interval.end = missing_value_end;
          } else {
            missing_value_intervals.push(missing_value_interval);
            missing_value_interval = {
              start: missing_value_time,
              end: missing_value_end
            };
          }
        }
      });
      missing_value_intervals.push(missing_value_interval);
    }
    // Consider ignored values as missing values
    if (property?.quality?.ignored_intervals?.length > 0) {
      missing_value_intervals.push.apply(missing_value_intervals, find_ignored_values(key, property));
    }

    missing_value_intervals.sort((a, b) => a.start - b.start);

    return missing_value_intervals;
  }

  const find_ignored_values = (key, property) => {
    // Add ignored intervals
    if (property?.quality?.ignored_intervals?.length > 0) {
      return property?.quality?.ignored_intervals?.map((element) =>
      ({
        start: new Date(element.start_date).getTime(),
        end: new Date(element.end_date).getTime()
      }));
    }
    return [];
  }

  const push_missing_values_to_data = (data, missing_value_intervals, typeItem, serieStartTime, serieEndTime, depthIndex) => {
    let serie_interval = {
      name: typeItem.name,
      value: [depthIndex, serieStartTime, missing_value_intervals[0].start, missing_value_intervals[0].start - serieStartTime],
      itemStyle: {
        color: typeItem.color
      }
    };
    data.push(serie_interval);
    missing_value_intervals.forEach((missing_value_interval, index) => {
      let start = missing_value_interval.end;
      let end = missing_value_intervals[index + 1] ? missing_value_intervals[index + 1].start : serieEndTime;
      serie_interval = {
        name: typeItem.name,
        value: [depthIndex, start, end, end - start],
        itemStyle: {
          color: typeItem.color
        }
      }
      data.push(serie_interval);
    })
    return data
  }

  // Add ignored values
  const push_ignored_value_to_data = (data, intervals, typeItem, serieStartTime, serieEndTime, depthIndex) => {
    data.push.apply(data, intervals.map(element => (
      {
        name: typeItem.name + " (" + t("devices.quality.ignored_data") + ")",
        value: [depthIndex, element.start, element.end, element.end - element.start],
        itemStyle: {
          color: typeItem.color + transparency[2]
        }
      }
    )));
    return data;
  }

  const add_other_sources_to_data = (data, groupedSerie) => {
    Object.keys(types).forEach((key, index) => {
      if (key !== "serie_aegeon" && key !== "default") {
        var typeItem = types[key];
        var depthIndex = Object.keys(types).findIndex(key_1 => key_1 === key);
        var property = record?.properties?.find(property => property.name === corresponding_qualities[key]);
        var serieStart = property?.quality?.timeseries_start_date;
        var serieStartTime = serieStart ? (new Date(serieStart)).getTime() : '';
        var serieEnd = property?.quality?.timeseries_end_date;
        if (key === "airtable_manual_bills" || key === "third_party_energy" || key === "third_party_active_power_in_kwh") {
          serieEnd = new Date(serieEnd).setMonth(new Date(serieEnd).getMonth() + 1);
          serieEnd = new Date(serieEnd).getTime() - one_day
        }
        var serieEndTime = serieEnd ? (new Date(serieEnd)).getTime() : '';

        if (serieStartTime && serieEndTime) {
          serieEndTime = serieEndTime + one_day;
          let missing_value_intervals = find_missing_values_intervals(key, property);
          const ignored_intervals = find_ignored_values(key, property);
          if (missing_value_intervals.length > 0) {
            data = push_missing_values_to_data(data, missing_value_intervals, typeItem, serieStartTime, serieEndTime, depthIndex);
          }
          else {
            data.push({
              name: typeItem.name,
              value: [depthIndex, serieStartTime, serieEndTime, serieEndTime - serieStartTime],
              itemStyle: {
                color: typeItem.color
              }
            });
          }

          if (ignored_intervals.length > 0) {
            data = push_ignored_value_to_data(data, ignored_intervals, typeItem, serieStartTime, serieEndTime, depthIndex);
          }

        }
        else {
          var groupedSerieItem = groupedSerie.find(element => element.source === key)
          if (groupedSerieItem) {
            let start = new Date(groupedSerieItem.start_date).getTime();
            let end = new Date(groupedSerieItem.end_date).getTime();
            data.push({
              name: typeItem.name,
              value: [depthIndex, start, end, end - start],
              itemStyle: {
                color: typeItem.color,
              },
            });
          }
          else {
            delete types[key];
          }
        }
      }
    })
    return data
  }


  function renderItem(params, api) {
    var categoryIndex = api.value(0);
    var start = api.coord([api.value(1), categoryIndex]);
    var end = api.coord([api.value(2), categoryIndex]);
    var height = api.size([0, 1])[1] * 0.6;
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height
      }
    );
    return (
      rectShape && {
        type: 'rect',
        transition: ['shape'],
        shape: rectShape,
        style: { fill: api.visual('color') }
      }
    );
  }

  const dataZoom = isZoom ? [
    {
      type: 'slider',
      filterMode: 'weakFilter',
      showDataShadow: false,
      top: "90%",
      labelFormatter: ''
    },
    {
      type: 'inside',
      filterMode: 'weakFilter'
    }
  ] : [];

  useEffect(() => {
    if (record && record?.properties && timeseries && timeseries.length > 0 && !options) {
      const startDate = timeseries && timeseries?.[0]?.time;
      var startTime = (new Date(startDate)).getTime();
      var endTime = (new Date()).setHours(0, 0, 0, 0);
      const groupedSerie = groupTimeseries(timeseries, "source");
      const groupedSerieByReason = groupTimeseries(timeseries, "reason");
      let filtered_types = filter_types_by_existing_sources(types, groupedSerie);
      let data = [];
      data = add_serie_aegeon_to_data(data, groupedSerieByReason, filtered_types);
      data = add_other_sources_to_data(data, groupedSerie);
      let options = {
        tooltip: {
          formatter: function (params) {
            let end_date = new Date(params.value[2])
            end_date.setSeconds(end_date.getSeconds() - 1)
            return params.marker + " " + t("devices.quality.from") + " " + new Date(params.value[1]).toLocaleDateString(localStorage.getItem("language")) + " " + t("devices.quality.to") + " " + end_date.toLocaleDateString(localStorage.getItem("language")) + ' </br>' + params.name + ': ' + Math.round(params.value[3] / one_day) + " " + t("devices.quality.days");
          },
          trigger: 'item',
          position: ['20%', '20%']
        },
        dataZoom: dataZoom,
        grid: {
          height: isZoom ? "70%" : "90%",
          left: isZoom ? "10%" : "22%",
          right: isZoom ? "5%" : "10%",
          top: "0"
        },
        xAxis: {
          min: startTime,
          max: endTime,
          scale: true,
          interval: !isZoom ? (endTime - startTime) / 3 : null,
          axisLabel: {
            formatter: function (value, index) {
              return new Date(value).toLocaleDateString(localStorage.getItem("language"))
            },
            fontSize: 10
          }
        },
        yAxis: {
          data: Object.keys(types).filter(key => key !== "default").map(key => types[key].name),
          axisLabel: {
            formatter: function (value, index) {
              return value.split(" ").join("\n")
            },
            fontSize: 10
          },
        },
        series: [
          {
            type: 'custom',
            renderItem: renderItem,
            itemStyle: {
              opacity: 0.8
            },
            encode: {
              x: [1, 2],
              y: 0
            },
            data: data
          }
        ]
      };
      setOptions(options);
      setSerieCallback && groupedSerieByReason && groupedSerieByReason.length > 0 && setSerieCallback(groupedSerieByReason)
    }
  }, [record, timeseries, options]);

  return (
    isLoading === true ?
      <ChartLoading /> :
      timeseries && timeseries.length > 0 && options ?
        <>
          <ReactECharts
            notMerge={true}
            option={options}
            style={{ height: '100%', width: '100%' }}
          />
        </>
        : <ChartNoData />
  );
}

export default ChartProfile;