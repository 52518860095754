import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceIcon = (props) => {
  const color = props.color ? props.color : '';

  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="m64,13c-19.3,0-35,15.7-35,35,0,10.92,5.18,24.03,15.82,40.08,7.61,11.46,15.08,19.83,15.38,20.16.96,1.09,2.35,1.72,3.8,1.72s2.84-.62,3.78-1.7c.31-.35,7.79-8.72,15.4-20.18,10.65-16.04,15.82-29.15,15.82-40.08,0-19.3-15.7-35-35-35Zm14.18,71.76c-6.05,9.11-12.15,16.37-14.18,18.71-2.01-2.32-8.12-9.58-14.18-18.71-9.97-15.03-14.82-27.05-14.82-36.76,0-15.99,13.01-29,29-29s29,13.01,29,29c0,9.71-4.85,21.73-14.82,36.76Z" fill={color}/>
        <rect x="50.33" y="61" width="27.33" height="11.33" rx="3" ry="3"fill={color} />
        <path d="m64,55c3.86,0,7-3.14,7-7,0-3.55-2.66-6.48-6.09-6.93.05-.12.09-.26.09-.4v-14c0-.55-.45-1-1-1s-1,.45-1,1v14c0,.14.03.28.09.4-3.43.45-6.09,3.38-6.09,6.93,0,3.86,3.14,7,7,7Zm0-12c2.76,0,5,2.24,5,5s-2.24,5-5,5-5-2.24-5-5,2.24-5,5-5Z" fill={color}/>
        <path d="m79.79,32.21c-.39-.39-1.02-.39-1.41,0l-3.77,3.77c-.39.39-.39,1.02,0,1.41.2.2.45.29.71.29s.51-.1.71-.29l3.77-3.77c.39-.39.39-1.02,0-1.41Z" fill={color}/>
        <path d="m79,48c0,.55.45,1,1,1h5.33c.55,0,1-.45,1-1s-.45-1-1-1h-5.33c-.55,0-1,.45-1,1Z" fill={color}/>
        <path d="m48,47h-5.33c-.55,0-1,.45-1,1s.45,1,1,1h5.33c.55,0,1-.45,1-1s-.45-1-1-1Z" fill={color}/>
        <path d="m52.69,37.69c.26,0,.51-.1.71-.29.39-.39.39-1.02,0-1.41l-3.77-3.77c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l3.77,3.77c.2.2.45.29.71.29Z" fill={color}/>
    </SvgIcon>
  );
};

export default DeviceIcon;