import SiteIcon from "./SiteIcon";
import DeviceIcon from "./DeviceIcon";

const icons = {
  device: DeviceIcon({ color: "#71B9A1" }),
  site: SiteIcon({ color: "#71B9A1" }),
};

export default function Icons(props) {
  const icone = icons[props.icon];
  return icone;
}
