import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceTypeGasIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="m64,66c-1.13,0-6,11.21-6,19.44,0,6.63,3.31,10.54,6,10.56,2.69-.02,6-3.93,6-10.56,0-8.22-4.87-19.44-6-19.44Z"/>
        <path d="m80.13,35.36v-14.85c0-2.31-1.87-4.18-4.18-4.18h-23.9c-2.31,0-4.18,1.87-4.18,4.18v14.85c-9.23,3.17-15.87,11.92-15.87,22.23v30.91c0,12.98,10.52,23.5,23.5,23.5h17c12.98,0,23.5-10.52,23.5-23.5v-30.91c0-10.31-6.64-19.06-15.87-22.23Zm-21.55-15.03h10.85c1.66,0,3,1.34,3,3s-1.34,3-3,3h-10.85c-1.66,0-3-1.34-3-3s1.34-3,3-3Zm5.42,78.67c-7.16-.03-16-6.81-16-18.31,0-14.26,12.99-33.69,16-33.69s16,19.44,16,33.69c0,11.5-8.84,18.27-16,18.31Z"/>
    </SvgIcon>
  );
};

export default DeviceTypeGasIcon;