import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import InfoTooltipIcon from "../../../assets/InfoTooltipIcon";
import globalUseStyles from '../../../styles/globalCustomStyles';
import CustomLargeTable from '../../Table/CustomLargeTable';
import CustomTooltip from '../../aegeon/CustomTooltip';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import ChartNoData from '../../aegeon/charts/ChartNoData';
import { getTableSeries } from '../../aegeon/compute_table';
import { filter_and_sort_devices } from '../../aegeon/helper';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import DeviceTypeIcon from '../../devices/CustomComponents/DeviceTypeIcon';

const TableSiteDevicesDetailsComponent = (props) => {
    const globalClasses = globalUseStyles();
    const t = useTranslate();
    const { setDataSourcesCallback, devices, lastMonths, title, source, site_code } = props;
    const {
        data,
        isLoading,
        hasNextPage,
    } = useQueryContext(props);

    const tooltip_text = props.tooltip_text ? props.tooltip_text : "";
    const [subtitle, setSubtitle] = useState("");
    const [rows, setRows] = useState([]);
    const [isDone, setIsDone] = useState(false);

    const handleSetSubtitleCallback = (childData) => {
        if (childData) {
            setSubtitle(childData);
        }
    };

    useEffect(() => {
        props.setIsLoading(!isDone)
    }, [isDone]);

    const cols_data = [t("sites.table.devices.name"), t("sites.table.devices.unit"), t("sites.table.devices.destination"), t("sites.table.devices.quality"), t("sites.table.devices.tenth")]

    const getRows = (devices_with_data) => {
        let rows = []
        devices_with_data.forEach((device) => {
            let row = {
                name: device.name,
                name_icon: <DeviceTypeIcon deviceType={device.deviceType} />,
                name_icon_tooltip: t("devices.deviceTypes." + device.deviceType),
                name_path: "/devices/" + device.id + "/show",
                name_tooltip_link: "sites.composition.device_tooltip_link",
                values: [
                    device.unit,
                    device.destination,
                    device.quality,
                    device.tenth,
                ]
            }
            Object.keys(lastMonths).forEach((year) => {
                lastMonths[year].forEach((month) => {
                    let value = device.data[year] && (device.data[year][parseInt(month)] || device.data[year][parseInt(month)] === 0) ? device.data[year][parseInt(month)] : ''
                    row.values.push(value)
                })
            })
            rows.push(row)
        })
        return rows
    }

    const convertData = (rows) => {
        if (rows) {
            let data = [[...cols_data, ...getFlattedMonths(lastMonths)]]
            rows.forEach((row) => {
                let row_data = [row.name, ...row.values]
                data.push(row_data)
            }
            )
            return data
        }
        return []
    }

    useEffect(() => {
        if (!isLoading && !hasNextPage && data && data.items && data.items.length > 0 && devices) {
            let devices_filtered = filter_and_sort_devices(devices)
            let devices_with_data = devices_filtered.map((device) => {
                let device_data = data.items.find((item) => item.id === device.name)
                let grouped_device_data = device_data?.timeseries ? getTableSeries(device_data?.timeseries, false) : {}
                let unit = device?.properties?.find((property) => property?.name === "fusioned_energy")?.unit
                if (unit === "l") {
                    unit = "L"
                }
                let site_tenth = device?.airtable?.building_tenths?.find(tenth => tenth.site_code === site_code)?.tenth;
                return {
                    name: device.name,
                    destination: device?.airtable?.destination,
                    unit: unit,
                    quality: device?.fusionedEnergyQuality,
                    deviceType: device?.deviceType,
                    tenth: site_tenth ? site_tenth.toString() : "",
                    id: device.id,
                    data: grouped_device_data
                }
            })
            let rows = getRows(devices_with_data)
            setDataSourcesCallback && setDataSourcesCallback(convertData(rows), source)
            setRows(rows)
            setSubtitle(t("sites.table.devices.subtitle"))
            setIsDone(true)
        }
        else if (!isLoading && !hasNextPage && (!data || !data.items || data.items.length === 0)) {
            setSubtitle(t("sites.table.devices.subtitle_no_data"))
            setIsDone(true)
        }
    }, [data, isLoading, devices, subtitle, source])

    const getFlattedMonths = (lastMonths) => {
        let months = []
        Object.keys(lastMonths).forEach((year) => {
            lastMonths?.[year].forEach((month) => {
                let month_year = t("months." + (parseInt(month) - 1)) + " " + year
                months.push(month_year)
            })
        })
        return months
    }

    const child =
        isDone && rows && rows.length > 0 ?
            <CustomLargeTable
                rows={rows}
                head={[...cols_data, ...getFlattedMonths(lastMonths)]}
                className={globalClasses.DetailCardTableCells}
                setSubtitleCallback={handleSetSubtitleCallback}
                disableMissingDataTooltip={true}
                disabledBoldLastValue={true}
                rounded={true}
                cellWithIconAndLink={true}
            />
            :
            !isDone ?
                <ChartLoading />
                :
                <ChartNoData />

    return (
        <>
            <div className={globalClasses.DetailCardTableTitle}>
                <Typography variant="h8">{title}</Typography>
                {tooltip_text && <CustomTooltip title={tooltip_text} icon={<InfoTooltipIcon />} />}
            </div>
            <div className={globalClasses.DetailCardTableSubtitle}>
                {subtitle}
            </div>
            <div style={{ minHeight: "200px" }}>
                {child}
            </div>
        </>
    )
};


export default TableSiteDevicesDetailsComponent;

