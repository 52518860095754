
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const AegeonPlatformCenteredIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 184 32"  xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M26.7379 31.119L24.049 24.7106H9.24224L6.55334 31.119H0.535889L13.8264 0.806641H19.8438L32.9685 31.119H26.7379ZM11.327 19.7355H21.976L16.693 7.00177L11.327 19.7355Z" fill="#37424A"/>
        <path d="M35.8588 0.806641H58.0688V5.87646H41.663V13.3627H56.3868V18.4326H41.663V26.0491H58.59V31.119H35.8588V0.806641Z" fill="#37424A"/>
        <path d="M94.8251 0.806641H117.035V5.87646H100.629V13.3627H115.353V18.4326H100.629V26.0491H117.556V31.119H94.8251V0.806641Z" fill="#37424A"/>
        <path d="M144.291 2.45318C146.755 3.81539 148.698 5.67512 150.119 8.04419C151.529 10.4133 152.24 13.0429 152.24 15.9214C152.24 18.7998 151.529 21.4413 150.119 23.8222C148.71 26.2032 146.767 28.0866 144.291 29.4725C141.828 30.8584 139.091 31.5454 136.082 31.5454C133.074 31.5454 130.349 30.8584 127.874 29.4725C125.41 28.0866 123.467 26.2032 122.046 23.8222C120.636 21.4413 119.925 18.8116 119.925 15.9214C119.925 13.0311 120.636 10.4133 122.046 8.04419C123.455 5.67512 125.398 3.81539 127.874 2.45318C130.337 1.09096 133.074 0.415771 136.082 0.415771C139.091 0.415771 141.816 1.09096 144.291 2.45318ZM131.025 6.97811C129.437 7.8902 128.182 9.13397 127.258 10.6976C126.334 12.273 125.872 14.0143 125.872 15.9095C125.872 17.8048 126.346 19.5579 127.281 21.1452C128.217 22.7325 129.473 23.9881 131.048 24.912C132.624 25.8359 134.329 26.2979 136.177 26.2979C138.025 26.2979 139.683 25.8359 141.247 24.912C142.811 23.9881 144.043 22.7325 144.955 21.1452C145.867 19.5579 146.317 17.8048 146.317 15.9095C146.317 14.0143 145.867 12.2611 144.955 10.6976C144.043 9.13397 142.811 7.87836 141.247 6.97811C139.683 6.06601 138.001 5.61589 136.177 5.61589C134.353 5.61589 132.612 6.06601 131.025 6.97811Z" fill="#37424A"/>
        <path d="M177.837 0.806641H183.464V31.119H177.968L162.379 10.2474V31.119H156.705V0.806641H162.202L177.837 21.7256V0.806641Z" fill="#37424A"/>
        <path d="M77.0096 16.0398V20.778H83.9154V24.4027C82.873 25.0068 81.7833 25.4806 80.6461 25.8123C79.509 26.1439 78.4192 26.3098 77.3768 26.3098C75.446 26.3098 73.681 25.8478 72.0938 24.9239C70.5065 23.9999 69.239 22.7443 68.3032 21.157C67.3675 19.5697 66.8936 17.8285 66.8936 15.9214C66.8936 14.0143 67.3556 12.3085 68.2795 10.7212C69.2035 9.13397 70.4709 7.8902 72.0701 6.97811C73.6692 6.06601 75.4223 5.61589 77.3294 5.61589C78.8575 5.61589 80.3855 5.92387 81.9017 6.52798C83.4179 7.1321 84.7565 7.98497 85.9055 9.08659L89.1985 4.79856C87.6704 3.43634 85.8462 2.37026 83.7378 1.58846C81.6293 0.806669 79.4971 0.415771 77.3294 0.415771C74.297 0.415771 71.537 1.1028 69.0377 2.47687C66.5383 3.85093 64.5719 5.71065 63.1268 8.06788C61.6817 10.4251 60.9591 13.0429 60.9591 15.9214C60.9591 18.7998 61.6698 21.4413 63.0794 23.8222C64.489 26.2032 66.4198 28.0866 68.86 29.4725C71.3001 30.8584 74.0009 31.5454 76.974 31.5454C79.047 31.5454 81.1792 31.1782 83.335 30.4438C85.5027 29.7094 87.3743 28.7499 88.9616 27.5654V16.0517H76.9859L77.0096 16.0398Z" fill="#37424A"/>
    </SvgIcon>
  );
};

export default AegeonPlatformCenteredIcon;