const translateDevicesFr = {
  devices: {
    title: "Compteur ",
    page_subtitle: {
      part1: "Données",
      part2: " disponibles depuis ",
      part3: " de Qualité ",
    },
    export: {
      prefix: "AEGEON_export_",
      fusioned_energy: "Série AEGEON",
      billed_energy: "Données télérelévées",
      enedis_active_power: "Courbe de charge",
      manual_bills: "Factures",
      third_party_energy: "Données télérelévées tiers",
      third_party_active_power: "Courbe de charge aggregée tiers",
      informative_energy: "Données informatives",
      ten_years_table: "Données 10 ans",
    },
    no_data: "Données non disponibles",
    no_data_locked: "Données vérouillées.",
    meter: "Compteur",
    exports: {
      heatmap: "AEGEON_heatmap_",
      line: "AEGEON_courbe_de_charge_",
      monotone: "AEGEON_monotone_",
      table: "AEGEON_series_",
      success: "Toutes les données ont été collectées.",
      error: "Erreur lors de l'export.",
      warning: "Ce compteur n'a pas de données exportables.",
    },
    sort_button: {
      sort_by_default: "RÉINITIALISER LE TRI",
      sort_by_default_tooltip:
        "Le tri par défaut correspond à un tri par qualité de synoptique du bâtiment, code bâtiment, type de fluide et destination.",
    },
    general_information: {
      title: "Informations générales",
      subtitle: "Quel suivi et quelles données techniques sont associées à ce compteur ?",
      tooltip_text:
        "Cette tuile vous donne une foultitude raisonnée des informations nécessaires à la bonne connaissance de votre compteur, de sa consommation et de son usage.",
      no_data_text: "",
      no_data_tooltip: "Cette donnée n'est pas renseignée",
      id: "Numéro du compteur",
      quality: "Donnée de qualité",
      fluid: "Fluide",
      fluid_no_data:
        "Cette donnée n’est pas renseignée, contactez SVP votre Energy Manager pour confirmer ce qu’il lui manque.",
      contract_status: "État contractuel",
      contract_status_no_data: "Ce compteur ne remonte pas d’information automatique distributeur.",
      grdf_status: "État des droits d'accès GRDF",
      grdf_status_no_data:
        "Ce compteur ne remonte pas d’information automatique distributeur. Veuillez signer une autorisation de collecte auprès de votre Energy Manager.",
      mes_date: "Date de mise en service",
      site_code: "Codes bâtiments",
      site_code_no_data:
        "Cette donnée n’est pas renseignée, contactez SVP votre Energy Manager pour confirmer ce qu’il lui manque.",
      site_name: "Noms des bâtiments",
      site_name_no_data:
        "Cette donnée n’est pas renseignée, contactez SVP votre Energy Manager pour confirmer ce qu’il lui manque.",
      site_code_link_tooltip: "Cliquez ici pour découvrir le bâtiment",
      data_quality: "Qualité de données série AEGEON",
      data_quality_no_data:
        "Oups... il y a eu une erreur, revenez plus tard ou contactez votre Energy Manager pour tirer cela au clair au plus vite.",
      destination: "Nature de la ou des parties alimentées",
      destination_no_data:
        "Ce champs permet de qualifier le type d'espace alimenté par le point et en creux de qualifier si ce point est dans le périmètre de responsabilité du bailleur ou du preneur.",
      automatic_upload: "Type de remontée des données",
      automatic_upload_tooltip_no_data_missing_mandate:
        "Merci de fournir un mandat à votre Energy Manager afin de rendre la remontée de vos données automatique.",
      automatic_upload_tooltip_no_data_missing_customer_space:
        "Merci de fournir un espace client à votre Energy Manager afin de récupérer vos factures pour vous.",
      segment: "Segment tarifaire",
      error_tooltip:
        "Oups... il y a eu une erreur, revenez plus tard ou contactez votre Energy Manager pour corriger cela au plus vite.",
      need_mandate_tooltip:
        "Signez un mandat d’autorisation de transmission des données énergétiques auprès de votrer Energy Manager pour avoir accès automatiquement à cette donnée.",
      car: "CAR (Consommation Annuelle de Référence)",
      car_last_12_months: "CAR (Consommation Annuelle de Référence) sur 12 mois",
      forfait_change_at: "Dernier changement tarifaire",
      season_billing: "Type des tarifs et horosaisonnalité",
      distributor: "Distributeur",
      supplier: "Fournisseur",
      addres_by_distributor: "Adresse selon le distributeur",
      type_meter: "Type de compteur",
      power: "Puissance souscrite ",
      max_subscribed_power: "Puissance souscrite max",
      holder: "Titulaire du compteur",
      holder_no_data:
        "Ce champs permet de savoir qui est signataire du contrat. Cette notion nous permet de proposer à la bonne personne les optimisations tarifaires identifiées eu égard au profil de consommation du point mais aussi à déterminer qui doit autoriser la communication des données énergétiques.",
      opening_level: "Niveau d'ouverture de service",
      opening_level_tooltip:
        "Cette donnée est disponible pour les compteurs de segment C5. 0 correspond à un compteur non ouvert, 1 en cours d'ouverture et 2 ouvert à des services, donc où la collecte de données détaillées est possible.",
      acquisition_authorization: "Autorisation de collecte associée",
      acquisition_authorization_no_data:
        "En renseignant cette information auprès de votre Energy Manager vous améliorez nettement la qualité de votre suivi.",
      acquisition_authorization_end_date: "Date de fin de validité autorisation",
      unique_metering_point: "Point unique",
      attached_sub_metering: "Sous-comptage",
      frequency: "Fréquence de télérelève",
      usage: "Usage(s) connu(s) du point",
      usage_no_data_commune:
        "Attention, ce point est un point commun et si il dessert des usages tels que Chauffage, CVC, vous gagnerez à contacter votre Energy Manager pour renseigner cette information afin d’améliorer nettement la qualité de votre suivi de ce point.",
      usage_no_data_priv:
        "Attention, si ce point dessert un process spécifique différent de l’activité de votre locataire, ne pas en connaitre l’usage rend plus difficile le bon suivi énergétique de votre bâtiment.",
      usage_no_data_tierce: "Merci de contacter votre Energy Manager pour renseigner cette donnée.",
      usage_no_data:
        "Attention, sans données d’usage et de destination d’un point de livraison énergétique, le suivi des performances énergétique de votre bâtiment peut être nettement affecté.",
      tenths: "Tantièmes",
      tenths_tooltip_info:
        "Les tantièmes permettent de tenir compte d'une éventuelle refacturation par ou à un tiers (copropriété, ASL, RIE…) ainsi que la répartition de la consommation d'un compteur commun sur plusieurs bâtiments. Ils sont utilisés pour le calcul de la consommation totale d'un bâtiment.",
      tenths_no_data:
        "Merci de contacter votre Energy Manager pour renseigner cette donnée. Les tantièmes sont importants pour le calcul de la consommation du point au sein d'un bâtiment.",
      deet_category: "Catégorisation DEET",
      deet_no_data:
        "En renseignant cette information auprès de votre Energy Manager vous améliorez nettement la qualité de votre suivi.",
      customer_space_available: "Espace client disponible",
      customer_space_available_no_data:
        "Merci de fournir un espace client à votre Energy Manager pour récupérer vos factures pour vous.",
      tooltip_expired_date:
        "La dernière autorisation est expirée. Veuillez fournir un nouveau mandat à votre Energy Manager.",
      left_panel: {
        title: "Données de suivi",
        tooltip_no_data:
          "En renseignant cette information auprès de votre Energy Manager vous améliorez nettement la qualité de votre suivi.",
      },
      right_panel: {
        title: "Données techniques",
        no_data_not_automatic: "Ce compteur n’est pas téléopérable/télémétré chez votre distributeur.",
        no_data_not_automatic_telereleve:
          "Ce compteur est téléopérable chez votre distributeur mais n'est pas ouvert à des services. Veuillez contacter votre Energy Manager pour signer une autorisation de collecte.",
      },
      allocation: {
        title: "Allocation aux entités fonctionnelles",
        no_data: "Ce compteur n'est alloué à aucune entité fonctionnelle.",
        no_data_tooltip: "Merci de contacter votre Energy Manager pour renseigner cette donnée.",
      },
      fluids: {
        EAU: "Eau",
        GAZ: "Gaz",
        ELEC: "Électricité",
        HEAT: "Réseau de chaleur urbain",
        COLD: "Réseau de froid urbain",
        ERREUR: "Erreur",
        FOD: "Fioul domestique",
        PROPANE: "Propane",
        WOOD: "Bois",
        BUTANE: "Butane",
        COAL: "Charbon",
        "OTHER FOSSIL FUEL": "Autre combustible fossile",
        null: "",
        undefined: "",
        order: {
          ELEC: "1",
          GAZ: "2",
          GAS: "2",
          HEAT: "3",
          COLD: "4",
          FOD: "5",
          PROPANE: "6",
          WOOD: "7",
          BUTANE: "8",
          COAL: "9",
          "OTHER FOSSIL FUEL": "10",
          EAU: "11",
          ERREUR: "12",
        },
      },
      subdevices: {
        title: "Sous-compteurs",
        no_data_tooltip: "Merci de contacter votre Energy Manager pour renseigner cette donnée si nécessaire.",
        no_data: "Aucun sous-compteur associé à ce compteur.",
        name: "Nom",
        description: "Description",
        device: "Compteur associé",
        building: "Bâtiment",
        lessor: "Bailleur-Preneur",
        contract_holder: "Titulaire",
        timeseries: "Consommations",
        unit: "Unité",
        subtitle: "La consommation par sous-compteur sur les 12 derniers mois",
        total: "Total",
        start_date_min: "Date de début",
        end_date_min: "Date de fin",
        diff: "Compteur virtuel (services généraux)",
        diff_tooltip: "Compteur virtuel représentant la différence entre la somme des sous-compteurs et la donnée télérelevée",
        total_tooltip: "Total des sous-compteurs et du compteur virtuel, c'est-à-dire la donnée télérelevée si elle est disponible",
      },
    },
    name: {
      electricity: "Compteur électrique",
      gas: "Compteur de gaz",
      water: "Compteur d'eau",
      heat: "Réseau de chaleur urbain",
      cold: "Réseau de froid urbain",
      undefined: "Non défini",
    },
    list: {
      name: "Numéro de compteur",
      building: "Bâtiment",
      fluid_type: "Type de fluide",
      destination: "Parties alimentées",
      site: "Bâtiment",
      properties: "Propriétés",
    },
    power: {
      // title: "Courbe de charge et index",
      title: "Courbe de charge",
      detailledTitle: "Courbe de charge des derniers jours",
      subtitle: "Comment la consommation a-t-elle évoluée sur les derniers jours ?",
      tooltip_text:
        "Nous affichons ici les données détaillées. Les données sont affichées au pas de temps le plus petit disponible.",
      noDataNotAuto: "Données non disponibles. Veuillez signer un mandat de collecte.",
      noDataNeedActivation:
        "Données non disponibles. Une demande d'activation ou de réactivation des courbes de charge est à faire",
      noData: "Données non disponibles",
    },
    index:{
      title: "Index",
      subtitle: "Comment l'index a-t-il évolué sur la dernière année selon les classes temporelles ?",
      energy: "Énergie",
      tooltip_text: "Nous affichons ici l'index du compteur. Cette donnée est fournie par Enedis au pas journalier. Les relevés ne sont pas toujours effectués à heure fixe, c'est pourquoi nous précisons l'heure de relevé pour chaque jour.",
    },
    index_substracted: {
      title: "Différence de l'index par classe temporelle",
      subtitle: "Comment la consommation a-t-elle évolué sur la dernière année selon les classes temporelles à partir des données d'index ?",
      button: "AGRÉGER PAR MOIS",
      tooltip_text: "Cette donnée est calculée à partir de l'index, en effectuant la différence pour obtenir la consommation du compteur. Les relevés ne sont pas toujours effectués à heure fixe, c'est pourquoi nous précisons l'heure de relevé pour chaque jour et qu'il y a potentiellement un dépassement lors du passage d'une saison à une autre.",
    },
    maximum_power: {
      title: "Puissance maximale appelée",
      subtitle: "Quelle puissance maximale a été appelée chaque jour selon les classes temporelles ?",
      tooltip_text: "Nous affichons ici la puissance maximale appelée chaque jour d'après Enedis. Les relevés ne sont pas toujours effectués à heure fixe, c'est pourquoi nous précisons l'heure de relevé pour chaque jour.",
    },
    energy: {
      title: "Énergie",
      subtitle: "Comment la consommation a-t-elle évolué sur la dernière année ?",
      tooltip_text: "Cette donnée provient directement d'Enedis et est calculée par ceux-ci à partir de l'index. Elle est censée coller au plus près de la somme des classes temporelles du graphe précédent, excepté le décalage dû aux heures de relevé et les potentielles règles de calculs spécifiques à Enedis.",
      button: "AGRÉGER PAR MOIS",
    },
    time_of_use: {
      title: "Temps d'utilisation",
      subtitle: "Quelle est la répartition du temps d'utilisation des différentes classes temporelles sur la dernière année ?",
      button: "CONVERTIR EN DIFFÉRENCE",
      tooltip_text: "Cette donnée provient directement d'Enedis au format index remis à zéro pour chaque période de relevé. Converti en différence, nous obtenons le temps d'utilisation journalier de chaque classe temporelle. Les relevés ne sont pas toujours effectués à heure fixe, c'est pourquoi nous précisons l'heure de relevé pour chaque jour et pourquoi certaines journées peuvent avoir une utilisation supérieure à 24h.",
    },
    overrun_duration: {
      title: "Durée de dépassement",
      subtitle: "Quelle est la durée de dépassement de la puissance souscrite sur la dernière année ?",
      tooltip_text: "Nous affichons ici la durée de dépassement de la puissance souscrite sur chaque jour de l'année. Si les courbes sont à 0, cela signifie que la puissance souscrite n'a pas été dépassée. Cette donnée est exprimée en secondes.",
    },
    overrun_quadratic: {
      title: "Puissance de dépassement",
      subtitle: "Quel est la puissance appelée lors des dépassements de la puissance souscrite sur la dernière année ?",
      tooltip_text: "Nous affichons ici la puissance appelée lors des dépassements de la puissance souscrite sur chaque jour de l'année. Si les courbes sont à 0, cela signifie que la puissance souscrite n'a pas été dépassée.",
    },
    evolution: {
      title: "Évolution de la consommation",
      subtitle: "Comment la consommation a-t-elle évolué ",
      subtitle_dates_1: "du ",
      subtitle_dates_2: " au ",
      subtitle_dates_3: " de la période en cours et de la précédente ?",
      tooltip_warning_1:
        "Attention, le nombre de mois de données de l'année dernière et de cette année est différent : ",
      tooltip_warning_2: " mois l'année dernière contre ",
      tooltip_warning_3: " mois cette année. Le calcul de la variation de consommation est donc peu fiable.",
      tooltip_text_source: "Nous utilisons les données de la série AEGEON. ",
      tooltip_text_1: "La comparaison est faite sur ",
      tooltip_text_2: " mois.",
      tooltip_no_data:
        "Pas assez de données disponibles pour effectuer cette comparaison. Vous pouvez envoyer des factures ou données tierces à votre Energy Manager ou attendre quelques mois.",
      subtitle_red: " Votre compteur a une qualité dégradée ce qui impacte la comparaison présentée ici.",
      subtitle_orange: " Votre compteur a une qualité moyenne ce qui impacte la comparaison présentée ici.",
      subtitle_green: " Votre compteur a une très bonne qualité.",
    },
    heatmap: {
      title: "Heatmap",
      subtitle: "Comment la consommation est-elle répartie sur la semaine ?",
      tooltip_text:
        "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      missing_values: "Données manquantes ou estimées",
      details: {
        title: "Heatmaps",
        weekly_heatmap: "Heatmap par jour de la semaine",
        last_month_heatmap: "Heatmap du dernier mois",
      },
      day: {
        title: "Heatmap par jour de la semaine",
        subtitle: "Moyenne par jour de la semaine sur le dernier mois",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      month: {
        title: "Heatmap du dernier mois",
        subtitle: "Heatmap du dernier mois",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      last_winter: {
        title: "Heatmap de l'hiver dernier",
        subtitle: "Heatmap d'un mois type sur l'hiver dernier",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      last_summer: {
        title: "Heatmap de l'été dernier",
        subtitle: "Heatmap d'un mois type sur l'été dernier",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      last_autumn: {
        title: "Heatmap de l'automne dernier",
        subtitle: "Heatmap d'un mois type sur l'automne dernier",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      last_spring: {
        title: "Heatmap du printemps dernier",
        subtitle: "Heatmap d'un mois type sur le printemps dernier",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
    },
    dataTable: {
      title: "Série de données AEGEON",
      subtitle: "Quelles sont les consommations disponibles mois par mois ? ",
      tooltip_text: "Il est possible d'exporter ces données en fichier excel. Cliquez pour ouvrir le détail.",
      totalIncludesMissingValuesTooltip: "Une partie des données est manquante",
      export: "EXPORTER",
      export_tooltip: "Exporter les données de la page en fichier excel.",
      nature_tooltip: {
        data: "Donnée en partie ",
        and: " et ",
        E: "estimée",
        R: "régulée",
        C: "corrigée",
        M: "manquante",
      },
    },
    sources: {
      unit_prefix: "Les données sont exprimées en ",
      no_data: "Aucune donnée disponible.",
      tooltip_locked:
        "Contactez votre Energy manager pour lui donner les autorisations nécessaires à la collecte de ces données.",
      enedis_billed_energy: "Données télérelévées",
      billed_energy: "Données télérelévées",
      enedis_active_power_day_in_kwh: "Données détaillées",
      manual_bills: "Factures",
      airtable_manual_bills: "Factures",
      third_party_energy: "Données tiers",
      third_party_active_power_in_kwh: "Données détaillées tiers",
      serie_aegeon: "Série AEGEON",
      origin: "Origine de la série AEGEON",
      origin_subtitle: "Quelles sont les données disponibles qui constituent la série AEGEON ?",
      fusioned_energy: "Série AEGEON",
      enedis_billed_energy_tooltip: "Données télérelévées",
      enedis_active_power_day_in_kwh_tooltip: "Données détaillées",
      manual_bills_tooltip: "Factures",
      third_party_energy_tooltip: "Données tiers",
      third_party_active_power_in_kwh_tooltip: "Données détaillées tiers",
      serie_aegeon_tooltip: "Série AEGEON",
      origin_tooltip: "Origine de la série AEGEON",
      ten_years: "Données 10 ans",
      ten_years_tooltip: "Données 10 ans",
      grdf_informative_energy: "Données détaillées",
      informative_energy: "Données détaillées",
      grdf_informative_energy_tooltip: "Données détaillées",
      grdf_billed_energy: "Données télérelévées",
      grdf_billed_energy_tooltip: "Données télérelévées",
      DJU: "DJU",
      DJR: "DJR",
      subdevice: "Sous-compteurs",
      subdevice_tooltip: "Sous-compteurs",
      index: "Index",
      energy: "Énergie",
      index_substracted: "Différences d'index",
      index_substracted_tooltip: "Ces données proviennent des index journaliers fournis par Enedis. Nous calculons via la différence la consommation du compteur.",
      time_of_use: "Temps d'utilisation",
      active_power: "Courbe de charge",
      overrun_duration: "Durée de dépassement",
      overrun_quadratic: "Puissance de dépassement",
      monotone:"Monotone",
    },
    reasons_splitted: {
      active_power: "Série de données composée de données ",
      nature_brut: "brutes",
      nature_reel: "réelles",
      nature_estime: "estimées",
      nature_corrige: "corrigées",
      nature_debut_de_coupure_secteur: "avec début de coupure secteur",
      nature_fin_de_coupure_secteur: "avec fin de coupure secteur",
      nature_coupure_secteur: "avec coupure secteur",
      nature_coupure_secteur_courte: "coupure secteur courte",
      nature_puissance_reconstituee_et_coupure_secteur: "reconstituées et avec coupure secteur",
      informative_energy: "Série de données détaillées ",
      actual: "réelles",
      estimated: "estimées",
      corrected: "corrigées",
      no_data: "absentes",
      unknown: "inconnues",
    },
    reasons: {
      default_reason: "Série de données de nature : ",
      start_power_failure: "Début de coupure secteur",
      end_power_failure: "Fin de coupure secteur",
      actual: "Réel",
      estimated: "Estimé",
      manual: "Importé",
      raw: "Brut",
      nature_brut: "Brut",
      nature_reel: "Réel",
      nature_estime: "Estimé",
      nature_corrige: "Corrigé",
      nature_coupure_secteur_courte: "Coupure secteur courte",
      nature_debut_de_coupure_secteur: "Début de coupure secteur",
      nature_fin_de_coupure_secteur: "Fin de coupure secteur",
      nature_coupure_secteur: "Coupure secteur",
      nature_puissance_reconstituee_et_coupure_secteur: "Puissance reconstituée et coupure secteur",
      active_power__raw: "Série de données issues de télérelève fine réelle d'un compteur Linky.",
      active_power__nature_brut: "Série de données issues de télérelève fine réelle d'un compteur Linky.",
      active_power__manual: "Série de données issues d'un import manuel de courbe par Enedis.",
      active_power__actual: "Série de données issue de télérelève fine réelle.",
      active_power__nature_reel: "Série de données issue de télérelève fine réelle.",
      active_power__nature_reel__nature_corrige:
        "Série de données issue de télérelève fine réelle et corrigée par Enedis.",
      active_power__estimated:
        "Série de données issue d'une estimation automatique d'Enedis pour combler un \"trou\" de mesure.",
      active_power__nature_estime:
        "Série de données issue d'une estimation automatique d'Enedis pour combler un \"trou\" de mesure.",
      active_power__actual__estimated:
        "Série de données issue de télérelève fine réelle et d'une estimation automatique d'Enedis.",
      active_power__estimated__manual:
        "Série de données issue d'une estimation automatique et d'un import manuel par Enedis.",
      active_power__actual__manual:
        "Série de données issue de télérelève fine réelle et d'un import manuel par Enedis.",
      active_power__actual__start_power_failure:
        "Série de données issue de télérelève fine réelle et où une coupure d'alimentation a commencé.",
      active_power__actual__end_power_failure:
        "Série de données issue de télérelève fine réelle et où une coupure d'alimentation a pris fin.",
      active_power__actual__start_power_failure__end_power_failure:
        "Série de données issue de télérelève fine réelle et où une coupure d'alimentation a commencé et pris fin.",
      reason_detailed_measures: "Série de données issue de télérelève fine réelle (pas d’estimation).",
      airtable_manual_bills: "Série de données issue de factures.",
      third_party_energy:
        "Sur cette période, la seule donnée de consommation accessible par AEGEON est une série de données de mesures (données facturantes) de consommation issue de services tiers sans possibilité de vérification des sources par AEGEON. Dans le cas d'AEGILIM, cette source est ENERGISME.",
      third_party_active_power_in_kwh:
        "Sur cette période, la seule donnée de consommation accessible par AEGEON est une série de télérelèves fines par un service tiers sans possibilité de vérification des sources par AEGEON. Dans le cas d'AEGILIM, cette source est ENERGISME.",
      reason_distributor_canceled_measures:
        "Données annulées dans série de donnée issue de la grille de données Réseau. Non visible en raisonnement final mais synthétisé lors de l'exécution de l'algorithme.",
      reason_supplier_canceled_measures:
        "Données annulées dans série de donnée issue de la grille de données Fournisseur. Non visible en raisonnement final mais synthétisé lors de l'exécution de l'algorithme.",
      reason_different_measures:
        "Mesures différentes entre la grille réseau et la grille fournisseur sur la même période.",
      reason_supplier_duplicated_measures:
        "Sur cette période, des doublons / triplons de données de mesures (données facturantes) sont présents dans la grille Fournisseur par erreur et sont nettoyés lors de la création de la Série AEGEON.",
      reason_distributor_duplicated_measures:
        "Sur cette période, des doublons / triplons de données de mesures (données facturantes) sont présents dans la grille Réseau par erreur et sont nettoyés lors de la création de la Série AEGEON.",
      reason_supplier_unique_measures:
        "Sur cette période, la meilleure donnée de consommation accessible par AEGEON est une série de données de mesures (données facturantes) présente uniquement dans la grille Fournisseur.",
      reason_distributor_unique_measures:
        "Sur cette période, la meilleure donnée de consommation accessible par AEGEON est une série de données de mesures (données facturantes) présente uniquement dans la grille Réseau.",
      reason_canceled_without_started_measures:
        "Sur cette période, nous observons une annulation. Elle n’est pas considérée dans la construction de la grille puisqu'elle est non utilisable sans la donnée précédente qu'elle annule.",
      reason_distributor_replace_actual_regularized_measures:
        "Sur cette période, on choisit une mesure réelle (données de facturation) dans la grille Réseau au lieu d'une mesure régularisée (correction) dans la grille Fournisseur.",
      reason_supplier_replace_actual_regularized_measures:
        "Sur cette période, on choisit une mesure réelle (donnée de facturation) dans la grille Fournisseur au lieu d'une mesure régularisée (correction) dans la grille Réseau.",
      reason_distributor_replace_actual_estimated_measures:
        "Sur cette période, on choisit une mesure réelle (données de facturation) dans la grille Réseau au lieu d'une mesure estimée dans la grille Fournisseur.",
      reason_supplier_replace_actual_estimated_measures:
        "Sur cette période, on choisit une mesure réelle (données de facturation) dans la grille Fournisseur au lieu d'une mesure estimée dans la grille Réseau.",
      reason_default_actual_measures:
        "Sur cette période, des données de mesure Réelles (données facturantes réelles) sont présentes sur les grilles fournisseur et réseau dont on choisit par défaut la mesure fournisseur étant donné qu'ils émettent les factures.",
      reason_default_estimated_measures:
        "Sur cette période, des données de mesure Estimées (données facturantes mais issues d'estimation) sont présentes sur les grilles fournisseur et réseau dont on choisit par défaut la mesure fournisseur étant donné qu'ils émettent les factures.",
      reason_default_regularized_measures:
        "Sur cette période, des données de mesure Régularisées (données facturantes mais issues de correction) sont présentes sur les grilles fournisseur et réseau dont on choisit par défaut la mesure fournisseur étant donné qu'ils émettent les factures.",
      reason_different_values_in_distributor_and_supplier_measures:
        "Mesures sans valeurs égales mais dans la même période.",
      reason_supplier_estimated_overlapped_measures: "Mesures venant de la grille Fournisseur.",
      reason_distributor_estimated_overlapped_measures: "Mesures venant de la grille Réseau.",
      billed_energy_actual: "Série de données issue de données facturantes.",
      billed_energy_estimated: "Série de données issue de données facturantes estimées automatiquement par GRDF.",
      billed_energy_unknown: "Série de données issue de données facturantes d'origine inconnue.",
    },
    quality: {
      title: "Formation de la série AEGEON",
      subtitle: "De quelles données est constituée la série AEGEON ?",
      tooltip_text:
        "La série AEGEON vous permet d'analyser vos consommations au plus proche du réel sur la plus longue période disponible en agrégeant au mieux des données hétérogènes.",
      from: "Du",
      to: "au",
      days: "jours",
      ignored_data: "Données estimées",
      graph: {
        title: "Série AEGEON",
      },
      explanation: {
        title: "Explication du raisonnement de la série AEGEON",
      },
      stats: {
        title: "Indicateurs de Qualité de la Série AEGEON",
        reasoning_quality: "Raisonnements sur la Qualité de la Donnée",
        no_reasoning: "Aucune donnée manquante ou anomalie pour ce compteur.",
        missing_values: "Données manquantes",
        outliers: "Données aberrantes",
        notify: {
          success: "Reasonement sauvegardé avec succès",
          error: "Erreur lors de la sauvegarde du raisonnement",
        },
        types: {
          missing_value: "Donnée manquante",
          outlier: "Donnée aberrante",
        },

        datagrid: {
          name: "Nom",
          value: "Valeur",
          unit: "Unité",
          type: "Type",
          start_date: "Date de début",
          end_date: "Date de fin",
          reviewed: "Révisé",
          reasoning: "Raisonnement",
          actions: "Actions",
        },
      },
    },
    comparison: {
      title: "Consommation mensuelle",
      subtitle: "Quels sont les mois les plus consommateurs ?",
      tooltip_text: "Nous utilisons les données de la série AEGEON agrégées par mois.",
      no_data: "Donnée non disponible",
      yearly: {
        title: "Comparaison mensuelle",
        subtitle: "Quelles différences de consommation y a-t-il entre les mois des différentes années ?",
      },
      weather: {
        title: "Relation avec les températures",
        subtitle: "La consommation est-elle liée aux températures (chauffage, climatisation) ?",
        tooltip_text:
          "Les données DJU/DJR sont issus de Météo France et sont ceux de la station météo de référence la plus proche.",
      },
      subdevices: {
        title: "Découpage par sous-compteurs",
        subtitle: "Comment la consommation est-elle répartie entre les sous-compteurs ?",
        diff: "Compteur virtuel (services généraux)",
        tooltip: "La consommation de chaque sous-compteur par mois, ainsi que la différence entre celles-ci et la donnée télérelevée.",
      },
    },
    monotone: {
      // title: "Appels de puissances et temps d'utilisation",
      title: "Monotone",
      detailledTitle: "Monotone de puissance sur la dernière période",
      subtitle: "Quelle est la répartition de mes appels de puissance ",
      subtitle_2: " du ",
      tooltip_text:
        "La monotone permet de visualiser le pourcentage de temps durant lequel la consommation est supérieure à une puissance donnée. Cela permet de visualiser la cohérence de la puissance souscrite ainsi que le talon de consommation. Nous utilisons ici les données détaillées.",
    },
    analyse: {
      title: "Analyses", 
      subtitle: "Comment interpréter ces données ?",
      tooltip_text: "Analyses",
      comparison: {
        title: "Comparaison des données",
        subtitle: "Quelle différence existe-t-il entre les différentes données de consommation ?",
        tooltip_text: "Les données affichées sont les données télérelevées, détaillées, d'index et de factures, selon leur disponibilité. Il est possible de retrouver et d'exporter ces données dans l'onglet Série de données AEGEON",
      }
    },
    deviceTypes: {
      enedisMeter: "Électricité (enedis)",
      electricityMeter: "Électricité",
      gasMeter: "Gaz",
      grdfMeter: "Gaz (GRDF)",
      propaneMeter: "Propane",
      butaneMeter: "Butane",
      fuelMeter: "Fioul",
      waterMeter: "Eau",
      woodMeter: "Bois",
      heatMeter: "Réseau de chaud urbain",
      coldMeter: "Réseau de froid urbain",
      coalMeter: "Charbon",
      otherFossileFuelMeter: "Autre combustible fossile",
      unknownMeter: "Inconnu",
    },
    import_subdevices: {
      title: "Import des sous-compteurs",
    },
    subdevices: {
      import: {
        title: "Import des sous-compteurs",
        page_subtitle: "",
        button_tooltip: "Importer des sous-compteurs",
        button_name: "IMPORT DES SOUS-COMPTEURS",
        configuration: {
          title: "Configuration",
          subtitle: "Importer le fichier excel comprenant les consommations à jour de tous les sous-compteurs.",
          source: "Source",
          dropzone_text: "Déposez le fichier ici",
          tooltip_text: "Sélectionnez le fichier à importer",
        },
      },
      charts: {
        aggregation_contract_holder: "AGRÉGER PAR TITULAIRE :",
      },
      new_timeseries: "Nouvelle valeur ",
      previous_timeseries: "Ancienne valeur ",
      button: {
        cancel: "ANNULER",
        synchronize: "IMPORTER",
        apply: "APPLIQUER",
      },
      synchronize: {
        success: "Synchronisation réussie",
      },
      old_value_tooltip: "Ancienne valeur : ",
    },
  },
};

export default translateDevicesFr;
