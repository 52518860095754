import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ExportButton from '../../Buttons/ExportButton';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';
import MonotoneCardDetails from './MonotoneCardDetails';

const MonotonePageDetails = (props) => {
  const t = useTranslate();
  const title = t("devices.monotone.title");
  const globalClasses = globalUseStyles();
  const [data, setData] = useState(null);

  const setDataCallback = (childData) => {
    if (childData) {
      setData(childData)
    }
  }

  const [subtitle, setSubtitle] = useState(" ");

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitle(childData);
    }
  };

  const handleExport = (name) => {
    var sheets_data = [];
    if (data) {
      Object.keys(data).forEach((source) => {
        if (data[source].length > 0) {
          const sheetName = t(`devices.sources.${source}`).slice(0, 30)
          sheets_data.push({ sheetName: sheetName, data: data[source] });
        }
      });
      export_row_to_excel(sheets_data, `${t('devices.exports.monotone')}${name}.xlsx`);
    };
  };

  return (
    <DetailsShow title={title} resource="devices" tooltip={<ExportButton onClick={handleExport} disabled={!data} />} lessMargin={true}>
      <MonotoneCardDetails setSubtitleCallback={handleSetSubtitleCallback} setDataCallback={setDataCallback} subtitle={subtitle} />
    </DetailsShow>
  );
};

export default MonotonePageDetails;

