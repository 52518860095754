import React from 'react';
import globalUseStyles from '../../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../../assets/InfoTooltipIcon';
import CustomTable from '../../../Table/CustomTable';
import CustomTooltip from '../../../aegeon/CustomTooltip';
import ChartLoading from '../../../aegeon/charts/ChartLoading';
import { useQueryContext } from '../../../aegeon/query/useQueryContext';

const SiteMissingInformationDetailsContentLeftBottom = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const {
    data,
    isLoading,
  } = useQueryContext(props);

  const record = useRecordContext();

  if (isLoading === true) {
    return (
      <ChartLoading />
    );
  }

  const devices = props.devices;
  const devices_filtered = devices ? devices.filter(device => (device?.airtable?.energy_data_upload_possibilities?.includes("TÉLÉRELEVÉ")) && (!device?.airtable?.authorization_end_date || new Date(device?.airtable?.authorization_end_date) < new Date())) : []

  const rows = devices_filtered.map((device) => {
    return {
      name: device.name,
      icon_tooltip: t("devices.deviceTypes." + device.deviceType),
      value: device?.airtable?.authorization_end_date ? "Expirée : " + new Date(device?.airtable?.authorization_end_date).toLocaleDateString(localStorage.getItem("language")) : "Pas d'autorisation",
      path: "/devices/" + device.id + "/show",
      tooltip_link: "sites.composition.device_tooltip_link"
    }
  }).sort(function (a, b) {
    if (a.value === b.value) {
      return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
    }
    else {
      return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0);
    }
  });

  return (
    <Card className={globalClasses.DetailCard}>
      <div className={globalClasses.DetailCardTableTitle}>
        <Typography display="block" variant="h8">{t('sites.missing_information.left_bottom_panel.title')}</Typography>
        <CustomTooltip title={t('sites.missing_information.left_bottom_panel.tooltip')} icon={<InfoTooltipIcon />} />
      </div>
      <div className={globalClasses.DetailCardTableSubtitle} />
      {devices_filtered.length === 0 ? <Typography display="block" variant="h9" component="p">{t('sites.missing_information.no_missing_autorisation')}</Typography> : <CustomTable list={true} rows={rows} defaultTooltip={"sites.no_data_tooltip"} />}
    </Card>
  );
};

export default SiteMissingInformationDetailsContentLeftBottom;
