import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import useSubscriptionDataProvider from "./useSubscriptionDataProvider";

const useSubscriptionProvider = (params = {}, onSubscribedData, options = {}) => {
    const {
        subscriptionName,
        queryParams
    } = params;
    const { enabled } = options;

    const subscriptionDataProvider = useSubscriptionDataProvider();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (enabled === undefined || enabled === true) {
            console.log("useSubscriptionProvider: ", subscriptionName, queryParams);
            subscriptionDataProvider.subscribe(subscriptionName, queryParams, onSubscribedData)
        }
    }, [enabled, queryClient]);

    return subscriptionDataProvider;
};

export default useSubscriptionProvider;
