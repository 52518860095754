import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import ExclamationTooltipIcon from "../../../assets/ExclamationTooltipIcon";
import InfoTooltipIcon from "../../../assets/InfoTooltipIcon";
import globalUseStyles from '../../../styles/globalCustomStyles';
import { theme } from "../../../theme";
import CustomTooltip from '../../aegeon/CustomTooltip';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import EnergeticPerformanceCardDetails from './EnergeticPerformanceCardDetails';


const SiteEnergeticPerformanceDetails = (props) => {
    const globalClasses = globalUseStyles();
    const t = useTranslate();
    const [subtitleDPE, setSubtitleDPE] = React.useState('');
    const [subtitleClimat, setSubtitleClimat] = React.useState('');

    const handleSetSubtitleDPECallback = (childData) => {
        if (childData) {
            setSubtitleDPE(childData);
        }
    };

    const handleSetSubtitleClimatCallback = (childData) => {
        if (childData) {
            setSubtitleClimat(childData);
        }
    };

    const InsideCardComponent = (props) => {
        const { child, type, subtitle } = props;
        const record = useRecordContext(props);
        const siteId = record?.id;
        const { data } = useGetList(
            'devices',
            {
                filter: {
                    siteIds: { contains: siteId },
                },
            }
        );

        const title = t('sites.' + type + '.title');
        const qualityWarning = data?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'C') ? "red" : data?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'B') ? "orange" : "green"
        const tooltip_text = t('sites.' + type + '.tooltip_text') + (["red", "orange"].includes(qualityWarning) ? t('sites.' + type + '.bad_quality_tooltip') : '')
        const icon = qualityWarning === "red" ? <ExclamationTooltipIcon color={theme.palette.aegeon.danger} /> : qualityWarning === "orange" ? <ExclamationTooltipIcon color={theme.palette.aegeon.warning} /> : <InfoTooltipIcon />;
        return (
            <Card className={globalClasses.DetailCard}>
                <div className={globalClasses.DetailCardTableTitle}>
                    <Typography variant="h8">{title}</Typography>
                    <CustomTooltip title={tooltip_text} icon={icon} />
                </div>
                <div className={globalClasses.DetailCardTableSubtitle}>
                    {subtitle}
                </div>
                <div className={globalClasses.DetailCardGraph} >
                    {child}
                </div>
            </Card>
        )
    }


    return (
        <DetailsShow title={t('sites.energetic_performance.title')} resource="sites" lessMargin={true}>
            <Grid2 container spacing={2}>
                <Grid2 item="true" small={12} medium={12} big={6}>
                    <InsideCardComponent child={<EnergeticPerformanceCardDetails type="energeticPerformance" handleSetSubtitleCallback={handleSetSubtitleDPECallback} />} type='energetic_performance.dpe' subtitle={subtitleDPE} />
                </Grid2>
                {/* <Grid2 item="true" small={12} medium={12} big={6}>
                    <InsideCardComponent child={<EnergeticPerformanceCardDetails type="climaticPerformance" handleSetSubtitleCallback={handleSetSubtitleClimatCallback} />} type='energetic_performance.climat' subtitle={subtitleClimat} />
                </Grid2> */}
            </Grid2>
        </DetailsShow>
    )
};

export default SiteEnergeticPerformanceDetails;