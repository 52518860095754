import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceTypeHeatIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m64,37.73c4.61,0,8.54-2.88,10.12-6.93,17.03,4.49,29.63,20.02,29.63,38.44,0,1.66,1.34,3,3,3s3-1.34,3-3c0-21.56-14.99-39.67-35.1-44.49-.98-4.99-5.38-8.75-10.66-8.75-6,0-10.86,4.86-10.86,10.86s4.86,10.86,10.86,10.86Z"/>
      <path d="m25.64,79.7c-1.84-6.72-1.89-13.84-.04-20.75,2.75-10.26,9.33-18.83,18.52-24.14,1.43-.83,1.93-2.66,1.1-4.1-.83-1.43-2.66-1.93-4.1-1.1-10.58,6.11-18.16,15.98-21.32,27.78-2.22,8.29-2.07,16.84.33,24.87-3.82,3.35-4.87,9.03-2.24,13.59,3,5.2,9.64,6.98,14.84,3.98,5.2-3,6.98-9.64,3.98-14.84-2.31-4-6.77-5.96-11.07-5.29Z"/>
      <path d="m106.13,81.02c-5.2-3-11.84-1.22-14.84,3.98-2.31,3.99-1.78,8.84.94,12.23-12.4,12.51-32.16,15.65-48.11,6.44-1.44-.83-3.27-.34-4.1,1.1-.83,1.43-.34,3.27,1.1,4.1,7.19,4.15,15.04,6.12,22.8,6.12,12.39,0,24.52-5.05,33.26-14.28,4.81,1.65,10.28-.27,12.92-4.85,3-5.2,1.22-11.84-3.98-14.84Z"/>
      <path d="m50.59,87.1c-.1.1-.17.22-.22.34h-7.34c-.55,0-1,.45-1,1s.45,1,1,1h41.93c.55,0,1-.45,1-1s-.45-1-1-1h-8.03c4.25-5.32,3.93-13.23-.94-18.24-5.41-5.57-5.54-14.57-.3-20.08.38-.4.37-1.03-.03-1.41-.4-.38-1.03-.37-1.41.03-6.08,6.38-5.95,16.42.31,22.86,4.46,4.59,4.46,11.99.01,16.51-.1.1-.17.22-.22.34h-9.41c4.25-5.32,3.93-13.23-.94-18.24-5.41-5.57-5.54-14.57-.3-20.08.38-.4.37-1.03-.03-1.41-.4-.38-1.03-.37-1.41.03-6.08,6.38-5.95,16.42.31,22.86,4.46,4.59,4.46,11.99.01,16.51-.1.1-.17.22-.22.34h-9.41c4.25-5.32,3.93-13.23-.94-18.24-5.41-5.57-5.55-14.58-.3-20.08.38-.4.37-1.03-.03-1.41s-1.03-.37-1.41.03c-6.08,6.38-5.95,16.42.31,22.86,4.46,4.59,4.46,11.99.01,16.51Z"/>
    </SvgIcon>
  );
};

export default DeviceTypeHeatIcon;