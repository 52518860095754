import ReactECharts from 'echarts-for-react';
import React from 'react';
import { useTranslate } from 'react-admin';
import ChartNoData from './ChartNoData';

const ChartHeatmap = (props) => {
  const {
    isLoading,
    type,
    data,
    minMax,
    unit,
    isDetailed,
  } = props;

  const t = useTranslate();
  const unit_value = unit ? unit : "kWh"

  const getHours = () => {
    let hours = []
    for (let i = 0; i < 24; i++) {
      hours.push(i)
    }
    return hours
  }

  const hours = getHours()

  const getTimestampsDays = (data) => {
    let timestamps = []
    data.forEach(element => {
      if (!timestamps.includes(element[1])) {
        timestamps.push(element[1])
      }
    })
    return timestamps
  }

  function isMissingValues(data, days) {
    let missing = false
    days.forEach(day => {
      let values = data.filter(element => element[1] === day)
      if (values.length !== 24) {
        missing = true
      }
    }
    )
    return missing
  }

  function isEstimatedValues(data) {
    let estimated = false
    data.forEach(element => {
      if (element[3]) {
        estimated = true
      }
    })
    return estimated
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  const calculateLocalMinMax = (data) => {
    let min = 1000000
    let max = 0
    data.forEach(element => {
      if (element[2] < min) {
        min = element[2]
      }
      if (element[2] > max) {
        max = element[2]
      }
    })
    return { min: min, max: max }
  }

  const localMinMax = calculateLocalMinMax(data)


  const convertRatioToColor = (ratio) => {
    let minColor = hexToRgb('#f8fafd')
    let maxColor = hexToRgb('#6c74d8')
    let middleColor = hexToRgb('#5da4d9')

    if (ratio < 0.5) {
      return {
        r: Math.round(minColor.r + (middleColor.r - minColor.r) * ratio * 2),
        g: Math.round(minColor.g + (middleColor.g - minColor.g) * ratio * 2),
        b: Math.round(minColor.b + (middleColor.b - minColor.b) * ratio * 2),
      }
    }
    else {
      return {
        r: Math.round(middleColor.r + (maxColor.r - middleColor.r) * (ratio - 0.5) * 2),
        g: Math.round(middleColor.g + (maxColor.g - middleColor.g) * (ratio - 0.5) * 2),
        b: Math.round(middleColor.b + (maxColor.b - middleColor.b) * (ratio - 0.5) * 2),
      }
    }
  }

  const calculGradient = () => {
    if (minMax.min === minMax.max) {
      if (minMax.min === 0) {
        return {
          minColor: `#f8fafd`,
          middleColor: `#f8fafd`,
          maxColor: `#f8fafd`,
        }
      }
      return {
        minColor: `#6c74d8`,
        middleColor: `#6c74d8`,
        maxColor: `#6c74d8`,
      }
    }

    let minRatio = (localMinMax.min - minMax.min) / (minMax.max - minMax.min)
    let maxRatio = (localMinMax.max - minMax.min) / (minMax.max - minMax.min)

    let newMinColor = convertRatioToColor(minRatio)
    let newMaxColor = convertRatioToColor(maxRatio)
    let newMidColor = convertRatioToColor((minRatio + maxRatio) / 2)

    return {
      minColor: `rgb(${newMinColor.r}, ${newMinColor.g}, ${newMinColor.b})`,
      middleColor: `rgb(${newMidColor.r}, ${newMidColor.g}, ${newMidColor.b})`,
      maxColor: `rgb(${newMaxColor.r}, ${newMaxColor.g}, ${newMaxColor.b})`,
    }

  }

  const newMinMaxColors = calculGradient()

  let itemStyle = type === "day_of_week" ? {
    borderWidth: 2,
    borderColor: '#FFFFFF',
    borderRadius: 3,
  } : {
    borderWidth: 2,
    borderColor: '#FFFFFF',
    borderRadius: 3,
  }

  let series = [{
    type: 'heatmap',
    data: data.map((element) => {
      return {
        value: [element[0], element[1], element[2]],
        isEstimated: element[3],
        itemStyle: {
          color: element[3] ? "#A6ACAF" : undefined,
        }
      }
    }),
    itemStyle: itemStyle,
    emphasis: {
      itemStyle: {
        borderColor: '#6c74d850',
        borderWidth: 1,
        shadowColor: "#6c74d850",
      }
    }
  },
  {
    type: 'scatter',
    name: t("resources.heatmap.missing_values"),
    color: '#F48021',
    data: [
      {
        value: [-1, -1]
      }
    ],
  }, {
    type: 'scatter',
    name: t("resources.heatmap.estimated_values"),
    color: '#A6ACAF',
    data: [
      {
        value: [-1, -1]
      }
    ],
  }]

  const legend = isMissingValues(data, getTimestampsDays(data)) || isEstimatedValues(data) ? {
    show: true,
    data: [isMissingValues(data, getTimestampsDays(data)) && {
      name: t("resources.heatmap.missing_values"),
      icon: 'square',
    },
    isEstimatedValues(data) && {
      name: t("resources.heatmap.estimated_values"),
      icon: 'square',
    }],
    bottom: '0',
    left: "0",
    textStyle: {
      fontSize: 10,
      color: '#90979c',
    },
    selectedMode: false,
  } : {
    show: false
  }

  const option = {
    legend: legend,
    grid: {
      left: '0%',
      right: '15%',
      bottom: '15%',
      top: '2px',
      containLabel: true,
      backgroundColor: '#F48021',
      show: true,
    },
    xAxis: {
      type: 'category',
      data: hours,
      splitArea: {
        show: true
      },
      name: t('resources.heatmap.xAxis_title') + unit_value,
      nameGap: 25,
      nameTextStyle: {
        fontSize: 10,
      },
      nameLocation: 'middle',
    },
    yAxis: {
      type: 'category',
      data: getTimestampsDays(data),
      splitArea: {
        show: true
      },
      inverse: true,
      nameLocation: 'middle',
      nameGap: 45,
    },
    visualMap: {
      orient: 'vertical',
      calculable: isDetailed ? true : false,
      right: 20,
      top: 0,
      textStyle: {
        fontSize: 10
      },
      inRange: {
        color: [newMinMaxColors.minColor, newMinMaxColors.middleColor, newMinMaxColors.maxColor]
      },
    },
    tooltip: {
      position: ["20%", "20%"],
      formatter: function (params) {
        return `${params.value[1]} ${params.value[0]}h00 : ${Math.round(params.value[2] * 100) / 100} ${unit}${params.data?.isEstimated ? " (Donnée estimée)" : ""}`;
      }
    },
    // toolbox: {
    //   show: true,
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },

    series: series
  };

  if (!isLoading && localMinMax !== undefined) {
    option.visualMap.min = localMinMax.min
    option.visualMap.max = localMinMax.max
  }

  if (!isDetailed) {
    option.visualMap.text = [Math.round(minMax.max), Math.round(minMax.min)]
    option.visualMap.calculable = false
  }

  return (
    data && data.length === 0 ?
      <ChartNoData />
      :
      <ReactECharts
        notMerge={true}
        option={option}
        style={{ height: '100%', width: '100%' }}
      />
  )
}

export default ChartHeatmap
