/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addBuilding = /* GraphQL */ `
  mutation AddBuilding($input: AddBuildingInput) {
    addBuilding(input: $input) {
      Devices {
        items {
          airtable {
            analog_periods_without_data
            authorization_end_date
            automatic_upload
            building_tenths {
              building_tenth
              site_code
              site_id
              tenth
              property_owner_tenth
              distribution_tenth
              distribution_tenth_comment
              organisation_tenant
              __typename
            }
            delegated_authorization
            current_synoptic_quality_reporting
            consumption_2010_10years_in_kwh_pcs
            consumption_2011_10years_in_kwh_pcs
            consumption_2012_10years_in_kwh_pcs
            consumption_2013_10years_in_kwh_pcs
            consumption_2014_10years_in_kwh_pcs
            consumption_2015_10years_in_kwh_pcs
            consumption_2016_10years_in_kwh_pcs
            consumption_2017_10years_in_kwh_pcs
            consumption_2018_10years_in_kwh_pcs
            consumption_2019_10years_in_kwh_pcs
            consumption_2020_10years_in_kwh_pcs
            consumption_2021_10years_in_kwh_pcs
            consumption_2022_10years_in_kwh_pcs
            consumption_2023_10years_in_kwh_pcs
            contextual_information
            contract_holder
            customer_space_available
            deet_category
            deposited
            destination
            distributor_name
            energy_data_upload_possibilities
            fluid_type
            functional_entities {
              code
              tenant_code
              tenant_name
              type
              surface
              lots
              tertiary
              __typename
            }
            last_analog_invoice_date
            named_authorization
            pdl_id
            placement
            quality_of_data_completeness_2023
            questions_about_pdl_to_energy_manager
            site_code
            site_name
            sites {
              id
              address
              city
              code
              current_synoptic_quality_reporting
              name
              organisation_tenant
              postal_code
              __typename
            }
            sub_metering
            supplier_name
            tenth
            unique_point
            usage
            __typename
          }
          analysis {
            meter_commissioning {
              created_at
              text
              __typename
            }
            __typename
          }
          buildingId
          destination
          deviceType
          fluidSupplierId
          fluidType
          id
          name
          properties {
            id
            name
            quality {
              completeness_end_date
              completeness_start_date
              completenes_end_date
              completenes_start_date
              completeness
              missing_values
              ignored_intervals {
                start_date
                end_date
                __typename
              }
              overall_quality
              overall_quality_reason
              timeseries_end_date
              timeseries_start_date
              outliers {
                timestamp
                value
                unit
                __typename
              }
              __typename
            }
            unit
            value
            __typename
          }
          siteCode
          siteCodes
          siteId
          siteIds
          fusionedEnergyQuality
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      id
      location {
        address
        city
        country
        latitude
        longitude
        zipcode
        __typename
      }
      name
      portfolioId
      __typename
    }
  }
`;
export const addDevice = /* GraphQL */ `
  mutation AddDevice($input: AddDeviceInput!) {
    addDevice(input: $input) {
      airtable {
        analog_periods_without_data
        authorization_end_date
        automatic_upload
        building_tenths {
          building_tenth
          site_code
          site_id
          tenth
          property_owner_tenth
          distribution_tenth
          distribution_tenth_comment
          organisation_tenant
          __typename
        }
        delegated_authorization
        current_synoptic_quality_reporting
        consumption_2010_10years_in_kwh_pcs
        consumption_2011_10years_in_kwh_pcs
        consumption_2012_10years_in_kwh_pcs
        consumption_2013_10years_in_kwh_pcs
        consumption_2014_10years_in_kwh_pcs
        consumption_2015_10years_in_kwh_pcs
        consumption_2016_10years_in_kwh_pcs
        consumption_2017_10years_in_kwh_pcs
        consumption_2018_10years_in_kwh_pcs
        consumption_2019_10years_in_kwh_pcs
        consumption_2020_10years_in_kwh_pcs
        consumption_2021_10years_in_kwh_pcs
        consumption_2022_10years_in_kwh_pcs
        consumption_2023_10years_in_kwh_pcs
        contextual_information
        contract_holder
        customer_space_available
        deet_category
        deposited
        destination
        distributor_name
        energy_data_upload_possibilities
        fluid_type
        functional_entities {
          code
          tenant_code
          tenant_name
          type
          surface
          lots
          tertiary
          __typename
        }
        last_analog_invoice_date
        named_authorization
        pdl_id
        placement
        quality_of_data_completeness_2023
        questions_about_pdl_to_energy_manager
        site_code
        site_name
        sites {
          id
          address
          city
          code
          current_synoptic_quality_reporting
          name
          organisation_tenant
          postal_code
          __typename
        }
        sub_metering
        supplier_name
        tenth
        unique_point
        usage
        __typename
      }
      analysis {
        meter_commissioning {
          created_at
          text
          __typename
        }
        __typename
      }
      buildingId
      destination
      deviceType
      fluidSupplierId
      fluidType
      id
      name
      properties {
        id
        name
        quality {
          completeness_end_date
          completeness_start_date
          completenes_end_date
          completenes_start_date
          completeness
          missing_values
          ignored_intervals {
            start_date
            end_date
            __typename
          }
          overall_quality
          overall_quality_reason
          timeseries_end_date
          timeseries_start_date
          outliers {
            timestamp
            value
            unit
            __typename
          }
          __typename
        }
        unit
        value
        __typename
      }
      siteCode
      siteCodes
      siteId
      siteIds
      fusionedEnergyQuality
      __typename
    }
  }
`;
export const addDeviceQualityReasoning = /* GraphQL */ `
  mutation AddDeviceQualityReasoning($input: AddDeviceQualityReasoningInput) {
    addDeviceQualityReasoning(input: $input) {
      id
      createAt
      updatedAt
      updateBy
      reasoningType
      reasoningId
      reasoningText
      endDate
      startDate
      value
      reviewed
      outdated
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        username
        email
        role
        enabled
        perimeter {
          sites
          devices
          __typename
        }
        lastConnectionAt
        timeConstraintPerimeter {
          sites {
            id
            periods {
              startDate
              endDate
              __typename
            }
            __typename
          }
          devices {
            id
            periods {
              startDate
              endDate
              __typename
            }
            __typename
          }
          __typename
        }
        excludedPerimeter {
          sites
          devices
          __typename
        }
        __typename
      }
      password {
        username
        password
        __typename
      }
      __typename
    }
  }
`;
export const deleteBuilding = /* GraphQL */ `
  mutation DeleteBuilding($id: ID) {
    deleteBuilding(id: $id) {
      Devices {
        items {
          airtable {
            analog_periods_without_data
            authorization_end_date
            automatic_upload
            building_tenths {
              building_tenth
              site_code
              site_id
              tenth
              property_owner_tenth
              distribution_tenth
              distribution_tenth_comment
              organisation_tenant
              __typename
            }
            delegated_authorization
            current_synoptic_quality_reporting
            consumption_2010_10years_in_kwh_pcs
            consumption_2011_10years_in_kwh_pcs
            consumption_2012_10years_in_kwh_pcs
            consumption_2013_10years_in_kwh_pcs
            consumption_2014_10years_in_kwh_pcs
            consumption_2015_10years_in_kwh_pcs
            consumption_2016_10years_in_kwh_pcs
            consumption_2017_10years_in_kwh_pcs
            consumption_2018_10years_in_kwh_pcs
            consumption_2019_10years_in_kwh_pcs
            consumption_2020_10years_in_kwh_pcs
            consumption_2021_10years_in_kwh_pcs
            consumption_2022_10years_in_kwh_pcs
            consumption_2023_10years_in_kwh_pcs
            contextual_information
            contract_holder
            customer_space_available
            deet_category
            deposited
            destination
            distributor_name
            energy_data_upload_possibilities
            fluid_type
            functional_entities {
              code
              tenant_code
              tenant_name
              type
              surface
              lots
              tertiary
              __typename
            }
            last_analog_invoice_date
            named_authorization
            pdl_id
            placement
            quality_of_data_completeness_2023
            questions_about_pdl_to_energy_manager
            site_code
            site_name
            sites {
              id
              address
              city
              code
              current_synoptic_quality_reporting
              name
              organisation_tenant
              postal_code
              __typename
            }
            sub_metering
            supplier_name
            tenth
            unique_point
            usage
            __typename
          }
          analysis {
            meter_commissioning {
              created_at
              text
              __typename
            }
            __typename
          }
          buildingId
          destination
          deviceType
          fluidSupplierId
          fluidType
          id
          name
          properties {
            id
            name
            quality {
              completeness_end_date
              completeness_start_date
              completenes_end_date
              completenes_start_date
              completeness
              missing_values
              ignored_intervals {
                start_date
                end_date
                __typename
              }
              overall_quality
              overall_quality_reason
              timeseries_end_date
              timeseries_start_date
              outliers {
                timestamp
                value
                unit
                __typename
              }
              __typename
            }
            unit
            value
            __typename
          }
          siteCode
          siteCodes
          siteId
          siteIds
          fusionedEnergyQuality
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      id
      location {
        address
        city
        country
        latitude
        longitude
        zipcode
        __typename
      }
      name
      portfolioId
      __typename
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice($id: ID!) {
    deleteDevice(id: $id) {
      airtable {
        analog_periods_without_data
        authorization_end_date
        automatic_upload
        building_tenths {
          building_tenth
          site_code
          site_id
          tenth
          property_owner_tenth
          distribution_tenth
          distribution_tenth_comment
          organisation_tenant
          __typename
        }
        delegated_authorization
        current_synoptic_quality_reporting
        consumption_2010_10years_in_kwh_pcs
        consumption_2011_10years_in_kwh_pcs
        consumption_2012_10years_in_kwh_pcs
        consumption_2013_10years_in_kwh_pcs
        consumption_2014_10years_in_kwh_pcs
        consumption_2015_10years_in_kwh_pcs
        consumption_2016_10years_in_kwh_pcs
        consumption_2017_10years_in_kwh_pcs
        consumption_2018_10years_in_kwh_pcs
        consumption_2019_10years_in_kwh_pcs
        consumption_2020_10years_in_kwh_pcs
        consumption_2021_10years_in_kwh_pcs
        consumption_2022_10years_in_kwh_pcs
        consumption_2023_10years_in_kwh_pcs
        contextual_information
        contract_holder
        customer_space_available
        deet_category
        deposited
        destination
        distributor_name
        energy_data_upload_possibilities
        fluid_type
        functional_entities {
          code
          tenant_code
          tenant_name
          type
          surface
          lots
          tertiary
          __typename
        }
        last_analog_invoice_date
        named_authorization
        pdl_id
        placement
        quality_of_data_completeness_2023
        questions_about_pdl_to_energy_manager
        site_code
        site_name
        sites {
          id
          address
          city
          code
          current_synoptic_quality_reporting
          name
          organisation_tenant
          postal_code
          __typename
        }
        sub_metering
        supplier_name
        tenth
        unique_point
        usage
        __typename
      }
      analysis {
        meter_commissioning {
          created_at
          text
          __typename
        }
        __typename
      }
      buildingId
      destination
      deviceType
      fluidSupplierId
      fluidType
      id
      name
      properties {
        id
        name
        quality {
          completeness_end_date
          completeness_start_date
          completenes_end_date
          completenes_start_date
          completeness
          missing_values
          ignored_intervals {
            start_date
            end_date
            __typename
          }
          overall_quality
          overall_quality_reason
          timeseries_end_date
          timeseries_start_date
          outliers {
            timestamp
            value
            unit
            __typename
          }
          __typename
        }
        unit
        value
        __typename
      }
      siteCode
      siteCodes
      siteId
      siteIds
      fusionedEnergyQuality
      __typename
    }
  }
`;
export const deleteDeviceQualityReasoning = /* GraphQL */ `
  mutation DeleteDeviceQualityReasoning(
    $input: deleteDeviceQualityReasoningInput
  ) {
    deleteDeviceQualityReasoning(input: $input) {
      id
      createAt
      updatedAt
      updateBy
      reasoningType
      reasoningId
      reasoningText
      endDate
      startDate
      value
      reviewed
      outdated
      __typename
    }
  }
`;
export const streamAnalysis = /* GraphQL */ `
  mutation StreamAnalysis($input: StreamAnalysisInput) {
    streamAnalysis(input: $input) {
      id
      analysisType
      analysisClass
      assistent_prompt
      status
      system_prompt
      user_prompt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  mutation SyncUsers($input: SyncUsersInput) {
    syncUsers(input: $input) {
      users {
        username
        id
        email
        role
        enabled
        perimeter {
          sites
          devices
          __typename
        }
        timeConstraintPerimeter {
          sites {
            id
            periods {
              startDate
              endDate
              __typename
            }
            __typename
          }
          devices {
            id
            periods {
              startDate
              endDate
              __typename
            }
            __typename
          }
          __typename
        }
        excludedPerimeter {
          sites
          devices
          __typename
        }
        __typename
      }
      new_users {
        username
        id
        email
        role
        enabled
        perimeter {
          sites
          devices
          __typename
        }
        timeConstraintPerimeter {
          sites {
            id
            periods {
              startDate
              endDate
              __typename
            }
            __typename
          }
          devices {
            id
            periods {
              startDate
              endDate
              __typename
            }
            __typename
          }
          __typename
        }
        excludedPerimeter {
          sites
          devices
          __typename
        }
        __typename
      }
      same_users {
        username
        id
        email
        role
        enabled
        perimeter {
          sites
          devices
          __typename
        }
        timeConstraintPerimeter {
          sites {
            id
            periods {
              startDate
              endDate
              __typename
            }
            __typename
          }
          devices {
            id
            periods {
              startDate
              endDate
              __typename
            }
            __typename
          }
          __typename
        }
        excludedPerimeter {
          sites
          devices
          __typename
        }
        __typename
      }
      changed_users {
        id
        username
        changed_values {
          email {
            new_value
            old_value
            __typename
          }
          enabled {
            new_value
            old_value
            __typename
          }
          role {
            new_value
            old_value
            __typename
          }
          perimeter {
            new_value {
              sites
              devices
              __typename
            }
            old_value {
              sites
              devices
              __typename
            }
            __typename
          }
          timeConstraintPerimeter {
            new_value {
              sites {
                id
                periods {
                  startDate
                  endDate
                  __typename
                }
                __typename
              }
              devices {
                id
                periods {
                  startDate
                  endDate
                  __typename
                }
                __typename
              }
              __typename
            }
            old_value {
              sites {
                id
                periods {
                  startDate
                  endDate
                  __typename
                }
                __typename
              }
              devices {
                id
                periods {
                  startDate
                  endDate
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          excludedPerimeter {
            new_value {
              sites
              devices
              __typename
            }
            old_value {
              sites
              devices
              __typename
            }
            __typename
          }
          __typename
        }
        same_values {
          id
          username
          email
          role
          enabled
          perimeter {
            sites
            devices
            __typename
          }
          timeConstraintPerimeter {
            sites {
              id
              periods {
                startDate
                endDate
                __typename
              }
              __typename
            }
            devices {
              id
              periods {
                startDate
                endDate
                __typename
              }
              __typename
            }
            __typename
          }
          excludedPerimeter {
            sites
            devices
            __typename
          }
          __typename
        }
        __typename
      }
      passwords {
        username
        password
        __typename
      }
      __typename
    }
  }
`;
export const syncSubdevices = /* GraphQL */ `
  mutation SyncSubdevices($input: SyncSubdevicesInput) {
    syncSubdevices(input: $input) {
      items {
        id
        name
        deviceId
        siteId
        description
        building
        lessor
        contract_holder
        timeseries {
          grid_name
          monotone_cumulative_time_in_min
          monotone_end_date
          monotone_execution_time
          monotone_start_date
          nature
          reason
          sampling_rate_in_min
          source
          time
          value
          value_nature_brut
          value_nature_corrige
          value_nature_coupure_secteur
          value_nature_coupure_secteur_courte
          value_nature_debut_de_coupure_secteur
          value_nature_esti
          value_nature_estime
          value_nature_fin_de_coupure_secteur
          value_nature_importe_manuellement_par_le_metier_enedis
          value_nature_puissance_reconstituee_et_coupure_secteur
          value_nature_reel
          value_nature_regu
          value_nature_estimated
          value_nature_actual
          value_nature_corrected
          value_nature_no_data
          value_nature_absence
          value_source_airtable_manual_bills
          value_source_airtable_third_party_active_power_in_kwh
          value_source_airtable_third_party_energy
          value_source_enedis_active_power_daily_in_kwh
          value_source_enedis_billed_energy
          value_season_base
          value_season_hc
          value_season_hcb
          value_season_hce
          value_season_hch
          value_season_hp
          value_season_hpb
          value_season_hph
          value_season_hpe
          value_season_ja
          value_season_p
          value_season_pointe
          likelihood_index_season_base
          likelihood_index_season_hc
          likelihood_index_season_hcb
          likelihood_index_season_hce
          likelihood_index_season_hch
          likelihood_index_season_hp
          likelihood_index_season_hpb
          likelihood_index_season_hpe
          likelihood_index_season_hph
          likelihood_index_season_ja
          likelihood_index_season_p
          likelihood_index_season_pointe
          read_reason
          season_class_dict
          __typename
        }
        __typename
      }
      new {
        id
        name
        deviceId
        siteId
        description
        building
        lessor
        contract_holder
        timeseries {
          grid_name
          monotone_cumulative_time_in_min
          monotone_end_date
          monotone_execution_time
          monotone_start_date
          nature
          reason
          sampling_rate_in_min
          source
          time
          value
          value_nature_brut
          value_nature_corrige
          value_nature_coupure_secteur
          value_nature_coupure_secteur_courte
          value_nature_debut_de_coupure_secteur
          value_nature_esti
          value_nature_estime
          value_nature_fin_de_coupure_secteur
          value_nature_importe_manuellement_par_le_metier_enedis
          value_nature_puissance_reconstituee_et_coupure_secteur
          value_nature_reel
          value_nature_regu
          value_nature_estimated
          value_nature_actual
          value_nature_corrected
          value_nature_no_data
          value_nature_absence
          value_source_airtable_manual_bills
          value_source_airtable_third_party_active_power_in_kwh
          value_source_airtable_third_party_energy
          value_source_enedis_active_power_daily_in_kwh
          value_source_enedis_billed_energy
          value_season_base
          value_season_hc
          value_season_hcb
          value_season_hce
          value_season_hch
          value_season_hp
          value_season_hpb
          value_season_hph
          value_season_hpe
          value_season_ja
          value_season_p
          value_season_pointe
          likelihood_index_season_base
          likelihood_index_season_hc
          likelihood_index_season_hcb
          likelihood_index_season_hce
          likelihood_index_season_hch
          likelihood_index_season_hp
          likelihood_index_season_hpb
          likelihood_index_season_hpe
          likelihood_index_season_hph
          likelihood_index_season_ja
          likelihood_index_season_p
          likelihood_index_season_pointe
          read_reason
          season_class_dict
          __typename
        }
        __typename
      }
      same {
        id
        name
        deviceId
        siteId
        description
        building
        lessor
        contract_holder
        timeseries {
          grid_name
          monotone_cumulative_time_in_min
          monotone_end_date
          monotone_execution_time
          monotone_start_date
          nature
          reason
          sampling_rate_in_min
          source
          time
          value
          value_nature_brut
          value_nature_corrige
          value_nature_coupure_secteur
          value_nature_coupure_secteur_courte
          value_nature_debut_de_coupure_secteur
          value_nature_esti
          value_nature_estime
          value_nature_fin_de_coupure_secteur
          value_nature_importe_manuellement_par_le_metier_enedis
          value_nature_puissance_reconstituee_et_coupure_secteur
          value_nature_reel
          value_nature_regu
          value_nature_estimated
          value_nature_actual
          value_nature_corrected
          value_nature_no_data
          value_nature_absence
          value_source_airtable_manual_bills
          value_source_airtable_third_party_active_power_in_kwh
          value_source_airtable_third_party_energy
          value_source_enedis_active_power_daily_in_kwh
          value_source_enedis_billed_energy
          value_season_base
          value_season_hc
          value_season_hcb
          value_season_hce
          value_season_hch
          value_season_hp
          value_season_hpb
          value_season_hph
          value_season_hpe
          value_season_ja
          value_season_p
          value_season_pointe
          likelihood_index_season_base
          likelihood_index_season_hc
          likelihood_index_season_hcb
          likelihood_index_season_hce
          likelihood_index_season_hch
          likelihood_index_season_hp
          likelihood_index_season_hpb
          likelihood_index_season_hpe
          likelihood_index_season_hph
          likelihood_index_season_ja
          likelihood_index_season_p
          likelihood_index_season_pointe
          read_reason
          season_class_dict
          __typename
        }
        __typename
      }
      changed {
        id
        name
        changedValues {
          name {
            new_value
            old_value
            __typename
          }
          deviceId {
            new_value
            old_value
            __typename
          }
          siteId {
            new_value
            old_value
            __typename
          }
          description {
            new_value
            old_value
            __typename
          }
          building {
            new_value
            old_value
            __typename
          }
          lessor {
            new_value
            old_value
            __typename
          }
          contract_holder {
            new_value
            old_value
            __typename
          }
          timeseries {
            new_value {
              grid_name
              monotone_cumulative_time_in_min
              monotone_end_date
              monotone_execution_time
              monotone_start_date
              nature
              reason
              sampling_rate_in_min
              source
              time
              value
              value_nature_brut
              value_nature_corrige
              value_nature_coupure_secteur
              value_nature_coupure_secteur_courte
              value_nature_debut_de_coupure_secteur
              value_nature_esti
              value_nature_estime
              value_nature_fin_de_coupure_secteur
              value_nature_importe_manuellement_par_le_metier_enedis
              value_nature_puissance_reconstituee_et_coupure_secteur
              value_nature_reel
              value_nature_regu
              value_nature_estimated
              value_nature_actual
              value_nature_corrected
              value_nature_no_data
              value_nature_absence
              value_source_airtable_manual_bills
              value_source_airtable_third_party_active_power_in_kwh
              value_source_airtable_third_party_energy
              value_source_enedis_active_power_daily_in_kwh
              value_source_enedis_billed_energy
              value_season_base
              value_season_hc
              value_season_hcb
              value_season_hce
              value_season_hch
              value_season_hp
              value_season_hpb
              value_season_hph
              value_season_hpe
              value_season_ja
              value_season_p
              value_season_pointe
              likelihood_index_season_base
              likelihood_index_season_hc
              likelihood_index_season_hcb
              likelihood_index_season_hce
              likelihood_index_season_hch
              likelihood_index_season_hp
              likelihood_index_season_hpb
              likelihood_index_season_hpe
              likelihood_index_season_hph
              likelihood_index_season_ja
              likelihood_index_season_p
              likelihood_index_season_pointe
              read_reason
              season_class_dict
              __typename
            }
            old_value {
              grid_name
              monotone_cumulative_time_in_min
              monotone_end_date
              monotone_execution_time
              monotone_start_date
              nature
              reason
              sampling_rate_in_min
              source
              time
              value
              value_nature_brut
              value_nature_corrige
              value_nature_coupure_secteur
              value_nature_coupure_secteur_courte
              value_nature_debut_de_coupure_secteur
              value_nature_esti
              value_nature_estime
              value_nature_fin_de_coupure_secteur
              value_nature_importe_manuellement_par_le_metier_enedis
              value_nature_puissance_reconstituee_et_coupure_secteur
              value_nature_reel
              value_nature_regu
              value_nature_estimated
              value_nature_actual
              value_nature_corrected
              value_nature_no_data
              value_nature_absence
              value_source_airtable_manual_bills
              value_source_airtable_third_party_active_power_in_kwh
              value_source_airtable_third_party_energy
              value_source_enedis_active_power_daily_in_kwh
              value_source_enedis_billed_energy
              value_season_base
              value_season_hc
              value_season_hcb
              value_season_hce
              value_season_hch
              value_season_hp
              value_season_hpb
              value_season_hph
              value_season_hpe
              value_season_ja
              value_season_p
              value_season_pointe
              likelihood_index_season_base
              likelihood_index_season_hc
              likelihood_index_season_hcb
              likelihood_index_season_hce
              likelihood_index_season_hch
              likelihood_index_season_hp
              likelihood_index_season_hpb
              likelihood_index_season_hpe
              likelihood_index_season_hph
              likelihood_index_season_ja
              likelihood_index_season_p
              likelihood_index_season_pointe
              read_reason
              season_class_dict
              __typename
            }
            __typename
          }
          __typename
        }
        sameValues {
          id
          name
          deviceId
          siteId
          description
          building
          lessor
          contract_holder
          timeseries {
            grid_name
            monotone_cumulative_time_in_min
            monotone_end_date
            monotone_execution_time
            monotone_start_date
            nature
            reason
            sampling_rate_in_min
            source
            time
            value
            value_nature_brut
            value_nature_corrige
            value_nature_coupure_secteur
            value_nature_coupure_secteur_courte
            value_nature_debut_de_coupure_secteur
            value_nature_esti
            value_nature_estime
            value_nature_fin_de_coupure_secteur
            value_nature_importe_manuellement_par_le_metier_enedis
            value_nature_puissance_reconstituee_et_coupure_secteur
            value_nature_reel
            value_nature_regu
            value_nature_estimated
            value_nature_actual
            value_nature_corrected
            value_nature_no_data
            value_nature_absence
            value_source_airtable_manual_bills
            value_source_airtable_third_party_active_power_in_kwh
            value_source_airtable_third_party_energy
            value_source_enedis_active_power_daily_in_kwh
            value_source_enedis_billed_energy
            value_season_base
            value_season_hc
            value_season_hcb
            value_season_hce
            value_season_hch
            value_season_hp
            value_season_hpb
            value_season_hph
            value_season_hpe
            value_season_ja
            value_season_p
            value_season_pointe
            likelihood_index_season_base
            likelihood_index_season_hc
            likelihood_index_season_hcb
            likelihood_index_season_hce
            likelihood_index_season_hch
            likelihood_index_season_hp
            likelihood_index_season_hpb
            likelihood_index_season_hpe
            likelihood_index_season_hph
            likelihood_index_season_ja
            likelihood_index_season_p
            likelihood_index_season_pointe
            read_reason
            season_class_dict
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const syncSubdevicesTimeseries = /* GraphQL */ `
  mutation SyncSubdevicesTimeseries($input: SyncSubdevicesTimeseriesInput) {
    syncSubdevicesTimeseries(input: $input) {
      nextToken
      scannedCount
      startedAt
      items {
        id
        deviceId
        pagination {
          nextToken
          startedAt
          __typename
        }
        propertyId
        timeseries {
          grid_name
          monotone_cumulative_time_in_min
          monotone_end_date
          monotone_execution_time
          monotone_start_date
          nature
          reason
          sampling_rate_in_min
          source
          time
          value
          value_nature_brut
          value_nature_corrige
          value_nature_coupure_secteur
          value_nature_coupure_secteur_courte
          value_nature_debut_de_coupure_secteur
          value_nature_esti
          value_nature_estime
          value_nature_fin_de_coupure_secteur
          value_nature_importe_manuellement_par_le_metier_enedis
          value_nature_puissance_reconstituee_et_coupure_secteur
          value_nature_reel
          value_nature_regu
          value_nature_estimated
          value_nature_actual
          value_nature_corrected
          value_nature_no_data
          value_nature_absence
          value_source_airtable_manual_bills
          value_source_airtable_third_party_active_power_in_kwh
          value_source_airtable_third_party_energy
          value_source_enedis_active_power_daily_in_kwh
          value_source_enedis_billed_energy
          value_season_base
          value_season_hc
          value_season_hcb
          value_season_hce
          value_season_hch
          value_season_hp
          value_season_hpb
          value_season_hph
          value_season_hpe
          value_season_ja
          value_season_p
          value_season_pointe
          likelihood_index_season_base
          likelihood_index_season_hc
          likelihood_index_season_hcb
          likelihood_index_season_hce
          likelihood_index_season_hch
          likelihood_index_season_hp
          likelihood_index_season_hpb
          likelihood_index_season_hpe
          likelihood_index_season_hph
          likelihood_index_season_ja
          likelihood_index_season_p
          likelihood_index_season_pointe
          read_reason
          season_class_dict
          __typename
        }
        unit
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateAnalysis = /* GraphQL */ `
  mutation UpdateAnalysis($input: UpdateAnalysisInput!) {
    updateAnalysis(input: $input) {
      id
      analysisType
      analysisClass
      assistent_prompt
      status
      system_prompt
      user_prompt
      __typename
    }
  }
`;
export const updateBuilding = /* GraphQL */ `
  mutation UpdateBuilding($input: UpdateBuildingInput!) {
    updateBuilding(input: $input) {
      Devices {
        items {
          airtable {
            analog_periods_without_data
            authorization_end_date
            automatic_upload
            building_tenths {
              building_tenth
              site_code
              site_id
              tenth
              property_owner_tenth
              distribution_tenth
              distribution_tenth_comment
              organisation_tenant
              __typename
            }
            delegated_authorization
            current_synoptic_quality_reporting
            consumption_2010_10years_in_kwh_pcs
            consumption_2011_10years_in_kwh_pcs
            consumption_2012_10years_in_kwh_pcs
            consumption_2013_10years_in_kwh_pcs
            consumption_2014_10years_in_kwh_pcs
            consumption_2015_10years_in_kwh_pcs
            consumption_2016_10years_in_kwh_pcs
            consumption_2017_10years_in_kwh_pcs
            consumption_2018_10years_in_kwh_pcs
            consumption_2019_10years_in_kwh_pcs
            consumption_2020_10years_in_kwh_pcs
            consumption_2021_10years_in_kwh_pcs
            consumption_2022_10years_in_kwh_pcs
            consumption_2023_10years_in_kwh_pcs
            contextual_information
            contract_holder
            customer_space_available
            deet_category
            deposited
            destination
            distributor_name
            energy_data_upload_possibilities
            fluid_type
            functional_entities {
              code
              tenant_code
              tenant_name
              type
              surface
              lots
              tertiary
              __typename
            }
            last_analog_invoice_date
            named_authorization
            pdl_id
            placement
            quality_of_data_completeness_2023
            questions_about_pdl_to_energy_manager
            site_code
            site_name
            sites {
              id
              address
              city
              code
              current_synoptic_quality_reporting
              name
              organisation_tenant
              postal_code
              __typename
            }
            sub_metering
            supplier_name
            tenth
            unique_point
            usage
            __typename
          }
          analysis {
            meter_commissioning {
              created_at
              text
              __typename
            }
            __typename
          }
          buildingId
          destination
          deviceType
          fluidSupplierId
          fluidType
          id
          name
          properties {
            id
            name
            quality {
              completeness_end_date
              completeness_start_date
              completenes_end_date
              completenes_start_date
              completeness
              missing_values
              ignored_intervals {
                start_date
                end_date
                __typename
              }
              overall_quality
              overall_quality_reason
              timeseries_end_date
              timeseries_start_date
              outliers {
                timestamp
                value
                unit
                __typename
              }
              __typename
            }
            unit
            value
            __typename
          }
          siteCode
          siteCodes
          siteId
          siteIds
          fusionedEnergyQuality
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      id
      location {
        address
        city
        country
        latitude
        longitude
        zipcode
        __typename
      }
      name
      portfolioId
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      airtable {
        analog_periods_without_data
        authorization_end_date
        automatic_upload
        building_tenths {
          building_tenth
          site_code
          site_id
          tenth
          property_owner_tenth
          distribution_tenth
          distribution_tenth_comment
          organisation_tenant
          __typename
        }
        delegated_authorization
        current_synoptic_quality_reporting
        consumption_2010_10years_in_kwh_pcs
        consumption_2011_10years_in_kwh_pcs
        consumption_2012_10years_in_kwh_pcs
        consumption_2013_10years_in_kwh_pcs
        consumption_2014_10years_in_kwh_pcs
        consumption_2015_10years_in_kwh_pcs
        consumption_2016_10years_in_kwh_pcs
        consumption_2017_10years_in_kwh_pcs
        consumption_2018_10years_in_kwh_pcs
        consumption_2019_10years_in_kwh_pcs
        consumption_2020_10years_in_kwh_pcs
        consumption_2021_10years_in_kwh_pcs
        consumption_2022_10years_in_kwh_pcs
        consumption_2023_10years_in_kwh_pcs
        contextual_information
        contract_holder
        customer_space_available
        deet_category
        deposited
        destination
        distributor_name
        energy_data_upload_possibilities
        fluid_type
        functional_entities {
          code
          tenant_code
          tenant_name
          type
          surface
          lots
          tertiary
          __typename
        }
        last_analog_invoice_date
        named_authorization
        pdl_id
        placement
        quality_of_data_completeness_2023
        questions_about_pdl_to_energy_manager
        site_code
        site_name
        sites {
          id
          address
          city
          code
          current_synoptic_quality_reporting
          name
          organisation_tenant
          postal_code
          __typename
        }
        sub_metering
        supplier_name
        tenth
        unique_point
        usage
        __typename
      }
      analysis {
        meter_commissioning {
          created_at
          text
          __typename
        }
        __typename
      }
      buildingId
      destination
      deviceType
      fluidSupplierId
      fluidType
      id
      name
      properties {
        id
        name
        quality {
          completeness_end_date
          completeness_start_date
          completenes_end_date
          completenes_start_date
          completeness
          missing_values
          ignored_intervals {
            start_date
            end_date
            __typename
          }
          overall_quality
          overall_quality_reason
          timeseries_end_date
          timeseries_start_date
          outliers {
            timestamp
            value
            unit
            __typename
          }
          __typename
        }
        unit
        value
        __typename
      }
      siteCode
      siteCodes
      siteId
      siteIds
      fusionedEnergyQuality
      __typename
    }
  }
`;
export const updateDeviceQualityReasoning = /* GraphQL */ `
  mutation UpdateDeviceQualityReasoning(
    $input: UpdateDeviceQualityReasoningInput!
  ) {
    updateDeviceQualityReasoning(input: $input) {
      id
      createAt
      updatedAt
      updateBy
      reasoningType
      reasoningId
      reasoningText
      endDate
      startDate
      value
      reviewed
      outdated
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      username
      email
      role
      enabled
      perimeter {
        sites
        devices
        __typename
      }
      lastConnectionAt
      timeConstraintPerimeter {
        sites {
          id
          periods {
            startDate
            endDate
            __typename
          }
          __typename
        }
        devices {
          id
          periods {
            startDate
            endDate
            __typename
          }
          __typename
        }
        __typename
      }
      excludedPerimeter {
        sites
        devices
        __typename
      }
      __typename
    }
  }
`;
export const updateSubDevice = /* GraphQL */ `
  mutation UpdateSubDevice($input: UpdateSubDeviceInput!) {
    updateSubDevice(input: $input) {
      id
      name
      deviceId
      siteId
      description
      building
      lessor
      contract_holder
      __typename
    }
  }
`;
export const updateSubDeviceTimeseries = /* GraphQL */ `
  mutation UpdateSubDeviceTimeseries($input: UpdateSubDeviceTimeseriesInput!) {
    updateSubDeviceTimeseries(input: $input) {
      nextToken
      scannedCount
      startedAt
      items {
        id
        deviceId
        pagination {
          nextToken
          startedAt
          __typename
        }
        propertyId
        timeseries {
          grid_name
          monotone_cumulative_time_in_min
          monotone_end_date
          monotone_execution_time
          monotone_start_date
          nature
          reason
          sampling_rate_in_min
          source
          time
          value
          value_nature_brut
          value_nature_corrige
          value_nature_coupure_secteur
          value_nature_coupure_secteur_courte
          value_nature_debut_de_coupure_secteur
          value_nature_esti
          value_nature_estime
          value_nature_fin_de_coupure_secteur
          value_nature_importe_manuellement_par_le_metier_enedis
          value_nature_puissance_reconstituee_et_coupure_secteur
          value_nature_reel
          value_nature_regu
          value_nature_estimated
          value_nature_actual
          value_nature_corrected
          value_nature_no_data
          value_nature_absence
          value_source_airtable_manual_bills
          value_source_airtable_third_party_active_power_in_kwh
          value_source_airtable_third_party_energy
          value_source_enedis_active_power_daily_in_kwh
          value_source_enedis_billed_energy
          value_season_base
          value_season_hc
          value_season_hcb
          value_season_hce
          value_season_hch
          value_season_hp
          value_season_hpb
          value_season_hph
          value_season_hpe
          value_season_ja
          value_season_p
          value_season_pointe
          likelihood_index_season_base
          likelihood_index_season_hc
          likelihood_index_season_hcb
          likelihood_index_season_hce
          likelihood_index_season_hch
          likelihood_index_season_hp
          likelihood_index_season_hpb
          likelihood_index_season_hpe
          likelihood_index_season_hph
          likelihood_index_season_ja
          likelihood_index_season_p
          likelihood_index_season_pointe
          read_reason
          season_class_dict
          __typename
        }
        unit
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const publishGetDevicePropertyTimeseriesAsyncResult = /* GraphQL */ `
  mutation PublishGetDevicePropertyTimeseriesAsyncResult(
    $input: PublishGetDevicePropertyAsyncResultInput!
  ) {
    publishGetDevicePropertyTimeseriesAsyncResult(input: $input) {
      id
      timeseries {
        grid_name
        monotone_cumulative_time_in_min
        monotone_end_date
        monotone_execution_time
        monotone_start_date
        nature
        reason
        sampling_rate_in_min
        source
        time
        value
        value_nature_brut
        value_nature_corrige
        value_nature_coupure_secteur
        value_nature_coupure_secteur_courte
        value_nature_debut_de_coupure_secteur
        value_nature_esti
        value_nature_estime
        value_nature_fin_de_coupure_secteur
        value_nature_importe_manuellement_par_le_metier_enedis
        value_nature_puissance_reconstituee_et_coupure_secteur
        value_nature_reel
        value_nature_regu
        value_nature_estimated
        value_nature_actual
        value_nature_corrected
        value_nature_no_data
        value_nature_absence
        value_source_airtable_manual_bills
        value_source_airtable_third_party_active_power_in_kwh
        value_source_airtable_third_party_energy
        value_source_enedis_active_power_daily_in_kwh
        value_source_enedis_billed_energy
        value_season_base
        value_season_hc
        value_season_hcb
        value_season_hce
        value_season_hch
        value_season_hp
        value_season_hpb
        value_season_hph
        value_season_hpe
        value_season_ja
        value_season_p
        value_season_pointe
        likelihood_index_season_base
        likelihood_index_season_hc
        likelihood_index_season_hcb
        likelihood_index_season_hce
        likelihood_index_season_hch
        likelihood_index_season_hp
        likelihood_index_season_hpb
        likelihood_index_season_hpe
        likelihood_index_season_hph
        likelihood_index_season_ja
        likelihood_index_season_p
        likelihood_index_season_pointe
        read_reason
        season_class_dict
        __typename
      }
      unit
      token
      status
      chunkTotal
      chunkCurrent
      __typename
    }
  }
`;
