import { Card, CardHeader } from "@mui/material";
import React from 'react';
import { useGetOne, useTranslate } from "react-admin";

import DeviceIcon from "../../assets/DeviceIcon";
import InfoTooltipIcon from "../../assets/InfoTooltipIcon";
import SiteIcon from "../../assets/SiteIcon";
import { findAlgoliaMatchsKeys } from "../../components/aegeon/helper";
import globalUseStyles from "../../styles/globalCustomStyles";
import CustomTable from "../Table/CustomTable";
import CustomTooltip from "../aegeon/CustomTooltip";
import ChartLoading from "../aegeon/charts/ChartLoading";
import { value_with_unit } from "../aegeon/helper";

const SearchResultCard = (props) => {
    const t = useTranslate();
    const globalClasses = globalUseStyles();
    const hit = props.hit;
    const resource = hit?.resource;
    const id = hit.id;

    const {
        data: record,
        isLoading,
    } = useGetOne(
        resource + "s",
        { id: id }
    );

    const highlightResult = hit._highlightResult
    const matchedItems = findAlgoliaMatchsKeys(highlightResult, highlightResult, "card", t)

    const MatchedItemsComponent = () => {
        if (matchedItems.length > 0) {
            return (
                <span>
                    {matchedItems.map((item) => (
                        <span dangerouslySetInnerHTML={{ __html: item + ' • ' }} />
                    ))}
                </span>
            )
        }
        else {
            return (
                <></>
            )
        }
    }

    const aegeon_energy_data_type = Array.from(new Set(record?.airtable?.aegeon_energy_data_type))
    const aegeon_energy_data_type_value = aegeon_energy_data_type?.length === 1 ? aegeon_energy_data_type[0] : aegeon_energy_data_type?.length > 1 ? t('sites.general_information.aegeon_energy_data_types.mixed') : ""

    const info = resource === "device" ?
        {
            title: t(`${resource}s.title`) + " " + record?.name,
            subtitle: t(`sites.title`) + (record?.airtable?.site_code ? record?.airtable?.site_code : '') + ' ',
            rows: [
                { name: t('devices.general_information.fluid'), value: t(`devices.general_information.fluids.${record?.airtable?.fluid_type}`), hide_if_null: true },
                { name: t('devices.general_information.data_quality'), value: record?.fusionedEnergyQuality, hide_if_null: true },
                { name: t('devices.general_information.usage'), value: record?.airtable?.destination, hide_if_null: true },
                { name: t('devices.general_information.automatic_upload'), value: record?.airtable?.automatic_upload, hide_if_null: true },
            ],
            icon: <DeviceIcon />
        } : resource === "site" ? {
            title: t(`${resource}s.title`) + " " + record?.airtable?.code,
            subtitle: record?.name,
            rows: [
                { name: t('sites.general_information.address'), value: record?.airtable?.address + ', ' + record?.airtable?.postal_code + ' ' + record?.airtable?.city, hide_if_null: true },
                { name: t('sites.general_information.fluid'), value: record?.airtable?.fluids_typology?.filter(element => element).map(element => t("sites.general_information.fluids." + element)).join(', '), hide_if_null: true },
                { name: t('sites.general_information.synoptic_quality'), value: record?.airtable?.current_synoptic_quality_reporting, hide_if_null: true },
                { name: t('sites.general_information.current_typology'), value: record?.airtable?.current_typology, hide_if_null: true },
                { name: t('sites.general_information.validated_client_surface'), value: value_with_unit(record?.airtable?.validated_client_surface, "m²"), hide_if_null: true },
                { name: t('sites.general_information.aegeon_energy_data_type'), value: aegeon_energy_data_type_value, hide_if_null: true },
            ],
            icon: <SiteIcon />
        } : {
        }

    const tooltip_text = "Cliquez pour ouvrir la fiche"

    return (
        <a href={"#/" + resource + "s/" + hit?.id + "/show"}>
            <Card className={`${globalClasses.Card} ${props.className}`} >
                {isLoading ? <ChartLoading /> :
                    <>
                        <div className={globalClasses.cardTitle}>
                            <div className={globalClasses.cardIconTitle}>
                                {info.icon}
                                <CardHeader
                                    title={info.title}
                                    className={globalClasses.CardHeader}
                                />
                            </div>
                            {tooltip_text && <CustomTooltip title={tooltip_text} icon={<InfoTooltipIcon />} />}
                        </div>
                        <div className={globalClasses.cardSubtitle}>
                            {/* {info.subtitle} */}
                            {matchedItems.length > 0 && <MatchedItemsComponent />}
                        </div>
                        {/* <MatchedItemsComponent /> */}
                        <div style={{ width: "100%", height: "80%" }}>
                            <CustomTable rows={info.rows} defaultTooltip={""} />
                        </div>
                    </>
                }
            </Card>

        </a>
    )
};

export default SearchResultCard;

