
const getSeasonFilter = (season) => {
  const moment = require('moment-timezone');
  const one_month_ago = moment().tz('Europe/Paris').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(30, 'days');
  const today = moment().tz('Europe/Paris').set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).subtract(2, 'days');
  const filter_month = { startDate: one_month_ago, endDate: today };
  
  const today_month = today.month();
  const today_date = today.date();
  switch (season) {
    case 'winter':
      const winter_has_passed = today_month > 0 || (today_month === 0 && today_date > 15);
      const winter_start_date = winter_has_passed ? moment().tz('Europe/Paris').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').month(11).date(15) : moment().tz('Europe/Paris').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(2, 'years').month(11).date(15);
      const winter_end_date = winter_start_date.clone().add(1, 'months');
      return { startDate: winter_start_date, endDate: winter_end_date };
    case 'spring':
      const spring_has_passed = today_month > 3 || (today_month === 3 && today_date > 15);
      const spring_start_date = spring_has_passed ? moment().tz('Europe/Paris').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).month(2).date(15) : moment().tz('Europe/Paris').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').month(2).date(15);
      const spring_end_date = spring_start_date.clone().add(1, 'months');
      return { startDate: spring_start_date, endDate: spring_end_date };
    case 'summer':
      const summer_has_passed = today_month > 6 || (today_month === 6 && today_date > 15);
      const summer_start_date = summer_has_passed ? moment().tz('Europe/Paris').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).month(5).date(15) : moment().tz('Europe/Paris').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').month(5).date(15);
      const summer_end_date = summer_start_date.clone().add(1, 'months');
      return { startDate: summer_start_date, endDate: summer_end_date };
    case 'autumn':
      const autumn_has_passed = today_month > 9 || (today_month === 9 && today_date > 15);
      const autumn_start_date = autumn_has_passed ? moment().tz('Europe/Paris').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).month(8).date(15) : moment().tz('Europe/Paris').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').month(8).date(15);
      const autumn_end_date = autumn_start_date.clone().add(1, 'months');
      return { startDate: autumn_start_date, endDate: autumn_end_date };
    default:
      return filter_month;
  }
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function getFormattedDate(date_string) {
  const moment = require('moment-timezone');
  const date = moment(date_string).tz('Europe/Paris');
  return date
}

const getGroupedData = (timeseries) => {
  let data_result = []
  data_result = timeseries.map(function (item) { return { "time": getFormattedDate(item.time), "value": item.value, "estimated_value": (item.value_nature_brut + item.value_nature_reel + item.value_nature_corrige) < 0.95*item.value } });
  var result = [];
  data_result.reduce(function (res, value) {
    if (!res[value.time]) {
      res[value.time] = { time: value.time, value: 0, estimated_value: value.estimated_value };
      result.push(res[value.time])
    }
    res[value.time].value += value.value
    res[value.time].estimated_value = res[value.time].estimated_value || value.estimated_value
    return res;
  }, {});
  return result
}

function getDayMondayFirst(day) {
  return day === 0 ? 6 : day - 1;
}

const getGroupedDataByDayOfWeek = (groupedData) => {
  let data = []
  const moment = require('moment-timezone');
  require('moment/locale/fr'); 
  moment.locale('fr');

  groupedData.forEach(item => {
    let day = item.time.locale('fr').format("d")
    let day_name = capitalize(item.time.locale('fr').format("ddd"))
    let hour = parseInt(item.time.format("HH"))
    let found = false
    data.forEach(element => {
      if (element[0] === hour && element[5] === day) {
        element[2] += item.value
        element[3] = element[3] || item.estimated_value
        element[4] += 1
        found = true
      }
    })
    if (!found) {
      data.push([hour, day_name, item.value, item.estimated_value, 1, day])
    }
  }
  )

  data.sort(function (a, b) {
    return getDayMondayFirst(parseInt(a[5])) - getDayMondayFirst(parseInt(b[5]));
  });

  data.forEach(element => {
    element[2] = element[2] / element[4]
  })

  return data
}

const getGroupedDataByDay = (groupedData) => {
  let data = []
  const moment = require('moment-timezone');
  require('moment/locale/fr'); 
  moment.locale('fr');

  groupedData.forEach(item => {
    let day = capitalize(item.time.locale('fr').format("ddd DD MMM YY"))
    let hour = parseInt(item.time.format("HH"))
    let found = false
    data.forEach(element => {
      if (element[1] === day && element[0] === hour) {
        element[2] += item.value
        element[3] = element[3] || item.estimated_value
        found = true
      }
    })
    if (!found) {
      data.push([hour, day, item.value, item.estimated_value])
    }
  }
  )

  return data
}

const calcul_heatmap_data = (data, groupBy, filter) => {
  let groupedData = getGroupedData(data)
  let result = groupBy === "day_of_week" ? getGroupedDataByDayOfWeek(groupedData) : getGroupedDataByDay(groupedData)
  return result
}

function convertHeatmapDataToLines(data, unit) {
  let data_rows = [["Jour"], ["Heure"], ["Consommation ("+unit+")"]]
  data.forEach(element => {
      data_rows[0].push(element[1])
      data_rows[1].push(element[0])
      data_rows[2].push(element[2])
  })
  return data_rows
}

export { getSeasonFilter, calcul_heatmap_data, convertHeatmapDataToLines }






