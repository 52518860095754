import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import ExportButton from '../../Buttons/ExportButton';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';
import QueryInfiniteField from '../../aegeon/query/QueryInfiniteField';
import TableCardDetails from './TableCardDetails';

const TableSitePageDetails = (props) => {

  const t = useTranslate();
  const title = t('sites.table.title');
  const [data, setData] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const setDataCallback = (data) => {
    setData(data)
  }

  const handleExport = (name) => {
    var sheets_data = [];
    if (data) {
      Object.keys(data).forEach((source) => {
        if (data[source].length > 0) {
          const sheetName = source.slice(0, 30)
          sheets_data.push({ sheetName: sheetName, data: data[source] });
        }
      });
      export_row_to_excel(sheets_data, `${t('sites.exports.table')}${name}.xlsx`);
    };
  }

  const moment = require('moment');
  const startDate = moment().set({ year: 2019, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  return (
    <DetailsShow title={title} tooltip={<ExportButton onClick={handleExport} disabled={disabled} />} resource="sites" lessMargin={true}>
      <QueryInfiniteField query_name="getSiteTimeseries" params={{ time: { between: [startDate, endDate] } }}>
        <TableCardDetails setDataCallback={setDataCallback} setDisabled={setDisabled}
        />
      </QueryInfiniteField>
    </DetailsShow>
  )
};

export default TableSitePageDetails;
