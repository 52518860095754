import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const FunctionalEntityIcon = (props) => {
  const color = props.color ? props.color : '';
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m96.95,22.75l-28.8-9.6c-.62-.21-1.28-.21-1.9,0l-28.8,9.6c-1.23.41-2.05,1.55-2.05,2.85v76.8c0,1.29.83,2.44,2.05,2.85l28.8,9.6c.31.1.63.15.95.15s.64-.05.95-.15l28.8-9.6c1.23-.41,2.05-1.55,2.05-2.85V25.6c0-1.29-.83-2.44-2.05-2.85Zm-3.95,77.48l-25.8,8.6-25.8-8.6V27.76l25.8-8.6,25.8,8.6v72.48Z" fill={color}/>
      <path d="m66.73,47.21l-17.28,5.76c-.61.2-1.03.78-1.03,1.42v23.04c0,.65.41,1.22,1.03,1.42l17.28,5.76c.15.05.31.08.47.08s.32-.03.47-.08l17.28-5.76c.61-.2,1.03-.78,1.03-1.42v-23.04c0-.65-.41-1.22-1.03-1.42l-17.28-5.76c-.31-.1-.64-.1-.95,0Z" fill={color}/>
      <path d="m66.2,25.6v17.28c0,.55.45,1,1,1s1-.45,1-1v-17.28c0-.55-.45-1-1-1s-1,.45-1,1Z" fill={color}/>
      <path d="m66.83,88.03l-20.16,8.06c-.51.21-.76.79-.56,1.3.21.51.79.76,1.3.56l19.79-7.92,19.79,7.92c.12.05.25.07.37.07.4,0,.77-.24.93-.63.21-.51-.04-1.09-.56-1.3l-20.16-8.06c-.24-.1-.5-.1-.74,0Z" fill={color}/>
    </SvgIcon>
  );
};

export default FunctionalEntityIcon;
