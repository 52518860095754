
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const PlatformCentered = (props) => {
  return (
    <SvgIcon viewBox="0 0 90 17"  xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.9623 2.89089C11.981 3.76745 12.4903 5.01121 12.4903 6.59849C12.4903 8.18577 11.981 9.54799 10.9504 10.4719C9.9317 11.384 8.48657 11.846 6.63869 11.846H2.93109V16.4894H0.881836V1.56421H6.63869C8.49841 1.56421 9.94355 2.00249 10.9623 2.89089ZM9.50527 9.14525C10.1923 8.58851 10.524 7.75934 10.524 6.65772C10.524 5.5561 10.1805 4.78615 9.50527 4.24126C8.81824 3.69637 7.84692 3.42393 6.56762 3.42393L2.94293 3.44762V9.99812H6.56762C7.84692 9.99812 8.83008 9.71383 9.50527 9.1571V9.14525Z" fill="#71B9A1"/>
      <path d="M15.1082 0.675781H17.0863V16.5012H15.1082V0.675781Z" fill="#71B9A1"/>
      <path d="M27.9604 16.5012V14.9257C27.1194 16.0392 25.8046 16.5841 24.0159 16.5841C23.2341 16.5841 22.5471 16.4301 21.9548 16.134C21.3625 15.8378 20.9124 15.4233 20.5926 14.9021C20.2728 14.3809 20.1188 13.7886 20.1188 13.1371C20.1188 12.1302 20.486 11.3248 21.2322 10.7443C21.9785 10.1639 23.0209 9.85592 24.3594 9.84408H27.9367V9.31104C27.9367 8.5174 27.688 7.90144 27.2023 7.46316C26.7166 7.02488 25.9941 6.81167 25.0583 6.81167C23.9211 6.81167 22.7603 7.21441 21.5876 8.03174L20.7584 6.66952C21.5639 6.14832 22.322 5.76927 23.009 5.53236C23.6961 5.29545 24.5134 5.177 25.4729 5.177C26.8825 5.177 27.9723 5.52052 28.7304 6.20755C29.4885 6.89458 29.8912 7.85405 29.9031 9.09782L29.9267 16.513H27.9604V16.5012ZM26.7759 14.2742C27.4274 13.8241 27.8064 13.2319 27.9367 12.4974V11.3721H24.6319C23.7316 11.3721 23.0683 11.5024 22.6418 11.7749C22.2154 12.0473 21.9903 12.4738 21.9903 13.0542C21.9903 13.6346 22.2154 14.0966 22.6537 14.4401C23.092 14.7836 23.6842 14.9494 24.4186 14.9494C25.3426 14.9494 26.1244 14.7244 26.7759 14.2742Z" fill="#71B9A1"/>
      <path d="M39.5215 15.7548C38.6568 16.3116 37.7683 16.584 36.8799 16.584C35.9915 16.584 35.2571 16.3234 34.7004 15.7904C34.1437 15.2573 33.8712 14.4518 33.8712 13.3739V7.13139H32.2721V5.61518H33.8712V2.58276H35.8731V5.61518H39.3319V7.13139H35.8731V13.0185C35.8731 13.6463 35.9915 14.0965 36.2166 14.3571C36.4417 14.6295 36.7852 14.7598 37.2353 14.7598C37.7802 14.7598 38.3606 14.5821 38.9884 14.2268L39.5215 15.7667V15.7548Z" fill="#71B9A1"/>
      <path d="M44.8045 2.79603C44.461 3.10401 44.2951 3.55413 44.2951 4.13456V5.60339H47.4105V7.11959H44.2951V16.5011H42.317V7.11959H40.8481V5.60339H42.317V4.38331C42.317 3.55413 42.4946 2.85526 42.8382 2.28668C43.1817 1.70625 43.6436 1.27982 44.2122 0.983688C44.7808 0.687553 45.3968 0.54541 46.0483 0.54541C46.6998 0.54541 47.3276 0.711246 48.0146 1.05476L47.5408 2.67758C47.0314 2.44067 46.5458 2.31037 46.0956 2.31037C45.5863 2.31037 45.1599 2.46436 44.8163 2.78419L44.8045 2.79603Z" fill="#71B9A1"/>
      <path d="M56.6972 5.88761C57.5738 6.37327 58.249 7.03661 58.7465 7.90132C59.2321 8.76603 59.4809 9.7492 59.4809 10.8508C59.4809 11.9524 59.2321 12.9474 58.7465 13.8122C58.2608 14.6769 57.5738 15.3521 56.6972 15.8377C55.8207 16.3234 54.8257 16.5603 53.7004 16.5603C52.575 16.5603 51.5563 16.3234 50.6798 15.8377C49.8032 15.3521 49.128 14.6887 48.6305 13.824C48.1449 12.9593 47.8961 11.9761 47.8961 10.8508C47.8961 9.72551 48.1449 8.75419 48.6305 7.90132C49.1162 7.03661 49.8032 6.37327 50.6798 5.88761C51.5563 5.40195 52.5632 5.16504 53.7004 5.16504C54.8375 5.16504 55.8207 5.40195 56.6972 5.88761ZM51.734 7.40382C51.1536 7.74733 50.7153 8.22115 50.3955 8.82526C50.0757 9.42937 49.9217 10.1164 49.9217 10.8864C49.9217 11.6563 50.0757 12.367 50.3955 12.9711C50.7153 13.5871 51.1654 14.0609 51.734 14.3926C52.3144 14.7243 52.9659 14.8901 53.7122 14.8901C54.4585 14.8901 55.0863 14.7243 55.6667 14.3926C56.2471 14.0609 56.6854 13.5871 57.0052 12.9711C57.325 12.3552 57.4909 11.6682 57.4909 10.8864C57.4909 10.1046 57.325 9.42937 57.0052 8.82526C56.6854 8.22115 56.2353 7.74733 55.6667 7.40382C55.0863 7.0603 54.4348 6.89447 53.7122 6.89447C52.9896 6.89447 52.3144 7.0603 51.734 7.40382Z" fill="#71B9A1"/>
      <path d="M65.8063 5.80482C66.4933 5.39023 67.2988 5.16517 68.2346 5.15332V7.07227C67.0856 7.02489 66.1498 7.33287 65.4391 7.97252C64.7284 8.61217 64.3256 9.47688 64.219 10.5548V16.5012H62.2408V5.22439H64.219V7.6527C64.5862 6.84721 65.1193 6.23125 65.7945 5.80482H65.8063Z" fill="#71B9A1"/>
      <path d="M88.0046 6.32589C88.739 7.10768 89.118 8.17377 89.118 9.52414V16.5011H87.1162V10.0572C87.1162 9.10955 86.8556 8.36329 86.3344 7.81841C85.8132 7.27352 85.1024 7.01292 84.1904 7.01292C83.1243 7.03661 82.2833 7.41566 81.6791 8.13823C81.0632 8.8608 80.767 9.79658 80.767 10.9337V16.5011H78.7652V10.0572C78.7652 9.10955 78.5046 8.36329 77.9952 7.81841C77.4859 7.27352 76.7633 7.01292 75.8394 7.01292C74.7733 7.03661 73.9323 7.41566 73.3163 8.13823C72.7003 8.8608 72.3924 9.79658 72.3924 10.9337V16.5011H70.4142V5.22427H72.3924V7.59334C73.1268 6.00606 74.5364 5.18873 76.5975 5.16504C77.6399 5.16504 78.4927 5.41379 79.1916 5.9113C79.8905 6.4088 80.3525 7.10768 80.5894 7.99609C81.2882 6.13636 82.7452 5.18873 84.984 5.16504C86.2751 5.16504 87.2938 5.55594 88.0283 6.33773L88.0046 6.32589Z" fill="#71B9A1"/>
    </SvgIcon>
  );
};

export default PlatformCentered;