import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import { value_with_unit } from '../../aegeon/helper';
import { useQueryContext } from '../../aegeon/query/useQueryContext';

import CustomTable from '../../Table/CustomTable';

const SiteGeneralInformation = (props) => {
  const t = useTranslate();

  const {
    isLoading,
  } = useQueryContext(props);

  const record = useRecordContext();

  if (isLoading === true) {
    return (
      <ChartLoading />
    );
  }

  const address = (record?.airtable?.address ? record?.airtable?.address + ", " : "") +
    (record?.airtable?.postal_code ? record?.airtable?.postal_code + ", " : "") +
    (record?.airtable?.city ? record?.airtable?.city : "")

  const owner = record?.airtable?.organizations ? record?.airtable?.organizations[0].name?.replace('\n', ' ') : null

  const rows = [
    { name: t('sites.general_information.name'), value: record?.name, tooltip: 'sites.general_information.name_no_data' },
    { name: t('sites.general_information.address'), value: address, tooltip: 'sites.general_information.address_no_data' },
    { name: t('sites.general_information.organizations'), value: owner, tooltip: 'sites.general_information.organizations_no_data' },
    { name: t('sites.general_information.declared_detention_regime'), value: record?.airtable?.declared_detention_regime, tooltip: 'sites.general_information.declared_detention_regime_no_data' },
    { name: t('sites.general_information.validated_client_surface'), value: value_with_unit(record?.airtable?.validated_client_surface, "m²"), tooltip: 'sites.general_information.validated_client_surface_no_data' },
    { name: t('sites.general_information.fluid'), value: record?.airtable?.fluids_typology?.filter(element => element !== null).map(element => t("sites.general_information.fluids." + element)).join(', '), tooltip: 'sites.general_information.fluid_no_data' },
    { name: t('sites.general_information.number_of_potentially_subjected_functional_entities'), value: record?.airtable?.number_of_potentially_subjected_functional_entities, tooltip: 'sites.general_information.number_of_potentially_subjected_functional_entities_no_data' },
    { name: t('sites.general_information.number_of_tenants_following_rental_state_ex_parking'), value: record?.airtable?.number_of_tenants_following_rental_state_ex_parking, tooltip: 'sites.general_information.number_of_tenants_following_rental_state_ex_parking_no_data' },
    { name: t('sites.general_information.current_typology'), value: record?.airtable?.current_typology, tooltip: 'sites.general_information.current_typology_no_data' },
  ]

  // The rows tooltips are translated in the CustomTable component
  return (<CustomTable rows={rows} default_tooltip={"sites.no_data_tooltip"} />)
}

export default SiteGeneralInformation
