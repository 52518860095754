import React, { useContext, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import CustomLargeTable from '../../Table/CustomLargeTable';
import { adaptative_unit, convert_to_right_unit } from '../helper';
import ChartLoading from './ChartLoading';
import ChartNoData from './ChartNoData';

const ChartTable = (props) => {

  const {
    isLoading,
    timeseries,
    isLocked,
    groupedSerie,
    unit,
    className,
    setSubtitleCallback,
    groupBy,
    setDataCallback,
    disabledTotal,
    source
  } = props

  const { record } = useContext(RecordContext);

  const t = useTranslate();

  const groupByTrimester = groupBy === "trimester"

  const isData = (timeseries && timeseries.length > 0) || (groupedSerie && Object.keys(groupedSerie).length > 0)
  const maxValue = isData ? Math.max(...Object.values(groupedSerie).map((group) => {
    return Math.max(...Object.values(group))
  })) : 0
  const new_unit = adaptative_unit(maxValue, unit)
  const new_groupedSerie = new_unit !== unit ? Object.keys(groupedSerie).reduce((acc, curr) => {
    acc[curr] = Object.keys(groupedSerie[curr]).reduce((acc2, curr2) => {
      acc2[curr2] = convert_to_right_unit(groupedSerie[curr][curr2], unit, new_unit, "")
      return acc2
    }, {})
    return acc
  }, {}) : groupedSerie

  useEffect(() => {
    setSubtitleCallback && isData && new_unit &&
      setSubtitleCallback(t("devices.sources.unit_prefix") + new_unit + ".")
  }, [isData, new_unit]);

  const months_names = [];
  for (let i = 0; i < 12; i++) {
    months_names.push(t(`months.${i}`));
  }
  const trimesters_names = [];
  for (let i = 0; i < 4; i++) {
    trimesters_names.push(t(`trimesters.${i}`));
  }

  const group_names = groupByTrimester ? trimesters_names : months_names
  if (!disabledTotal) group_names.push(t("total"))

  const getRows = (groupedSerie) => {
    let rows = []
    Object.keys(groupedSerie).forEach((key) => {
      let row = { name: key, values: [] }
      group_names.forEach((month, index) => {
        if (index === group_names.length - 1 && !disabledTotal) {
          let total = 0
          Object.keys(groupedSerie[key]).forEach((month) => {
            total += groupedSerie[key][month]
          })
          row.values.push(total)
        }
        else {
          groupedSerie[key][index + 1] ? row.values.push(groupedSerie[key][index + 1]) :
            groupedSerie[key][index + 1] === 0 ? row.values.push(0) :
              row.values.push('')
        }
      })
      rows.push(row)
    })
    return rows
  }

  const rows = getRows(new_groupedSerie)
  const rounded_values_rows = rows.map((row) => {
    let rounded_values = row.values.map((value) => {
      return value === '' ? '' : Math.round(value * 100) / 100;
    })
    return { name: row.name, values: rounded_values }
  })

  const convertData = (rows) => {
    if (rows) {
      let data = [['Année'], ['Mois'], ['Consommation (' + new_unit + ')']]
      rows.forEach((row) => {
        row.values.forEach((value, index) => {
          data[0].push(row.name)
          data[1].push(group_names[index])
          data[2].push(value)
        })
      })
      return data
    }
    return []
  }

  const estimated_fields = ["value_nature_coupure_secteur", "value_nature_coupure_secteur_courte", "value_nature_debut_de_coupure_secteur", "value_nature_esti", "value_nature_estimated", "value_nature_estime", "value_nature_fin_de_coupure_secteur", "value_nature_importe_manuellement_par_le_metier_enedis", "value_nature_puissance_reconstituee_et_coupure_secteur"]

  const addEstimatedValues = (rows, timeseries) => {
    if (!Array.isArray(timeseries)) {
      return rows
    }
    let property = record?.properties?.find((element) => element.name === source)
    let timeseries_start_date = property?.quality?.timeseries_start_date
    let is_first_of_month = new Date(timeseries_start_date).getDate() === 1
    let first_index_not_empty = rows[0].values.findIndex((element) => element !== "")
    let last_row = rows[rows.length - 1].values.slice(0, -1)
    let last_index_not_empty = last_row.length - 1 - last_row.slice().reverse().findIndex((element) => element !== "")
    let timeseries_end_date = property?.quality?.timeseries_end_date
    let is_last_of_month = new Date(timeseries_end_date).getDate() === 1
    let missing_values = property?.quality?.missing_values
    let grouped_missing_values = {}
    missing_values && missing_values.forEach((element) => {
      let year = element.split("-")[0]
      let month = parseInt(element.split("-")[1])
      if (!grouped_missing_values[year]) {
        grouped_missing_values[year] = []
      }
      if (!grouped_missing_values[year].includes(month)) {
        grouped_missing_values[year].push(month)
      }
    })
    return rows.map((row, rowIndex) => {
      let estimated_values = row.values.map((value, index) => {
        let filtered_timeseries = timeseries && timeseries?.filter((element) => element.time.split("-")[0] === row.name && parseInt(element.time.split("-")[1]) === index + 1)
        let estimated = false
        let regulated = false
        let missing = false
        let corrected = false
        filtered_timeseries && filtered_timeseries.forEach((element) => {
          Object.keys(element).forEach((key) => {
            if (key === "value_nature_regu" && Math.abs(element[key]) > 0) {
              regulated = true
            }
            else if (key === "value_nature_corrected" && Math.abs(element[key]) > 0) { // Equivalent to regulated for GRDF
              corrected = true
            }
            if (key.startsWith("value_nature") && estimated_fields.includes(key) && Math.abs(element[key]) > 0) {
              estimated = true
            }
          }
          )
          if ((rowIndex === 0 && index === first_index_not_empty && !is_first_of_month) || (rowIndex === (rows?.length - 1) && index === last_index_not_empty && !is_last_of_month) || (grouped_missing_values[row.name] && grouped_missing_values[row.name].includes(index + 1))) {
            missing = true
          }
        })
        return `${corrected ? "C" : ""}${regulated ? "R" : ""}${estimated ? "E" : ""}${missing ? "M" : ""}`
      })
      return { ...row, estimated_values }
    })
  }

  useEffect(() => {
    !isLoading && setDataCallback && setDataCallback(convertData(rows))
  }, [rows]);

  return (
    isLoading === true ?
      <ChartLoading />
      :
      isData && rows.length > 0 ?
        <div className={className}>
          <CustomLargeTable
            head={["", ...group_names]}
            rows={addEstimatedValues(rounded_values_rows, timeseries)}
            disableMissingDataTooltip={props.disableMissingDataTooltip}
            disabledBoldLastValue={props.disabledBoldLastValue}
          />
        </div>
        :
        <ChartNoData isLocked={isLocked} />
  )
}

export default ChartTable