import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { allowedClickComponents, useRolePermissions } from "../../aegeon-rbac/AuthRolePermissions";
import TableDJComponent from '../../aegeon/charts/TableDJComponent';
import QueryField from '../../aegeon/query/QueryField';
import TableDetailsComponent from './TableDetailsComponent';
import TableSubDevicesComponent from './TableSubdevicesComponent';

const TableCardDetailsNotAuto = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const { record } = useContext(RecordContext);
  const [isLoadingDju, setIsLoadingDju] = useState(true);
  const [isLoadingDjr, setIsLoadingDjr] = useState(true);
  const [isLoadingFusioned, setIsLoadingFusioned] = useState(true);
  const [isLoadingManualBills, setIsLoadingManualBills] = useState(true);
  const [isLoadingThird, setIsLoadingThird] = useState(true);
  const setDisabled = props.setDisabled;
  const { isLoading, permissions } = useRolePermissions();

  const moment = require('moment');

  const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const startDate10years = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter_all_data = { startDate: startDate10years, endDate: today };
  const startDateOneYear = moment().set({ year: moment().year() - 1, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });

  const djStartDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const djEndDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const sources = {
    fusioned_energy: {
      name: t('devices.sources.serie_aegeon'),
      tooltip_text: t('devices.sources.serie_aegeon_tooltip'),
      source: 'fusioned_energy',
      filter: filter_all_data,
      aggregation: { period: 'MONTH', operation: 'SUM' },
      unit: record?.properties?.find((property) => property?.name === "fusioned_energy")?.unit,
    },
    manual_bills: {
      name: t('devices.sources.manual_bills'),
      tooltip_text: t('devices.sources.manual_bills_tooltip'),
      source: 'manual_bills',
      filter: filter_all_data,
      aggregation: undefined,
      unit: record?.properties?.find((property) => property?.name === "manual_bills")?.unit,
    },
    third_party_energy: {
      name: t('devices.sources.third_party_energy'),
      tooltip_text: t('devices.sources.third_party_energy_tooltip'),
      source: 'third_party_energy',
      filter: filter_all_data,
      aggregation: undefined,
      unit: record?.properties?.find((property) => property?.name === "third_party_energy")?.unit,
    },
    origin: {
      name: t('devices.sources.origin'),
      tooltip_text: t('devices.sources.origin_tooltip'),
      source: 'fusioned_energy',
      filter: filter_all_data,
      aggregation: undefined,
      unit: undefined,
    }
  }

  useEffect(() => {
    if (!isLoadingDju && !isLoadingDjr && !isLoadingFusioned && !isLoadingManualBills && !isLoadingThird) {
      setDisabled && setDisabled(false);
    }
  }, [isLoadingDju, isLoadingDjr, isLoadingFusioned, isLoadingManualBills, isLoadingThird, setDisabled]);

  const permissionControlledComponents = {
    resource: "devices",
    elements: {
      click: {
        subdevices: <TableSubDevicesComponent setDataSourcesCallback={props.setDataSourcesCallback} record={record} />,
      }
    }
  };

  return (
    <>
      <Grid2 item="true" small={12} medium={12} big={12}>
        <Card className={globalClasses.DetailCard}>
          <Grid2 container direction="row" justifyContent="center" alignItems="center"
            spacing={5}
            style={{ padding: '0 !important' }}
          >
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["fusioned_energy"]} setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingFusioned} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["origin"]} type="origin" />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["manual_bills"]} setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingManualBills} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["third_party_energy"]} setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingThird} />
            </Grid2>
          </Grid2>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={12}>
        <QueryField query_name="getDeviceSubdevicesTimeseries" params={{ id: record?.id, time: { between: [startDateOneYear, today] } }}>
          {!isLoading && allowedClickComponents(permissions, permissionControlledComponents)}
        </QueryField>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={12}>
        <Card className={globalClasses.DetailCard}>
          <Grid2 container direction="row" justifyContent="center" alignItems="center"
            spacing={5}
            style={{ padding: '0 !important' }}
          >
            <Grid2 item="true" small={12} medium={12} big={6}>
              <QueryField query_name="getSiteDjuTimeseries" id={record?.siteId} params={{ time: { between: [djStartDate, djEndDate] } }}>
                <TableDJComponent type="dju" setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingDju} />
              </QueryField>
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <QueryField query_name="getSiteDjrTimeseries" id={record?.siteId} params={{ time: { between: [djStartDate, djEndDate] } }}>
                <TableDJComponent type="djr" setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingDjr} />
              </QueryField>
            </Grid2>
          </Grid2>
        </Card>
      </Grid2>
    </>
  )
};

export default TableCardDetailsNotAuto;

