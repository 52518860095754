// Libs
import React from "react";
import { Show, SimpleShowLayout } from "react-admin";

// Components
import globalUseStyles from "../../styles/globalCustomStyles";
import UsersShowDetails from "./UsersShowDetails";
import { useGetList } from "react-admin";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const UsersShow = (props) => {
  const globalClasses = globalUseStyles();
  const location = useLocation();

  const [username, setUsername] = useState(null);
  const id = location.pathname.split("/")[2];
  const { data: users } = useGetList(
    'users'
  );

  useEffect(() => {
    setUsername(users?.find(user => user?.id === id)?.username);
  }, [users, id]);

  return (
    username ?
    <Show {...props} className={globalClasses.Show} queryOptions={{ meta: { username: username } }}>
      <SimpleShowLayout className={globalClasses.Show}>
        <UsersShowDetails />
      </SimpleShowLayout>
    </Show>
    : 
    <></>
  );
};

export default UsersShow;
