import { Card, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';

import { useTheme } from '@mui/material/styles';
import InfoTooltipIcon from '../../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../../styles/globalCustomStyles';
import CustomTable from '../../../Table/CustomTable';
import CustomTooltip from '../../../aegeon/CustomTooltip';
import { calculReportingDates } from '../../../aegeon/compute_missing_bills';
import DeviceQualitiesReceiver from '../../CustomComponents/DeviceQualitiesReceiver';

const SiteMissingInformationDetailsContentRightBottom = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const record = useRecordContext();
  const theme = useTheme();

  const devices = props?.devices;
  const [rows, setRows] = React.useState({});
  const [percentage, setPercentage] = React.useState({});
  const [score, setScore] = React.useState({});

  const calcul_color = (percentage) => {
    if (percentage < 10) {
      return theme.palette.gradation.low
    } else if (percentage < 50) {
      return theme.palette.gradation.midLow
    } else if (percentage < 75) {
      return theme.palette.gradation.midHigh
    } else {
      return theme.palette.gradation.high
    }
  }

  let start_date = record?.airtable?.reporting_start_date
  let reporting_dates = calculReportingDates(start_date)
  let reporting_start_date = reporting_dates.reporting_start_date
  let reporting_end_date = reporting_dates.reporting_end_date

  return (
    <Card className={globalClasses.DetailCard}>
      <DeviceQualitiesReceiver
        devices={devices}
        reportingStartDate={reporting_start_date}
        reportingEndDate={reporting_end_date}
        setRows={setRows}
        setPercentage={setPercentage}
        setScore={setScore}
      />
      <div className={globalClasses.DetailCardTableTitle}>
        <Typography display="block" variant="h8">{t('sites.missing_information.right_bottom_panel.title')}</Typography>
        <CustomTooltip title={t('sites.missing_information.right_bottom_panel.tooltip')} icon={<InfoTooltipIcon />} />
      </div>
      <div className={globalClasses.DetailCardTableSubtitle} >
        <Typography variant="h9" >
          {t('sites.missing_information.right_bottom_panel.subtitle') + reporting_start_date.format("DD/MM/YYYY") + " au " + reporting_end_date.subtract(1, 'day').format("DD/MM/YYYY")}
        </Typography>
      </div>
      {
        percentage["energetic"] === 0 && percentage["water"] === 0 ?
          <Typography display="block" variant="h9" component="p">{t('sites.missing_information.right_bottom_panel.no_missing_bills')}</Typography>
          :
          percentage && percentage?.["energetic"] !== undefined && percentage?.["water"] !== undefined ?
            <div>
              <div>
                {
                  Object.keys(rows).map((key, index) => (
                    <div key={index}>
                      <Typography variant="h9">
                        <b>{t('sites.missing_information.right_bottom_panel.table_title_' + key)}</b></Typography>
                      <div key={index} className={globalClasses.missingBillsNumbers}>
                        <div className={globalClasses.missingBillsNumbersElement}>
                          <Typography variant="h4" color={calcul_color(percentage[key])} >
                            {percentage[key]}%
                          </Typography>
                          <Typography variant="h9" className={globalClasses.missingBillsText}>
                            {t('sites.missing_information.right_bottom_panel.percentage_text_1')}
                            <br />
                            {t('sites.missing_information.right_bottom_panel.percentage_text_2')}
                          </Typography>
                        </div>
                        <div className={globalClasses.missingBillsNumbersElement}>
                          <Typography variant="h4" color={calcul_color(percentage[key]) + "AA"}>
                            {score[key].missing}
                          </Typography>
                          <Typography variant="h9" className={globalClasses.missingBillsText}>
                            {t('sites.missing_information.right_bottom_panel.months_text')}
                            <br />
                            {"/ " + score[key].total + " "}
                          </Typography>
                        </div>
                      </div>
                      {percentage[key] !== 0 &&
                        <>
                          <br />
                          <CustomTable key={index} list={true} rows={rows?.[key]} defaultTooltip={"sites.no_data_tooltip"} />
                          <br />
                        </>}
                    </div>
                  ))
                }
              </div>
            </div>
            : <></>
      }
    </Card >
  );
};

export default SiteMissingInformationDetailsContentRightBottom;
