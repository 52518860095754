import React from 'react';
import { useTranslate } from 'react-admin';
import ChartHorizontalBar from '../../aegeon/charts/ChartHorizontalBar';
import { adaptative_unit, convert_to_right_unit } from '../../aegeon/helper';
import { useTimeseriesContext } from '../../aegeon/timeseries/useTimeseriesContext';


const ChartDeviceHorizontalBar = (props) => {
    const {
        timeseries,
        isLoading,
    } = useTimeseriesContext(props);
    const t = useTranslate();
    const unit = props.unit

    const groupByMonth = (timeseries) => {
        const moment = require('moment-timezone');
        let grouped = timeseries.map((item) => {
            let date = moment.utc(item.time).tz('Europe/Paris')
            return {
                month: date.month() + 1,
                year: date.year(),
                value: item.value
            }
        })
        return grouped
    }

    const maxValue = timeseries && Math.max(...timeseries.map(item => item.value))
    const new_unit = adaptative_unit(maxValue, unit)
    const no_data_text = t('devices.comparison.no_data')
    const currentYear = new Date().getFullYear()
    const oneYearAgo = currentYear - 1
    const twoYearsAgo = currentYear - 2
    const listOfMonths = [];
    for (let i = 0; i < 12; i++) {
        listOfMonths.push(t(`months.${i}`));
    }

    const getYearData = (data, year) => {
        let yearData = []
        for (let i = 1; i <= 12; i++) {
            let month = data.find(c => c.month === i && c.year === year)
            if (month !== undefined) {
                yearData.push(convert_to_right_unit(Math.round(month.value), unit, new_unit, no_data_text))
            }
            else {
                yearData.push(no_data_text)
            }
        }
        return yearData
    }

    const data = timeseries ? groupByMonth(timeseries) : []

    const isData = timeseries && timeseries.length > 0

    let series = [
        {
            name: `${twoYearsAgo}`,
            type: 'bar',
            data: getYearData(data, twoYearsAgo),
            itemStyle: {
                color: "#6c74d8"
            }
        }, {
            name: `${oneYearAgo}`,
            type: 'bar',
            data: getYearData(data, oneYearAgo),
            itemStyle: {
                color: "#5da4d9"
            }
        }, {
            name: `${currentYear}`,
            type: 'bar',
            data: getYearData(data, currentYear),
            itemStyle: {
                color: "#4ecca2"
            }
        }]

    return (
        <ChartHorizontalBar
            new_unit={new_unit}
            isLoading={isLoading}
            title={props.title}
            isData={isData}
            series={series}
            x_data={listOfMonths}
        />
    )
}

export default ChartDeviceHorizontalBar;
