// Libs
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from "react";

import { useState } from "react";
import {
  useGetList,
  useRecordContext,
  useTranslate
} from "react-admin";
import BackButton from "../Buttons/BackButton";

// Material UI components
import { Typography } from "@mui/material";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";
import CardAnalysis from './Cards/CardAnalysis';
import CardComposition from "./Cards/CardComposition";
import CardEnergeticPerformance from "./Cards/CardEnergeticPerformance";
import CardEvolution from "./Cards/CardEvolution";
import CardGeneralInformation from "./Cards/CardGeneralInformation";
import CardHeatmap from './Cards/CardHeatmap';
import CardHistogram from "./Cards/CardHistogram";
import CardMissingInformation from "./Cards/CardMissingInformation";
import CardTable from './Cards/CardTable';
import SiteTypeIcon from "./CustomComponents/SiteTypeIcon";

import { useRolePermissions } from "../aegeon-rbac/AuthRolePermissions";
import SiteExportButton from './CustomButtons/SiteExportButton';

import { allowedClickComponents, allowedShowComponents } from "../aegeon-rbac/AuthRolePermissions";


const SitesShowDetails = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const [exporting, setExporting] = useState(false);

  const { data: sites } = useGetList(
    'sites',
  );

  const record = useRecordContext();
  const code = record?.airtable?.code;
  const name = record?.name ? record?.name : code;
  const quality = record?.airtable?.current_synoptic_quality_reporting ? record?.airtable?.current_synoptic_quality_reporting : "N/A";

  const handleExport = () => {
    setExporting(true);
  };

  const permissionControlledComponents = {
    resource: "sites",
    elements: {
      show: {
        general_information: <CardGeneralInformation />,
        energetic_performance: <CardEnergeticPerformance />,
        evolution: <CardEvolution />,
        histogram: <CardHistogram />,
        table: <CardTable />,
        analyse: <CardAnalysis />,
        heatmap: <CardHeatmap />,
        missing_information: <CardMissingInformation />,
        composition: <CardComposition />,
      },
      click: {
        export: <SiteExportButton onClick={handleExport} disabled={exporting} />
      }
    }
  };

  const { isLoading, permissions } = useRolePermissions();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    sites?.some((site) => site.id === record?.id) ?
      <div className={globalClasses.pageCard} >
        <BackButton record={record} />
        <div className={globalClasses.pageTitleContainer}>
          <div>
            <div className={globalClasses.pageTitle} >
              <Typography variant="h4">{t('sites.title')}{code}</Typography>
              <SiteTypeIcon siteType={record?.siteType} />
            </div>
            <div className={globalClasses.pageSubTitle} >
              <Typography variant="h5" >{name}{t('sites.page_subtitle')}{quality}</Typography>
            </div>
          </div>
          {allowedClickComponents(permissions, permissionControlledComponents)}
        </div>
        <div className={globalClasses.pageContent}>
          <Grid2 className={globalClasses.grid} container spacing={4}>
            {allowedShowComponents(permissions, permissionControlledComponents)}
          </Grid2 >
        </div>
      </div >
      : <></>
  );
};

export default SitesShowDetails;