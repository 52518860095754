import { ResourceContextProvider, useRecordContext } from "react-admin";
import QueryContextProvider from "./QueryContextProvider";
import useQueryInfiniteController from "./useQueryInfiniteController";

const QueryInfiniteField = (props) => {
    const {
        children,
        query_name,
        filter = {},
        aggregate = {},
        params = {},
    } = props;

    const record = useRecordContext(props);

    const controllerProps = useQueryInfiniteController(record, { query_name, record, filter, aggregate, params });

    return (
        <ResourceContextProvider value={record}>
            <QueryContextProvider value={controllerProps}>
                {children}
            </QueryContextProvider>
        </ResourceContextProvider>
    )
}

export default QueryInfiniteField;
