import React from 'react';
import globalUseStyles from '../../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../../assets/InfoTooltipIcon';
import CustomTable from '../../../Table/CustomTable';
import CustomTooltip from '../../../aegeon/CustomTooltip';
import ChartLoading from '../../../aegeon/charts/ChartLoading';
import { useQueryContext } from '../../../aegeon/query/useQueryContext';
import DeviceTypeIcon from '../../../devices/CustomComponents/DeviceTypeIcon';

const SiteMissingInformationDetailsContentRight = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const {
    data,
    isLoading,
  } = useQueryContext(props);

  const devices = props.devices;

  const record = useRecordContext();

  if (isLoading === true) {
    return (
      <ChartLoading />
    );
  }

  const pdl_bad_quality = devices?.filter(pdl => pdl?.fusionedEnergyQuality === "C").sort((a, b) => {
    if (a?.airtable?.destination < b?.airtable?.destination) {
      return -1;
    }
    else if (a?.airtable?.destination > b?.airtable?.destination) {
      return 1;
    }
    else {
      if (a?.deviceType < b?.deviceType) {
        return -1;
      }
      else if (a?.deviceType > b?.deviceType) {
        return 1;
      }
      else {
        return 0;
      }
    }
  })

  const pdl_bad_quality_row = pdl_bad_quality?.map((device) => {
    let destination = device.airtable?.destination ? device.airtable?.destination : "inconnue";
    return {
      name: device.name,
      icon: <DeviceTypeIcon deviceType={device.deviceType} />,
      icon_tooltip: t("devices.deviceTypes." + device.deviceType),
      value: t('sites.composition.device_destination') + ' ' + destination,
      path: "/devices/" + device.id + "/show",
      tooltip_link: "sites.composition.device_tooltip_link"
    }
  })

  const pdl_missing_authorization = devices?.filter(pdl => pdl?.airtable?.authorization_end_date && new Date(pdl?.airtable?.authorization_end_date) < new Date())
  const pdl_missing_authorization_row = pdl_missing_authorization?.map((pdl) => {
    return {
      name: pdl?.name,
      value: pdl?.airtable?.authorization_end_date ? new Date(pdl?.airtable?.authorization_end_date).toLocaleDateString(localStorage.getItem("language")) : "Pas d'autorisation",
      path: "/devices/" + pdl.id + "/show",
      tooltip_link: "sites.composition.device_tooltip_link"
    }
  })


  const pdl_without_destination = devices?.filter(pdl => !pdl?.airtable?.destination)
  const pdl_without_destination_row = pdl_without_destination?.map((pdl) => {
    return {
      name: pdl.name,
      value: " ",
      path: "/devices/" + pdl.id + "/show",
      tooltip_link: "sites.composition.device_tooltip_link"
    }
  })

  const pdl_shared_without_usage = devices?.filter(pdl => pdl?.airtable?.destination?.includes("COMMUNE") && (!pdl?.airtable?.usage || pdl?.airtable?.usage?.length === 0))
  const pdl_shared_without_usage_row = pdl_shared_without_usage?.map((pdl) => {
    return {
      name: pdl.name,
      value: " ",
      path: "/devices/" + pdl.id + "/show",
      tooltip_link: "sites.composition.device_tooltip_link"
    }
  })


  // The rows tooltips are translated in the CustomTable component
  const rows = [
    { name: t('sites.general_information.synoptic_quality'), value: 'À renseigner', isMissing: !record?.airtable?.current_synoptic_quality_reporting },
  ]

  const rows_no_data = rows.filter(row => row.isMissing);

  return (
    <Card className={globalClasses.DetailCard}>
      <div className={globalClasses.DetailCardTableTitle}>
        <Typography display="block" variant="h8">{t('sites.missing_information.right_panel.title')}</Typography>
        <CustomTooltip title={t('sites.missing_information.right_panel.tooltip')} icon={<InfoTooltipIcon />} />
      </div>
      <div className={globalClasses.DetailCardTableSubtitle} />
      <>
        {rows_no_data?.length > 0 &&
          <>
            <CustomTable list={true} rows={rows_no_data} defaultTooltip={"sites.no_data_tooltip"} />
            <br />
          </>
        }
        {pdl_bad_quality?.length > 0 &&
          <>
            <Typography display="block" variant="h9"><b>{t('sites.missing_information.pdl_bad_quality')}</b></Typography>
            <CustomTable list={true} rows={pdl_bad_quality_row} defaultTooltip={"sites.no_data_tooltip"} />
            <br />
          </>}
        {pdl_missing_authorization_row?.length > 0 &&
          <>
            <Typography display="block" variant="h9"><b>{t('sites.missing_information.pdl_with_passed_authorization')}</b></Typography>
            <CustomTable list={true} rows={pdl_missing_authorization_row} defaultTooltip={"sites.no_data_tooltip"} />
            <br />
          </>}
        {pdl_without_destination?.length > 0 &&
          <>
            <Typography display="block" variant="h9"><b>{t('sites.missing_information.pdl_without_destination')}</b></Typography>
            <CustomTable list={true} rows={pdl_without_destination_row} defaultTooltip={"sites.no_data_tooltip"} />
            <br />
          </>}
        {pdl_shared_without_usage?.length > 0 &&
          <>
            <Typography display="block" variant="h9"><b>{t('sites.missing_information.pdl_shared_without_usage')}</b></Typography>
            <CustomTable list={true} rows={pdl_shared_without_usage_row} defaultTooltip={"sites.no_data_tooltip"} />
            <br />
          </>}
        {rows_no_data?.length === 0 && pdl_bad_quality?.length === 0 && pdl_missing_authorization_row?.length === 0 && pdl_without_destination?.length === 0 && pdl_shared_without_usage?.length === 0 &&
          <Typography display="block" variant="h9" component="p">{t('sites.missing_information.no_missing_info')}</Typography>
        }
      </>
    </Card>
  );
};

export default SiteMissingInformationDetailsContentRight;
