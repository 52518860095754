
import { useEffect, useState } from 'react';
import { calcul_heatmap_data } from '../../aegeon/compute_heatmap';
import { useTimeseriesContext } from '../../aegeon/timeseries/useTimeseriesContext';

const HeatmapDataReceiver = (props) => {
    const {
        timeseries,
        unit,
        isLoading,
    } = useTimeseriesContext(props);
    const filter = props.filter;

    const groupBy = props.groupBy;
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (!isLoading && timeseries && timeseries.length > 0 && !done) {
            let new_data = calcul_heatmap_data(timeseries, groupBy, filter);
            props.setHeatmapData(new_data, unit);
            setDone(true);
        }
        else if (!isLoading && !done && timeseries && timeseries.length === 0) {
            let new_data = [];
            props.setHeatmapData(new_data, unit);
            setDone(true);
        }

    }, [isLoading, timeseries, done, groupBy, props, unit]);


    return null;
};

export default HeatmapDataReceiver;