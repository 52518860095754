import { useContext, useMemo } from 'react';
import defaults from 'lodash/defaults';

import { TimeseriesContext } from './TimeseriesContext';

export const useTimeseriesContext = ( props )   => {
    const context = useContext(TimeseriesContext);
    // Props take precedence over the context
    // @ts-ignore
    return useMemo(
        () =>
            defaults(
                {},
                props != null ? extractTimeseriesContextProps(props) : {},
                context
            ),
        [context, props]
    );
};

const extractTimeseriesContextProps = ({
    sort,
    name,
    timeseries,
    unit,
    defaultTitle,
    displayedFilters,
    exporter,
    filterValues,
    hasCreate,
    hideFilter,
    isFetching,
    isLoading,
    onSelect,
    onToggleItem,
    onUnselectItems,
    page,
    perPage,
    refetch,
    resource,
    selectedIds,
    setFilters,
    setPage,
    setPerPage,
    setSort,
    showFilter,
    total,
}) => ({
    sort,
    timeseries,
    unit,
    defaultTitle,
    displayedFilters,
    exporter,
    filterValues,
    hasCreate,
    hideFilter,
    isFetching,
    isLoading,
    onSelect,
    onToggleItem,
    onUnselectItems,
    page,
    perPage,
    refetch,
    resource,
    selectedIds,
    setFilters,
    setPage,
    setPerPage,
    setSort,
    showFilter,
    total,
});
