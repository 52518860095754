import React from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';

import CustomTable from '../../Table/CustomTable';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import { calculReportingDates } from '../../aegeon/compute_missing_bills';
import DeviceQualitiesReceiver from '../CustomComponents/DeviceQualitiesReceiver';

const SiteMissingInformation = (props) => {
  const t = useTranslate();
  const record = useRecordContext();

  const [percentage, setPercentage] = React.useState({ "energetic": undefined, "water": undefined });
  const siteId = record?.id;
  const { data, isLoading } = useGetList(
    'devices',
    {
      filter: {
        siteIds: { contains: siteId },
      },
    }
  );

  let start_date = record?.airtable?.reporting_start_date
  let reporting_dates = calculReportingDates(start_date)
  let reporting_start_date = reporting_dates.reporting_start_date
  let reporting_end_date = reporting_dates.reporting_end_date

  const pdl_no_authorisation = data ? data.filter(device => (device?.airtable?.energy_data_upload_possibilities?.includes("TÉLÉRELEVÉ")) && (!device?.airtable?.authorization_end_date || new Date(device?.airtable?.authorization_end_date) < new Date())).length : 0

  const pdl_with_bad_quality = data ? data.filter(pdl => pdl.fusionedEnergyQuality === "C").length : 0

  const calculate_number_of_missing_client_information = (record) => {
    const is_missing_surface = record?.airtable?.validated_client_surface && record?.airtable?.declared_ef_rental_areas && (parseInt(record?.airtable?.validated_client_surface) !== parseInt(record?.airtable?.declared_ef_rental_areas));
    const rows = [
      !record?.airtable?.declared_detention_regime,
      !record?.airtable?.construction_date,
      !record?.airtable?.acquisition_date,
      !record?.airtable?.last_renovation_date,
      is_missing_surface,
      !record?.airtable?.validated_client_surface,
      !record?.airtable?.last_dpe_date,
      !record?.airtable?.opening_hours || !record?.airtable?.closing_hours,
      !record?.airtable?.current_typology,
      !record?.airtable?.technical_contact,
      !record?.airtable?.billing_contact,
      !record?.airtable?.asset_manager_contact,
      !record?.airtable?.rental_manager_contact,
      !record?.airtable?.maintainers_intervenants_contact,
      !record?.airtable?.property_manager_contact,
      !record?.airtable?.property_manager_agency
    ]
    return rows.filter(row => row).length
  }

  const missing_client_information = calculate_number_of_missing_client_information(record)

  const rows = [
    { name: t('sites.missing_information.nb_missing_client_information'), value: missing_client_information.toString() },
    { name: t('sites.missing_information.nb_pdl_bad_quality'), value: pdl_with_bad_quality.toString() },
    { name: t('sites.missing_information.nb_pdl_no_authorisation'), value: pdl_no_authorisation.toString() },
    { name: t('sites.missing_information.missing_bills_percentage_energetic'), value: `${percentage["energetic"]} %`, tooltip_with_value: (percentage["energetic"] && percentage["energetic"] > 10), tooltip: "sites.missing_information.missing_bills_percentage_tooltip" },
    { name: t('sites.missing_information.missing_bills_percentage_water'), value: `${percentage["water"]} %`, tooltip_with_value: (percentage["water"] && percentage["water"] > 10), tooltip: "sites.missing_information.missing_bills_percentage_tooltip" },
  ]

  // The rows tooltips are translated in the CustomTable component
  return (
    <>
      <DeviceQualitiesReceiver
        devices={data}
        reportingStartDate={reporting_start_date}
        reportingEndDate={reporting_end_date}
        setPercentage={setPercentage}
      />
      {isLoading || percentage["energetic"] === undefined || percentage["water"] === undefined ?
        <ChartLoading />
        :
        <CustomTable rows={rows} defaultTooltip={"sites.no_data_tooltip"} leftSizeBigger={true} />
      }
    </>
  )
}

export default SiteMissingInformation;