import React from 'react';
import globalUseStyles from '../../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../../assets/InfoTooltipIcon';
import CustomTable from '../../../Table/CustomTable';
import CustomTooltip from '../../../aegeon/CustomTooltip';

const SiteMissingInformationDetailsContentLeft = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const record = useRecordContext();

  const is_missing_surface = record?.airtable?.validated_client_surface && record?.airtable?.declared_ef_rental_areas && (parseInt(record?.airtable?.validated_client_surface) !== parseInt(record?.airtable?.declared_ef_rental_areas));
  const difference_surface = parseInt(record?.airtable?.validated_client_surface) + ' - ' + parseInt(record?.airtable?.declared_ef_rental_areas)

  // The rows tooltips are translated in the CustomTable component
  const rows = [
    { name: t('sites.general_information.declared_detention_regime'), value: 'À renseigner', isMissing: !record?.airtable?.declared_detention_regime },
    { name: t('sites.general_information.construction_date'), value: 'À renseigner', isMissing: !record?.airtable?.construction_date },
    { name: t('sites.general_information.acquisition_date'), value: 'À renseigner', isMissing: !record?.airtable?.acquisition_date },
    { name: t('sites.general_information.last_renovation_date'), value: 'À renseigner', isMissing: !record?.airtable?.last_renovation_date },
    { name: t('sites.missing_information.difference_surface'), value: difference_surface, isMissing: is_missing_surface },
    { name: t('sites.general_information.validated_client_surface'), value: 'À renseigner', isMissing: !record?.airtable?.validated_client_surface },
    { name: t('sites.general_information.last_dpe_date'), value: 'À renseigner', isMissing: !record?.airtable?.last_dpe_date },
    { name: t('sites.general_information.opening_hours'), value: 'À renseigner', isMissing: !record?.airtable?.opening_hours || !record?.airtable?.closing_hours },
    { name: t('sites.general_information.current_typology'), value: 'À renseigner', isMissing: !record?.airtable?.current_typology },

    { name: t('sites.general_information.technical_contact_name'), value: 'À renseigner', isMissing: !record?.airtable?.technical_contact },
    { name: t('sites.general_information.billing_contact_name'), value: 'À renseigner', isMissing: !record?.airtable?.billing_contact },
    { name: t('sites.general_information.asset_manager_contact_name'), value: 'À renseigner', isMissing: !record?.airtable?.asset_manager_contact },
    { name: t('sites.general_information.rental_manager_contact_name'), value: 'À renseigner', isMissing: !record?.airtable?.rental_manager_contact },
    { name: t('sites.general_information.maintainers_intervenants_contact_name'), value: 'À renseigner', isMissing: !record?.airtable?.maintainers_intervenants_contact },
    { name: t('sites.general_information.property_manager_contact_name'), value: 'À renseigner', isMissing: !record?.airtable?.property_manager_contact },
    { name: t('sites.general_information.property_manager_agency_name'), value: 'À renseigner', isMissing: !record?.airtable?.property_manager_agency },
  ]


  const rows_no_data = rows.filter(row => row.isMissing);
  // const rows_no_data = rows

  return (
    <Card className={globalClasses.DetailCard}>
      <div className={globalClasses.DetailCardTableTitle}>
        <Typography display="block" variant="h8">{t('sites.missing_information.left_panel.title')}</Typography>
        <CustomTooltip title={t('sites.missing_information.left_panel.tooltip')} icon={<InfoTooltipIcon />} />
      </div>
      <div className={globalClasses.DetailCardTableSubtitle} />
      {rows_no_data.length === 0 ? <Typography display="block" variant="h9" component="p">{t('sites.missing_information.no_missing_info')}</Typography> :
        <CustomTable list={true} rows={rows_no_data} defaultTooltip={"sites.no_data_tooltip"} />}
    </Card>
  );
};

export default SiteMissingInformationDetailsContentLeft;

