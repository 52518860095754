import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Typography } from '@mui/material';
import fr from "date-fns/locale/fr";
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import globalUseStyles from '../../styles/globalCustomStyles';

const ChooseDateButton = (props) => {
    const t = useTranslate();
    const globalClasses = globalUseStyles();
    const initialStartDate = props.startDate ? new Date(props.startDate) : null;
    const initialEndDate = props.endDate ? new Date(props.endDate) : null;
    const [dateRange, setDateRange] = useState([initialStartDate, initialEndDate]);

    const maxDays = props.maxDaysRange ? props.maxDaysRange : 15
    const maxRange = maxDays * 24 * 60 * 60 * 1000;

    const checkIfDatesAreValid = (dateRange) => {
        if (dateRange[0] && dateRange[1]) {
            if (dateRange[1] >= dateRange[0] && dateRange[1] <= new Date(dateRange[0]?.getTime() + maxRange)) {
                return true;
            }
        }
        return false;
    }

    const onValidateClick = (dateRange) => {
        if (!checkIfDatesAreValid(dateRange)) {
            return;
        }
        props.onDateChange(dateRange);
    }

    return (

        <div className={globalClasses.datePickerContainer}>
            <Typography variant="h9" style={{ marginRight: '10px' }}>{t('resources.global.chooseDateTitle')}</Typography>
            <Tooltip title={t('resources.global.chooseDates') + `${maxDays} ${t('resources.global.chooseDatesSuffix')}`}
                classes={{ popper: globalClasses.dateTooltip }} placement="top-start">
                <div>
                    <DatePicker
                        showIcon
                        selectsRange={true}
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        onChange={(update) => {
                            setDateRange(update);
                            onValidateClick(update);
                        }}
                        className={globalClasses.datePicker}
                        calendarClassName={globalClasses.datePickerCalendar}
                        locale={fr}
                        maxDate={new Date(dateRange[0]?.getTime() + maxRange)}
                        dateFormat="dd/MM/yyyy"
                        icon={<FontAwesomeIcon icon={faCalendar} className={globalClasses.datePickerIcon} />}
                    />
                </div>
            </Tooltip>
        </div>)
}

export default ChooseDateButton;