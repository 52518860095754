import { API, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const defaultOptions = {
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  enableAdminQueries: false,
};

class QueryDataProvider {
  static queries;
  static mutations;
  static models;

  static authMode;
  static enableAdminQueries;

  constructor(operations, options) {
    this.queries = operations.queries;
    this.mutations = operations.mutations;

    this.authMode = options?.authMode || defaultOptions.authMode;
  };

  getQuery = async (query_name, id, params = {}) => {
    const { time, nextToken } = params;
    const query = this.getQueryByName(query_name);
    let response;
    if (time && nextToken) {
      response = await this.graphql(query, { id: id, time: time, nextToken: nextToken });
    }
    else if (time) {
      response = await this.graphql(query, { id: id, time: time });
    }
    else if (Object.keys(params).length !== 0 && params.constructor === Object) {
      response = await this.graphql(query, params);
    }
    else {
      response = await this.graphql(query, { id: id });
    }

    // TODO: Remove asap (temporary fix for timeseries time field)
    if (query_name === 'getSiteAggElecTimeseries') {
      let timeseries = response[query_name].timeseries.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });
      // Convert timeseries time field from  UTC to Europe/Paris using moment.js
      timeseries.timeseries = timeseries.map((element) => {
        const moment = require('moment-timezone');
        const europeParisDate = moment.utc(element.time).tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss Z');
        return { ...element, time: europeParisDate };
      });

      return {
        data: timeseries,
        unit: response[query_name].unit,
        total: timeseries.length
      };
    } else if (query_name === 'getSiteTimeseries') {
      let timeseries = response[query_name].items.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });
      // Convert timeseries time field from  UTC to Europe/Paris using moment.js
      timeseries.items = timeseries.map((element) => {
        const moment = require('moment-timezone');
        const europeParisDate = moment.utc(element.time).tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss Z');
        return { ...element, time: europeParisDate };
      });
      response[query_name].items = timeseries;

      return {
        data: response[query_name],
        items: timeseries,
        unit: response[query_name].unit,
        total: timeseries.length
      };
    }
    else if (query_name === 'getSiteDjrTimeseries' || query_name === 'getSiteDjuTimeseries') {
      let timeseries = response[query_name].timeseries.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });
      // Convert timeseries time field from  UTC to Europe/Paris using moment.js
      timeseries.timeseries = timeseries.map((element) => {
        const moment = require('moment-timezone');
        const europeParisDate = moment.utc(element.time).tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss Z');
        return { ...element, time: europeParisDate };
      });

      return {
        data: timeseries,
        unit: response[query_name].unit,
        total: timeseries.length
      };
    }
    else if (query_name === 'getSiteDevicesTimeseries') {
      let timeseries_array = response[query_name].items.map((element) => {

        let timeseries = element.timeseries.sort((a, b) => {
          return new Date(a.time) - new Date(b.time);
        });
        // Convert timeseries time field from  UTC to Europe/Paris using moment.js
        timeseries = timeseries.map((element) => {
          const moment = require('moment-timezone');
          const europeParisDate = moment.utc(element.time).tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss Z');
          return { ...element, time: europeParisDate };
        });
        return { ...element, timeseries: timeseries };

      });
      return {
        data: { items: timeseries_array, nextToken: response[query_name].nextToken },
        total: timeseries_array.length
      };
    }

    return {
      data: response[query_name],
      total: 0
    };
  };

  getQueryByName(queryName) {
    if (this.queries[queryName]) {
      return this.queries[queryName];
    }

    if (this.mutations[queryName]) {
      return this.mutations[queryName];
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`Could not find query ${queryName}`);
    }

    throw new Error("Data provider error");
  }

  getQueryName(operation, resource) {
    const pluralOperations = ["list"];
    if (pluralOperations.includes(operation)) {
      return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1)}`;
    }
    // else singular operations ["create", "delete", "get", "update"]
    return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1, -1)}`;
  }

  getQueryNameMany(operation, resource, target) {
    const queryName = this.getQueryName(operation, resource);

    return `${queryName}By${target.charAt(0).toUpperCase() + target.slice(1, -2)}Id`;
  }

  async graphql(query, variables) {
    const queryResult = await API.graphql({ query, variables, authMode: this.authMode });

    if (queryResult.errors || !queryResult.data) {
      throw new Error("Data provider error");
    }

    return queryResult.data;
  }
}


export default QueryDataProvider;