// Lib
import React from "react";
import { TextField, useTranslate,FunctionField,TextInput,AutocompleteInput, SelectArrayInput, AutocompleteArrayInput } from "react-admin";
import Chip from '@mui/material/Chip';
// Components
import DeviceIcon from "../../assets/DeviceIcon";
import { destinationList, fluidTypeList, automaticUploadList } from "../aegeon/helper"
import CustomList from "../aegeon/CustomList";
import ImportSubDevicesButton from "../devices/CustomButtons/ImportSubDevicesButton";
import { allowedClickComponents, useRolePermissions } from "../aegeon-rbac/AuthRolePermissions";
import { useGetList } from "react-admin";
import globalUseStyles from "../../styles/globalCustomStyles";

const DevicesList = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();

  const { data: devices } = useGetList('devices');

  const fluidTypeChoices = (fluidTypeList).map((elem) => { 
    if (elem !== "ERREUR") {
      return {id: elem, name: t('devices.general_information.fluids.'+elem)}
    }
  })
  const destinationChoices = (destinationList).map((elem) => {
    return {id: elem, name: elem}
  })
  const automaticUploadChoices = (automaticUploadList).map((elem) => {
    return {id: elem, name: elem}
  })
  const qualityChoices = [{id:"A",name:"A"},{id:"B",name:"B"},{id:"C",name:"C"},{id:"N/A",name:"N/A"}]

  let buildingChoices = devices?.map((device) => {
    return device?.siteCodes?.map((site) => {
      return {id: site, name: site}
    })
  }).flat()
  buildingChoices = buildingChoices?.filter((elem, index, self) => self.findIndex((t) => {return t?.id === elem?.id}) === index)

  let defaultSortFields = [{field: "airtable.site_current_synoptic_quality_reporting", order: "ASC"},{field: "siteCodes", order: "ASC"},{field: "fluidType", order: "ASC"},{field: "destination", order: "ASC"}]

  const filters = [
    <TextInput source="name"/>,
    <AutocompleteInput label={t("devices.list.fluid_type")} source="fluidType.eq" choices={fluidTypeChoices} noOptionsText={t("resources.global.no_options")}/>,
    <AutocompleteInput source="siteCodes.contains" label={t("devices.list.site")} choices={buildingChoices} noOptionsText={t("resources.global.no_options")}/>,
    <AutocompleteInput label={t("devices.list.destination")} source="destination.eq" choices={destinationChoices} noOptionsText={t("resources.global.no_options")} />,
    <AutocompleteInput label={t("devices.general_information.automatic_upload")} source="airtable.automatic_upload.eq" choices={automaticUploadChoices} noOptionsText={t("resources.global.no_options")} />,
    // TODO : add this filter when its ready
    // <AutocompleteInput label={t("devices.general_information.data_quality")} source="fusionedEnergyQuality" choices={qualityChoices} noOptionsText={t("resources.global.no_options")} />,
  ];

  const findFluidTranslation = (fluid) => {
    if (t("devices.general_information.fluids."+fluid)!=="devices.general_information.fluids."+fluid) {
      return t("devices.general_information.fluids."+fluid)
    }
    return fluid
  }

  const defaultSortData = (data, sortedFieldsList) => {
    data.sort((a, b) => {
      if (sortedFieldsList.filter((elem) => elem.field === "siteCodes").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "siteCodes")[0].order
        const a_site = a.siteCodes?.[0] ? a.siteCodes?.sort() : ["Z"]
        const b_site = b.siteCodes?.[0] ? b.siteCodes?.sort() : ["Z"]
        if (a_site < b_site) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_site > b_site) {
          return order === "ASC" ? 1 : -1;
        }
      }
      if (sortedFieldsList.filter((elem) => elem.field === "fluidType").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "fluidType")[0].order
        const a_fluid = a?.fluidType ? parseInt(t("devices.general_information.fluids.order." + a?.fluidType),10) : 15;
        const b_fluid = b?.fluidType ? parseInt(t("devices.general_information.fluids.order." + b?.fluidType),10) : 15;
        if (a_fluid < b_fluid) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_fluid > b_fluid) {
          return order === "ASC" ? 1 : -1;
        }
      }
      if (sortedFieldsList.filter((elem) => elem.field === "destination").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "destination")[0].order
        const a_destination = a?.destination ? a?.destination : "Z";
        const b_destination = b?.destination ? b?.destination : "Z";
        if (a_destination < b_destination) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_destination > b_destination) {
          return order === "ASC" ? 1 : -1;
        }
      }
      if (sortedFieldsList.filter((elem) => elem.field === "fusionedEnergyQuality").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "fusionedEnergyQuality")[0].order
        const a_quality = a.fusionedEnergyQuality ? a.fusionedEnergyQuality : "Z";
        const b_quality = b.fusionedEnergyQuality ? b.fusionedEnergyQuality : "Z";
        if (a_quality < b_quality) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_quality > b_quality) {
          return order === "ASC" ? 1 : -1;
        }
      }
      if (sortedFieldsList.filter((elem) => elem.field === "name").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "name")[0].order
        const a_name = a.name ? a.name : "Z";
        const b_name = b.name ? b.name : "Z";
        if (a_name < b_name) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_name > b_name) {
          return order === "ASC" ? 1 : -1;
        }
      }
      if (sortedFieldsList.filter((elem) => elem.field === "airtable.automatic_upload").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "airtable.automatic_upload")[0].order
        const a_automatic_upload = a.airtable?.automatic_upload ? a.airtable?.automatic_upload : "Z";
        const b_automatic_upload = b.airtable?.automatic_upload ? b.airtable?.automatic_upload : "Z";
        if (a_automatic_upload < b_automatic_upload) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_automatic_upload > b_automatic_upload) {
          return order === "ASC" ? 1 : -1;
        }
      }
      return 0
    })
    return data
  }

  const { isLoading, permissions } = useRolePermissions();

  const permissionControlledComponents = {
    resource: "devices",
    elements: {
      click: {
        import_subdevices: <ImportSubDevicesButton />
      }
    }
  };

  const fields = [
    <TextField source="name" key="name" />,
    <FunctionField source="siteCodes" label={t("devices.list.site")} render={(record) => {
      return <div className={globalClasses.devicesListBuildingList}>
      {record?.siteCodes ? 
      record?.siteCodes.map((element, index) =>
        <Chip key={index} size="small" label={element} /> ) : <Chip size="small" label={record?.airtable?.site_code} />
      }
      </div>
    }} key="siteCodes" />,
    <FunctionField label={t("devices.list.fluid_type")} source="fluid_type" render={(record) => record?.airtable?.fluid_type ? findFluidTranslation(record?.airtable?.fluid_type) : ""} key="fluid_type"/>,
    <TextField source="destination" label={t("devices.list.destination")} key="destination" />,
    <TextField source="airtable.automatic_upload" label={t("devices.general_information.automatic_upload")} key="airtable.automatic_upload" />,
    <TextField label={t("devices.general_information.data_quality")} source="fusionedEnergyQuality" key="fusionedEnergyQuality" />,
  ];

  return (
    <CustomList defaultSortFields={defaultSortFields} filters={filters} defaultSortData={defaultSortData} Icon={DeviceIcon} resource="devices" fields={fields} sortingByDefault={true} CustomButton={
      () =>
      !isLoading && allowedClickComponents(permissions, permissionControlledComponents)
    }
    />
  );
};

export default DevicesList;