
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const AegeonPlatformLogoCentered = (props) => {
  return (
    <SvgIcon viewBox="0 0 183 123"  xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M26.202 96.7032L23.5131 90.2948H8.70635L6.01745 96.7032H0L13.2905 66.3909H19.308L32.4326 96.7032H26.202ZM10.7911 85.3198H21.4401L16.1571 72.586L10.7911 85.3198Z" fill="#37424A"/>
      <path d="M35.3229 66.3909H57.533V71.4607H41.1271V78.947H55.8509V84.0168H41.1271V91.6334H58.0542V96.7032H35.3229V66.3909Z" fill="#37424A"/>
      <path d="M94.2892 66.3909H116.499V71.4607H100.093V78.947H114.817V84.0168H100.093V91.6334H117.02V96.7032H94.2892V66.3909Z" fill="#37424A"/>
      <path d="M143.755 68.0374C146.219 69.3996 148.162 71.2593 149.583 73.6284C150.993 75.9975 151.704 78.6272 151.704 81.5056C151.704 84.384 150.993 87.0255 149.583 89.4065C148.174 91.7874 146.231 93.6708 143.755 95.0567C141.292 96.4426 138.555 97.1296 135.547 97.1296C132.538 97.1296 129.813 96.4426 127.338 95.0567C124.874 93.6708 122.931 91.7874 121.51 89.4065C120.1 87.0255 119.39 84.3959 119.39 81.5056C119.39 78.6153 120.1 75.9975 121.51 73.6284C122.919 71.2593 124.862 69.3996 127.338 68.0374C129.802 66.6752 132.538 66 135.547 66C138.555 66 141.28 66.6752 143.755 68.0374ZM130.489 72.5623C128.901 73.4744 127.646 74.7182 126.722 76.2818C125.798 77.8572 125.336 79.5985 125.336 81.4938C125.336 83.389 125.81 85.1421 126.746 86.7294C127.681 88.3167 128.937 89.5723 130.512 90.4962C132.088 91.4202 133.793 91.8821 135.641 91.8821C137.489 91.8821 139.148 91.4202 140.711 90.4962C142.275 89.5723 143.507 88.3167 144.419 86.7294C145.331 85.1421 145.781 83.389 145.781 81.4938C145.781 79.5985 145.331 77.8454 144.419 76.2818C143.507 74.7182 142.275 73.4626 140.711 72.5623C139.148 71.6502 137.466 71.2001 135.641 71.2001C133.817 71.2001 132.076 71.6502 130.489 72.5623Z" fill="#37424A"/>
      <path d="M177.302 66.3909H182.928V96.7032H177.432L161.843 75.8316V96.7032H156.169V66.3909H161.666L177.302 87.3098V66.3909Z" fill="#37424A"/>
      <path d="M76.4738 81.6241V86.3622H83.3796V89.9869C82.3372 90.591 81.2474 91.0648 80.1103 91.3965C78.9731 91.7282 77.8834 91.894 76.841 91.894C74.9102 91.894 73.1452 91.432 71.5579 90.5081C69.9706 89.5841 68.7032 88.3285 67.7674 86.7413C66.8316 85.154 66.3578 83.4127 66.3578 81.5056C66.3578 79.5985 66.8198 77.8928 67.7437 76.3055C68.6677 74.7182 69.9351 73.4744 71.5342 72.5623C73.1334 71.6502 74.8865 71.2001 76.7936 71.2001C78.3216 71.2001 79.8497 71.5081 81.3659 72.1122C82.8821 72.7163 84.2206 73.5692 85.3696 74.6708L88.6626 70.3828C87.1346 69.0206 85.3104 67.9545 83.2019 67.1727C81.0935 66.3909 78.9613 66 76.7936 66C73.7612 66 71.0012 66.687 68.5018 68.0611C66.0025 69.4352 64.0361 71.2949 62.591 73.6521C61.1458 76.0093 60.4233 78.6272 60.4233 81.5056C60.4233 84.384 61.134 87.0255 62.5436 89.4065C63.9532 91.7874 65.884 93.6708 68.3241 95.0567C70.7643 96.4426 73.465 97.1296 76.4382 97.1296C78.5112 97.1296 80.6433 96.7624 82.7992 96.028C84.9669 95.2936 86.8385 94.3341 88.4257 93.1496V81.6359H76.4501L76.4738 81.6241Z" fill="#37424A"/>
      <path d="M57.0804 109.224C58.0991 110.1 58.6085 111.344 58.6085 112.932C58.6085 114.519 58.0991 115.881 57.0686 116.805C56.0499 117.717 54.6047 118.179 52.7569 118.179H49.0492V122.822H47V107.897H52.7569C54.6166 107.897 56.0617 108.335 57.0804 109.224ZM55.6234 115.478C56.3105 114.922 56.6421 114.092 56.6421 112.991C56.6421 111.889 56.2986 111.119 55.6234 110.574C54.9364 110.029 53.9651 109.757 52.6858 109.757L49.0611 109.781V116.331H52.6858C53.9651 116.331 54.9482 116.047 55.6234 115.49V115.478Z" fill="#71B9A1"/>
      <path d="M61.2263 107.009H63.2045V122.834H61.2263V107.009Z" fill="#71B9A1"/>
      <path d="M74.0785 122.834V121.259C73.2375 122.372 71.9227 122.917 70.134 122.917C69.3522 122.917 68.6652 122.763 68.0729 122.467C67.4806 122.171 67.0305 121.756 66.7107 121.235C66.3909 120.714 66.2369 120.122 66.2369 119.47C66.2369 118.463 66.6041 117.658 67.3503 117.077C68.0966 116.497 69.139 116.189 70.4775 116.177H74.0548V115.644C74.0548 114.85 73.8061 114.234 73.3204 113.796C72.8348 113.358 72.1122 113.145 71.1764 113.145C70.0392 113.145 68.8784 113.547 67.7057 114.365L66.8765 113.003C67.682 112.481 68.4401 112.102 69.1271 111.865C69.8142 111.628 70.6315 111.51 71.591 111.51C73.0006 111.51 74.0904 111.854 74.8485 112.541C75.6066 113.228 76.0093 114.187 76.0212 115.431L76.0448 122.846H74.0785V122.834ZM72.894 120.607C73.5455 120.157 73.9245 119.565 74.0548 118.83V117.705H70.75C69.8497 117.705 69.1864 117.835 68.7599 118.108C68.3335 118.38 68.1084 118.807 68.1084 119.387C68.1084 119.968 68.3335 120.43 68.7718 120.773C69.2101 121.117 69.8023 121.282 70.5367 121.282C71.4607 121.282 72.2425 121.057 72.894 120.607Z" fill="#71B9A1"/>
      <path d="M85.6396 122.088C84.7749 122.645 83.8865 122.917 82.9981 122.917C82.1097 122.917 81.3753 122.656 80.8186 122.123C80.2618 121.59 79.9894 120.785 79.9894 119.707V113.464H78.3903V111.948H79.9894V108.916H81.9913V111.948H85.4501V113.464H81.9913V119.352C81.9913 119.979 82.1097 120.429 82.3348 120.69C82.5598 120.963 82.9033 121.093 83.3535 121.093C83.8984 121.093 84.4788 120.915 85.1066 120.56L85.6396 122.1V122.088Z" fill="#71B9A1"/>
      <path d="M90.9227 109.129C90.5791 109.437 90.4133 109.887 90.4133 110.468V111.936H93.5286V113.453H90.4133V122.834H88.4351V113.453H86.9663V111.936H88.4351V110.716C88.4351 109.887 88.6128 109.188 88.9563 108.62C89.2998 108.039 89.7618 107.613 90.3304 107.317C90.899 107.021 91.5149 106.878 92.1664 106.878C92.8179 106.878 93.4457 107.044 94.1328 107.388L93.6589 109.011C93.1496 108.774 92.6639 108.643 92.2138 108.643C91.7045 108.643 91.278 108.797 90.9345 109.117L90.9227 109.129Z" fill="#71B9A1"/>
      <path d="M102.815 112.221C103.692 112.706 104.367 113.37 104.865 114.234C105.35 115.099 105.599 116.082 105.599 117.184C105.599 118.285 105.35 119.28 104.865 120.145C104.379 121.01 103.692 121.685 102.815 122.171C101.939 122.656 100.944 122.893 99.8185 122.893C98.6932 122.893 97.6745 122.656 96.7979 122.171C95.9214 121.685 95.2462 121.022 94.7487 120.157C94.263 119.292 94.0143 118.309 94.0143 117.184C94.0143 116.059 94.263 115.087 94.7487 114.234C95.2344 113.37 95.9214 112.706 96.7979 112.221C97.6745 111.735 98.6814 111.498 99.8185 111.498C100.956 111.498 101.939 111.735 102.815 112.221ZM97.8522 113.737C97.2718 114.08 96.8335 114.554 96.5137 115.158C96.1938 115.762 96.0398 116.449 96.0398 117.219C96.0398 117.989 96.1938 118.7 96.5137 119.304C96.8335 119.92 97.2836 120.394 97.8522 120.726C98.4326 121.057 99.0841 121.223 99.8304 121.223C100.577 121.223 101.204 121.057 101.785 120.726C102.365 120.394 102.804 119.92 103.123 119.304C103.443 118.688 103.609 118.001 103.609 117.219C103.609 116.438 103.443 115.762 103.123 115.158C102.804 114.554 102.353 114.08 101.785 113.737C101.204 113.393 100.553 113.227 99.8304 113.227C99.1078 113.227 98.4326 113.393 97.8522 113.737Z" fill="#71B9A1"/>
      <path d="M111.925 112.138C112.612 111.723 113.417 111.498 114.353 111.486V113.405C113.204 113.358 112.268 113.666 111.557 114.306C110.847 114.945 110.444 115.81 110.337 116.888V122.834H108.359V111.557H110.337V113.986C110.704 113.18 111.237 112.564 111.913 112.138H111.925Z" fill="#71B9A1"/>
      <path d="M134.123 112.659C134.857 113.441 135.236 114.507 135.236 115.857V122.834H133.234V116.39C133.234 115.443 132.974 114.696 132.453 114.151C131.931 113.607 131.221 113.346 130.309 113.346C129.242 113.37 128.401 113.749 127.797 114.471C127.181 115.194 126.885 116.13 126.885 117.267V122.834H124.883V116.39C124.883 115.443 124.623 114.696 124.113 114.151C123.604 113.607 122.881 113.346 121.958 113.346C120.891 113.37 120.05 113.749 119.434 114.471C118.819 115.194 118.511 116.13 118.511 117.267V122.834H116.532V111.557H118.511V113.926C119.245 112.339 120.655 111.522 122.716 111.498C123.758 111.498 124.611 111.747 125.31 112.244C126.009 112.742 126.471 113.441 126.708 114.329C127.406 112.469 128.863 111.522 131.102 111.498C132.393 111.498 133.412 111.889 134.146 112.671L134.123 112.659Z" fill="#71B9A1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M116.547 0.0298701C115.811 0.0488783 114.97 0.0719595 114.079 0.0950408C116.036 0.111334 113.113 0.153423 112.642 0.14935C112.065 0.175147 111.5 0.199586 110.997 0.222667C109.088 0.332643 108.129 0.487423 111.955 0.597399H114.823C115.201 0.640846 114.983 0.688367 114.756 0.733172C116.252 0.738603 116.118 0.776619 114.56 0.775261C114.349 0.828213 114.404 0.874375 115.778 0.90696C116.314 0.916464 116.767 0.923253 117.269 0.931399C117.807 0.932757 118.395 0.935473 119.044 0.93683C119.047 3.51108 118.923 48.7464 118.926 56.0442C117.09 56.0564 115.323 56.0672 113.501 56.0795C105.851 56.1677 108.718 56.4773 116.37 56.5873V56.6755C108.72 56.6321 112.543 56.852 111.589 56.962C114.249 56.9742 116.874 56.9864 119.904 57C119.919 55.7699 119.936 54.4597 119.955 53.0151C119.955 52.081 120 0.0991139 120 0C118.916 0.00950407 117.837 0.0176508 116.546 0.0285126L116.547 0.0298701Z" fill="#71B9A1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M66.6296 0.412748V0.324496C74.2789 0.367943 70.4556 0.147992 71.41 0.0380166C68.7502 0.0257971 66.1252 0.0135772 63.0961 0C63.0808 1.2301 63.0641 2.5403 63.0446 3.98492C63.0446 4.91904 63 56.9009 63 57C64.084 56.9905 65.1624 56.9823 66.454 56.9715C67.1897 56.9525 68.0312 56.9294 68.9216 56.9063C66.9654 56.89 69.8871 56.8479 70.3581 56.852C70.9349 56.8262 71.5006 56.8018 72.0036 56.7787C73.9124 56.6687 74.871 56.5139 71.045 56.404H68.1775C67.8 56.3605 68.0173 56.313 68.2444 56.2682C66.7494 56.2628 66.8832 56.2247 68.4409 56.2261C68.6513 56.1731 68.5969 56.127 67.2231 56.0944C66.6867 56.0849 66.2339 56.0781 65.7323 56.07C65.1945 56.0686 64.6065 56.0659 63.9572 56.0645C63.9544 53.4903 64.0784 8.25497 64.0756 0.957196C65.9106 0.944977 67.6773 0.934115 69.4998 0.921895C77.1491 0.833643 74.2816 0.524082 66.631 0.414106L66.6296 0.412748Z" fill="#71B9A1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M87 48L103 32.6563L87.0928 26L87 47.9987V48Z" fill="#71B9A1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M96 9L80 24.3437L95.9072 31L96 9.00134V9Z" fill="#71B9A1"/>
    </SvgIcon>
  );
};

export default AegeonPlatformLogoCentered;