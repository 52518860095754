import { faArrowUpToLine } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import globalUseStyles from '../../../styles/globalCustomStyles';

const ImportSubDevicesButton = () => {
    const globalClasses = globalUseStyles();
    const t = useTranslate();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/devices/import_subdevices")
    }

    return (
        <Tooltip title={t("devices.subdevices.import.button_tooltip")} classes={{ popper: globalClasses.tooltip }} placement="right">
            <span>
                <IconButton onClick={handleClick} className={globalClasses.customButton} style={{ marginLeft: "15px" }}>
                    <FontAwesomeIcon icon={faArrowUpToLine} style={{ padding: 5, height: "17px" }} />
                    <Typography variant="h3" color="white !important">{t("devices.subdevices.import.button_name")}</Typography>
                </IconButton>
            </span>
        </Tooltip>
    )
}

export default ImportSubDevicesButton;