
import DeviceTypeColdIcon from '../../../assets/DeviceTypeColdIcon';
import DeviceTypeElectricityIcon from '../../../assets/DeviceTypeElecIcon';
import DeviceTypeGasIcon from '../../../assets/DeviceTypeGasIcon';
import DeviceTypeHeatIcon from '../../../assets/DeviceTypeHeatIcon';
import DeviceTypeWaterIcon from '../../../assets/DeviceTypeWaterIcon';
import DeviceTypeOtherIcon from '../../../assets/DeviceTypeOtherIcon';
import DeviceTypeWoodIcon from '../../../assets/DeviceTypeWoodIcon';
import DeviceTypeCityGasIcon from '../../../assets/DeviceTypeCityGasIcon';
import DeviceTypeFuelIcon from '../../../assets/DeviceTypeFuelIcon';

const DeviceTypeIcon = (props) => {  

    const deviceType = props.deviceType;
    if (deviceType === "enedisMeter" || deviceType === "electricityMeter") {
      return <DeviceTypeElectricityIcon />;
    } else if (deviceType === "grdfMeter" || deviceType === "gasMeter") {
      return <DeviceTypeCityGasIcon />;
    } else if (deviceType === "propaneMeter" || deviceType === "butaneMeter") {
      return <DeviceTypeGasIcon />;
    } else if (deviceType === "fuelMeter") {
      return <DeviceTypeFuelIcon />;
    } else if (deviceType === "waterMeter") {
      return <DeviceTypeWaterIcon />;
    } else if (deviceType === "woodMeter") {
      return <DeviceTypeWoodIcon />;
    } else if (deviceType === "heatMeter") {
      return <DeviceTypeHeatIcon />;
    } else if (deviceType === "coldMeter") {
      return <DeviceTypeColdIcon />;
    } else {
      return <DeviceTypeOtherIcon/>;
    }

};

export default DeviceTypeIcon;