import { createContext } from 'react';

import TimeseriesDataProvider from './TimeseriesDataProvider';

import * as mutations from "../../../graphql/mutations";
import * as queries from "../../..//graphql/queries";

const timeseriesDataProvider = new TimeseriesDataProvider({ queries, mutations, authMode: "AMAZON_COGNITO_USER_POOLS" })

const TimeseriesDataProviderContext = createContext(timeseriesDataProvider);

TimeseriesDataProviderContext.displayName = 'TimeseriesDataProviderContext';

export default TimeseriesDataProviderContext;
