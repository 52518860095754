import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';

var formatTooltipLine = function (color, text) {
  return "<span style='display:inline-block;width:10px;height:10px;border-radius:50%;background-color:" + color + ";margin-right:5px;'></span><span><b>" + text + "<b></span>"
}

const SubDeviceTimeseriesComparisonChart = ({new_timeseries, previous_timeseries}) => {
    const t = useTranslate();
    const globalClasses = globalUseStyles();

    const [options, setOptions] = useState(null);

    const getSerie = (timeseries, color, name) => {
        return {
        name: name,
        type: 'line',
        showSymbol: false,
        data: timeseries?.map(elem => [new Date(elem.time), elem.value]),
        itemStyle: {
            color: color
        },
        areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
                offset: 0.5,
                color: color
            },
            {
                offset: 1,
                color: color + '50'
            }
            ])
            },
    }}

    const getSeries = (new_timeseries, previous_timeseries) => {
        if (new_timeseries && previous_timeseries) {
            return [
                getSerie(new_timeseries, '#4ecca2', t("devices.subdevices.new_timeseries")),
                getSerie(previous_timeseries, '#6c74d8', t("devices.subdevices.previous_timeseries"))
            ]
        }
        else if (new_timeseries) {
            return [
                getSerie(new_timeseries, '#4ecca2', t("devices.subdevices.new_timeseries"))
            ]
        }
        return []
    }

    useEffect(() => {
        if (new_timeseries && !options) {
            let series = getSeries(new_timeseries, previous_timeseries)
            const option = {
                legend: {
                show: false,
                orient: 'vertical',
                bottom: 10,
                left: 0,
                textStyle: {
                    fontSize: 10
                },
                selectedMode: false,
                formatter: function (name) {
                    return name;
                },
                itemWidth: 10,
                itemHeight: 10,
                itemGap: 10,
                tooltip: {
                    show: true,
                    textStyle: {
                    fontSize: 10
                    },
                    position: [0, '50%']
                }
                },
                tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    return "<b>" + params[0].value[0].toLocaleDateString('fr-FR', { month: '2-digit', year: 'numeric' }) + "</b><br>" +
                    params.map(param => formatTooltipLine(param.color, param.seriesName) + ": " + param.value[1] + " kWh PCS").join("<br>")
                }
                },
                grid: {
                top: '5%',
                left: '5%',
                right: '5%',
                bottom: '20%',
                },
                xAxis: {
                    type: 'time',
                    axisLabel: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false,
                    },
                },
                series: series
            };
            setOptions(option)
        }
    }, [new_timeseries, previous_timeseries])

    return (
        options ?
            <ReactECharts
                key={new_timeseries}
                notMerge={false}
                lazyUpdate={true}
                option={options}
                style={{ height: '100%', width: '100%' }}
            />
        : <></>
        )
}

export default SubDeviceTimeseriesComparisonChart