import React from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import CustomTable from '../../Table/CustomTable';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import { convert_boolean_to_string, formatDateString } from '../../aegeon/helper';
import { useQueryContext } from '../../aegeon/query/useQueryContext';

const DeviceGeneralInformationDetailsContentRight = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const {
    data,
    isLoading,
  } = useQueryContext(props);

  const { record } = useContext(RecordContext);

  if (isLoading === true) {
    return (
      <ChartLoading />
    );
  }

  const formatClassesTemporelles = (rows) => {
    if (rows === undefined) {
      return [];
    }
    return rows.map(
      (row) => ({ name: t("devices.general_information.power") + row.libelle.toLowerCase().replace('eté', 'été'), value: row.puissance.valeur + " " + row.puissance.unite })
    )
  };

  const enedis_address = (data?.donneesGenerales?.adresseInstallation?.numeroEtNomVoie ? data?.donneesGenerales?.adresseInstallation?.numeroEtNomVoie + ", " : "") +
    (data?.donneesGenerales?.adresseInstallation?.codePostal ? data?.donneesGenerales?.adresseInstallation?.codePostal + ", " : "") +
    (data?.donneesGenerales?.adresseInstallation?.commune?.libelle ? data?.donneesGenerales?.adresseInstallation?.commune?.libelle : "")

  const grdf_address = data?.technical?.situation_compteur ? ((data?.technical?.situation_compteur?.numero_rue + " ") || "") + ((data?.technical?.situation_compteur?.nom_rue + ", ") || "") + ((data?.technical?.situation_compteur?.code_postal + ", ") || "") + (data?.technical?.situation_compteur?.commune || "") : ""

  const max_subscribed_power = data?.situationContractuelle?.structureTarifaire?.denivelePuissances?.classesTemporelles?.classeTemporelle ? "" :
    (data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.valeur && data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.unite) ?
      data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.valeur + " " + data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.unite : ""

  const opening_level = data?.donneesGenerales?.segment?.libelle === "C5" ? data?.donneesGenerales?.niveauOuvertureServices : null

  const need_mandate_or_error_tooltip = (() => {
    if (record?.airtable?.automatic_upload === "Automatique") {
      return 'devices.general_information.error_tooltip';
    }
    else {
      return 'devices.general_information.need_mandate_tooltip';
    }
  })();

  const is_distributor_enedis_or_grdf = (record?.airtable?.distributor_name === "ENEDIS" || record?.airtable?.distributor_name === "GRDF")
  const is_not_automatic = record?.airtable?.automatic_upload !== "Automatique";
  const is_GRDF = (record?.airtable?.distributor_name === "GRDF")
  const is_enedis = (record?.airtable?.distributor_name === "ENEDIS")

  const display_supplier = record?.airtable?.destination === "COMMUNES" || record?.airtable?.destination === "PRIVATIVES VACANTES ET COMMUNES" ? true : false
  const list_of_generic_suppliers = ['EAU', 'EAU DÉPOSÉE', 'ÉLECTRICITÉ (kWh PCS)', 'FIOUL DOMESTIQUE (litre)', 'ÉLECTRICITÉ DÉPOSÉE', 'GAZ DEPOSE', 'GAZ DE VILLE (kWh PCS)', 'GAZ LIQUÉFIÉ (kg)', 'PROPANE (kg)', 'PROPANE (m3)', 'BUTANE (kg)', 'CHARBON (kg de lignite)', 'HOUILLE (kg)', 'BOIS (kg de granulés / pellets ou briquettes)', 'BOIS (kg de plaquettes d\'industrie)', 'BOIS (kg de plaquettes forestières)', 'BOIS (Stère en buches)', 'FIOUL DOMESTIQUE (litre)', 'AUTRES COMBUSTIBLES FOSSILES', 'PHOTOVOLTAÏQUE']
  const supplier = (!display_supplier || list_of_generic_suppliers.includes(record?.airtable?.supplier_name)) ? '' : record?.airtable?.supplier_name
  const customer_space = convert_boolean_to_string(record?.airtable?.customer_space_available)

  // The rows tooltips are translated in the CustomTable component
  const enedis_rows_1 = [
    { name: t('devices.general_information.distributor'), value: record?.airtable?.distributor_name, tooltip: 'devices.general_information.distributor_no_data' },
    { name: t('devices.general_information.contract_status'), value: data?.donneesGenerales?.etatContractuel?.libelle, tooltip: 'devices.general_information.contract_status_no_data' },
    { name: t('devices.general_information.segment'), value: data?.donneesGenerales?.segment?.libelle, tooltip: need_mandate_or_error_tooltip },
    { name: t('devices.general_information.frequency'), value: data?.situationComptage?.caracteristiquesReleve?.periodicite?.libelle, hide_if_null: true },
    { name: t('devices.general_information.opening_level'), value: opening_level, hide_if_null: true, tooltip_info: 'devices.general_information.opening_level_tooltip' },
    { name: t('devices.general_information.forfait_change_at'), value: formatDateString(data?.donneesGenerales?.dateDerniereModificationFormuleTarifaireAcheminement), tooltip: 'devices.general_information.forfait_change_at_no_data', hide_if_null: true },
    { name: t('devices.general_information.type_meter'), value: data?.situationComptage?.dispositifComptage?.typeComptage?.libelle, tooltip: 'devices.general_information.type_meter_no_data' },
  ]
  const enedis_rows_2 = [
    { name: t('devices.general_information.supplier'), value: supplier, hide_if_null: true },
    { name: t('devices.general_information.addres_by_distributor'), value: enedis_address, tooltip: 'devices.general_information.addres_by_distributor_no_data' },
    { name: t('devices.general_information.max_subscribed_power'), value: max_subscribed_power, hide_if_null: true },
  ]
  const enedis_rows = enedis_rows_1.concat(formatClassesTemporelles(data?.situationContractuelle?.structureTarifaire?.denivelePuissances?.classesTemporelles?.classeTemporelle)).concat(enedis_rows_2)

  const grdfFrequency = (() => {
    if (data?.technical?.caracteristiques_compteur?.frequence) {
      switch (data?.technical?.caracteristiques_compteur?.frequence) {
        case "MM":
          return "Mensuelle (" + data?.technical?.caracteristiques_compteur?.frequence + ")"
        case "1M":
          return "Mensuelle (" + data?.technical?.caracteristiques_compteur?.frequence + ")"
        case "6M":
          return "Semestrielle (" + data?.technical?.caracteristiques_compteur?.frequence + ")"
        case "JJ":
          return "Journalière (" + data?.technical?.caracteristiques_compteur?.frequence + ")"
        default:
          return null;
      };
    }
    else {
      return null
    }
  })();

  const grdf_rows = [
    { name: t('devices.general_information.distributor'), value: record?.airtable?.distributor_name, tooltip: 'devices.general_information.distributor_no_data' },
    { name: t('devices.general_information.supplier'), value: supplier, hide_if_null: true },
    { name: t('devices.general_information.grdf_status'), value: is_not_automatic ? null : data?.permissions?.etat_droit_acces, tooltip: need_mandate_or_error_tooltip },
    { name: t('devices.general_information.segment'), value: is_not_automatic ? null : data?.contracts?.tarif_acheminement, tooltip: need_mandate_or_error_tooltip },
    { name: t('devices.general_information.frequency'), value: is_not_automatic ? null : grdfFrequency, hide_if_null: true },
    { name: t('devices.general_information.mes_date'), value: is_not_automatic ? null : formatDateString(data?.contracts?.date_mes), hide_if_null: true },
    { name: t('devices.general_information.addres_by_distributor'), value: is_not_automatic ? null : grdf_address, tooltip: need_mandate_or_error_tooltip },
  ]

  const analog_rows = [
    { name: t('devices.general_information.supplier'), value: supplier, hide_if_null: true },
    { name: t('devices.general_information.customer_space_available'), value: customer_space, tooltip: 'devices.general_information.customer_space_available_no_data', tooltip_with_value: !record?.airtable?.customer_space_available },
  ]

  const not_auto_text = is_distributor_enedis_or_grdf ? t('devices.general_information.right_panel.no_data_not_automatic_telereleve') : t('devices.general_information.right_panel.no_data_not_automatic')

  return (
    <Card className={globalClasses.DetailCard}>
      <Typography display="block" variant="h8">
        <span><b>{t('devices.general_information.right_panel.title')}</b></span>
      </Typography>
      <div className={globalClasses.DetailCardTableSubtitle} />
      {is_not_automatic &&
        <>
          <Typography display="block" variant="h9" component="p">
            <span><b>{not_auto_text}</b></span>
          </Typography>
          <br />
        </>
      }
      <CustomTable rows={is_GRDF ? grdf_rows : is_enedis ? enedis_rows : analog_rows} defaultTooltip={"devices.general_information.no_data_tooltip"} />
    </Card>
  );
};

export default DeviceGeneralInformationDetailsContentRight;
