import Icons from "../../assets/Icons";
import { findAlgoliaMatchsKeys } from "../../components/aegeon/helper";

const Item = ({hit, components, t}) => {
  const highlightResult = hit._highlightResult
  const matchedItems = findAlgoliaMatchsKeys(highlightResult, highlightResult, "item", t)

  const MatchedItemsComponent = () => {
    if (matchedItems.length > 0) {
      return (
        <span>
          <br />
          {matchedItems.map((item) => (
            <span style={{color:"#A6ACAF", fontSize:"15px"}} dangerouslySetInnerHTML={{ __html: item+' • ' }} />
          ))}
        </span>
      )
    }
    else {
      return (
        <></>
      )
    }
  }

  return (
    <a href={"#/" + hit.resource + "s/" + hit.id + "/show"}>
      <div className="aa-ItemWrapper">
        <div className="aa-ItemContent">
          <div className="aa-ItemIcon">
            <Icons icon={hit.resource} />
          </div>
          <div className="aa-ItemContentBody ">
            <div className="aa-ItemContentTitle">
              {hit.resource === "site" ?
                <>
                  <span>{t("sites.title") + hit.airtable.code + " • "}</span>
                  <components.Highlight hit={hit} attribute="name" />
                  <MatchedItemsComponent />
                </>
                :
                <>
                  <span>{t("devices.title")}</span>
                  <components.Highlight hit={hit} attribute="name" />
                  <span>{" • "+t("sites.title") + hit.airtable?.site_code}</span>
                  <MatchedItemsComponent />
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}


export default Item;