import React from 'react';

import globalUseStyles from "../../../styles/globalCustomStyles";

import CardWrapper from "../../aegeon/cards/CardWrapper";
import ChartActivePower from "../../aegeon/charts/ChartActivePower";
import QueryField from "../../aegeon/query/QueryField";
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
const CardChartLine = (props) => {
  const { filter, source, enabled } = props;

  const globalClasses = globalUseStyles();

  if (!enabled) return null;

  return (
    <CardWrapper resource="devices" type="power">
      <TimeseriesField source={source} filter={filter}>
        <QueryField query_name='getDeviceContract' >
          <ChartActivePower className={globalClasses.ChartCard}
            isWeekendsHighlight={true}
            isNightsHighlight={true}
            isAutomatic={props.isAutomatic}
          />
        </QueryField>
      </TimeseriesField>
    </CardWrapper>
  )
};

export default CardChartLine;

