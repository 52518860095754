const trimPeriods = (timeseries, newCurrentPeriodStartDate, newCurrentPeriodEndDate, newPreviousPeriodEndDate) => {
  // If the available data finishes before the end of the current period
  if (
    new Date(timeseries[timeseries?.length - 1].time) < newCurrentPeriodEndDate &&
    new Date(timeseries[timeseries?.length - 1].time) >= newCurrentPeriodStartDate
  ) {
    newCurrentPeriodEndDate = new Date(timeseries[timeseries?.length - 1].time);
    newPreviousPeriodEndDate = new Date(newCurrentPeriodEndDate);
    newPreviousPeriodEndDate.setFullYear(newPreviousPeriodEndDate.getFullYear() - 1);
  }
  return { newCurrentPeriodEndDate, newCurrentPeriodStartDate, newPreviousPeriodEndDate };
};

const getPeriods = (startDate, timeseries) => {
  let currentPeriodEndDate = new Date();
  currentPeriodEndDate.setDate(1);
  currentPeriodEndDate.setDate(currentPeriodEndDate.getDate() - 1);
  currentPeriodEndDate.setHours(0, 0, 0, 0);
  let previousPeriodEndDate = new Date(currentPeriodEndDate);
  previousPeriodEndDate.setFullYear(previousPeriodEndDate.getFullYear() - 1);

  if (startDate == null) {
    var currentPeriodStartDate = new Date();
    currentPeriodStartDate.setDate(1);
    currentPeriodStartDate.setMonth(0);
    currentPeriodStartDate.setHours(0, 0, 0, 0);
  } else {
    let day = startDate.split("/")[0];
    let month = startDate.split("/")[1];
    var currentPeriodStartDate = new Date();
    currentPeriodStartDate.setDate(day);
    currentPeriodStartDate.setMonth(month - 1);
    currentPeriodStartDate.setHours(0, 0, 0, 0);
    if (currentPeriodStartDate > currentPeriodEndDate) {
      currentPeriodStartDate.setFullYear(currentPeriodStartDate.getFullYear() - 1);
    }
  }

  let previousPeriodStartDate = new Date(currentPeriodStartDate);
  previousPeriodStartDate.setFullYear(previousPeriodStartDate.getFullYear() - 1);

  let { newCurrentPeriodEndDate, newCurrentPeriodStartDate, newPreviousPeriodEndDate } = trimPeriods(
    timeseries,
    currentPeriodStartDate,
    currentPeriodEndDate,
    previousPeriodEndDate
  );

  return {
    previousPeriodEndDate: newPreviousPeriodEndDate,
    previousPeriodStartDate: previousPeriodStartDate,
    currentPeriodEndDate: newCurrentPeriodEndDate,
    currentPeriodStartDate: newCurrentPeriodStartDate,
  };
};

const getConsumptionVariation = (timeseries, period) => {
  const { previousPeriodEndDate, previousPeriodStartDate, currentPeriodEndDate, currentPeriodStartDate } = period;

  let lastYearConsumption = 0;
  let lastYearDays = 0;
  let currentYearConsumption = 0;
  let currentYearDays = 0;

  timeseries.forEach((element) => {
    let date = new Date(element.time);
    if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
      lastYearConsumption += parseFloat(element.value);
      lastYearDays += 1;
    } else if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
      currentYearConsumption += parseFloat(element.value);
      currentYearDays += 1;
    }
  });
  if (lastYearDays === 0 || currentYearDays === 0) {
    var consumptionVariation = null;
  } else {
    var consumptionVariation = (currentYearConsumption - lastYearConsumption) / lastYearConsumption;
  }
  return { consumptionVariation, lastYearDays, currentYearDays };
};

const formatDate = (date) => {
  if (!date) return null;
  return date.toLocaleDateString(localStorage.getItem("language"), { day: "2-digit", month: "2-digit" });
};

const getWarningColor = (lastYearDays, currentYearDays, consumption, step) => {
  if (lastYearDays === 0 || currentYearDays === 0 || !consumption) {
    return "grey";
  } else if (step === "day" && Math.abs(lastYearDays - currentYearDays) > 20) {
    return "red";
  } else if (step === "month" && Math.abs(lastYearDays - currentYearDays) > 0) {
    return "red";
  } else if (Math.abs(lastYearDays - currentYearDays) > 0) {
    return "orange";
  } else {
    return "green";
  }
};

export { getConsumptionVariation, getPeriods, formatDate, getWarningColor };
