import { Typography } from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-admin';
import NoDataLockIcon from '../../../assets/NoDataLockIcon';
import NoDataXIcon from '../../../assets/NoDataXIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';

const ChartNoData = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  let text = props.customText ? props.customText : props.isLocked ? t('devices.no_data_locked') : t('devices.no_data')
  let icon = props.customIcon ? props.customIcon : props.isLocked ? <NoDataLockIcon /> : <NoDataXIcon />

  return (
    <div className={`${globalClasses.NoData} ${props.className}`}>
      {icon}
      <Typography variant='body' color="#71b9a1">{text}</Typography>
    </div>
  )
};

export default ChartNoData;
