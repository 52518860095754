import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslate } from 'react-admin';
import ExclamationTooltipIcon from '../../assets/ExclamationTooltipIcon';
import InfoTooltipIcon from '../../assets/InfoTooltipIcon';
import globalUseStyles from '../../styles/globalCustomStyles';
import CustomTooltip from '../aegeon/CustomTooltip';

const CustomLargeTable = (props) => {
  const head = props.head;
  const rows = props.rows;
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const disableMissingDataTooltip = props.disableMissingDataTooltip;
  const disabledBoldLastValue = props.disabledBoldLastValue;
  const rounded = props.rounded;
  const cellWithIconAndLink = props.cellWithIconAndLink;

  const getNatureParameters = (value_nature) => {
    return value_nature === "R" || value_nature === "C" ? {
      class: globalClasses.customTableCellRegulated,
      tooltip: t(`devices.dataTable.nature_tooltip.data`) + t(`devices.dataTable.nature_tooltip.${value_nature}`),
      tooltip_color: "#80C3FF"
    } : value_nature === "E" ? {
      class: globalClasses.customTableCellEstimated,
      tooltip: t(`devices.dataTable.nature_tooltip.data`) + t(`devices.dataTable.nature_tooltip.E`),
      tooltip_color: "grey"
    } : value_nature === "M" ? {
      class: globalClasses.customTableCellMissing,
      tooltip: t(`devices.dataTable.nature_tooltip.data`) + t(`devices.dataTable.nature_tooltip.M`),
      tooltip_color: "#F48021"
    } : value_nature === "RE" || value_nature === "CE" ? {
      class: globalClasses.customTableCellRegulatedEstimated,
      tooltip: t(`devices.dataTable.nature_tooltip.data`) + t(`devices.dataTable.nature_tooltip.${value_nature[0]}`) + t(`devices.dataTable.nature_tooltip.and`) + t(`devices.dataTable.nature_tooltip.E`),
      tooltip_color: "#80C3FF"
    } : value_nature === "RM" || value_nature === "CM" ? {
      class: globalClasses.customTableCellRegulatedMissing,
      tooltip: t(`devices.dataTable.nature_tooltip.data`) + t(`devices.dataTable.nature_tooltip.${value_nature[0]}`) + t(`devices.dataTable.nature_tooltip.and`) + t(`devices.dataTable.nature_tooltip.M`),
      tooltip_color: "#F48021"
    } : value_nature === "EM" ? {
      class: globalClasses.customTableCellEstimatedMissing,
      tooltip: t(`devices.dataTable.nature_tooltip.data`) + t(`devices.dataTable.nature_tooltip.E`) + t(`devices.dataTable.nature_tooltip.and`) + t(`devices.dataTable.nature_tooltip.M`),
      tooltip_color: "grey"
    } : value_nature === "REM" || value_nature === "CEM" ? {
      class: globalClasses.customTableCellRegulatedEstimatedMissing,
      tooltip: t(`devices.dataTable.nature_tooltip.data`) + t(`devices.dataTable.nature_tooltip.${value_nature[0]}`) + ", " + t(`devices.dataTable.nature_tooltip.E`) + t(`devices.dataTable.nature_tooltip.and`) + t(`devices.dataTable.nature_tooltip.M`),
      tooltip_color: "#F48021"
    } : {
      class: "",
      tooltip: ""
    }
  }



  const TableCellWithIconAndLink = ({ row }) => {
    return (
      <TableCell
        className={globalClasses.customTableCell}
      >
        <div className={globalClasses.customLinkIconTableCell}>
          <div className={globalClasses.tableCellNameIcon}>
            <CustomTooltip title={row.name_icon_tooltip} icon={row.name_icon} />
          </div>
          {row.name}
          <CustomTooltip title={t(row.name_tooltip_link)} clickable={true} path={row.name_path} icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} className={globalClasses.tableCellIcon} />} />
        </div>
      </TableCell>
    )
  }

  return (
    <TableContainer component={Paper} size="small" elevation={0}>
      <Table sx={{ minWidth: 100 }} aria-label="simple table">
        <TableHead>
          <TableRow key="head" className={globalClasses.customTableRow}>
            {head.map((elem, index) => {
              return (
                <TableCell
                  className={globalClasses.customTableCell}
                  key={index}
                >
                  {elem}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={globalClasses.customTableRow}
            >
              {
                cellWithIconAndLink ?
                  <TableCellWithIconAndLink row={row} /> :
                  row.name_tooltip ?
                    <TableCell
                      className={globalClasses.customTableCell}
                    >
                      <div className={globalClasses.customLinkIconTableCell}>
                        {row.name}
                        <CustomTooltip title={t(row.name_tooltip)} icon={<InfoTooltipIcon />} />
                      </div>
                    </TableCell>
                    :
                    <TableCell
                      className={globalClasses.customTableCell}
                    >
                      {row.name}
                    </TableCell>
              }
              {row.values?.map((value, index) => {
                const value_nature = row.estimated_values && row.estimated_values[index] ? row.estimated_values[index] : "";
                const natureParameters = getNatureParameters(value_nature);
                if (!disableMissingDataTooltip &&
                  (index === row.values?.length - 1 && ((row.values?.some((elem) => elem === "") || row?.estimated_values?.some((elem) => elem.includes("M"))))
                  )
                ) {
                  return (
                    <TableCell className={`${globalClasses.customTableCell} ${globalClasses.tableNoData} ${natureParameters.class}`} key={index}>
                      <div className={globalClasses.tableCellNoDataCentered}>
                        {rounded && typeof value === "number" ? Math.round(value).toLocaleString(localStorage.getItem("language")) : value && value.toLocaleString(localStorage.getItem("language"))}
                        <CustomTooltip title={t(`devices.dataTable.totalIncludesMissingValuesTooltip`)} icon={<ExclamationTooltipIcon />} />
                      </div>
                    </TableCell>
                  )
                }
                return (
                  <TableCell
                    className={`${globalClasses.customTableCell} ${(index === row.values.length - 1) && !disabledBoldLastValue ? globalClasses.customTableCellTotal : ''} ${natureParameters.class}`}
                    key={index}
                  >
                    {rounded && typeof value === "number" ? Math.round(value).toLocaleString(localStorage.getItem("language")) : value && value.toLocaleString(localStorage.getItem("language"))}
                    {natureParameters.tooltip && <CustomTooltip title={natureParameters.tooltip} icon={<ExclamationTooltipIcon color={natureParameters.tooltip_color} />} />}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default CustomLargeTable;