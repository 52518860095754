import { createContext } from 'react';

import QueryDataProvider from './QueryDataProvider';

import * as mutations from "../../../graphql/mutations";
import * as queries from "../../..//graphql/queries";

const queryDataProvider = new QueryDataProvider({ queries, mutations, authMode: "AMAZON_COGNITO_USER_POOLS" })

const QueryDataProviderContext = createContext(queryDataProvider);

QueryDataProviderContext.displayName = 'QueryDataProviderContext';

export default QueryDataProviderContext;
