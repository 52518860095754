import { CardContent, CircularProgress, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import { ThemeProvider } from "@mui/styles";
import { Form, useLogin } from "react-admin";
import globalUseStyles from "../styles/globalCustomStyles";

import * as React from "react";
import { useState } from "react";
import { Notification, PasswordInput, TextInput, required, useNotify, useSafeSetState, useTranslate } from "react-admin";
import AegeonPlatformLogoCentered from "../assets/AegeonPlatformLogoCentered";


const CustomLoginPage = ({ theme }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const globalClasses = globalUseStyles();
    const t = useTranslate();
    const login = useLogin();
    const notify = useNotify();
    const [loading, setLoading] = useSafeSetState(false);
    const redirectTo = "/";
    const submit = (values) => {
        setLoading(true);
        login(values, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    {
                        type: 'error',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                        ? error.message
                                        : undefined,
                        },
                    }
                );
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={globalClasses.loginContent}>
                <div className={globalClasses.loginItems}>
                    <AegeonPlatformLogoCentered className={globalClasses.loginLogo} />
                </div>
                <div className={globalClasses.loginItems}>
                    <Form
                        onSubmit={submit}
                        className={globalClasses.loginForm}
                    >
                        <CardContent className={globalClasses.loginFormContent}>
                            <Typography
                                variant="h6"
                                className={globalClasses.loginText}
                            >
                                {t("general.identification")}
                            </Typography>
                            <TextInput
                                autoFocus
                                source="username"
                                label={t('ra.auth.username')}
                                autoComplete="username"
                                validate={required()}
                                onChange={e => setUsername(e.target.value)}
                                fullWidth
                                name="username"
                                type="string"
                                value={username}
                                className={globalClasses.input}
                            />
                            <PasswordInput
                                source="password"
                                label={t('ra.auth.password')}
                                autoComplete="current-password"
                                validate={required()}
                                name="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                className={globalClasses.input}
                                fullWidth
                            />

                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                fullWidth
                                disabled={loading}
                                className={globalClasses.loginFormButton}
                            >
                                {loading ? (
                                    <CircularProgress
                                        className={globalClasses.loginFormIcon}
                                        size={19}
                                        thickness={3}
                                    />
                                ) : (
                                    t('ra.auth.sign_in')
                                )}
                            </Button>
                        </CardContent>
                    </Form>
                </div>
            </div>
            <Notification />
        </ThemeProvider>
    );
};

export default CustomLoginPage;
