import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const PortfoliosIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="m87.28,75.98v-.04c0-.07-.03-.12-.04-.18,0-.01,0-.04-.01-.05-.01-.04-.03-.08-.05-.12-.03-.07-.07-.14-.11-.19,0-.03-.03-.04-.04-.05-.03-.04-.07-.09-.11-.14-.04-.05-.09-.09-.15-.13,0,0-.11-.08-.16-.11h-.03c-.07-.03-.12-.05-.19-.08h-.03l-9.94-3.32v-9.91s0-.13-.01-.19v-.04c0-.05-.01-.11-.04-.16h0s-.01-.07-.03-.09c-.04-.09-.08-.2-.15-.28-.01-.03-.04-.05-.05-.08,0-.01-.03-.04-.04-.05-.03-.03-.04-.04-.07-.07-.04-.04-.07-.07-.11-.09-.03-.03-.05-.04-.08-.07-.04-.03-.08-.05-.12-.07-.03-.01-.07-.04-.09-.05,0,0-.03,0-.04-.01-.03,0-.05-.03-.08-.03l-10.9-3.63c-.28-.09-.58-.09-.86,0l-10.9,3.63s-.05.01-.08.03c0,0-.03,0-.04.01-.04,0-.07.03-.09.05-.04.01-.08.04-.12.07-.03.01-.05.04-.08.05-.04.04-.08.07-.12.11-.05.05-.09.11-.13.16-.04.05-.07.09-.09.15-.03.05-.05.11-.08.16,0,.03-.01.05-.03.08h0c-.01.07-.03.12-.04.18v.04c0,.07-.01.12-.01.19v20.83l-9.97,3.32s-.05.01-.08.03c0,0-.03,0-.04.01-.04,0-.07.03-.09.05-.07.03-.12.07-.19.12-.05.04-.09.08-.14.12-.05.05-.09.11-.13.16-.04.05-.07.09-.09.15-.03.05-.05.11-.08.16,0,.03-.01.05-.03.08h0c-.01.07-.03.12-.04.18v.04c0,.07-.01.12-.01.19v10.9c0,.58.38,1.11.93,1.28l10.8,3.61s.14.05.22.07c.03,0,.05,0,.08.01h.46s.05,0,.08-.01c.08,0,.15-.04.22-.07l10.37-3.46,10.37,3.46s.13.05.22.07c.03,0,.05,0,.08.01h.36c.13,0,.27-.04.39-.09l10.8-3.61c.55-.19.93-.7.93-1.28v-21.81s0-.13-.01-.19h-.04Zm-35.41,23.74l-8.17-2.73v-8.02l8.17,2.73v8.02Zm10.9-2.73l-8.17,2.73v-8.04l8.17-2.73v8.02h0Zm0-11.8l-2.05-.69-.45-.15-.45-.15-2.28-.77-.45-.15-.45-.15-2.05-.69v-18.92l8.17,2.73v18.94Zm2.63,1.39s0,.04.01.05c0-.03-.01-.05-.03-.07h.01Zm8.28,13.14l-8.17-2.73v-9.49l-.07-.03s.01-.05.03-.09c0-.03,0-.05.01-.08h0v-9.24l8.17,2.73v18.94h.03Zm0-28.17l-2.05.69-.89.3-2.28.77-.89.3-2.05.68v-8.04l8.17-2.73v8.02h0Zm10.9,25.44l-8.17,2.73v-18.94l8.17-2.73v18.92h0Z"/>
  <path d="m41.92,74.47s.05,0,.08.01h.36c.13,0,.27-.04.39-.09l6.78-2.26v-2.83l-5.91,1.97v-18.93l8.17-2.73v8.47l.47-.02,2.23-.74v-9.59s0-.13-.01-.19h.01v-.04c0-.07-.03-.12-.04-.18,0-.01,0-.04-.01-.05-.01-.04-.03-.08-.05-.12-.03-.07-.07-.13-.11-.19,0-.03-.03-.04-.04-.05-.03-.04-.07-.1-.11-.13-.04-.05-.09-.1-.15-.14,0,0-.11-.08-.16-.11h-.03c-.07-.03-.12-.05-.19-.08h-.03l-9.94-3.32v-9.91s0-.13-.01-.19v-.04c0-.05-.01-.11-.04-.16h0s-.01-.07-.03-.09c-.04-.09-.08-.2-.15-.28-.01-.03-.04-.05-.05-.08,0-.01-.03-.04-.04-.05-.03-.03-.04-.04-.07-.07-.04-.04-.07-.07-.11-.09-.03-.03-.05-.04-.08-.07-.04-.03-.08-.05-.12-.07-.03-.01-.07-.04-.09-.05,0,0-.03,0-.04-.01-.03,0-.05-.03-.08-.03l-10.95-3.65c-.28-.09-.58-.09-.86,0l-10.9,3.63s-.05.01-.08.03c0,0-.03,0-.04.01-.04,0-.07.03-.09.05-.04.01-.08.04-.12.07-.03.01-.05.04-.08.05-.04.04-.08.07-.12.11-.05.05-.09.11-.13.16-.04.05-.07.1-.09.15l-.08.16s-.01.05-.03.08h0c-.01.07-.03.12-.04.18v.04c0,.07-.01.12-.01.19v20.83l-9.97,3.32s-.05.01-.08.03c0,0-.03,0-.04.01-.04,0-.07.03-.09.05-.07.03-.12.07-.19.12-.05.04-.09.08-.13.12-.05.05-.09.11-.13.16s-.07.09-.09.15c-.03.05-.05.11-.08.16,0,.03-.01.05-.03.08h0c-.01.07-.03.12-.04.18v.04c0,.07-.01.12-.01.19v10.9c0,.58.38,1.11.93,1.28l10.8,3.61s.13.05.22.07c.03,0,.05,0,.08.01h.46s.05,0,.08-.01c.08,0,.15-.04.22-.07l10.37-3.46,10.37,3.46s.14.05.22.07Zm-22.84-3.2l-8.17-2.73v-8.02l8.17,2.73v8.02Zm10.9-2.73l-8.17,2.73v-8.04l8.17-2.73v8.04Zm0-11.8l-2.05-.69-.45-.15-.45-.15-2.28-.77-.45-.15-.45-.15-2.05-.69v-18.92l8.17,2.73v18.94Zm2.62,1.38h.01s0,.05.01.07c0-.03-.01-.05-.03-.07Zm.05.9s.01-.05.03-.09c0-.03,0-.05.01-.08h0v-9.24l8.17,2.73v18.93l-8.14-2.72v-9.49l-.07-.03Zm8.24-15.92l-2.05.69-.89.3-2.28.77-.89.3-2.05.68v-8.04l8.17-2.73v8.04Z"/>
  <path d="m119.99,38.03v-.04c0-.07-.03-.12-.04-.18,0-.01,0-.04-.01-.05-.01-.04-.03-.08-.05-.12-.03-.07-.07-.13-.11-.19,0-.03-.03-.04-.04-.05-.03-.04-.07-.1-.11-.14-.04-.05-.09-.09-.15-.13,0,0-.11-.08-.16-.11h-.03c-.07-.03-.12-.05-.19-.08h-.03l-9.94-3.32v-9.93s0-.13-.01-.19v-.04c0-.05-.01-.11-.04-.16h0s-.01-.07-.03-.09c-.04-.1-.08-.2-.15-.28-.01-.03-.04-.05-.05-.08,0-.01-.03-.04-.04-.05-.03-.03-.04-.04-.07-.07-.04-.04-.07-.07-.11-.09-.03-.03-.05-.04-.08-.07-.04-.03-.08-.05-.12-.07-.03-.01-.07-.04-.09-.05,0,0-.03,0-.04-.01-.03,0-.05-.03-.08-.03l-10.94-3.63c-.28-.1-.58-.1-.86,0l-10.9,3.63s-.05.01-.08.03c0,0-.03,0-.04.01-.04,0-.07.03-.09.05-.04.01-.08.04-.12.07-.03.01-.05.04-.08.05-.04.04-.08.07-.12.11-.05.05-.09.11-.14.16-.04.05-.07.09-.09.15-.03.05-.05.11-.08.16,0,.03-.01.05-.03.08h0c-.01.07-.03.12-.04.18v.04c0,.07-.01.12-.01.19v20.83l-9.97,3.32s-.05.01-.08.03c0,0-.03,0-.04.01-.04,0-.07.03-.09.05-.07.03-.12.07-.19.12-.05.04-.09.08-.14.12-.05.05-.09.11-.13.16-.04.05-.07.09-.09.15-.03.05-.05.11-.08.16,0,.03-.01.05-.03.08h0c-.01.07-.03.12-.04.18v.04c0,.07-.01.12-.01.19v8.13l2.21.74h.19l.06.03h.27v-6.99l8.17,2.73v8.02l-6.36-2.12c.14.22.23.42.28.55.06.13.11.27.15.41.07.22.11.45.12.68.01.11.02.23.02.37v1.04l6.62,2.21s.13.05.22.07c.03,0,.05,0,.08.01h.46s.05,0,.08-.01c.08,0,.15-.04.22-.07l10.37-3.46,10.37,3.46s.13.05.22.07c.03,0,.05,0,.08.01h.36c.14,0,.27-.04.39-.09l10.8-3.61c.55-.19.93-.7.93-1.28v-21.79s0-.14-.01-.19Zm-24.51,21.02l-8.17,2.73v-8.04l8.17-2.73v8.04Zm0-11.8l-2.05-.69-.45-.15-.45-.15-2.28-.77-.45-.15-.45-.15-2.05-.69v-18.92l8.17,2.73v18.94Zm2.62,1.38h.01s0,.05.01.07c0-.03-.01-.05-.03-.07Zm.05.91s.01-.05.03-.09c0-.03,0-.05.01-.08h0v-9.24l8.17,2.73v18.93l-8.14-2.72v-9.49l-.07-.03Zm8.24-15.92l-2.05.69-.89.3-2.28.77-.89.3-2.05.68v-8.04l8.17-2.73v8.04Zm10.9,25.44l-8.17,2.73v-18.93l8.17-2.73v18.93Z"/>
    </SvgIcon>
  );
};

export default PortfoliosIcon;