import React from 'react';

import globalUseStyles from "../../../styles/globalCustomStyles";

import { useState } from 'react';
import CardWrapper from '../../aegeon/cards/CardWrapper';
import ChartMonotone from "../../aegeon/charts/ChartMonotone";
import QueryField from "../../aegeon/query/QueryField";
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";

const CardMonotone = (props) => {
  const { filter, source, enabled } = props;

  const globalClasses = globalUseStyles();

  const [subtitle, setSubtitle] = useState("");

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitle(childData);
    }
  };

  if (!enabled) return null;

  return (
    <TimeseriesField source={source} filter={filter}>
      <QueryField query_name='getDeviceContract' >
        <CardWrapper resource="devices" type="monotone" subtitleCallBack={subtitle}>
          <ChartMonotone className={globalClasses.ChartCard} setSubtitleCallback={handleSetSubtitleCallback} />
        </CardWrapper>
      </QueryField>
    </TimeseriesField>
  )
};

export default CardMonotone;

