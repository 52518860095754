import React from 'react';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import { useTimeseriesContext } from '../../aegeon/timeseries/useTimeseriesContext';
import ChartDataComparison from '../Cards/ChartDataComparison';

const moment = require('moment');
const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
const startDateOneYearAgo = endDate.clone().subtract(1, 'year').startOf('month').add(1, 'month');
const filter = { startDate: startDateOneYearAgo, endDate: endDate };

const DataComparisonWithAnalog = (props) => {
  const {
    timeseries,
    isLoading,
  } = useTimeseriesContext(props);

  return (
    !isLoading ?
      <TimeseriesField filter={filter} source="billed_energy" aggregation={{ period: 'MONTH', operation: 'SUM' }}>
        <ChartDataComparison activePowerTimeseries={props.activePowerTimeseries} indexTimeseries={props.indexTimeseries} analogTimeseries={timeseries} startDate={startDateOneYearAgo} endDate={endDate} />
      </TimeseriesField>
      : <ChartLoading />
  )
}

const DataComparisonWithIndex = (props) => {
  const {
    timeseries,
    isLoading,
  } = useTimeseriesContext(props);

  return (
    !isLoading ?
      <TimeseriesField filter={filter} source="manual_bills" >
        <DataComparisonWithAnalog activePowerTimeseries={props.activePowerTimeseries} indexTimeseries={timeseries} />
      </TimeseriesField>
      : <ChartLoading />
  )
}

const DataComparisonWithActivePower = (props) => {
  const {
    timeseries,
    isLoading,
  } = useTimeseriesContext(props);

  return (
    !isLoading ?
      <TimeseriesField filter={filter} source="index" >
        <DataComparisonWithIndex activePowerTimeseries={timeseries} />
      </TimeseriesField>
      : <ChartLoading />
  )
}

const DataComparisonAnalysisCardDetails = (props) => {

  return (
    <TimeseriesField filter={filter} source="active_power" aggregation={{ period: 'MONTH', operation: 'IN_KWH' }}>
      <DataComparisonWithActivePower />
    </TimeseriesField>
  )
};

export default DataComparisonAnalysisCardDetails;

