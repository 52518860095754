import { useContext, useMemo } from 'react';

import SubscriptionDataProviderContext from './SubscriptionDataProviderContext';

const useSubscriptionDataProvider = () => {
    const subscriptionDataProvider = useContext(SubscriptionDataProviderContext);

    // const logoutIfAccessDenied = useLogoutIfAccessDenied();

    const subscriptionDataProviderProxy = useMemo(() => {
        return new Proxy(subscriptionDataProvider, {
            get: (target, name) => {
                if (typeof name === 'symbol' || name === 'then') {
                    return;
                }
                return (...args) => {
                    const type = name.toString();

                    if (typeof subscriptionDataProvider[type] !== 'function') {
                        throw new Error(
                            `Unknown subscriptionDataProvider function: ${type}`
                        );
                    }

                    try {
                        return subscriptionDataProvider[type]
                            .apply(subscriptionDataProvider, args)
                            .then(response => {
                                if (process.env.NODE_ENV !== 'production') {
                                    console.log(response);
                                    console.log(type);
                                    // validateResponseFormat(response, type);
                                }
                                return response;
                            })
                            .catch(error => {
                                if (process.env.NODE_ENV !== 'production') {
                                    console.error(error);
                                }
                                return Promise.resolve(false);
                            });
                    } catch (e) {
                        if (process.env.NODE_ENV !== 'production') {
                            console.error(e);
                        }
                        throw new Error(
                            'The subscriptionDataProvider threw an error. It should return a rejected Promise instead.'
                        );
                    }
                };
            },
        });
        // }, [subscriptionDataProvider, logoutIfAccessDenied]);
    }, [subscriptionDataProvider]);

    return subscriptionDataProviderProxy;
};

export default useSubscriptionDataProvider;