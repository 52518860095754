import ReactECharts from 'echarts-for-react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import CustomLargeTable from '../../../components/Table/CustomLargeTable';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import ChartNoData from '../../aegeon/charts/ChartNoData';
import { getBarSeries } from '../../aegeon/compute_table';
import { adaptative_unit, convert_to_right_unit } from '../../aegeon/helper';
import { useTimeseriesContext } from '../../aegeon/timeseries/useTimeseriesContext';

const ChartDataComparison = (props) => {
    const {
        timeseries,
        isLoading,
    } = useTimeseriesContext(props);
    const t = useTranslate();
    const { record } = useContext(RecordContext);
    const { activePowerTimeseries, indexTimeseries, analogTimeseries, startDate, endDate } = props;
    const [series, setSeries] = useState([]);
    const [rows, setRows] = useState([]);
    let unit = "kWh"
    let [newUnit, setNewUnit] = useState(unit)
    const moment = require('moment-timezone');

    const convertToKwh = (data) => {
        let convertedData = data.map((item) => {
            return [item[0], convert_to_right_unit(item[1], "Wh", "kWh")]
        })
        return convertedData
    }

    const getSeries = (activePowerTimeseries, indexTimeseries, analogTimeseries, timeseries) => {
        let activePowerData = getBarSeries(activePowerTimeseries, "active_power")
        let indexData = convertToKwh(getBarSeries(indexTimeseries, "index"))
        let billedEnergyData = getBarSeries(timeseries, "billed_energy")
        let analogData = getBarSeries(analogTimeseries, "manual_bills")
        let series = []
        if (billedEnergyData.length > 0) {
            series.push(
                {
                    name: t('devices.sources.billed_energy'),
                    data: billedEnergyData,
                    type: "bar",
                    color: '#4ecca2'
                })
        }
        if (activePowerData.length > 0) {
            series.push(
                {
                    name: t('devices.sources.informative_energy'),
                    data: activePowerData,
                    type: "bar",
                    color: '#6c74d8'
                })
        }
        if (indexData.length > 0) {
            series.push({
                name: t('devices.sources.index'),
                data: indexData,
                type: "bar",
                color: '#5cd1ff'
            })
        }
        if (analogData.length > 0) {
            series.push({
                name: t('devices.sources.manual_bills'),
                data: analogData,
                type: "bar",
                color: '#d6ad56'
            })
        }
        return series
    }

    useEffect(() => {
        if (timeseries && !isLoading) {
            let series = getSeries(activePowerTimeseries, indexTimeseries, analogTimeseries, timeseries)
            let maxValue = series?.reduce((max, serie) => {
                let serieMax = serie?.data?.reduce((max, data) => {
                    return Math.max(max, data[1])
                }, 0)
                return Math.max(max, serieMax)
            }, 0)
            let new_unit = adaptative_unit(maxValue, unit)
            setNewUnit(new_unit)
            series = series?.map(serie => {
                return {
                    ...serie,
                    data: serie?.data?.map(data => {
                        if (data.length === 2) {
                            return [data[0], convert_to_right_unit(data[1], unit, new_unit)]
                        } else {
                            return [data[0], convert_to_right_unit(data[1], unit, new_unit), data[2]]
                        }
                    })
                }
            })
            setSeries(series)

        }
    }, [timeseries, isLoading, activePowerTimeseries, indexTimeseries]);

    const getOptions = (series) => {
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                textStyle: {
                    fontSize: 10,
                },
                formatter: function (params) {
                    let result = moment.utc(params[0].data[0]).tz('Europe/Paris').format('MM/YYYY') + '<br>'
                    params.forEach((param) => {
                        param.value[1] = Math.round(param.value[1] * 1000) / 1000
                        result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>' + param.seriesName + ' : <b>' + param.value[1] + ' ' + newUnit + '</b><br>'
                    })
                    return result
                }
            },
            legend: {
                data: series.map((item) => item.name),
                type: 'scroll',
                bottom: '1%',
                left: 'center',
                textStyle: {
                    fontSize: 10,
                },
            },
            grid: {
                left: '8%',
                right: '8%',
                bottom: '10%',
                top: '5%',
                containLabel: true
            },
            xAxis: {
                type: 'time',
                data: series[0]?.data.map((item) => item[0]),
                axisLabel: {
                    fontSize: 10,
                    name: 'Mois',
                    formatter: function (value) {
                        return moment.utc(value).tz('Europe/Paris').format('MM/YYYY')
                    }
                },
            },
            yAxis: {
                type: 'value',
                name: 'Consommation (' + newUnit + ')',
                nameLocation: 'center',
                nameGap: 50
            },
            series: series
        }
    }

    const getMonths = (startDate, endDate) => {
        let months = []
        let date = startDate.clone()
        while (date.isBefore(endDate)) {
            months.push(date.format("MM/YYYY"))
            date.add(1, 'month')
        }
        return months
    }

    const getTableData = (head, series) => {
        let rows = series.map((serie) => {
            let values = head.map((date) => {
                let data = serie.data.find((data) => {
                    return moment.utc(data[0]).tz('Europe/Paris').format('MM/YYYY') === date
                })
                if (data) {
                    return data[1]
                }
                return null
            }
            )
            return {
                name: serie.name,
                values: values
            }
        }
        )
        return rows
    }

    return (
        isLoading ?
            <ChartLoading />
            :
            series && rows ?
                <>
                    <ReactECharts
                        notMerge={true}
                        option={getOptions(series)}
                        style={{ height: '60%', width: '100%' }} />
                    <div style={{ height: '50px' }}></div>
                    <CustomLargeTable
                        head={["Source (" + newUnit + ')', ...getMonths(startDate, endDate)]}
                        rows={getTableData(getMonths(startDate, endDate), series)}
                        disableMissingDataTooltip={true}
                        disabledBoldLastValue={true}
                    />
                </>
                :
                <ChartNoData />
    )
}

export default ChartDataComparison;
