import {
    useMutation
} from 'react-query';

import useMutationDataProvider from "./useMutationDataProvider";

const useMutationProvider = (resource, params = {}, options, onSuccessCallback) => {
    const {
        mutation_name,
        id,
        input
    } = params;
    const mutationDataProvider = useMutationDataProvider();
    const mutation = useMutation(
        {
            mutationFn: (params) => {
                return mutationDataProvider
                    .getMutation(params.mutation_name, params.id, params.input)
                    .then(({ data }) => ({ data }));
            },
            onSuccess: (data, variables, context) => onSuccessCallback(data, variables, context),
        }
    );

    return mutation;

};

export default useMutationProvider;
