import React from 'react';

import CardWrapper from "../../aegeon/cards/CardWrapper";
import ChartProfile from "../../aegeon/charts/ChartProfile";
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";

const CardQuality = (props) => {
  const { source, filter } = props;

  return (
    <CardWrapper resource="devices" type="quality" >
      <TimeseriesField source={source} filter={filter}>
        <ChartProfile isZoom={false} />
      </TimeseriesField>
    </CardWrapper>

  )
};

export default CardQuality;

