const translateCostumersFr = {
  customers: {
    show: {
      general_information: {
        title: "Informations générales",
        name: "Nom du client",
      },
      organizations: {
        title: "Organisations",
        name: "Nom des organisations",
      },
      contacts: {
        title: "Contacts",
        name: "Nom des clients",
      },
      title: "Voir le client",
      name: "Nom du client",
    },
    create: {
      title: "Ajouter un client",
      general_information: {
        title: "Informations générales",
        name: "Nom du client",
      },
      organizations: {
        title: "Organisations",
        name: "Nom des organisations",
      },
      contacts: {
        title: "Contacts",
        name: "Nom des clients",
      },
    },
    edit: {
      title: "Éditer un client",
      general_information: {
        title: "Informations générales",
        name: "Nom du client",
      },
      organizations: {
        title: "Organisations",
        name: "Nom des organisations",
      },
      contacts: {
        title: "Contacts",
        name: "Nom des contacts",
      },
    },
  },
};

export default translateCostumersFr;
