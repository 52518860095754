import SortIcon from '@mui/icons-material/Sort';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../styles/globalCustomStyles';


const SortButton = ({ resource, onClick, defaultSort }) => {
    const globalClasses = globalUseStyles();
    const [disabled, setDisabled] = React.useState(defaultSort);
    const t = useTranslate();

    const handleClick = () => {
        onClick();
    }

    React.useEffect(() => {
        setDisabled(defaultSort)
    }, [defaultSort]);


    return (
        <Tooltip title={t(resource + ".sort_button.sort_by_default_tooltip")} classes={{ popper: globalClasses.tooltip }} placement="right">
            <span>
                <IconButton onClick={handleClick} disabled={disabled} className={globalClasses.customButton} style={{ marginLeft: "15px" }}>
                    <SortIcon style={{ marginRight: "8px" }} />
                    <Typography variant="h3" color="white !important">{t(resource + ".sort_button.sort_by_default")}</Typography>
                </IconButton>
            </span>
        </Tooltip>
    )
}

export default SortButton;