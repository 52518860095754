import React from "react";
import globalUseStyles from "../../styles/globalCustomStyles";
import AegeonCentered from "../../assets/AegeonCentered";
import PlatformCentered from "../../assets/PlatformCentered";
import { Typography } from "@mui/material";
import { useTranslate } from "react-admin";
import SearchBar from "../search/SearchBar";

const Home = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  return (
    <div className={globalClasses.home}>
      <div className={globalClasses.homeTitle}>
        <img src="/AEGEON_LOGO_SIGLE-TURQUOISE.svg" alt="Logo Aegeon" />
        <AegeonCentered className={globalClasses.aegeonCentered}/>
        <PlatformCentered className={globalClasses.platformCentered}/>
      </div>
      <Typography variant="p" className={globalClasses.homeMessage}>
        {t("resources.home.message")}
      </Typography>
      <div className={globalClasses.homeSearchBar}>
        <SearchBar />
      </div>
    </div>
  );
};

export default Home;
