import React from 'react';
import { useRecordContext } from 'react-admin';

import globalUseStyles from "../../../styles/globalCustomStyles";

import CardWrapper from "../../aegeon/cards/CardWrapper";
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import ChartDeviceHorizontalBar from "./ChartDeviceHorizontalBar";

const CardChartHorizontalBar = (props) => {
  const { filter, source } = props;

  const globalClasses = globalUseStyles();
  const record = useRecordContext();
  const unit = record?.properties?.find((property) => property?.name === "fusioned_energy")?.unit

  return (
    <CardWrapper resource="devices" type="comparison" >
      <TimeseriesField source={source} filter={filter} aggregation={{ period: 'MONTH', operation: 'SUM' }}>
        <ChartDeviceHorizontalBar className={globalClasses.ChartCard} unit={unit} />
      </TimeseriesField>
    </CardWrapper>
  )
};

export default CardChartHorizontalBar;

