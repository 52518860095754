import React from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import CustomTable from '../../Table/CustomTable';
import CustomTooltip from '../../aegeon/CustomTooltip';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import { convert_array_to_string, convert_string_boolean_to_string } from '../../aegeon/helper';
import { useQueryContext } from '../../aegeon/query/useQueryContext';

const DeviceGeneralInformationDetailsContentLeft = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const {
    data,
    isLoading,
  } = useQueryContext(props);

  const { record } = useContext(RecordContext);

  if (isLoading === true) {
    return (
      <ChartLoading />
    );
  }

  const usage_tooltip = (() => {
    switch (data?.destination) {
      case "COMMUNES":
        return 'devices.general_information.usage_no_data_commune';
      case "PRIVATIVES VACANTES ET COMMUNES":
        return 'devices.general_information.usage_no_data_commune';
      case "PRIVATIVES":
        return 'devices.general_information.usage_no_data_priv';
      case "PRIVATIVES VACANTES":
        return 'devices.general_information.usage_no_data_priv';
      case "TIERCES":
        return 'devices.general_information.usage_no_data_tierce';
      default:
        return 'devices.general_information.usage_no_data';
    };
  })();

  const is_distributor_enedis_or_grdf = record?.airtable?.distributor_name === "ENEDIS" || record?.airtable?.distributor_name === "GRDF";

  const is_authorization = is_distributor_enedis_or_grdf ? (record?.airtable?.automatic_upload === "Automatique" ? "Oui" : "Non") : null;

  const authorization_end_date = (() => {
    if (!record?.airtable?.authorization_end_date) {
      return null
    } else
      return new Date(record?.airtable?.authorization_end_date).toLocaleDateString(localStorage.getItem("language"))
  })();


  const automatic_upload_tooltip = (() => {
    if (record?.airtable?.automatic_upload !== "Automatique" && (is_distributor_enedis_or_grdf)) {
      return 'devices.general_information.automatic_upload_tooltip_no_data_missing_mandate';
    }
    else if (record?.airtable?.automatic_upload !== "Automatique" && !record?.airtable?.customer_space_available) {
      return 'devices.general_information.automatic_upload_tooltip_no_data_missing_customer_space';
    }
    else return null
  })();


  // TODO : convert this to array in backend
  const deet_category = record?.airtable?.deet_category ? record?.airtable?.deet_category.replace('[', '').replace(']', '') : null;

  const sitesInfos = record?.airtable?.sites

  const SiteWithLinkComponent = (name, id) => {
    return (
      <>
        <span>{name}</span>
        <CustomTooltip title={t('devices.general_information.site_code_link_tooltip')} icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} className={globalClasses.tableCellIconMultiple} />} clickable={true} path={"/sites/" + id + "/show"} />
      </>
    )
  }

  const sites_codes = sitesInfos?.length === 1 ? sitesInfos[0]?.code : (
    <div>
      {sitesInfos?.map((site) => SiteWithLinkComponent(site?.code, site?.id))}
    </div>
  )

  const sites_names = sitesInfos?.length === 1 ? sitesInfos[0]?.name : (
    <div>
      {sitesInfos?.map((site) => SiteWithLinkComponent(site?.name, site?.id))}
    </div>
  )

  let sites_tenths = record?.airtable?.building_tenths?.map((tenth) => {
    return tenth.site_code + " : " + tenth.tenth
  }).join('\n')

  // The rows tooltips are translated in the CustomTable component
  const rows = [
    {
      name: t('devices.general_information.site_code'), value: sites_codes, tooltip: 'devices.general_information.site_code_no_data',
      path: sitesInfos?.length === 1 ? '/sites/' + record?.siteId + '/show' : null,
      tooltip_link: 'devices.general_information.site_code_link_tooltip'
    },
    {
      name: t('devices.general_information.site_name'), value: sites_names, tooltip: 'devices.general_information.site_name_no_data',
      path: sitesInfos?.length === 1 ? '/sites/' + record?.siteId + '/show' : null,
      tooltip_link: 'devices.general_information.site_code_link_tooltip'
    },
    { name: t('devices.general_information.destination'), value: record?.airtable?.destination, tooltip: 'devices.general_information.left_panel.tooltip_no_data' },
    { name: t('devices.general_information.holder'), value: record?.airtable?.contract_holder, tooltip: 'devices.general_information.left_panel.tooltip_no_data' },
    { name: t('devices.general_information.automatic_upload'), value: record?.airtable?.automatic_upload, tooltip: automatic_upload_tooltip, tooltip_with_value: true },
    { name: t('devices.general_information.acquisition_authorization'), value: is_authorization, tooltip: automatic_upload_tooltip, tooltip_with_value: is_authorization === "Non", hide_if_null: true },
    { name: t('devices.general_information.acquisition_authorization_end_date'), value: authorization_end_date, hide_if_null: true, tooltip_with_value: new Date(record?.airtable?.authorization_end_date) < new Date(), tooltip: 'devices.general_information.tooltip_expired_date' },
    { name: t('devices.general_information.unique_metering_point'), value: convert_string_boolean_to_string(record?.airtable?.unique_point), hide_if_null: true },
    { name: t('devices.general_information.attached_sub_metering'), value: record?.airtable?.sub_metering === "true" ? "Oui" : null, hide_if_null: true },
    { name: t('devices.general_information.usage'), value: convert_array_to_string(record?.airtable?.usage), tooltip: usage_tooltip },
    { name: t('devices.general_information.deet_category'), value: deet_category, tooltip: 'devices.general_information.left_panel.tooltip_no_data' },
    { name: t('devices.general_information.tenths'), value: sites_tenths, tooltip: 'devices.general_information.tenths_no_data', tooltip_info: 'devices.general_information.tenths_tooltip_info' },
  ]

  return (
    <Card className={globalClasses.DetailCard}>
      <Typography display="block" variant="h8">
        <span><b>{t('devices.general_information.left_panel.title')}</b></span>
      </Typography>
      <div className={globalClasses.DetailCardTableSubtitle} />
      <CustomTable rows={rows} defaultTooltip={"devices.general_information.no_data_tooltip"} />
    </Card>
  );
};

export default DeviceGeneralInformationDetailsContentLeft;

