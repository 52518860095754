import React from 'react';
import { useTranslate } from 'react-admin';
import DetailsShow from '../../../aegeon/cards/DetailsShow';
import SiteGeneralInformationDetailsContent from './SiteGeneralInformationDetailsContent';

const SiteGeneralInformationDetails = (props) => {
  const t = useTranslate();

  return (
    <DetailsShow title={t('sites.general_information.title')} resource="sites" lessMargin={true}>
      <SiteGeneralInformationDetailsContent />
    </DetailsShow>
  )
};

export default SiteGeneralInformationDetails;

