import React from 'react';
import { useTranslate } from 'react-admin';
import QueryInfiniteField from '../../aegeon/query/QueryInfiniteField';
import SiteEnergeticPerformance from '../Cards/SiteEnergeticPerformance';

const EnergeticPerformanceCardDetails = (props) => {
  const t = useTranslate();
  const { handleSetSubtitleCallback, type } = props;

  const moment = require('moment');
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  endDate.set({ date: 1 });
  endDate.subtract(1, 'days');
  const startDate = moment(endDate).subtract(1, 'years');


  return (
    <QueryInfiniteField query_name="getSiteTimeseries" params={{ time: { between: [startDate, endDate] } }}>
      {type === "energeticPerformance" ?
        <SiteEnergeticPerformance setSubtitleCallback={handleSetSubtitleCallback} isDetailedCard={true} type="energetic" /> :
        <SiteEnergeticPerformance setSubtitleCallback={handleSetSubtitleCallback} isDetailedCard={true} type="climatic" />
      }
    </QueryInfiniteField>
  )
};

export default EnergeticPerformanceCardDetails;

