import React from 'react';
import globalUseStyles from '../../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import CustomTable from '../../../Table/CustomTable';
import { convert_array_to_multiple_lines_string, convert_single_select_to_string, formatDateString, round_number, value_with_unit } from '../../../aegeon/helper';


const SiteGeneralInformationDetailsContentRight = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const record = useRecordContext();

  const aegeon_energy_data_type = Array.from(new Set(record?.airtable?.aegeon_energy_data_type))
  const aegeon_energy_data_type_value = aegeon_energy_data_type.length === 1 ? aegeon_energy_data_type[0] : aegeon_energy_data_type.length > 1 ? t('sites.general_information.aegeon_energy_data_types.mixed') : ""

  const is_autoproduction = (() => {
    const photovoltaic_presence = typeof record?.airtable?.photovoltaic_presence === "string" ? record?.airtable?.photovoltaic_presence?.includes("OUI") : record?.airtable?.photovoltaic_presence
    const consumption_or_production = typeof record?.airtable?.consumption_or_production === "string" ? record?.airtable?.consumption_or_production?.includes("PRODUCTION") : record?.airtable?.consumption_or_production
    if (photovoltaic_presence || consumption_or_production) {
      return "Oui"
    }
    else {
      return "Non"
    }
  })()



  const construction_certification = convert_array_to_multiple_lines_string(record?.airtable?.construction_certification)
  const exploitation_certification = convert_array_to_multiple_lines_string(record?.airtable?.exploitation_certification)

  const last_dpe_emission = record?.airtable?.dpe_emissions ? t('sites.general_information.last_dpe_rate_emissions') + " " + record?.airtable?.dpe_emissions : ""
  const last_dpe_energy = record?.airtable?.dpe_energy ? t('sites.general_information.last_dpe_rate_energy') + " " + record?.airtable?.dpe_energy : ""
  const last_dpe_rate = [last_dpe_emission, last_dpe_energy].filter(Boolean).join(", ")

  // The rows tooltips are translated in the CustomTable component
  const rows = [
    { name: t('sites.general_information.fluid'), value: record?.airtable?.fluids_typology?.filter(element => element !== null).map(element => t("sites.general_information.fluids." + element)).join(', '), tooltip: 'sites.general_information.fluid_no_data' },
    { name: t('sites.general_information.last_dpe_date'), value: formatDateString(record?.airtable?.last_dpe_date), tooltip: 'sites.general_information.last_dpe_date_no_data' },
    { name: t('sites.general_information.last_dpe_rate'), value: last_dpe_rate, tooltip: 'sites.general_information.last_dpe_rate_no_data' },
    { name: t('sites.general_information.construction_certification'), value: construction_certification, tooltip: 'sites.general_information.construction_certification_no_data', hide_if_null: true },
    { name: t('sites.general_information.exploitation_certification'), value: exploitation_certification, tooltip: 'sites.general_information.exploitation_certification_no_data', hide_if_null: true },
    { name: t('sites.general_information.thermal_regulation'), value: record?.airtable?.thermal_regulation, tooltip: 'sites.general_information.thermal_regulation_no_data', hide_if_null: true },
    { name: t('sites.general_information.biodiversity_label'), value: record?.airtable?.biodiversity_label, tooltip: 'sites.general_information.biodiversity_label_no_data', hide_if_null: true },
    { name: t('sites.general_information.aegeon_energy_data_type'), value: aegeon_energy_data_type_value, tooltip: 'sites.general_information.aegeon_energy_data_type_no_data' },
    { name: t('sites.general_information.deet_geographic_zone'), value: record?.airtable?.deet_geographic_zone, tooltip: 'sites.general_information.deet_geographic_zone_no_data' },
    { name: t('sites.general_information.altitude'), value: value_with_unit(round_number(record?.airtable?.altitude), "m"), tooltip: 'sites.general_information.altitude_no_data' },
    { name: t('sites.general_information.reference_weather_station_city'), value: record?.airtable?.reference_weather_station_city, tooltip: 'sites.general_information.reference_weather_station_city_no_data' },
    { name: t('sites.general_information.irves_presence'), value: convert_single_select_to_string(record?.airtable?.irves_presence), tooltip: 'sites.general_information.irves_presence_no_data' },
    { name: t('sites.general_information.autoproduction_presence'), value: is_autoproduction, tooltip: 'sites.general_information.autoproduction_presence_no_data' },
    { name: t('sites.general_information.gtb_presence'), value: convert_single_select_to_string(record?.airtable?.gtb_presence), tooltip: 'sites.general_information.gtb_presence_no_data' },
    { name: t('sites.general_information.rie_presence'), value: convert_single_select_to_string(record?.airtable?.rie_presence), tooltip: 'sites.general_information.rie_presence_no_data' },
    { name: t('sites.general_information.number_of_outdoor_parkings'), value: record?.airtable?.number_of_outdoor_parkings, tooltip: 'sites.general_information.number_of_outdoor_parkings_no_data' },
    { name: t('sites.general_information.number_of_indoor_parkings'), value: record?.airtable?.number_of_indoor_parkings, tooltip: 'sites.general_information.number_of_indoor_parkings_no_data' },
  ]

  return (
    <Card className={globalClasses.DetailCard}>
      <Typography display="block" variant="h8">
        <span><b>{t('sites.general_information.right_panel.title')}</b></span>
      </Typography>
      <div className={globalClasses.DetailCardTableSubtitle} />
      <CustomTable rows={rows} defaultTooltip={"sites.no_data_tooltip"} />
    </Card>
  );
};

export default SiteGeneralInformationDetailsContentRight;
