// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_appsync_graphqlEndpoint: "https://2cywcgwprbaa3ioaoa6d4bkche.appsync-api.eu-west-3.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-3",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  //   aws_appsync_apiKey: "null",
  //   aws_cognito_identity_pool_id: "eu-west-3:1f427926-b270-4ec5-bf5c-b8e24a6f54fa",
  aws_cognito_region: "eu-west-3",
  aws_user_pools_id: "eu-west-3_Wwx2p2xwn",
  aws_user_pools_web_client_id: "402mvoh5bhef3vvls2189embca",
  //   2auuc6a9inq455vk5bit1c21sl
  //   aws_user_pools_web_client_id: "1qb2eg39ebcs4hnccp3ovuelb9",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
};

export default awsmobile;
