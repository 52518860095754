import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceTypeWaterIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="m39.58,15.64c-1.25-1.83-3.92-1.83-5.17,0-3.26,4.78-8.44,13.09-8.44,17.51,0,8.02,5.72,11.11,11,11.11s11-3.09,11-11.11c.02-4.45-5.14-12.74-8.4-17.51Zm-2.05,24.41c-3.79,0-7.63-2.64-7.63-7.69,0-1.66,1.34-3,3-3s3,1.34,3,3c0,.46,0,1.69,1.63,1.69s3,1.34,3,3-1.34,3-3,3Z"/>
        <path d="m58.97,58.57c-2.39-3.43-7.48-3.42-9.86,0-6.22,8.93-16.1,24.43-16.1,32.69,0,14.97,10.91,20.74,21,20.74s21-5.77,21-20.74c.04-8.3-9.82-23.78-16.03-32.69Zm-11.08,46.87c-3.79,0-7.63-2.64-7.63-7.69,0-1.66,1.34-3,3-3s3,1.34,3,3c0,.46,0,1.69,1.63,1.69s3,1.34,3,3-1.34,3-3,3Z"/>
        <path d="m89.48,29.36c-1.82-2.69-5.7-2.69-7.51,0-4.74,7.02-12.27,19.19-12.27,25.69,0,11.76,8.32,16.3,16,16.3s16-4.53,16-16.3c.03-6.52-7.48-18.68-12.22-25.69Zm-6.22,36.91c-3.79,0-7.63-2.64-7.63-7.69,0-1.66,1.34-3,3-3s3,1.34,3,3c0,.46,0,1.69,1.63,1.69s3,1.34,3,3-1.34,3-3,3Z"/>
    </SvgIcon>
  );
};

export default DeviceTypeWaterIcon;