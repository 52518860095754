import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const InfoTooltipIcon = (props) => {
  const color = props.color || "#71b9a1";
  return (
    <SvgIcon viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m20,4c-8.84,0-16,7.16-16,16s7.16,16,16,16,16-7.16,16-16S28.84,4,20,4Zm3,24c0,1.66-1.34,3-3,3s-3-1.34-3-3v-8c0-1.66,1.34-3,3-3s3,1.34,3,3v8Zm-.06-15.41c-.04.19-.1.38-.17.56-.08.18-.17.36-.28.52-.11.16-.23.32-.37.45-.69.7-1.73,1.02-2.71.82-.19-.03-.38-.09-.56-.17-.18-.07-.36-.16-.52-.27-.16-.11-.32-.24-.46-.38-.55-.55-.87-1.33-.87-2.12,0-.19.02-.39.06-.58.03-.19.09-.38.17-.56.07-.18.16-.36.27-.52.11-.17.24-.32.38-.46.55-.55,1.33-.87,2.12-.87s1.56.32,2.12.87c.14.14.26.29.37.46.11.16.2.34.28.52.07.18.13.37.17.56.04.19.06.39.06.58,0,.2-.02.4-.06.59Z" fill={color}/>
    </SvgIcon>
  );
};

export default InfoTooltipIcon;