// Libs
import React from "react";
import { Show, SimpleShowLayout } from "react-admin";

// Components
import globalUseStyles from "../../styles/globalCustomStyles";
import SitesShowDetails from "./SitesShowDetails";

const SitesShow = (props) => {
  const globalClasses = globalUseStyles();

  return (
    <Show {...props} className={globalClasses.Show}>
      <SimpleShowLayout className={globalClasses.Show}>
        <SitesShowDetails />
      </SimpleShowLayout>
    </Show>
  );
};

export default SitesShow;
