import { Card, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import ReactECharts from 'echarts-for-react';
import React, { useEffect, useState } from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import { aggregateDiff, aggregateTimeseriesByField, convertTimeseriesObjectToList } from '../../../helpers/helper';
import CustomTooltip from '../../aegeon/CustomTooltip';
import { useTimeseriesContext } from '../../aegeon/timeseries/useTimeseriesContext';

import globalUseStyles from '../../../styles/globalCustomStyles';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import { useQueryContext } from '../../aegeon/query/useQueryContext';


const ChartSubDeviceHorizontalBar = (props) => {
    const {
        data,
        isLoading,
        hasNextPage,
    } = useQueryContext(props);

    const {
        timeseries,
        isTimeseriesLoading,
    } = useTimeseriesContext(props);

    const t = useTranslate();
    const globalClasses = globalUseStyles();
    const [options, setOptions] = useState(null);
    const record = useRecordContext(props);
    const [isAggregateByContractHolder, setIsAggregateByContractHolder] = useState(false);

    const { data: subdevices } = useGetList(
        'subdevices',
        {
            filter: {
                deviceId: record.id,
            },
        }
    );

    let start_date = props.start_date;
    let end_date = props.end_date;

    const aggregateByContractHolder = (data, subdevices) => {
        let data_by_month = getDataByMonth(start_date, end_date);

        data.items.forEach(item => {

            let subdevice = subdevices?.find(subdevice => subdevice.name === item.id)
            if (subdevice) {
                item.name = subdevice.contract_holder ? subdevice.contract_holder : " "
            }
        });

        let agg_data = aggregateTimeseriesByField(data.items, "name");
        let agg_timeseries = convertTimeseriesObjectToList(agg_data.result);

        const option = {
            animation: false,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                textStyle: {
                    fontSize: 10,
                },
                formatter: function (params) {
                    let result = params[0].axisValue + '<br>';
                    params = params.filter(param => param.value);
                    // if (params.length > 10) {
                    let i = 0
                    while (i + 1 < params.length) {
                        result += params[i]?.marker + params[i]?.seriesName + ' : ' + Math.round(params[i]?.value) + ' kWh PCS' + ' | ' + params[i + 1]?.marker + params[i + 1]?.seriesName + ' : ' + Math.round(params[i + 1]?.value) + ' kWh PCS' + '<br>'
                        i += 2
                    }
                    if (i < params.length) {
                        result += params[i]?.marker + params[i]?.seriesName + ' : ' + Math.round(params[i]?.value) + ' kWh PCS' + '<br>'
                    }
                    return result
                    // }
                    // return result
                },
                position: ['20%', '-25%'],
            },
            legend: {
                type: 'scroll',
                show: true,
                bottom: '1%',
                left: 'center',
                textStyle: {
                    fontSize: 10,
                },
            },
            grid: {
                left: '5%',
                right: '5%',
                bottom: '10%',
                top: '5%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: data_by_month.map(item => t(`months.${item.month}`) + ' ' + item.year),
                axisLabel: {
                    fontSize: 10,
                    name: 'Mois'
                },
            },
            yAxis: {
                type: 'value',
                name: 'Consommation (kWh PCS)',
                nameLocation: 'middle',
                nameGap: 60,
                nameTextStyle: {
                    fontSize: 10,
                },
                axisLabel: {
                    fontSize: 10,
                },
            },
            series: getSeries(data_by_month, agg_timeseries),
        };
        return option;
    };

    const aggregateByMonth = (data, subdevices) => {
        let data_by_month = getDataByMonth(start_date, end_date);

        data.items.forEach(item => {
            let subdevice = subdevices?.find(subdevice => subdevice.name === item.id)
            if (subdevice) {
                item.name = subdevice.name + ' - ' + subdevice.description
            }
        });
        const option = {
            animation: false,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                textStyle: {
                    fontSize: 10,
                },
                formatter: function (params) {
                    let result = params[0].axisValue + '<br>'
                    params = params.filter(param => param.value)
                    if (params.length > 10) {
                        let i = 0
                        while (i + 1 < params.length) {
                            result += params[i]?.marker + params[i]?.seriesName + ' : ' + Math.round(params[i]?.value) + ' kWh PCS' + ' | ' + params[i + 1]?.marker + params[i + 1]?.seriesName + ' : ' + Math.round(params[i + 1]?.value) + ' kWh PCS' + '<br>'
                            i += 2
                        }
                        if (i < params.length) {
                            result += params[i]?.marker + params[i]?.seriesName + ' : ' + Math.round(params[i]?.value) + ' kWh PCS' + '<br>'
                        }
                        return result
                    }
                    return result
                },
                position: ['20%', '-25%'],
            },
            legend: {
                type: 'scroll',
                show: true,
                bottom: '1%',
                left: 'center',
                textStyle: {
                    fontSize: 10,
                },
            },
            grid: {
                left: '5%',
                right: '5%',
                bottom: '10%',
                top: '5%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: data_by_month.map(item => t(`months.${item.month}`) + ' ' + item.year),
                axisLabel: {
                    fontSize: 10,
                    name: 'Mois'
                },
            },
            yAxis: {
                type: 'value',
                name: 'Consommation (kWh PCS)',
                nameLocation: 'middle',
                nameGap: 60,
                nameTextStyle: {
                    fontSize: 10,
                },
                axisLabel: {
                    fontSize: 10,
                },
            },
            series: getSeries(data_by_month, data.items),
        };
        return option;
    };

    useEffect(() => {
        if (data && data.items && subdevices && timeseries && timeseries.length > 0) {
            const aggData = { "items": aggregateDiff(data.items, timeseries) };
            aggData.items[aggData.items.length - 1].name = t('devices.comparison.subdevices.diff');
            let option;
            if (isAggregateByContractHolder) {
                option = aggregateByContractHolder(aggData, subdevices);
            }
            else {
                option = aggregateByMonth(aggData, subdevices);
            }
            setOptions(option);
        }
    }, [data, subdevices, timeseries, isAggregateByContractHolder]);


    const getDataByMonth = (start_date, end_date) => {
        let data_by_month = [];
        let current_date = new Date(start_date);
        while (current_date < end_date) {
            let month = current_date.getMonth()
            let year = current_date.getFullYear()
            data_by_month.push({
                month: month,
                year: year,
                value: undefined
            })
            current_date.setMonth(current_date.getMonth() + 1)
        };
        return data_by_month;
    };

    const getColorIndex = (index) => {
        let colors = [
            "#4ecca2",
            "#6C74D8",
            "#5da4d9",
            "#aad956",
            "#d95f48",
            "#d6ad56",
            "#BC88BC",
        ];
        let transparencies = ["FF", "AA", "88", "66"];
        return colors[index % colors.length] + transparencies[Math.floor(index / colors.length) % transparencies.length];
    };

    const getTimeseriesData = (data_by_month, timeseries) => {
        return data_by_month.map(item => {
            let value = timeseries.find((timeserie) => {
                let time = new Date(timeserie.time)
                return time.getMonth() === item.month && time.getFullYear() === item.year
            })
            if (!value) {
                return undefined
            }
            else {
                return value.value
            }
        })
    };

    const getSeries = (data_by_month, data_items) => {

        const names = data_items.map(item => item.name);

        return data_items.map(item =>
        ({
            name: item.name,
            type: 'bar',
            stack: 'one',
            color: getColorIndex(names.indexOf(item.name)),
            data: getTimeseriesData(data_by_month, item.timeseries),
        })
        )
    };

    const switchAggregation = (event) => {
        setIsAggregateByContractHolder(event.target.checked);
    };

    return (
        data && data.items && data.items.length > 0 && options ?
            <Card className={globalClasses.DetailCard}>
                <div className={globalClasses.DetailCardTableTitle}>
                    <Typography display="block" variant="h8">{t('devices.comparison.subdevices.title')}</Typography>
                    <CustomTooltip title={t('devices.comparison.subdevices.tooltip')} icon={<InfoTooltipIcon />} />
                </div>
                <div className={globalClasses.DetailCardTableSubtitle}>
                    {t('devices.comparison.subdevices.subtitle')}
                </div>
                <div className={globalClasses.SubDevicesAggregation}>
                    <Typography variant="h12">
                        {t('devices.subdevices.charts.aggregation_contract_holder')}
                    </Typography>
                    <Switch onChange={switchAggregation} />
                </div>
                <div className={globalClasses.DetailCardGraph} >
                    <ReactECharts
                        notMerge={true}
                        option={options}
                        style={{ height: '100%', width: '100%' }}
                    />
                </div>
            </Card>
            : subdevices && subdevices.length > 0 ?
                <Card className={globalClasses.DetailCard}>
                    <ChartLoading />
                </Card>
                : <></>
    );
}

export default ChartSubDeviceHorizontalBar;
