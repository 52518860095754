import defaults from 'lodash/defaults';
import { useContext, useMemo } from 'react';

import { QueryContext } from './QueryContext';

export const useQueryContext = (props) => {
    const context = useContext(QueryContext);
    // Props take precedence over the context
    // @ts-ignore
    return useMemo(
        () =>
            defaults(
                {},
                props != null ? extractQueryContextProps(props) : {},
                context
            ),
        [context, props]
    );
};

const extractQueryContextProps = ({
    sort,
    data,
    defaultTitle,
    displayedFilters,
    exporter,
    filterValues,
    hasCreate,
    hideFilter,
    isFetching,
    isLoading,
    onSelect,
    onToggleItem,
    onUnselectItems,
    page,
    perPage,
    refetch,
    resource,
    selectedIds,
    setFilters,
    setPage,
    setPerPage,
    setSort,
    showFilter,
    total,
}) => ({
    sort,
    data,
    defaultTitle,
    displayedFilters,
    exporter,
    filterValues,
    hasCreate,
    hideFilter,
    isFetching,
    isLoading,
    onSelect,
    onToggleItem,
    onUnselectItems,
    page,
    perPage,
    refetch,
    resource,
    selectedIds,
    setFilters,
    setPage,
    setPerPage,
    setSort,
    showFilter,
    total,
});
