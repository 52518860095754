import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceTypeWoodIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m77.5,30.05c-3.74-8.19-10.5-14.08-12.13-14.05-1.69.03-3.83,6.09-8.12,14.26-3.92,8.17-9.99,18.44-11.03,27.12-1.99,13.98,11.24,22.42,19.14,22.63,7.98.12,14.25-8.11,16.27-22.44,1.07-8.87-.03-19.33-4.14-27.51Zm-4.64,32.73c-.9,7.16-3.69,11.28-7.23,11.22-3.51-.1-9.39-4.32-8.51-11.31.46-4.34,3.16-9.47,4.9-13.56,1.91-4.08,2.85-7.11,3.61-7.13.72-.01,3.73,2.93,5.39,7.03,1.83,4.09,2.32,9.32,1.84,13.75Z"/>
      <path d="m90.41,84.28c-.45-.18-.91-.28-1.39-.28h-50.03c-3.83,0-6.94,6.27-6.94,14s3.11,14,6.94,14h50.03c3.84,0,6.94-6.27,6.94-14,0-6.77-2.39-12.42-5.56-13.72Zm-51.43,18.2c-1.23,0-2.24-2.02-2.24-4.51s1-4.51,2.24-4.51,2.24,2.02,2.24,4.51-1,4.51-2.24,4.51Z"/>
    </SvgIcon>
  );
};

export default DeviceTypeWoodIcon;