import { API, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import _ from "lodash";

const defaultOptions = {
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  enableAdminQueries: false,
};

class TimeseriesDataProvider {
  static queries;
  static mutations;
  static models;

  static authMode;
  static enableAdminQueries;

  constructor(operations, options) {
    this.queries = operations.queries;
    this.mutations = operations.mutations;

    this.authMode = options?.authMode || defaultOptions.authMode;
  };

  getTimeseries = async (deviceId, propertyId, filter, aggregation) => {

    const query = this.getQuery("getDevicePropertyTimeseries");

    if (propertyId === undefined) {
      return {
        data: [],
        unit: "",
        total: 0
      };
    }

    let response = {}
    if (aggregation === undefined) {
      response = await this.graphql(query,
        {
          deviceId: deviceId,
          propertyId: propertyId,
          timestamp: { between: [filter.startDate, filter.endDate] }
        });
    }
    else {
      response = await this.graphql(query,
        {
          deviceId: deviceId,
          propertyId: propertyId,
          timestamp: { between: [filter.startDate, filter.endDate] },
          aggregation: { period: aggregation.period, operation: aggregation.operation }
        });
    }

    // Sort elements -> Probably do this one on backend
    let timeseries = response.getDevicePropertyTimeseries.timeseries.sort((a, b) => {
      return new Date(a.time) - new Date(b.time);
    });
    // Convert timeseries time field from  UTC to Europe/Paris using moment.js
    timeseries = timeseries.map((element) => {
      const moment = require('moment-timezone');
      const europeParisDate = moment.utc(element.time).tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss Z');
      return { ...element, time: europeParisDate };
    });

    return {
      data: timeseries,
      unit: response.getDevicePropertyTimeseries.unit,
      total: timeseries.length
    };
  };

  getQuery(queryName) {
    if (this.queries[queryName]) {
      return this.queries[queryName];
    }

    if (this.mutations[queryName]) {
      return this.mutations[queryName];
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`Could not find query ${queryName}`);
    }

    throw new Error("Data provider error");
  }

  getQueryName(operation, resource) {
    const pluralOperations = ["list"];
    if (pluralOperations.includes(operation)) {
      return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1)}`;
    }
    // else singular operations ["create", "delete", "get", "update"]
    return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1, -1)}`;
  }

  getQueryNameMany(operation, resource, target) {
    const queryName = this.getQueryName(operation, resource);

    return `${queryName}By${target.charAt(0).toUpperCase() + target.slice(1, -2)}Id`;
  }

  async graphql(query, variables) {
    const queryResult = await API.graphql({ query, variables, authMode: this.authMode });

    if (queryResult.errors || !queryResult.data) {
      throw new Error("Data provider error");
    }

    return queryResult.data;
  }
}

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
function getObjectDiff(obj1, obj2) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}




export default TimeseriesDataProvider;