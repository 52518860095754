const translateFluidsFr = {
  fluids: {
    show: {
      title: "Voir l'utilisateur",
      username: "Nom d'utilisateur",
      mail: "Adresse email",
      workspace: "Espace de travail",
      role: "Rôle",
      phonenumber: "Numéro de téléphone",
      password: "Mot de passe",
      activated: "Actif",
    },
    create: {
      title: "Ajouter un utilisteur",
    },
    edit: {
      title: "Éditer un utilisateur",
    },
  },
};

export default translateFluidsFr;
