import useQueryInfiniteProvider from "./useQueryInfiniteProvider";


const useQueryInfiniteController = (resource, props = {}) => {
    const {
        query_name,
        params = {},
    } = props;

    const id = resource.id;

    const {
        data,
        error,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
        isLoading } = useQueryInfiniteProvider(resource,
            {
                query_name,
                id,
                params
            },
            {
                staleTime: Infinity,
                cacheTime: Infinity,
            });

    return {
        resource: resource,
        data: data,
        error: error,
        hasNextPage,
        isFetchingNextPage,
        isFetching: isFetching,
        isLoading: isLoading,
        // refetch
    };
}

export default useQueryInfiniteController;
