import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import algoliasearch from 'algoliasearch/lite';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "react-admin";
// import { Configure, Hits, InstantSearch, Snippet, } from 'react-instantsearch-hooks-web';
import { faMagnifyingGlassArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { useNavigate } from 'react-router-dom';
import globalUseStyles from '../../styles/globalCustomStyles';
// import "../styles/algolia.scss";

import '@algolia/autocomplete-theme-classic';

import Autocomplete from "./Autocomplete";

const searchClient = algoliasearch(
    'BIFF2TJ9B3',
    '765501ec123d175eddaf0d49a35d6307'
);

// Instant Search example : https://codesandbox.io/s/github/algolia/autocomplete/tree/next/examples/instantsearch

export const INSTANT_SEARCH_INDEX_NAME = 'societe_de_la_tour_eiffel';
export const INSTANT_SEARCH_QUERY_SUGGESTIONS = 'societe_de_la_tour_eiffel_query_suggestions';


export const INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTES = [
    'hierarchicalCategories.lvl0',
    'hierarchicalCategories.lvl1',
];


function urlToSearchState({ search }) {
    return qs.parse(search.slice(1));
}

const SearchBar = (props) => {
    const t = useTranslate();
    const globalClasses = globalUseStyles();
    const navigate = useNavigate();

    const [searchState, setSearchState] = useState(() =>
        urlToSearchState(window.location)
    );
    const timerRef = useRef(null);

    useEffect(() => {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            window.history.pushState(
                searchState,
                null,
            );
        }, 400);
    }, [searchState]);

    const currentResource = useMemo(
        () =>
            searchState?.hierarchicalMenu?.[INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTES[0]] || '',
        [searchState]
    );

    const onSubmit = useCallback(({ state }) => {
        setSearchState((searchState) => ({
            ...searchState,
            query: state.query,
        }));
    }, []);
    const onReset = useCallback(() => {
        setSearchState((searchState) => ({
            ...searchState,
            query: '',
        }));
    }, []);


    const plugins = useMemo(() => {
        const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
            key: 'search',
            limit: 3,
            transformSource({ source }) {
                return {
                    ...source,
                    onSelect({ item }) {
                        setSearchState((searchState) => ({
                            ...searchState,
                            query: item.name,
                        }));
                    },
                };
            },
        });

        const querySuggestionsInResourcePlugin = createQuerySuggestionsPlugin({
            searchClient,
            indexName: INSTANT_SEARCH_QUERY_SUGGESTIONS,
            getSearchParams() {
                return recentSearchesPlugin.data.getAlgoliaSearchParams({
                    hitsPerPage: 3,
                });
            },
            transformSource({ source }) {
                return {
                    ...source,
                    sourceId: 'querySuggestionsInResourcePlugin',
                    onSelect({ item }) {
                        setSearchState((searchState) => ({
                            ...searchState,
                            query: item.query,
                        }));
                    },
                    getItems(params) {
                        return source.getItems(params);
                    },
                    templates: {
                        ...source.templates,
                        header({ items }) {
                            if (items.length === 0) {
                                return <></>;
                            }

                            return (
                                <>
                                    <span className="aa-SourceHeaderTitle">
                                        In {currentResource}
                                    </span>
                                    <span className="aa-SourceHeaderLine" />
                                </>
                            );
                        },
                    },
                };
            },
        });

        const querySuggestionsPlugin = createQuerySuggestionsPlugin({
            searchClient,
            indexName: INSTANT_SEARCH_QUERY_SUGGESTIONS,
            getSearchParams() {
                if (currentResource.length === 0) {
                    return recentSearchesPlugin.data.getAlgoliaSearchParams({
                        hitsPerPage: 6,
                    });
                }

                return recentSearchesPlugin.data.getAlgoliaSearchParams({
                    hitsPerPage: 3,
                });
            },
            resourceAttribute: [
                INSTANT_SEARCH_INDEX_NAME,
                'facets',
                'exact_matches',
                INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTES[0],
            ],
            transformSource({ source }) {
                return {
                    ...source,
                    sourceId: 'querySuggestionsPlugin',
                    onSelect({ item }) {
                        setSearchState((searchState) => ({
                            ...searchState,
                            query: item.query,
                        }));
                    },
                    getItems(params) {
                        if (!params.state.query) {
                            return [];
                        }

                        return source.getItems(params);
                    },
                    templates: {
                        ...source.templates,
                        header({ items }) {
                            if (currentResource.length === 0 || items.length === 0) {
                                return <></>;
                            }
                            return (
                                <>
                                    <span className="aa-SourceHeaderTitle">
                                        In other categories
                                    </span>
                                    <span className="aa-SourceHeaderLine" />
                                </>
                            );
                        },

                    },
                };
            },
        });

        return [
            recentSearchesPlugin,
            querySuggestionsInResourcePlugin,
            querySuggestionsPlugin,
        ];
    }, [currentResource]);


    return (
        <div className={globalClasses.searchBarAndButton}>
            <InstantSearch
                searchClient={searchClient}
                searchState={searchState}
                onSearchStateChange={setSearchState}
                routing
            >
                <div className="header-wrapper wrapper">
                    <div className="aa-searchbox">
                        <Autocomplete
                            openOnFocus={true}
                            insights
                        />
                    </div>
                </div>
            </InstantSearch>
            <Tooltip title={t('resources.search.advanced')} classes={{ popper: globalClasses.tooltip }} placement="right" >
                <IconButton className={globalClasses.iconButton} onClick={() => navigate("/search")}>
                    <FontAwesomeIcon icon={faMagnifyingGlassArrowRight} />
                </IconButton>
            </Tooltip>
        </div>
    );
}

export default SearchBar;
