import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceTypeCityGasIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m64,16c-4.89,0-26,29.16-26,50.54,0,17.25,14.36,27.41,26,27.46,11.64-.05,26-10.21,26-27.46,0-21.38-21.11-50.54-26-50.54Zm0,70c-4.03-.03-9-6.28-9-16.9,0-13.16,7.31-31.1,9-31.1s9,17.94,9,31.1c0,10.62-4.97,16.87-9,16.9Z"/>
        <g>
          <path d="m46.18,111.58h0c-1.66,0-3-1.35-2.99-3.01l.02-8.68c0-1.65,1.35-2.99,3-2.99h8.82c1.66,0,3,1.34,3,3s-1.34,3-3,3h-5.83v5.69c-.02,1.65-1.36,2.99-3.01,2.99Z"/>
          <path d="m81.19,111.58c-1.65,0-3-1.34-3-2.99v-5.68h-5.75c-1.66,0-3-1.34-3-3s1.34-3,3-3h8.73c1.65,0,3,1.34,3,2.99l.02,8.68c0,1.66-1.34,3-2.99,3.01h0Z"/>
          <path d="m63.69,111.58c-1.66,0-3-1.34-3-3v-3.54c0-1.66,1.34-3,3-3s3,1.34,3,3v3.54c0,1.66-1.34,3-3,3Z"/>
        </g>    
    </SvgIcon>
  );
};

export default DeviceTypeCityGasIcon;