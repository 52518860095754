// Libs
import React, { useEffect, useState } from "react";
import { useGetList, useNotify, useRecordContext, useTranslate } from "react-admin";
import ExportButton from "../../../components/Buttons/ExportButton";
import { export_row_to_excel } from '../../../components/aegeon/helper';
import { getTableSeries } from '../../aegeon/compute_table';
import useQueryInfiniteProvider from '../../aegeon/query/useQueryInfiniteProvider';
import useQueryProvider from "../../aegeon/query/useQueryProvider";
import useGetTimeseriesProvider from '../../aegeon/timeseries/useGetTimeseriesProvider';

const moment = require('moment');

const SiteExportButton = (props) => {
  const { onClick } = props;

  const t = useTranslate();
  const record = useRecordContext();
  const notify = useNotify();

  const [asyncData, setAsyncData] = useState({ timeseries: [], status: "IDLE" });
  const [completeAsyncData, setCompleteAsyncData] = useState(false);
  const [filteredDevices, setFilteredDevices] = useState([]);

  const startDateTenyears = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDateToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filterTenYears = { startDate: startDateTenyears, endDate: endDateToday };

  const [startYear, setStartYear] = useState(2019);

  const [exporting, setExporting] = useState(false);

  const { data: devices } = useGetList(
    'devices',
    {
      filter: {
        siteIds: { contains: record.id },
      },
    }
  );

  useEffect(() => {
    if (devices && filteredDevices.length === 0) {
      let devices_filtered = devices ? devices.filter((device) => (device.deviceType !== "unknownMeter" && device.airtable?.fluid_type !== "ERREUR")) : []
      devices_filtered.sort((a, b) => {
        let fluid_type_a = t("devices.general_information.fluids." + a.airtable?.fluid_type)
        let fluid_type_b = t("devices.general_information.fluids." + b.airtable?.fluid_type)
        if (fluid_type_a === "Électricité" && fluid_type_b !== "Électricité") {
          return -1;
        }
        if (fluid_type_a !== "Électricité" && fluid_type_b === "Électricité") {
          return 1;
        }
        if (fluid_type_a === "Eau" && fluid_type_b !== "Eau") {
          return 1;
        }
        if (fluid_type_a !== "Eau" && fluid_type_b === "Eau") {
          return -1;
        }
        if (fluid_type_a < fluid_type_b) {
          return -1;
        }
        else if (fluid_type_a > fluid_type_b) {
          return 1;
        }
        let destination_a = a.airtable?.destination ? a.airtable?.destination : "";
        let destination_b = b.airtable?.destination ? b.airtable?.destination : "";
        if (destination_a < destination_b) {
          return -1;
        }
        else if (destination_a > destination_b) {
          return 1;
        }
        return 0;
      })
      setFilteredDevices(devices_filtered)
    }
  }, [devices])

  // ****************************************************************************************************
  // Prepare All Calls to the Export API
  // ****************************************************************************************************
  let fastResponseProperties = [
    {
      name: "fusioned_energy",
    },
    // {
    //   name: "devices_fusioned_energy",
    // },
    {
      name: "dju",
    },
    {
      name: "djr",
    },
  ];

  filteredDevices.forEach((device) => {
    let device_name = device.name
    fastResponseProperties.push({
      device: device,
      type: "data",
      dataProvider: undefined,
      name: "data_" + device_name,
    })
    fastResponseProperties.push({
      device: device,
      type: "fusioned_energy_for_devices",
      dataProvider: undefined,
      name: "fusioned_energy_" + device_name,
    })
    fastResponseProperties.push({
      device: device,
      type: "billed_energy",
      dataProvider: undefined,
      name: "billed_energy_" + device_name,
    })
    fastResponseProperties.push({
      device: device,
      type: "third_party_energy",
      dataProvider: undefined,
      name: "third_party_energy_" + device_name,
    })
    fastResponseProperties.push({
      device: device,
      dataProvider: undefined,
      type: "manual_bills",
      name: "manual_bills_" + device_name,
    })


  })

  const get_headers = (first_year) => {
    let headers = []
    for (let year = first_year; year <= moment().year(); year++) {
      for (let month = 1; month <= 12; month++) {
        headers.push(year + "-" + month.toString().padStart(2, '0'))
      }
    }
    return headers
  }

  const convert_dj_data = (data) => {
    let row = []
    let headers = get_headers(startYear)
    if (!Array.isArray(data)) {
      data = data?.pages?.[0]?.data?.timeseries
    }
    data.forEach((element) => {
      let time = moment.utc(element.time).local().format("YYYY-MM")
      if (headers?.includes(time) && row[headers.indexOf(time)] === undefined) {
        row[headers.indexOf(time)] = element.value
      }
    })
    headers.unshift("")
    return [headers, row]
  }

  const convert_devices_data = (name, data, devices, data_properties) => {
    let first_year = data[0] ? moment(data[0].time).year() : 2023
    if (first_year < startYear) {
      setStartYear(first_year)
    }
    else {
      first_year = startYear
    }
    let headers = get_headers(first_year)
    if (name === "fusioned_energy") {
      let row = []
      data.forEach((element) => {
        let date = new Date(element.time)
        let group = date.getMonth() + 1
        let year = date.getFullYear()
        let value = parseFloat(element.value)
        let parsed_date = year + "-" + group.toString().padStart(2, '0')
        if (row[headers.indexOf(parsed_date)] === undefined) {
          row[headers.indexOf(parsed_date)] = value
        }
        else {
          row[headers.indexOf(parsed_date)] += value
        }
      })
      row.unshift("Consommation énergétique totale du site (kWh PCS)")
      headers.unshift("")
      return [headers, row]
    }
    else if (name === "devices_fusioned_energy") {
      let devices_data = [];
      devices.forEach((device) => {
        let timeseries = data?.find((element) => element.id === device.name)?.timeseries
        let data_property = data_properties.find((element) => element.name === "data_" + device.name)
        let data_property_data = data_property?.dataProvider?.data ? data_property.dataProvider.data : {}
        let last_tarif_change = data_property_data?.donneesGenerales?.dateDerniereModificationFormuleTarifaireAcheminement ? moment(data_property_data.donneesGenerales?.dateDerniereModificationFormuleTarifaireAcheminement).format("YYYY-MM-DD") : ""
        let contract_state = data_property_data?.donneesGenerales?.etatContractuel?.libelle ? data_property_data.donneesGenerales?.etatContractuel?.libelle : ""
        let device_data = {
          name: device.name,
          fluid: device.airtable?.fluid_type ? t("devices.general_information.fluids." + device.airtable?.fluid_type) : "",
          automatic_upload: device.airtable?.automatic_upload ? device.airtable?.automatic_upload : "",
          upload: (device.airtable?.energy_data_upload_possibilities ? device.airtable?.energy_data_upload_possibilities : []).join(', '),
          contract_state: contract_state,
          deposited: device.airtable?.deposited ? device.airtable?.deposited : "",
          last_tarif_change: last_tarif_change,
          start_date: timeseries ? moment(timeseries[0].time).format("YYYY-MM-DD") : "",
          end_date: timeseries ? moment(timeseries[timeseries.length - 1].time).format("YYYY-MM-DD") : "",
          // quality: device.fusionedEnergyQuality,
          quality: device.airtable?.quality_of_data_completeness_2023 ? device.airtable?.quality_of_data_completeness_2023 : "",
          unit: device.properties.find((element) => element.name === "fusioned_energy")?.unit,
          data: timeseries ? getTableSeries(timeseries, false) : {}
        }
        devices_data.push(device_data)
      })
      let devices_headers = [
        t("sites.sources.devices.code"), t("sites.sources.devices.fluid"), t("sites.sources.synoptic.automatic_upload"), t("sites.sources.synoptic.upload"), t("sites.sources.devices.contract_state"), t("sites.sources.devices.deposited"), t("sites.sources.devices.last_tarif_change"), t("sites.sources.devices.start_date"), t("sites.sources.devices.end_date"), t("sites.sources.devices.quality"), t("sites.sources.devices.unit"),
        ...headers]
      let rows = []
      devices_data.forEach((device) => {
        rows.push([device.name, device.fluid, device.automatic_upload, device.upload, device.contract_state, device.deposited, device.last_tarif_change, device.start_date, device.end_date, device.quality, device.unit])
      })

      for (let year = first_year; year <= moment().year(); year++) {
        for (let month = 1; month <= 12; month++) {
          devices_data.forEach((device) => {
            let value = device.data[year] && (device.data[year][month] || device.data[year][month] === 0) ? device.data[year][month] : ''
            rows[devices_data.indexOf(device)].push(value)
          }
          )
        }
      }
      return [devices_headers, ...rows]
    }
  };

  // All calls to the API - This is done like this to avoid calling the API when not needed and due to hook constaints
  fastResponseProperties[0].dataProvider = useQueryInfiniteProvider('sites', {
    query_name: "getSiteTimeseries",
    id: record.id,
    params: {
      time: { between: [startDateTenyears, endDateToday] },
    },
  }, { enabled: exporting });

  // fastResponseProperties[1].dataProvider = useQueryInfiniteProvider('sites', {
  //   query_name: "getSiteDevicesTimeseries",
  //   id: record.id,
  //   params: {
  //     time: { between: [startDateTwoYears, endDateToday] },
  //   },
  // }, { enabled: exporting });

  fastResponseProperties[1].dataProvider = useQueryInfiniteProvider('sites', {
    query_name: "getSiteDjuTimeseries",
    id: record.id,
    params: {
      time: { between: [startDateTenyears, endDateToday] },
    },
  }, { enabled: exporting });

  fastResponseProperties[2].dataProvider = useQueryInfiniteProvider('sites', {
    query_name: "getSiteDjrTimeseries",
    id: record.id,
    params: {
      time: { between: [startDateTenyears, endDateToday] },
    },
  }, { enabled: exporting });


  // Enable a spinner when exporting
  useEffect(() => {
  }, [exporting,]);

  // Handle Success
  useEffect(() => {
    let status = [];
    let data_list = [];

    // Loading static data
    let synoptic_rows = []
    let ten_years_rows = []
    filteredDevices.forEach((device) => {
      let automatic_upload = device.airtable?.automatic_upload ? device.airtable?.automatic_upload : "";
      let upload = (device.airtable?.energy_data_upload_possibilities ? device.airtable?.energy_data_upload_possibilities : []).join(', ');
      let fluid = device.airtable?.fluid_type ? t("devices.general_information.fluids." + device.airtable?.fluid_type) : ""
      let device_data = fastResponseProperties.find((element) => element.name === "data_" + device.name)
      let meter_type = device_data?.dataProvider?.data?.situationComptage?.dispositifComptage?.typeComptage?.libelle
      // let quality = device.fusionedEnergyQuality ? device.fusionedEnergyQuality : "";
      let quality = device.airtable?.quality_of_data_completeness_2023 ? device.airtable?.quality_of_data_completeness_2023 : "";
      let tenants = device.airtable?.functional_entities ? device.airtable?.functional_entities.map(ef => ef.tenant_name) : [];
      let usage = device.airtable?.usage ? device.airtable?.usage : [];
      tenants = tenants.filter((tenant) => tenant);
      tenants = [...new Set(tenants)].join(', ');
      synoptic_rows.push({
        name: device.name,
        fluid: fluid,
        destination: device.airtable?.destination ? device.airtable?.destination : "inconnue",
        automatic_upload: automatic_upload,
        upload: upload,
        meter_type: meter_type,
        quality: quality,
        usage: usage.join(', '),
        tenth: device.airtable?.tenth ? device.airtable?.tenth : "",
        tenants: tenants,
        questions: device.airtable?.questions_about_pdl_to_energy_manager ? device.airtable?.questions_about_pdl_to_energy_manager : "",
      })
      ten_years_rows.push({
        name: device.name,
        fluid: fluid,
        automatic_upload: automatic_upload,
        upload: upload,
        data_2010: device.airtable?.consumption_2010_10years_in_kwh_pcs,
        data_2011: device.airtable?.consumption_2011_10years_in_kwh_pcs,
        data_2012: device.airtable?.consumption_2012_10years_in_kwh_pcs,
        data_2013: device.airtable?.consumption_2013_10years_in_kwh_pcs,
        data_2014: device.airtable?.consumption_2014_10years_in_kwh_pcs,
        data_2015: device.airtable?.consumption_2015_10years_in_kwh_pcs,
        data_2016: device.airtable?.consumption_2016_10years_in_kwh_pcs,
        data_2017: device.airtable?.consumption_2017_10years_in_kwh_pcs,
        data_2018: device.airtable?.consumption_2018_10years_in_kwh_pcs,
        data_2019: device.airtable?.consumption_2019_10years_in_kwh_pcs,
        data_2020: device.airtable?.consumption_2020_10years_in_kwh_pcs,
        data_2021: device.airtable?.consumption_2021_10years_in_kwh_pcs,
        data_2022: device.airtable?.consumption_2022_10years_in_kwh_pcs,
        data_2023: device.airtable?.consumption_2023_10years_in_kwh_pcs,
      })
    }
    )
    data_list.push({
      serie: "synoptic_serie",
      data: synoptic_rows
    });

    data_list.push({
      serie: "ten_years_serie",
      data: ten_years_rows
    });

    data_list.push({
      serie: "ef_serie",
      data: record?.airtable?.functional_entities ? record?.airtable?.functional_entities.map((element) => {
        return {
          tenant_name: element.tenant_name,
          label: element.code,
          lots: element.lots,
          surface: element.surface,
          tenant_code: element.tenant_code,
          type: element.type,
        }
      }).sort((a, b) => {
        if (a.tenant_name < b.tenant_name) {
          return -1;
        }
        else if (a.tenant_name > b.tenant_name) {
          return 1;
        }
        else {
          return 0;
        }
      }).filter(element => !element.label?.includes("DEFAULT")) : []
    });
    status.push("success");

    // Loading data
    fastResponseProperties.forEach((property) => {
      switch (property.dataProvider?.status) {
        case "success":
          if (property.dataProvider.timeseries !== undefined || property.dataProvider.data !== undefined) {
            let data = property.name === "devices_fusioned_energy" || property.name === "fusioned_energy" ? property.dataProvider.data.items : property.name?.includes("data_") ? property.dataProvider.data : property.dataProvider.timeseries;
            if (property.name === "devices_fusioned_energy" || property.name === "fusioned_energy") {
              if (data === undefined) {
                status.push("loading");
              }
              else {
                let data_properties = fastResponseProperties.filter((element) => element.name?.includes("data_"))
                data_properties.forEach((element) => {
                  if (element.dataProvider?.status === "loading") {
                    status.push("loading");
                    return
                  }
                })
                const data_converted = convert_devices_data(property.name, data, filteredDevices, data_properties)
                data_list.push({
                  serie: property.name,
                  unit: property.unit,
                  data: data_converted
                });
                status.push("success");
              }
            }
            else if (property.name === "dju" || property.name === "djr") {
              let data = property.dataProvider.data;
              if (data === undefined) {
                status.push("loading");
              }
              let data_converted = convert_dj_data(data)
              if (property.name === "dju") {
                data_converted[1].unshift("DJU")
              }
              else {
                data_converted[1].unshift("DJR")
              }
              let data_list_index = data_list.findIndex((element) => element.serie === "fusioned_energy")
              if (data_list_index !== -1) {
                data_list[data_list_index].data.push(data_converted[1])
                status.push("success");
              }
              else {
                data_list.push({
                  serie: "fusioned_energy",
                  unit: property.unit,
                  data: data_converted
                });
                status.push("success");
              }

            }
            else if (property.name?.includes("data_")) {
              if (data === undefined) {
                status.push("loading");
              }
              status.push("success");
            }
            else {
              let data = property.dataProvider;
              if (data.timeseries) {
                data.timeseries = data.timeseries.sort((a, b) => {
                  return new Date(a.time) - new Date(b.time);
                });

                data.timeseries = data.timeseries.map((element) => {
                  const europeParisDate = moment(element.time).format('YYYY-MM');
                  return { ...element, time: europeParisDate };
                });

              } else {
                data.timeseries = [];
              }
              let device_data = fastResponseProperties.find((element) => element.name === "data_" + property.device.name)
              let last_tarif_change = device_data.dataProvider.data?.donneesGenerales?.dateDerniereModificationFormuleTarifaireAcheminement ? moment(device_data.dataProvider.data?.donneesGenerales?.dateDerniereModificationFormuleTarifaireAcheminement).format("YYYY-MM-DD") : ""
              let contract_state = device_data.dataProvider.data?.donneesGenerales?.etatContractuel?.libelle ? device_data.dataProvider.data?.donneesGenerales?.etatContractuel?.libelle : ""
              let first_date = data.timeseries[0] ? moment(data.timeseries[0].time).format("YYYY-MM") : ""
              let last_date = data.timeseries[data.timeseries.length - 1] ? moment(data.timeseries[data.timeseries.length - 1].time).format("YYYY-MM") : ""
              let device_property = property.device?.properties.find((element) => element.name === property.type)
              let fluid = property.device?.airtable?.fluid_type ? t("devices.general_information.fluids." + property.device?.airtable?.fluid_type) : ""
              let deposited = property.device?.airtable?.deposited ? property.device?.airtable?.deposited : ""
              let unit = device_property?.unit ? device_property?.unit : ""
              let automatic_upload = property.device?.airtable?.automatic_upload ? property.device?.airtable?.automatic_upload : "";
              let upload = (property.device?.airtable?.energy_data_upload_possibilities ? property.device?.airtable?.energy_data_upload_possibilities : []).join(', ');
              let row = [property.device?.name, fluid, automatic_upload, upload, contract_state, deposited, last_tarif_change, first_date, last_date, unit]
              if (property.type === "manual_bills") {
                let last_analog_invoice_date = property.device?.airtable?.last_analog_invoice_date ? moment(property.device?.airtable?.last_analog_invoice_date).format("YYYY-MM-DD") : ""
                let analog_periods_without_data = property.device?.airtable?.analog_periods_without_data ? property.device?.airtable?.analog_periods_without_data.join(", ") : []
                row.splice(row.length - 1, 0, last_analog_invoice_date, analog_periods_without_data)
              }
              for (let year = 2019; year <= moment().year(); year++) {
                for (let month = 1; month <= 12; month++) {
                  row.push(data.timeseries.find((element) => element.time === year + "-" + month.toString().padStart(2, '0'))?.value)
                }
              }
              let data_list_index = data_list.findIndex((element) => element.serie === property.type)
              if (data_list_index !== -1) {
                data_list[data_list_index].data.push(row)
                status.push("success");
              }
              else {
                let headers = [
                  t("sites.sources.devices.code"), t("sites.sources.devices.fluid"), t("sites.sources.synoptic.automatic_upload"), t("sites.sources.synoptic.upload"), t("sites.sources.devices.contract_state"), t("sites.sources.devices.deposited"), t("sites.sources.devices.last_tarif_change"), t("sites.sources.devices.start_date"), t("sites.sources.devices.end_date"), t("sites.sources.devices.unit")]
                if (property.type === "manual_bills") {
                  headers.splice(headers.length - 1, 0, t("sites.sources.devices.last_analog_invoice_date"), t("sites.sources.devices.analog_periods_without_data"))
                }

                for (let year = 2019; year <= moment().year(); year++) {
                  for (let month = 1; month <= 12; month++) {
                    headers.push(year + "-" + month.toString().padStart(2, '0'))
                  }
                }
                data_list.push({
                  serie: property.type,
                  unit: property.unit,
                  data: [headers, row]
                });
                status.push("success");
              }

            }
          }

          else {
            status.push("failed");
          }
          break;
        case "loading":
          status.push("loading");
          break;
        case "idle":
          status.push("idle");
          break;
        default:
          break;
      }
    });

    // Handle results of the API calls
    if (status?.includes("failed")) {
      if (exporting === true) {
        setExporting(false);
        notify(t('devices.exports.error'), { type: "error" });
      }
    }
    // All elements are idle
    else if (status.every((element) => element === "idle")) {
      if (exporting === true) {
        setExporting(false);
        notify(t('devices.exports.warning'), { type: "warning" });
      }
    }
    // All elements in status are success
    else if (status.every((element) => element === "success" || element === "idle")) {
      if (exporting === true) {
        const success = exportExcel(data_list);
        if (success) {
          notify(t('devices.exports.success'), { type: "success" });
        } else {
          notify(t('devices.exports.warning'), { type: "warning" });
        }
        setExporting(false);
      }
    }
  }, [fastResponseProperties, exporting, completeAsyncData]);

  // Export data to excel
  const exportExcel = (data) => {
    var sheets_data = [];
    if (data !== null) {
      data.forEach((item) => {
        if (item.data.length > 0) {
          const sheetName = t(`sites.sources.${item.serie}`).slice(0, 30);
          let rows = [];
          if (item.serie === 'general_information') {
            rows = [[t('sites.general_information.name'), record?.name]]
            item.data.forEach((element) => {
              rows.push([element.label, element.value]);
            });
          } else if (item.serie === 'synoptic_serie') {
            rows = [[t('sites.sources.synoptic.code'),
            t('sites.sources.synoptic.fluid'),
            t('sites.sources.synoptic.destination'),
            t('sites.sources.synoptic.automatic_upload'),
            t('sites.sources.synoptic.upload'),
            t('sites.sources.synoptic.meter_type'),
            t('sites.sources.synoptic.quality'),
            t('sites.sources.synoptic.usage'),
            t('sites.sources.synoptic.tenth'),
            t("sites.sources.synoptic.tenants"),
            t("sites.sources.synoptic.questions")]]
            item.data.forEach((element) => {
              rows.push(Object.values(element));
            });
          } else if (item.serie === 'ten_years_serie') {
            rows = [[t('sites.sources.synoptic.code'),
            t('sites.sources.synoptic.fluid'),
            t('sites.sources.synoptic.automatic_upload'),
            t('sites.sources.synoptic.upload'),
              "2010 (kWh PCS)",
              "2011 (kWh PCS)",
              "2012 (kWh PCS)",
              "2013 (kWh PCS)",
              "2014 (kWh PCS)",
              "2015 (kWh PCS)",
              "2016 (kWh PCS)",
              "2017 (kWh PCS)",
              "2018 (kWh PCS)",
              "2019 (kWh PCS)",
              "2020 (kWh PCS)",
              "2021 (kWh PCS)",
              "2022 (kWh PCS)",
              "2023 (kWh PCS)"]]
            item.data.forEach((element) => {
              rows.push(Object.values(element));
            });
          } else if (item.serie === 'ef_serie') {
            rows = [[t("sites.sources.ef.tenant"), t("sites.sources.ef.code"), t("sites.sources.ef.lots"), t("sites.sources.ef.surface"), t("sites.sources.ef.tenant_code"), t("sites.sources.ef.activity")]]
            item.data.forEach((element) => {
              rows.push([element.tenant_name, element.label, element.lots, element.surface, element.tenant_code, element.type]);
            });
          } else if (item.serie === 'active_power') {
            rows = [["pdl", "timestamp", "valeur", "unité", "pas de temps in minutes", "nature"]]
            item.data.forEach((element) => {
              rows.push(Object.values(element));
            });
          } else if (item.serie === 'fusioned_energy' || item.serie === 'devices_fusioned_energy') {
            rows = item.data;
          } else if (item.serie === 'billed_energy' || item.serie === 'third_party_energy' || item.serie === 'manual_bills' || item.serie === "fusioned_energy_for_devices") {
            rows = item.data;
          }
          if (rows.length > 0) {
            sheets_data.push({ sheetName: sheetName, data: rows });
          }
        }
      });
      if (sheets_data.length > 0) {
        export_row_to_excel(sheets_data, `${t('devices.export.prefix')}${record.name}.xlsx`);
        return true;
      }
      return false;
    };
  };

  const handleExport = () => {
    onClick();
    setExporting(true);
    setCompleteAsyncData(false);
    if (asyncData.status === "COMPLETE") {
      setAsyncData({ timeseries: [], status: "IDLE" });
    }
  };

  const DeviceDataProvider = (props) => {
    const { device, callback } = props;
    let deviceTypes = [
      "enedisMeter",
      "grdfMeter",
      "electricityMeter",
    ]
    const device_dataProvider_data = useQueryProvider('devices', {
      query_name: "getDeviceContract",
      id: device.id,
    }, { enabled: exporting && device.deviceType === "enedisMeter" });
    const device_dataProvider_fusioned_energy = useGetTimeseriesProvider('devices', {
      deviceId: device.id,
      propertyId: device.properties.find((element) => element.name === "fusioned_energy")?.id,
      filter: filterTenYears,
      aggregation: { period: 'MONTH', operation: 'SUM' },
    }, { enabled: exporting && device.properties.find((element) => element.name === "fusioned_energy")?.id !== undefined });
    const device_dataProvider_billed_energy = useGetTimeseriesProvider('devices', {
      deviceId: device.id,
      propertyId: device.properties.find((element) => element.name === "billed_energy")?.id,
      filter: filterTenYears,
      aggregation: { period: 'MONTH', operation: 'SUM' },
    }, { enabled: exporting && device.properties.find((element) => element.name === "billed_energy")?.id !== undefined && deviceTypes?.includes(device.deviceType) });
    const device_dataProvider_third_party = useGetTimeseriesProvider('devices', {
      deviceId: device.id,
      propertyId: device.properties.find((element) => element.name === "third_party_energy")?.id,
      filter: filterTenYears,
      aggregation: undefined,
    }, { enabled: exporting && device.properties.find((element) => element.name === "third_party_energy")?.id !== undefined });
    const device_dataProvider_manual_bills = useGetTimeseriesProvider('devices', {
      deviceId: device.id,
      propertyId: device.properties.find((element) => element.name === "manual_bills")?.id,
      filter: filterTenYears,
      aggregation: undefined,
    }, { enabled: exporting && device.properties.find((element) => element.name === "manual_bills")?.id !== undefined });
    callback(device_dataProvider_data, device_dataProvider_fusioned_energy, device_dataProvider_billed_energy, device_dataProvider_third_party, device_dataProvider_manual_bills)
    return (
      <div>
      </div>
    )
  }

  return (
    <div>
      <ExportButton onClick={handleExport} disabled={exporting} />
      {
        exporting && filteredDevices.map((device, index) => {
          return (
            <DeviceDataProvider key={index} device={device} callback={(device_dataProvider_data, device_dataProvider_fusioned_energy, device_dataProvider_billed_energy, device_dataProvider_third_party, device_dataProvider_manual_bills) => {
              fastResponseProperties.find((element) => element.name === "data_" + device.name).dataProvider = device_dataProvider_data
              fastResponseProperties.find((element) => element.name === "fusioned_energy_" + device.name).dataProvider = device_dataProvider_fusioned_energy
              fastResponseProperties.find((element) => element.name === "billed_energy_" + device.name).dataProvider = device_dataProvider_billed_energy
              fastResponseProperties.find((element) => element.name === "third_party_energy_" + device.name).dataProvider = device_dataProvider_third_party
              fastResponseProperties.find((element) => element.name === "manual_bills_" + device.name).dataProvider = device_dataProvider_manual_bills
            }} />
          )
        })
      }
    </div>
  );
};

export default SiteExportButton;
