import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import SvgIcon from "@mui/material/SvgIcon";
import globalUseStyles from "../styles/globalCustomStyles";

const UsersIcon = (props) => {
  const color = props.color ? props.color : "";
  const globalClasses = globalUseStyles();

  return (
      <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" className={globalClasses.usersIcon} {...props}>
        <FontAwesomeIcon icon={faUsers} color={color} />
      </SvgIcon>
  );
};

export default UsersIcon;