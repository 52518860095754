import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import CustomListTableRow from './CustomListTableRow';
import CustomTableRow from './CustomTableRow';

const CustomTable = (props) => {
  const rows = props.rows;
  const leftSizeBigger = props.leftSizeBigger;
  const defaultTooltip = props.defaultTooltip;
  // The rows tooltips are translated in the CustomTableCells component

  const longestNameNull = rows.reduce((a, b) => a?.name && a?.name.length > b?.name.length ? a : b, { name: "" });
  const longestNameLength = longestNameNull?.name?.length * 0.6;

  return (
    <TableContainer component={Paper} size="small" elevation={0} >
      <Table aria-label="simple table">
        <TableBody>
          {rows.map((row, index) => (
            props.list ?
              <CustomListTableRow row={row} key={index} index={index} longestNameLength={longestNameLength} leftSizeBigger={leftSizeBigger} defaultTooltip={defaultTooltip} hideOverflow={props.hideOverflow} /> :
              <CustomTableRow row={row} key={index} index={index} leftSizeBigger={leftSizeBigger} defaultTooltip={defaultTooltip} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default CustomTable;
