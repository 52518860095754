// Lib
import { default as React } from "react";
import { useTranslate } from "react-admin";
// Lib
import globalUseStyles from "../../../styles/globalCustomStyles";
// Components
import BackButton from '../../Buttons/BackButton';
import DevelopmentPage from './DevelopmentPage';

const DevelopmentPageList = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const resource = props.resource
  return (
    <div style={{ marginTop: "1.2em" }}>
      <BackButton />
      <div>
        {/* <div className={globalClasses.pageTitle}>
          <Typography variant="h4">{t("resources." + resource + ".name")}</Typography>
        </div> */}
        <DevelopmentPage />
      </div>
    </div>
  );
};

export default DevelopmentPageList;