const translateResourcesFr = {
  resources: {
    global: {
      save: "Enregistrer",
      add: "Ajouter",
      remove: "Supprimer",
      no_options: "Aucun résultat",
      export: "Exporter les données des graphes en fichier Excel",
      unavailable_data : "Donnée non disponible",
      chooseDates: "Sélectionner maximum ",
      chooseDatesSuffix: " jours",
      chooseDateTitle: "Sélection des dates :",
      continue: "Utiliser les dates sélectionnées",
      cancel: "Revenir aux dates par défaut",
    },
    home: {
      name: "Accueil",
      message: "Trouvez rapidement les informations",
    },
    organizations: {
      name: "Organisations",
      fields: {
        name: "Nom",
      },
    },
    functional_entities: {
      name: "Entités fonctionnelles",
      fields: {
        name: "Nom",
      },
    },
    portfolios: {
      name: "Portefeuilles",
      fields: {
        name: "Nom",
      },
    },
    sites: {
      name: "Bâtiments",
      prefix_name: "Bâtiment",
      fields: {
        name: "Nom",
        surface: "Surface",
        address: "Adresse",
        city: "Ville",
        country: "Pays",
        zipcode: "Code Postal",
        construction_year: "Année de construction",
      },
    },
    devices: {
      name: "Compteurs",
      prefix_name: "Compteur",
      fields: {
        title: "Équipements",
        name: "Numéro de compteur",
        parent: "Bâtiment",
        type: "Type",
        heater: "Chauffage",
        cooler: "Climatisation",
      },
    },
    search: {
      name: "Recherche",
      advanced: "Recherche avancée",
      indexes: {
        "code": "Code",
        "name": "Nom",
        "site_code": "Code bâtiment",
        "site_name": "Nom du bâtiment",
        "city": "Ville",
        "tenant_name": "Locataire",
        "contact": "Contact",
        "resource": "Ressource",
        "devices": "Compteur",
        "sites": "Bâtiment",
      }
    },
    heatmap: {
      xAxis_title: "Consommation horaire en ",
      missing_values: "Données manquantes",
      estimated_values: "Données estimées",
    },
    development: {
      title_1: "Revenez Bientôt !",
      title_2: "Page en développement",
      paragraph: "Cette page est en cours de développement, elle sera bientôt disponible.",
      button: "Retour à l'accueil",
    },
    analyse: {
      notify: "La dernière analyse a été faite aujourd’hui. Veuillez venir demain.",
      button_text: "Générer une analyse AEGEON",
      loading_button_text: "Analyse AEGEON en cours",
      waiting_message: "Attendez un moment s'il vous plaît, nous vous fournissons des informations sur ce compteur",
      no_analysis: "Ce compteur ne possède pas d'analyse jusqu'à présent.",
      no_analysis_type: "Ce compteur ne possède pas d'analyse de ce type jusqu'à présent.",
      disabled_tooltip: "Revenez plus tard pour générer une nouvelle analyse",
      site: {
        button_text: "Générer une analyse AEGEON",
        waiting_message: "Attendez un moment s'il vous plaît, nous vous fournissons des informations sur ce bâtiment",
        no_analysis: "Ce bâtiment ne possède pas d'analyse jusqu'à présent.",
        no_analysis_type: "Ce bâtiment ne possède pas d'analyse de ce type jusqu'à présent.",
      },
      streaming_title: "Analyse en temps réel du ",
      streaming_finished_title: "Analyse terminée du ",
      last_analyse_title: "Dernière analyse générée du ",
      last_analyse_generation_title: "Dernière analyse générée du ",
      is_loading_title: "Récupération des informations nécessaires à l'analyse du ",
      historic: "Historique",
      export_name: "Analyse_AEGEON_",
    },
    alerts: {
      name: "Alertes",
      fields: {
        name: "Nom",
        is_active: "Actif",
        check: {
          device: "Equipements",
        },
        notification: {
          users: "Utilisateurs",
          last_notified_at: "Dernière alerte",
        },
      },
    },
    anomalies: {
      name: "Anomalies",
      fields: {
        name: "Bâtiment",
        viewed: "Vue",
        timestamp: "Data et l'heure",
      },
    },
    reports: {
      name: "Rapport",
      fields: {
        building_name: "Nom du bâtiment",
        title: "Titre",
        created_at: "Date de création",
      },
    },
    contracts: {
      name: "Contrats",
      fields: {
        building_name: "Nom du bâtiment",
        device_name: "Nom de l'appareil",
        maximum_power_value: "Puissance souscrite",
        created_at: "Date de création",
        updated_at: "Dernière modification",
      },
    },
    billings: {
      name: "Factures",
      fields: {
        building_name: "Nom du bâtiment",
        device_name: "Mon de l'équipement",
        supplier: "Fournisseur",
        amount: "Valeur",
        date: "Date",
      },
      show: {
        title: "Afficher les factures",
      },
    },
    users: {
      name: "Utilisateurs",
      fields: {
        username: "Nom d'utilisateur",
        email: "Mail",
        workspaces: "Espaces de travail",
        roles: "Rôles",
        activated: "Actif",
      },
    },
    dashboards: {
      name: "Tableaux de bords",
      fields: {
        name: "Nom",
        created_at: "Créé le",
        updated_at: "Dernière mise à jour le",
      },
    },
    customers: {
      name: "Clients",
      fields: {
        name: "Nom",
      },
    },
    fluids: {
      name: "Fluides",
      fields: {
        name: "Nom",
      },
    },
    distributors: {
      name: "Réseaux de Distribuition",
      fields: {
        name: "Nom",
      },
    },
    suppliers: {
      name: "Fournisseurs",
      fields: {
        name: "Nom",
      },
    },
    bills: {
      name: "Factures",
      fields: {
        nom: "Nom",
      },
    },
    lessees: {
      name: "Locataires",
      fields: {
        nom: "Nom",
      },
    },
    authorizations: {
      name: "Autorisations",
      fields: {
        nom: "Nom",
      },
    },
  },
};

export default translateResourcesFr;
