import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../styles/globalCustomStyles';
import CustomTooltip from '../aegeon/CustomTooltip';


const CustomListTableRow = (props) => {
    const classes = globalUseStyles();
    const t = useTranslate();
    const row = props.row;
    const index = props.index;
    const hideOverflow = props.hideOverflow;
    const numberOfColumns = row?.values?.length + 1;

    const value = (row.value && typeof row.value === 'string' && row.value.includes('\n') ? row.value.split('\n').map((item, key) => {
        return <span key={key} className={classes.tableCellMultipleLines}>{item}<br /></span>
    }) : row.value)

    const CellRight = () => {
        return (
            <TableCell className={`${classes.tableCell} ${classes.tableCellRight}`}>
                <div className={classes.tableCellWithIconCentered}>
                    <div style={{ marginLeft: "30px", textAlign: "left" }} className={hideOverflow ? classes.tableCellOneLine : ""}>
                        {value}
                    </div>
                    {(row.path) &&
                        <div>
                            <CustomTooltip title={t(row.tooltip_link)} clickable={true} path={row.path} icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} className={classes.tableCellIcon} />} />
                        </div>
                    }
                </div>
            </TableCell>
        )
    }

    const CellsRight = () => {
        return (
            <>
                {row.values.map((value, index2) => {
                    return (
                        <TableCell key={index2} className={`${classes.tableCell} ${classes.tableCells} ${index === 0 ? classes.tableCellsFirst : ""}`}>
                            <div className={classes.tableCellWithIconCentered}>
                                <div style={{ marginLeft: row.path ? "30px" : "10px", textAlign: "left" }} className={hideOverflow ? classes.tableCellOneLine : ""}>
                                    {value}
                                </div>
                                {(row.path) && (index2 === row.values.length - 1) &&
                                    <div>
                                        <CustomTooltip title={t(row.tooltip_link)} clickable={true} path={row.path} icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} className={classes.tableCellIcon} />} />
                                    </div>
                                }
                            </div>
                        </TableCell>
                    )
                }
                )}
            </>
        )
    }

    return (
        <TableRow key={index} className={classes.tableRow}>
            {/* <TableCell className={`${classes.tableCell} ${classes.tableCellLeft}`} style={{ width: longestNameLength + "em" }}> */}
            {row.name &&
                <TableCell className={`${classes.tableCell} ${numberOfColumns < 3 ? classes.tableCellLeft : classes.tableCellLeftWithoutWidth}`}>
                    {row.icon ?
                        <div className={classes.tableCellNameIcon}>
                            <CustomTooltip title={row.icon_tooltip} icon={row.icon} />
                            {row.name}
                        </div>
                        : <div className={hideOverflow && row.name !== "..." ? classes.tableCellOneLine : ''}>{row.name}</div>}
                </TableCell>
            }
            {row.value ?
                <CellRight />
                :
                <CellsRight />
            }
        </TableRow>
    )
};

export default CustomListTableRow;