import { createContext } from 'react';

import SubscriptionDataProvider from './SubscriptionDataProvider';

import * as queries from "../../..//graphql/queries";
import * as subscriptions from "../../..//graphql/subscriptions";
import * as mutations from "../../../graphql/mutations";

const subscriptionDataProvider = new SubscriptionDataProvider({ queries, mutations, subscriptions, authMode: "AMAZON_COGNITO_USER_POOLS" })

const SubscriptionDataProviderContext = createContext(subscriptionDataProvider);

SubscriptionDataProviderContext.displayName = 'SubscriptionDataProviderContext';

export default SubscriptionDataProviderContext;
