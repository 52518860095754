import React from 'react';
import { useTranslate } from 'react-admin';
import DetailsShow from '../../../aegeon/cards/DetailsShow';
import SiteMissingInformationDetailsContent from './SiteMissingInformationDetailsContent';

const SiteMissingInformationDetails = (props) => {
  const t = useTranslate();

  return (
    <DetailsShow title={t('sites.missing_information.title')} resource="sites" lessMargin={true}>
      <SiteMissingInformationDetailsContent />
    </DetailsShow>
  )
};

export default SiteMissingInformationDetails;

