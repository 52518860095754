
import { useEffect, useState } from 'react';
import { calcul_heatmap_data } from '../../aegeon/compute_heatmap';
import { useQueryContext } from '../../aegeon/query/useQueryContext';

const HeatmapDataReceiver = (props) => {
    const { data, isLoading, hasNextPage } = useQueryContext(props);
    const groupBy = props.groupBy;
    const [done, setDone] = useState(false);
    const filter = props.filter;

    useEffect(() => {
        if (!isLoading && !hasNextPage && data && data.length > 0 && data.timeseries && data.timeseries.length > 0 && !done) {
            let new_data = calcul_heatmap_data(data.timeseries, groupBy, filter);
            props.setHeatmapData(new_data);
            setDone(true);
        }
        else if (!isLoading && !hasNextPage && !done && data && data.timeseries && data.timeseries.length === 0) {
            let new_data = [];
            props.setHeatmapData(new_data);
            setDone(true);
        }
        else if (!isLoading && !hasNextPage && !done && !data) {
            let new_data = [];
            props.setHeatmapData(new_data);
            setDone(true);
        }

    }, [isLoading, data, done, groupBy, props, hasNextPage, filter]);


    return null;
};

export default HeatmapDataReceiver;