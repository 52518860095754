import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useContext, useEffect, useState } from 'react';
import { RecordContext } from '../../../App';
import QueryField from '../../aegeon/query/QueryField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import TableCardDetailsAuto from './TableCardDetailsAuto';
import TableCardDetailsNotAuto from './TableCardDetailsNotAuto';

const TableCardDetails = (props) => {
  const {
    data,
  } = useQueryContext(props);
  const setDataCallback = props.setDataCallback;
  const { record, setRecord } = useContext(RecordContext);

  const [dataSources, setDataSources] = useState({});
  const setDataSourcesCallback = (childData, source) => {
    if (childData && !dataSources[source]) {
      setDataSources({ ...dataSources, [source]: childData });
    }
  };

  useEffect(() => {
    setDataCallback(dataSources);
  }, [dataSources, setDataCallback]);

  const TableCardDetailsComponent = (props) => {
    const { data: deviceData, isLoading } = useQueryContext(props);

    useEffect(() => {
      if (deviceData && !isLoading) {
        setRecord(deviceData);
      }
      else {
        setRecord(null);
      }
    }, [deviceData, record]);

    const deviceType = record?.deviceType;

    if (!record) {
      return null;
    }

    if (deviceType === 'enedisMeter' || deviceType === "grdfMeter") {
      return <TableCardDetailsAuto setDataSourcesCallback={setDataSourcesCallback} data={data} setDisabled={props.setDisabled} />;
    } else {
      return <TableCardDetailsNotAuto setDataSourcesCallback={setDataSourcesCallback} data={data} setDisabled={props.setDisabled} />;
    }
  };

  return (
    <Grid2 container spacing={2}>
      <QueryField query_name="getDevice" >
        <TableCardDetailsComponent setDisabled={props.setDisabled} />
      </QueryField>
    </Grid2>

  )
};

export default TableCardDetails;