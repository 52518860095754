// Lib
import { default as React } from "react";
import { useTranslate } from "react-admin";
// Lib
import { useNavigate } from "react-router-dom";
import globalUseStyles from "../../../styles/globalCustomStyles";

// Components
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DevelopmentImage from "../../../assets/DevelopmentImage";


const DevelopmentPage = (props) => {
    const globalClasses = globalUseStyles();
    const t = useTranslate();
    let navigate = useNavigate();
    const handleClick = () => {
        navigate('/')
    }

    return (
        <div className={globalClasses.developmentPage}>
            <div className={globalClasses.developmentText}>
                <div className={globalClasses.developmentTitle}>
                    <Typography variant="h10">{t("resources.development.title_1")}</Typography>
                    <Typography variant="h11">{t("resources.development.title_2")}</Typography>
                </div>
                <div className={globalClasses.developmentParagraph}>
                    <Typography variant="h6">{t("resources.development.paragraph")}</Typography>
                </div>
                <IconButton onClick={handleClick} className={globalClasses.customButton + ' ' + globalClasses.developmentButton}>
                    <Typography variant="h3" color="white !important">{t('resources.development.button')}</Typography>
                </IconButton>
            </div>
            <div className={globalClasses.developmentImage}>
                <DevelopmentImage className={globalClasses.developmentImg} />
            </div>
        </div>
    );
};

export default DevelopmentPage;