import React, { useContext, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import ExportButton from '../../Buttons/ExportButton';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';
import QueryField from '../../aegeon/query/QueryField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import TableCardDetails from './TableCardDetails';

const TablePageDetails = (props) => {
  const { data, isLoading } = useQueryContext(props);
  const { record, setRecord } = useContext(RecordContext);
  const isDeviceContract = ["enedisMeter", "grdfMeter"].includes(record?.deviceType);

  useEffect(() => {
    if (data && !isLoading) {
      setRecord(data)
    }
    else {
      setRecord(null)
    }
  }, [data, isLoading]);

  return (
    record && isDeviceContract ? (
      <QueryField query_name="getDeviceContract">
        <TableCardDetails setDataCallback={props.setDataCallback} setDisabled={props.setDisabled} />
      </QueryField>) : record &&
    <TableCardDetails setDataCallback={props.setDataCallback} setDisabled={props.setDisabled} />
  )
};

const TablePageDetailsWithContext = (props) => {
  const t = useTranslate();
  const title = t('devices.dataTable.title');
  const [data, setData] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const setDataCallback = (data) => {
    setData(data)
  }

  const handleExport = (name) => {
    var sheets_data = [];
    if (data) {
      Object.keys(data).forEach((source) => {
        if (data[source].length > 0) {
          const sheetName = source.slice(0, 30)
          sheets_data.push({ sheetName: sheetName, data: data[source] });
        }
      });
      export_row_to_excel(sheets_data, `${t('devices.exports.table')}${name}.xlsx`);
    };
  }

  return (
    <DetailsShow title={title} tooltip={<ExportButton onClick={handleExport} disabled={disabled} />} resource="devices" lessMargin={true}>
      <QueryField query_name='getDevice'>
        <TablePageDetails {...props} setDisabled={setDisabled} setDataCallback={setDataCallback} />
      </QueryField>
    </DetailsShow>
  )
}

export default TablePageDetailsWithContext;

