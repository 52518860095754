import { Card, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { default as React, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { allowedShowComponents, useRolePermissions } from "../../aegeon-rbac/AuthRolePermissions";
import ChartIndex from '../../aegeon/charts/ChartIndex';
import ChartMonotone from '../../aegeon/charts/ChartMonotone';
import CustomTooltip from '../../aegeon/CustomTooltip';
import QueryField from "../../aegeon/query/QueryField";
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";

const MonotoneCardDetails = ({ setDataCallback, setSubtitleCallback, subtitle }) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const { isLoading, permissions } = useRolePermissions();

  const moment = require('moment');
  const startDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(7, 'days');
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter = { startDate: startDate, endDate: endDate };

  const startDateOneYear = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'year');
  const filterOneYear = { startDate: startDateOneYear, endDate: endDate };

  const [dataSources, setDataSources] = useState({});
  const setDataSourcesCallback = (childData, source) => {
    if (childData && !dataSources[source]) {
      setDataSources({ ...dataSources, [source]: childData });
    }
  };

  useEffect(() => {
    setDataCallback(dataSources);
  }, [dataSources, setDataCallback]);

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitleCallback && setSubtitleCallback(childData)
    }
  };

  const ChartComponent = ({ type, line, stacked, monthAgg, conversionButton }) => {
    return (
      <Grid2 item="true" small={12} medium={12} big={6} key={type}>
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t(`devices.${type}.title`)}</Typography>
            {<CustomTooltip title={t(`devices.${type}.tooltip_text`)} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t(`devices.${type}.subtitle`)}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <TimeseriesField source={type === "index_substracted" ? "index" : type} filter={filterOneYear}>
              <ChartIndex
                line={line}
                stacked={stacked}
                type={type}
                setDataCallback={setDataSourcesCallback}
                conversionButton={conversionButton}
                monthAgg={monthAgg}
              />
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
    )
  }

  const permissionControlledComponentsCharts = {
    resource: "devices",
    elements: {
      show: {
        "monotone.maximum_power":
          <QueryField query_name='getDeviceContract' >
            <ChartComponent type="maximum_power" line={true} stacked={false} />
          </QueryField>
        ,
        "monotone.time_of_use": <ChartComponent type="time_of_use" line={true} stacked={true} conversionButton={true} />,
        "monotone.overrun_duration": <ChartComponent type="overrun_duration" line={true} stacked={false} />,
        "monotone.overrun_quadratic": <ChartComponent type="overrun_quadratic" line={true} stacked={false} />,
      }
    }
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={12} medium={12} big={12} key="1">
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.monotone.detailledTitle')}</Typography>
            {<CustomTooltip title={t('devices.monotone.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {subtitle}
          </div>
          <div style={{ width: "100%", height: "550px" }} >
            <TimeseriesField source="active_power_monotone" filter={filter}>
              <QueryField query_name='getDeviceContract' >
                <div style={{ width: "100%", height: "550px" }} >
                  <ChartMonotone isDetailedCard={true} setSubtitleCallback={handleSetSubtitleCallback} setMonotoneDataCallback={setDataSourcesCallback} />
                </div>
              </QueryField>
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
      {!isLoading && allowedShowComponents(permissions, permissionControlledComponentsCharts)}
    </Grid2 >
  )
};

export default MonotoneCardDetails;

