import React from 'react';
import { useTranslate, BooleanInput, SelectInput, TextInput, required } from 'react-admin';
import { email } from 'ra-core';

import globalUseStyles from '../../../styles/globalCustomStyles';

const UserCardGeneralInformation = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const create = props.create;

  const validateUsername = (value) => {
    const regex = /^[a-zA-Z.]+$/;
    if (!regex.test(value)) {
      return 'users.general_information.username_invalid';
    }
    return undefined;
  }


  const setSelectedRole = (value) => {
    props.setSelectedRoleCallback(value?.target?.value);
  }

  return (
    <div className={globalClasses.UserGeneralInformation} >
      <TextInput disabled={!create} source="username" variant="outlined" label={t('users.general_information.name')} validate={create ? [required(),validateUsername] : []} />
      <TextInput source="email" label={t('users.general_information.email')} variant="outlined" validate={[required(), email()]} />
      <SelectInput optionText="name" variant="outlined" source="role" label={t('users.general_information.profile')} validate={[required()]} choices={[
          { id: 'admin', name: t('users.general_information.profiles.admin') },
          { id: 'property_owner', name: t('users.general_information.profiles.property_owner') },
          { id: 'property_manager', name: t('users.general_information.profiles.property_manager') },
          { id: 'property_tenant', name: t('users.general_information.profiles.property_tenant') },

      ]} onChange={setSelectedRole} />
      <BooleanInput source="enabled" label={t('users.general_information.enabled_user') + ' : '} variant='outlined' />
    </div>
  )
};

export default UserCardGeneralInformation;

