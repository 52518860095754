// import { Resource as ResourceReactAdmin } from "react-admin";
import { useRolePermissions } from "./AuthRolePermissions";

// export const Resource = (props) => {
//   return <ResourceReactAdmin {...props} />;
//   //   const { isLoading, permissions } = useRolePermissions();
//   //   console.log("Resource permissions", permissions);

//   //   if (isLoading) {
//   //     return null;
//   //   }

//   //   // TODO: fine grain permissions for show list edit create delete

//   //   return <ResourceReactAdmin {...props} />;
// };

import * as React from "react";
import { isValidElement } from "react";
import { Route, Routes } from "react-router-dom";

import { ResourceContextProvider } from "react-admin";

export const Resource = (props) => {
  const { create: Create, edit: Edit, list: List, name, show: Show } = props;

  const { isLoading, permissions } = useRolePermissions();
  if (isLoading) {
    return null;
  }

  console.log("Resource permissions", permissions);

  return (
    <ResourceContextProvider value={name}>
      <Routes>
        {Create && <Route path="create/*" element={isValidElement(Create) ? Create : <Create />} />}
        {Show && <Route path=":id/show/*" element={isValidElement(Show) ? Show : <Show />} />}
        {Edit && <Route path=":id/*" element={isValidElement(Edit) ? Edit : <Edit />} />}
        {List && <Route path="/*" element={isValidElement(List) ? List : <List />} />}
        {props.children}
      </Routes>
    </ResourceContextProvider>
  );
};

Resource.raName = "Resource";

Resource.registerResource = ({
  create,
  edit,
  icon,
  list,
  name,
  options,
  show,
  recordRepresentation,
  hasCreate,
  hasEdit,
  hasShow,
}: ResourceProps) => ({
  name,
  options,
  hasList: !!list,
  hasCreate: !!create || !!hasCreate,
  hasEdit: !!edit || !!hasEdit,
  hasShow: !!show || !!hasShow,
  icon,
  recordRepresentation,
});
