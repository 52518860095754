import { API, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const defaultOptions = {
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  enableAdminQueries: false,
};

class MutationDataProvider {
  static queries;
  static mutations;
  static models;

  static authMode;
  static enableAdminQueries;

  constructor(operations, options) {
    this.queries = operations.queries;
    this.mutations = operations.mutations;

    this.authMode = options?.authMode || defaultOptions.authMode;
  };

  getMutation = async (mutation_name, id, input) => {

    const query = this.getMutationByName(mutation_name);
    const query_input = { ...input, id };

    const response = await this.graphql(query, { input: query_input });

    return {
      data: response[mutation_name],
      total: 0
    };
  };

  getMutationByName(mutationName) {
    if (this.mutations[mutationName]) {
      return this.mutations[mutationName];
    }

    if (this.mutations[mutationName]) {
      return this.mutations[mutationName];
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`Could not find mutation ${mutationName}`);
    }

    throw new Error("Data provider error");
  }

  getMutationName(operation, resource) {
    const pluralOperations = ["list"];
    if (pluralOperations.includes(operation)) {
      return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1)}`;
    }
    // else singular operations ["create", "delete", "get", "update"]
    return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1, -1)}`;
  }

  getQueryNameMany(operation, resource, target) {
    const queryName = this.getMutationName(operation, resource);

    return `${queryName}By${target.charAt(0).toUpperCase() + target.slice(1, -2)}Id`;
  }

  async graphql(query, variables) {
    const queryResult = await API.graphql({ query, variables, authMode: this.authMode });

    if (queryResult.errors || !queryResult.data) {
      throw new Error("Data provider error");
    }

    return queryResult.data;
  }
}


export default MutationDataProvider;