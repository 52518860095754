import React from "react";
import ErrorImageSvg from "./ErrorImage.svg";

const ErrorImage = (props) => {
    return (
        <img alt="errorPage" src={ErrorImageSvg} style={props.style}/>
  );
};

export default ErrorImage;

