const roles = {
  admin: [
    { action: ["list", "show"], resource: "portfolios" },
    { action: ["list", "show"], resource: "sites" },
    { action: ["show"], resource: "sites.general_information" },
    { action: ["show"], resource: "sites.energetic_performance" },
    { action: ["show"], resource: "sites.evolution" },
    { action: ["show"], resource: "sites.histogram" },
    { action: ["show"], resource: "sites.table" },
    { action: ["show"], resource: "sites.analyse" },
    { action: ["show"], resource: "sites.heatmap" },
    { action: ["show"], resource: "sites.missing_information" },
    { action: ["show"], resource: "sites.composition" },
    { action: ["click"], resource: "sites.export" },
    { action: ["list", "show"], resource: "functional_entities" },
    { action: ["list", "show"], resource: "devices" },
    { action: ["show"], resource: "devices.general_information" },
    { action: ["show"], resource: "devices.evolution" },
    { action: ["show"], resource: "devices.quality" },
    { action: ["show"], resource: "devices.histogram" },
    { action: ["show"], resource: "devices.general_information.subdevices" },
    { action: ["show"], resource: "devices.histogram.subdevices" },
    { action: ["show"], resource: "devices.table" },
    { action: ["show"], resource: "devices.table.subdevices" },
    { action: ["show"], resource: "devices.table.index" },
    { action: ["show"], resource: "devices.analyse" },
    { action: ["show"], resource: "devices.analyse.comparison" },
    { action: ["show"], resource: "devices.heatmap" },
    { action: ["show"], resource: "devices.monotone" },
    { action: ["show"], resource: "devices.monotone.maximum_power" },
    { action: ["show"], resource: "devices.monotone.time_of_use" },
    { action: ["show"], resource: "devices.monotone.overrun_duration" },
    { action: ["show"], resource: "devices.monotone.overrun_quadratic" },
    { action: ["show"], resource: "devices.active_power" },
    { action: ["show"], resource: "devices.active_power.index" },
    { action: ["show"], resource: "devices.active_power.index_substracted" },
    { action: ["show"], resource: "devices.active_power.energy" },
    { action: ["click"], resource: "devices.export" },
    { action: ["list", "show", "edit"], resource: "users" },
    { action: ["show"], resource: "search" },
    { action: ["click"], resource: "devices.import_subdevices" },
    { action: ["click"], resource: "devices.active_power.date_picker" },
  ],
  property_owner: [
    { action: ["list", "show"], resource: "portfolios" },
    { action: ["list", "show"], resource: "sites" },
    { action: ["show"], resource: "sites.general_information" },
    { action: ["show"], resource: "sites.energetic_performance" },
    { action: ["show"], resource: "sites.evolution" },
    { action: ["show"], resource: "sites.histogram" },
    { action: ["show"], resource: "sites.table" },
    { action: ["show"], resource: "sites.analyse" },
    { action: ["show"], resource: "sites.heatmap" },
    { action: ["show"], resource: "sites.missing_information" },
    { action: ["show"], resource: "sites.composition" },
    { action: ["list", "show"], resource: "functional_entities" },
    { action: ["list", "show"], resource: "devices" },
    { action: ["show"], resource: "devices.general_information" },
    { action: ["show"], resource: "devices.evolution" },
    { action: ["show"], resource: "devices.quality" },
    { action: ["show"], resource: "devices.histogram" },
    { action: ["show"], resource: "devices.table" },
    { action: ["show"], resource: "devices.analyse" },
    { action: ["show"], resource: "devices.heatmap" },
    { action: ["show"], resource: "devices.monotone" },
    { action: ["show"], resource: "devices.active_power" },
    { action: ["show"], resource: "search" },
  ],
  property_manager: [
    { action: ["list", "show"], resource: "sites" },
    { action: ["show"], resource: "sites.general_information" },
    // { action: ["show"], resource: "sites.energetic_performance" },
    { action: ["show"], resource: "sites.evolution" },
    { action: ["show"], resource: "sites.histogram" },
    { action: ["show"], resource: "sites.table" },
    { action: ["show"], resource: "sites.analyse" },
    { action: ["show"], resource: "sites.heatmap" },
    { action: ["show"], resource: "sites.missing_information" },
    { action: ["show"], resource: "sites.composition" },
    { action: ["list", "show"], resource: "devices" },
    { action: ["show"], resource: "devices.general_information" },
    { action: ["show"], resource: "devices.evolution" },
    { action: ["show"], resource: "devices.quality" },
    { action: ["show"], resource: "devices.histogram" },
    { action: ["show"], resource: "devices.table" },
    { action: ["show"], resource: "devices.analyse" },
    { action: ["show"], resource: "devices.heatmap" },
    { action: ["show"], resource: "devices.monotone" },
    { action: ["show"], resource: "devices.active_power" },
  ],
  property_tenant: [
    { action: ["list", "show"], resource: "sites" },
    { action: ["show"], resource: "sites.general_information" },
    // { action: ["show"], resource: "sites.energetic_performance" },
    { action: ["show"], resource: "sites.evolution" },
    { action: ["show"], resource: "sites.histogram" },
    { action: ["show"], resource: "sites.table" },
    { action: ["show"], resource: "sites.heatmap" },
    { action: ["list", "show"], resource: "devices" },
    { action: ["show"], resource: "devices.general_information" },
    { action: ["show"], resource: "devices.evolution" },
    { action: ["show"], resource: "devices.quality" },
    { action: ["show"], resource: "devices.histogram" },
    { action: ["show"], resource: "devices.table" },
    { action: ["show"], resource: "devices.heatmap" },
    { action: ["show"], resource: "devices.monotone" },
    { action: ["show"], resource: "devices.active_power" },
  ],
};

export default roles;
