import frenchMessages from "ra-language-french";
import translateResourcesFr from "./translateResourcesFr";
import translateHomeFr from "./translateHomeFr";
import translateDashboardFr from "./translateDashboardFr";
import translateAlertsFr from "./translateAlertsFr";
import translateAnalyticsFr from "./translateAnalyticsFr";
import translateAnomaliesFr from "./translateAnomaliesFr";
import translateBillingsFr from "./translateBillingsFr";
import translateBuildingsFr from "./translateSitesFr";
import translateDashboardsFr from "./translateDashboardsFr";
import translateDevicesFr from "./translateDevicesFr";
import translateUserFr from "./translateUserFr"; 
import translateFluidsFr from "./translateFluidsFr";
import translateCustomersFr from "./translateCustomersFr";
import translateOrganizationsFr from "./translateOrganizationsFr";
import translateContactsFr from "./translateContactsFr";
import translateSearchFr from "./translateSearchFr";

const customFrenchMessages = {
  ...frenchMessages,
  ...translateCustomersFr,
  ...translateContactsFr,
  ...translateOrganizationsFr,
  ...translateResourcesFr,
  ...translateHomeFr,
  ...translateDashboardFr,
  ...translateAlertsFr,
  ...translateAnalyticsFr,
  ...translateAnomaliesFr,
  ...translateBillingsFr,
  ...translateBuildingsFr,
  ...translateDashboardsFr,
  ...translateDevicesFr,
  ...translateUserFr,
  ...translateFluidsFr,
  ...translateSearchFr,
};

export default customFrenchMessages;
