import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceTypeOtherIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="m3.55,54.25c-.36-2.27-.55-4.6-.55-6.97C3,22.82,22.82,3,47.28,3l-12.96,12.96" transform="translate(5.09 37.26) rotate(-20.58) scale(.97)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
        <path d="m3.55,54.25c-.36-2.27-.55-4.6-.55-6.97C3,22.82,22.82,3,47.28,3l-12.96,12.96" transform="translate(70.3 128.39) rotate(-140.58) scale(.97)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
        <path d="m3.55,54.25c-.36-2.27-.55-4.6-.55-6.97C3,22.82,22.82,3,47.28,3l-12.96,12.96" transform="translate(116.62 26.36) rotate(99.42) scale(.97)"  fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
      </g>
      <g>
        <path d="m83.22,82.32c-.46.29-1.17.68-2.14,1.2-.97.51-2.18,1-3.64,1.46-1.46.46-3.14.86-5.05,1.2-1.91.34-4.01.51-6.29.51-3.2,0-6.18-.49-8.94-1.46-2.77-.97-5.19-2.41-7.28-4.32-2.08-1.91-3.72-4.25-4.92-7.02-1.2-2.77-1.8-5.95-1.8-9.54s.58-6.55,1.75-9.37c1.17-2.82,2.8-5.25,4.88-7.28,2.08-2.02,4.54-3.59,7.36-4.71s5.92-1.67,9.29-1.67c2.97,0,5.59.39,7.87,1.16,2.28.77,4.21,1.8,5.78,3.08,1.57,1.28,2.75,2.78,3.55,4.49.8,1.71,1.2,3.54,1.2,5.48s-.37,3.81-1.11,5.44c-.74,1.63-2.05,3.03-3.94,4.19-1.88,1.17-4.42,2.08-7.62,2.74-3.2.66-7.25.98-12.15.98-1.14,0-2.21-.01-3.21-.04-1-.03-2.07-.07-3.21-.13.46,2.91,1.87,5.26,4.24,7.06,2.37,1.8,5.75,2.7,10.14,2.7,1.48,0,2.85-.11,4.11-.34,1.25-.23,2.38-.51,3.38-.86,1-.34,1.85-.68,2.57-1.03.71-.34,1.24-.6,1.58-.77l3.59,6.85Zm-30.04-20.29c1.31.06,2.57.09,3.77.09h3.59c3.14,0,5.68-.2,7.62-.6,1.94-.4,3.45-.91,4.54-1.54,1.08-.63,1.8-1.34,2.14-2.14.34-.8.51-1.63.51-2.48,0-2.17-.87-3.79-2.61-4.88-1.74-1.08-3.89-1.63-6.46-1.63-2.11,0-3.97.37-5.56,1.11-1.6.74-2.94,1.73-4.02,2.95-1.08,1.23-1.93,2.64-2.52,4.24-.6,1.6-.93,3.22-.98,4.88Z" stroke-width="0"/>
        <path d="m83.22,82.32c-.46.29-1.17.68-2.14,1.2-.97.51-2.18,1-3.64,1.46-1.46.46-3.14.86-5.05,1.2-1.91.34-4.01.51-6.29.51-3.2,0-6.18-.49-8.94-1.46-2.77-.97-5.19-2.41-7.28-4.32-2.08-1.91-3.72-4.25-4.92-7.02-1.2-2.77-1.8-5.95-1.8-9.54s.58-6.55,1.75-9.37c1.17-2.82,2.8-5.25,4.88-7.28,2.08-2.02,4.54-3.59,7.36-4.71s5.92-1.67,9.29-1.67c2.97,0,5.59.39,7.87,1.16,2.28.77,4.21,1.8,5.78,3.08,1.57,1.28,2.75,2.78,3.55,4.49.8,1.71,1.2,3.54,1.2,5.48s-.37,3.81-1.11,5.44c-.74,1.63-2.05,3.03-3.94,4.19-1.88,1.17-4.42,2.08-7.62,2.74-3.2.66-7.25.98-12.15.98-1.14,0-2.21-.01-3.21-.04-1-.03-2.07-.07-3.21-.13.46,2.91,1.87,5.26,4.24,7.06,2.37,1.8,5.75,2.7,10.14,2.7,1.48,0,2.85-.11,4.11-.34,1.25-.23,2.38-.51,3.38-.86,1-.34,1.85-.68,2.57-1.03.71-.34,1.24-.6,1.58-.77l3.59,6.85Zm-30.04-20.29c1.31.06,2.57.09,3.77.09h3.59c3.14,0,5.68-.2,7.62-.6,1.94-.4,3.45-.91,4.54-1.54,1.08-.63,1.8-1.34,2.14-2.14.34-.8.51-1.63.51-2.48,0-2.17-.87-3.79-2.61-4.88-1.74-1.08-3.89-1.63-6.46-1.63-2.11,0-3.97.37-5.56,1.11-1.6.74-2.94,1.73-4.02,2.95-1.08,1.23-1.93,2.64-2.52,4.24-.6,1.6-.93,3.22-.98,4.88Z" stroke-width="0"/>
      </g>
    </SvgIcon>
  );
};

export default DeviceTypeOtherIcon;