import useQueryProvider from "./useQueryProvider";


const useQueryController = (resource, props = {}) => {
    const {
        query_name,
        params = {},
    } = props;

    const id = resource.id;

    const {
        data,
        error,
        // refetch,
        isFetching,
        isLoading } = useQueryProvider(resource,
            {
                query_name,
                id,
                params
            },
            {
                staleTime: Infinity,
                cacheTime: Infinity,
            });

    return {
        resource: resource,
        data: data,
        error: error,
        isFetching: isFetching,
        isLoading: isLoading,
        // refetch
    };
}

export default useQueryController;
