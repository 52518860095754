import ReactECharts from 'echarts-for-react';
import { indexOf } from 'lodash';
import React, { useEffect } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import ChartNoData from '../../aegeon/charts/ChartNoData';
import { getDataMarkPoint } from '../../aegeon/helper';
import { useQueryContext } from '../../aegeon/query/useQueryContext';

const SiteEnergeticPerformance = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const {
    data,
    isLoading,
    hasNextPage,
  } = useQueryContext(props);

  const { type } = props

  const record = useRecordContext();

  const setSubtitleCallback = props.setSubtitleCallback

  let timeseries = data ? data.items : []
  const isData = timeseries && timeseries.length > 0 && !hasNextPage

  const sizes = props.isDetailedCard ? {
    arrowLabelPosition: type === "climatic" ? [-15, -8] : [3, 8],
    barWidth: 30,
    symbolSizeRect: [300, 40],
    symbolSizeRect2: [300, 50],
    fontSize: 14,
    fontSizeSubtitle: 12,
    labelPosition: [5, 10],
  } : {
    arrowLabelPosition: type === "climatic" ? [-15, -8] : [-2, 2],
    barWidth: 18,
    symbolSizeRect: [160, 30],
    symbolSizeRect2: [160, 40],
    fontSize: 11,
    fontSizeSubtitle: 11,
    labelPosition: [5, 5]
  }

  const itemStyle = type === "climatic" ?
    {
      borderColor: "black",
      borderWidth: 0.5,
    } : {}

  const intervals_typology = (typology) => {
    typology = typology?.toUpperCase()
    switch (typology) {
      case "BUREAUX":
        return [0, 27, 58, 111, 185, 285, 395, 500];
      case "ENSEIGNEMENT":
        return [0, 27, 58, 111, 185, 285, 395, 500];
      case "HOTEL":
        return [0, 53, 111, 195, 306, 437, 595, 800];
      case "RESTAURANT":
        return [0, 16, 48, 90, 143, 201, 268, 350];
      case "BOUTIQUES":
        return [0, 16, 48, 90, 143, 201, 268, 350];
      case "ENTREPÔT":
        return [0, 16, 48, 90, 143, 201, 268, 350];
      case "ACTIVITÉ":
        return [0, 16, 48, 90, 143, 201, 268, 350];
      case "RETAIL PARK":
        return [0, 43, 64, 95, 122, 174, 237, 300];
      default:
        return [0, 27, 58, 111, 185, 285, 395, 500];
    }
  }

  const getNote = (value, interval) => {
    if (value > interval[6]) {
      return "G"
    } else if (value > interval[5]) {
      return "F"
    } else if (value > interval[4]) {
      return "E"
    } else if (value > interval[3]) {
      return "D"
    } else if (value > interval[2]) {
      return "C"
    } else if (value > interval[1]) {
      return "B"
    } else {
      return "A"
    }
  }

  const total_consumption_of_last_year = timeseries?.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.value)
  }, 0)
  const surface_consumption = !record?.airtable?.validated_client_surface || total_consumption_of_last_year === 0 ? null : total_consumption_of_last_year / record?.airtable?.validated_client_surface

  const interval = intervals_typology(record?.airtable?.current_typology)
  const value = surface_consumption ? (Math.round(surface_consumption * 100) / 100) : Math.round(record?.airtable?.reporting_2023_surface_consumption * 100) / 100
  const note = getNote(value, interval)

  let startDate = isData ? new Date(timeseries[0]?.time)?.toLocaleDateString(localStorage.getItem("language")) : null
  let endDate = isData ? new Date(timeseries[timeseries.length - 1]?.time) : null
  endDate = endDate ? new Date(endDate.setDate(endDate.getDate() + 1)).toLocaleDateString(localStorage.getItem("language")) : null
  let subtitle_text = surface_consumption && startDate && endDate ? t('sites.energetic_performance.subtitle') + ' ' + (t('sites.energetic_performance.subtitle_dates_1') + startDate + t('sites.energetic_performance.subtitle_dates_2') + endDate + t('sites.energetic_performance.subtitle_dates_3')) : record?.airtable?.reporting_2023_surface_consumption ? t('sites.energetic_performance.subtitle_airtable_value') : t('sites.energetic_performance.subtitle') + '?'

  useEffect(() => {
    setSubtitleCallback && setSubtitleCallback(subtitle_text)
  }, [subtitle_text])

  const colors = type === "climatic" ? {
    "A": "#f5eef6",
    "B": "#dac0dd",
    "C": "#c8a9cf",
    "D": "#bd99c4",
    "E": "#a274b6",
    "F": "#a274b6",
    "G": "#784a9c"
  } : type === "dpe" ? {
    "A": "#2e994c",
    "B": "#54b751",
    "C": "#cddc28",
    "D": "#f6ea0c",
    "E": "#fdcc0c",
    "F": "#f89839",
    "G": "#ee1f25"
  } : {
    "A": "#4ecca2",
    "B": "#5da4d9",
    "C": "#6c74d8",
    "D": "#bc88bc",
    "E": "#d6ad56",
    "F": "#d8864f",
    "G": "#d95f48"
  }

  const values = {
    "A": 20,
    "B": 30,
    "C": 40,
    "D": 50,
    "E": 60,
    "F": 70,
    "G": 80
  }

  const ref = record?.airtable?.energy_perf_oid_ref
  const ref_note = getNote(ref, interval)

  const unit = "kWh EF PCI / m²"
  const ref_text = ref_note && ref ? `\n Référence ${ref_note} : \n${ref} ${unit}` : ""

  let option = {
    tooltip: {
      position: [10, -50],
      formatter: (params) => {
        if (params.dataIndex === 6) {
          return `A : < ${interval[1]} ${unit}`
        } else if (params.dataIndex === 5) {
          return `B : entre ${interval[1]} et ${interval[2]} ${unit}`
        } else if (params.dataIndex === 4) {
          return `C : entre ${interval[2]} et ${interval[3]} ${unit}`
        } else if (params.dataIndex === 3) {
          return `D : entre ${interval[3]} et ${interval[4]} ${unit}`
        } else if (params.dataIndex === 2) {
          return `E : entre ${interval[4]} et ${interval[5]} ${unit}`
        } else if (params.dataIndex === 1) {
          return `F : entre ${interval[5]} et ${interval[6]} ${unit}`
        } else if (params.dataIndex === 0) {
          return `G : > ${interval[6]} ${unit}`
        }
      }
    },
    grid: {
      top: 0,
      bottom: 10,
    },
    yAxis: {
      show: false,
      min: -1,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        fontSize: 14,
        color: "black",
      },
      data: Object.keys(values).reverse(),
    },
    color: Object.values(colors).reverse(),
    barWidth: sizes.barWidth,
    emphasis: {
      disabled: true
    },
    xAxis: {
      show: false,
      max: 150,
    },
    animation: false,
    series: [{
      name: "",
      type: "bar",
      itemStyle: itemStyle,
      markPoint: {
        symbol: "diamond",
        emphasis: {
          disabled: true
        },
        animation: false,
        symbolSize: type === "climatic" ? 0 : sizes.barWidth,
        data: [
          getDataMarkPoint("A", values, colors, sizes.arrowLabelPosition, props.isDetailedCard),
          getDataMarkPoint("B", values, colors, sizes.arrowLabelPosition, props.isDetailedCard),
          getDataMarkPoint("C", values, colors, sizes.arrowLabelPosition, props.isDetailedCard),
          getDataMarkPoint("D", values, colors, sizes.arrowLabelPosition, props.isDetailedCard),
          getDataMarkPoint("E", values, colors, sizes.arrowLabelPosition, props.isDetailedCard),
          getDataMarkPoint("F", values, colors, sizes.arrowLabelPosition, props.isDetailedCard),
          getDataMarkPoint("G", values, colors, sizes.arrowLabelPosition, props.isDetailedCard),
          {
            symbol: "rect",
            symbolSize: sizes.symbolSizeRect,
            symbolOffset: [-15, note === "G" ? -30 : 0],
            value: `${note}`,
            label: {
              show: true,
              color: "black",
              fontWeight: "600",
              fontSize: sizes.fontSize,
            },
            itemStyle: {
              color: colors[note],
            },
            coord: [140, indexOf(Object.keys(values).reverse(), note)],
          },
          {
            symbol: "rect",
            symbolSize: sizes.symbolSizeRect2,
            symbolOffset: [-15, note === "G" ? 0 : 30],
            value: `${value} ${unit} ${ref_text}`,
            label: {
              show: true,
              color: "black",
              fontSize: sizes.fontSizeSubtitle,
            },
            itemStyle: {
              color: colors[note],
            },
            coord: [140, indexOf(Object.keys(values).reverse(), note)],
          }
        ],
      },
      markLine: {
        symbol: "none",
        animation: false,
        lineStyle: {
          color: colors[note],
          type: "dashed",
          width: 1,
        },
        data: [
          [
            {
              coord: [values[note], indexOf(Object.keys(values).reverse(), note)],
              emphasis: {
                disabled: true
              }
            },
            {
              coord: [120, indexOf(Object.keys(values).reverse(), note)]
            }
          ],
        ],
      },
      label: {
        show: true,
        position: sizes.labelPosition,
        color: "black",
        fontWeight: "500",
        fontSize: 10,
        formatter: (params) => {
          if (params.dataIndex === 6) {
            return `< ${interval[1]}`
          } else if (params.dataIndex === 5) {
            return `${interval[1]} à ${interval[2]}`
          } else if (params.dataIndex === 4) {
            return `${interval[2]} à ${interval[3]}`
          } else if (params.dataIndex === 3) {
            return `${interval[3]} à ${interval[4]}`
          } else if (params.dataIndex === 2) {
            return `${interval[4]} à ${interval[5]}`
          } else if (params.dataIndex === 1) {
            return `${interval[5]} à ${interval[6]}`
          } else if (params.dataIndex === 0) {
            return `> ${interval[6]}`
          }
        }
      },
      data: Object.values(values).reverse(),
      colorBy: "data"
    }]
  }

  return (
    isLoading ?
      <ChartLoading /> :
      value ?
        <div className={globalClasses.chartDPE}>
          <ReactECharts
            option={option}
            notMerge={true}
            style={{ height: '100%', width: '100%' }}
          />

        </div>
        :
        <ChartNoData />
  )
}

export default SiteEnergeticPerformance