import React, { useEffect, useState } from 'react';
import ChartTable from '../../aegeon/charts/ChartTable';
import { useQueryContext } from '../../aegeon/query/useQueryContext';

const ChartSiteTable = (props) => {
  const {
    data,
    isLoading,
    hasNextPage,
  } = useQueryContext(props);

  const [isDone, setIsDone] = useState(false)
  const [groupedSerie, setGroupedSerie] = useState({})

  const groupByTrimester = props.groupBy === "trimester"

  const getGroup = (month) => {
    if (groupByTrimester) {
      return Math.floor(month / 3) + 1
    }
    return month + 1
  }

  const getUnit = (data) => {
    let unit = ''
    if (data && data.items && data.items.length > 0) {
      unit = data.items[0].unit
    }
    return unit
  }

  const getSeries = (timeseries) => {
    let groupedTimeseries = {}
    timeseries.forEach(element => {
      let date = new Date(element.time)
      let group = getGroup(date.getMonth())
      let year = date.getFullYear()
      let key = year
      let value = parseFloat(element.value)
      if (value) {
        if (groupedTimeseries[key] === undefined) {
          groupedTimeseries[key] = {}
        }
        if (groupedTimeseries[key][group] === undefined) {
          groupedTimeseries[key][group] = value
        }
        else {
          groupedTimeseries[key][group] += value
        }
      }
    })
    groupedTimeseries = Object.keys(groupedTimeseries).sort().reverse().reduce(
      (obj, key) => {
        obj[key] = groupedTimeseries[key];
        return obj;
      },
      {}
    );

    if (groupByTrimester) {
      let years = Object.keys(groupedTimeseries)
      if (years.length > 5) {
        years = years.slice(years.length - 5, years.length)
        let newGroupedTimeseries = {}
        years.forEach(year => {
          newGroupedTimeseries[year] = groupedTimeseries[year]
        })
        groupedTimeseries = newGroupedTimeseries
      }
    }

    return groupedTimeseries
  }

  useEffect(() => {
    if (!isLoading && !hasNextPage && !isDone && data && data.items) {
      if (data.items.length === 0) {
        setIsDone(true)
      }
      else if (data.items.length > 0) {
        setGroupedSerie(getSeries(data.items))
        setIsDone(true)
      }
    }
    else if (!isLoading && !hasNextPage && !isDone && data === undefined) {
      setIsDone(true)
    }
  }, [isLoading, hasNextPage, isDone, data])

  return (
    <ChartTable
      isLoading={!isDone}
      timeseries={groupedSerie}
      isLocked={props.isLocked}
      groupedSerie={groupedSerie}
      unit={getUnit(data)}
      className={props.className}
      setSubtitleCallback={props.setSubtitleCallback}
      groupBy={props.groupBy}
      setDataCallback={props.setDataCallback}
    />
  )
}

export default ChartSiteTable