import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { Link } from 'react-router-dom';
import globalUseStyles from '../../styles/globalCustomStyles';

const CustomTooltip = (props) => {
    const { icon, title, text, clickable, path, externalLink } = props;
    const globalClasses = globalUseStyles();
    return (
        <Tooltip title={title} classes={{ popper: globalClasses.tooltip }} placement="right" >
            {externalLink ?
                <a href={path} target="_blank" rel="noreferrer">
                    <IconButton className={globalClasses.tooltipIcon}>
                        {text && <Typography variant="h7">{text}</Typography>}
                        {icon}
                    </IconButton>
                </a>
                :
                <IconButton className={globalClasses.tooltipIcon} component={clickable ? Link : ''} to={path}>
                    {text && <Typography variant="h7">{text}</Typography>}
                    {icon}
                </IconButton>
            }

        </Tooltip>
    )
}

export default CustomTooltip;