import { Card, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { allowedShowComponents, useRolePermissions } from "../../aegeon-rbac/AuthRolePermissions";
import CustomTooltip from '../../aegeon/CustomTooltip';
import QueryField from '../../aegeon/query/QueryField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import ChartDeviceHorizontalBar from '../Cards/ChartDeviceHorizontalBar';
import ChartDeviceHorizontalBarDJ from '../Cards/ChartDeviceHorizontalBarDJ';
import HorizontalBarSubdeviceDetails from '../CardsDetails/HorizontalBarSubdeviceDetails';

const ChildComponentWithDjuDjr = (props) => {
  const moment = require('moment');
  const startDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const { data, isLoading } = useQueryContext(props);
  const unit = props.unit

  return (
    <QueryField query_name="getSiteDjrTimeseries" id={props.id} params={{ time: { between: [startDate, endDate] } }}>
      <ChildComponentWithDjr dju={data} isLoadingDJU={isLoading} unit={unit} />
    </QueryField>
  );
}

const ChildComponentWithDjr = (props) => {
  const startDateBeginningOfTwoYearsAgo = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter = { startDate: startDateBeginningOfTwoYearsAgo, endDate: endDate };
  const { data, isLoading } = useQueryContext(props);
  const unit = props.unit

  return (
    <TimeseriesField filter={filter} source="fusioned_energy" aggregation={{ period: 'MONTH', operation: 'SUM' }}>
      <ChartDeviceHorizontalBarDJ dju={props.dju} djr={data} isLoadingDJ={isLoading || props.isLoadingDJU} unit={unit} />
    </TimeseriesField>
  );
}


const HorizontalBarCardDetails = (props) => {
  const moment = require('moment');
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const startDateBeginningOfTwoYearsAgo = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter = { startDate: startDateBeginningOfTwoYearsAgo, endDate: endDate };
  const { record } = useContext(RecordContext);
  const unit = record?.properties?.find((property) => property?.name === "fusioned_energy")?.unit
  const { isLoading, permissions } = useRolePermissions();

  const permissionControlledComponents = {
    resource: "devices",
    elements: {
      show: {
        "histogram.subdevices": <HorizontalBarSubdeviceDetails />
      }
    }
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={12} medium={12} big={6} key="1">
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.comparison.yearly.title')}</Typography>
            {/* <CustomTooltip title={t('.tooltip_text')} icon={<InfoTooltipIcon />} /> */}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.comparison.yearly.subtitle')}
          </div>
          <div className={globalClasses.DetailCardGraph}>
            <TimeseriesField filter={filter} source="fusioned_energy" aggregation={{ period: 'MONTH', operation: 'SUM' }}>
              <ChartDeviceHorizontalBar unit={unit} />
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6} key="2">
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.comparison.weather.title')}</Typography>
            <CustomTooltip title={t('devices.comparison.weather.tooltip_text')} icon={<InfoTooltipIcon />} />
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.comparison.weather.subtitle')}
          </div>
          <div className={globalClasses.DetailCardGraph}>
            <QueryField query_name="getSiteDjuTimeseries" id={record.siteId} params={{ time: { between: [startDateBeginningOfTwoYearsAgo, endDate] } }}>
              <ChildComponentWithDjuDjr id={record.siteId} unit={unit} />
            </QueryField>
          </div>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={12} key="3">
        {!isLoading && allowedShowComponents(permissions, permissionControlledComponents)}
      </Grid2>
    </Grid2>

  )
};

export default HorizontalBarCardDetails;

