import { makeStyles } from "@mui/styles";
import * as React from "react";

const useStyles = makeStyles({
  logo: {
    width: "57px",
    margin: '28px',
  },
});

const Logo = () => {
  const classes = useStyles();
  return (
    <img src="/AEGEON_LOGO_SIGLE-TURQUOISE.svg" alt="Logo Aegeon" className={classes.logo} />
  );
};

export default Logo;
