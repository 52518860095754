import { useTheme } from '@mui/material/styles';
import ReactECharts from 'echarts-for-react';
import React from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from "../../../styles/globalCustomStyles";
import ChartLoading from './ChartLoading';
import ChartNoData from './ChartNoData';


const ChartGauge = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const theme = useTheme();

  const {
    consumptionVariation,
    isLoading,
  } = props;

  const option = {
    series: [
      {
        type: 'gauge',
        startAngle: 156,
        endAngle: 24,
        center: ['50%', '85%'],
        radius: '140%',
        min: -0.20,
        max: 0.20,
        splitNumber: 2,
        axisLine: {
          lineStyle: {
            width: 16,
            color: [
              [0.25, theme.palette.gradation.low],
              [0.5, theme.palette.gradation.midLow],
              [0.75, theme.palette.gradation.midHigh],
              [1, theme.palette.gradation.high]
            ]
          }
        },
        pointer: {
          icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
          length: '30%',
          width: 12,
          offsetCenter: [0, '-52%'],
          itemStyle: {
            color: 'inherit'
          }
        },
        axisTick: {
          length: 10,
          lineStyle: {
            color: '#A6ACAF',
            width: 1
          },
          splitNumber: 4
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: '#A6ACAF',
            width: 2
          }
        },
        axisLabel: {
          distance: 20,
          color: "#A6ACAF",
          formatter: function (value) {
            if (value === 0) {
              return value
            }
          }
        },
        title: {
          show: false,
        },
        animation: false,
        detail: {
          offsetCenter: [0, '-35%'],
          valueAnimation: false,
          formatter: function (value) {
            return Math.round(value * 100) + '%';
          },
          fontSize: 32,
          fontFamily: 'Montserrat',
          fontWeight: '600',
          color: 'inherit'
        },
        data: [
          {
            value: consumptionVariation,
            name: t("devices.evolution.title")
          }
        ]
      }
    ]
  };

  return (
    isLoading === true ?
      <ChartLoading />
      :
      typeof consumptionVariation === "number" && !isNaN(consumptionVariation) && isFinite(consumptionVariation) ?
        <ReactECharts
          notMerge={true}
          option={option}
          style={{ height: '100%', width: '100%' }}
        />
        :
        <ChartNoData />
  )
}

export default ChartGauge