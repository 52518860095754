import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DeviceTypeElecIcon = (props) => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m94.29,77.05l-28.62-59.6c-.89-1.85-3.67-1.22-3.67.84v32.41l-26.35-2.67c-1.5-.15-2.59,1.4-1.93,2.76l28.61,59.58c.89,1.85,3.67,1.22,3.67-.84v-32.39l26.36,2.67c1.5.15,2.59-1.4,1.94-2.76Z"/>
    </SvgIcon>
  );
};

export default DeviceTypeElecIcon;