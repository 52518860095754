import React from 'react';
import globalUseStyles from '../../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import CustomTable from '../../../Table/CustomTable';
import { convert_array_to_string, formatDateString, value_with_unit } from '../../../aegeon/helper';

const SiteGeneralInformationDetailsContentLeft = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const record = useRecordContext();

  const address = (record?.airtable?.address ? record?.airtable?.address + ", " : "") +
    (record?.airtable?.postal_code ? record?.airtable?.postal_code + ", " : "") +
    (record?.airtable?.city ? record?.airtable?.city : "")

  const owner = record?.airtable?.organizations ? record?.airtable?.organizations[0].name : null

  const address_path = record?.airtable?.google_maps_url ? record?.airtable?.google_maps_url : null

  const tiers_presence = record?.airtable?.third_party_parts_supply?.length > 0 ? "Oui" : (record?.airtable?.asl_aful_names || record?.airtable?.asl_and_aful?.length > 0 || record?.airtable?.co_ownership_name || record?.airtable?.rie_exploitation) ? "À confirmer" : "Non"

  const ef_type_filtered = record?.airtable?.efa_type?.filter((type) => (!["LOT FICTIF", "LOGEMENT", "HABITATION"].includes(type)))

  // The rows tooltips are translated in the CustomTable component
  const rows = [
    { name: t('sites.general_information.name'), value: record?.name, tooltip: 'sites.general_information.name_no_data' },
    { name: t('sites.general_information.code'), value: record?.airtable?.code, tooltip: 'sites.general_information.code_no_data' },
    { name: t('sites.general_information.address'), value: address, tooltip: 'sites.general_information.address_no_data', path: address_path, tooltip_link: 'sites.general_information.address_link_tooltip', externalLink: true },
    { name: t('sites.general_information.organizations'), value: owner, tooltip: 'sites.general_information.organizations_no_data' },
    { name: t('sites.general_information.declared_detention_regime'), value: record?.airtable?.declared_detention_regime, tooltip: 'sites.general_information.declared_detention_regime_no_data' },
    { name: t('sites.general_information.construction_date'), value: formatDateString(record?.airtable?.construction_date), tooltip: 'sites.general_information.construction_date_no_data' },
    { name: t('sites.general_information.acquisition_date'), value: formatDateString(record?.airtable?.acquisition_date), tooltip: 'sites.general_information.acquisition_date_no_data' },
    { name: t('sites.general_information.last_renovation_date'), value: formatDateString(record?.airtable?.last_renovation_date), tooltip: 'sites.general_information.last_renovation_date_no_data' },
    { name: t('sites.general_information.functional_entities_state_date'), value: formatDateString(record?.airtable?.functional_entities_state_date), tooltip: 'sites.general_information.functional_entities_state_date_no_data' },
    { name: t('sites.general_information.validated_client_surface'), value: value_with_unit(record?.airtable?.validated_client_surface, 'm²'), tooltip: 'sites.general_information.validated_client_surface_no_data' },
    { name: t('sites.general_information.number_of_buildings'), value: record?.airtable?.number_of_buildings, tooltip: 'sites.general_information.number_of_buildings_no_data' },
    { name: t('sites.general_information.tiers'), value: tiers_presence, tooltip: 'sites.general_information.tiers_no_data' },
    { name: t('sites.general_information.number_of_potentially_subjected_functional_entities'), value: record?.airtable?.number_of_potentially_subjected_functional_entities, tooltip: 'sites.general_information.number_of_potentially_subjected_functional_entities_no_data' },
    { name: t('sites.general_information.number_of_tenants_following_rental_state_ex_parking'), value: record?.airtable?.number_of_tenants_following_rental_state_ex_parking, tooltip: 'sites.general_information.number_of_tenants_following_rental_state_ex_parking_no_data' },
    { name: t('sites.general_information.activities'), value: convert_array_to_string(ef_type_filtered), tooltip: 'sites.general_information.activities_no_data' },
    { name: t('sites.general_information.perimeter_2023'), value: record?.airtable?.perimeter_2023, tooltip: 'sites.general_information.perimeter_2023_no_data' },
  ]


  return (
    <Card className={globalClasses.DetailCard}>
      <Typography display="block" variant="h8">
        <span><b>{t('sites.general_information.left_panel.title')}</b></span>
      </Typography>
      <div className={globalClasses.DetailCardTableSubtitle} />
      <CustomTable rows={rows} defaultTooltip={"sites.no_data_tooltip"} />
    </Card>
  );
};

export default SiteGeneralInformationDetailsContentLeft;

