import { default as React, useState } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ExportButton from '../../Buttons/ExportButton';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';
import LineCardDetails from './LineCardDetails';

const LineDetails = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const title = t("devices.power.title");
  const [data, setData] = useState(null);

  const setDataCallback = (childData) => {
    if (childData) {
      setData(childData)
    }
  }

  const handleExport = (name) => {
    var sheets_data = [];
    Object.keys(data).forEach((source) => {
      if (data[source].length > 0) {
        const sheetName = t(`devices.sources.${source}`).slice(0, 30)
        sheets_data.push({ sheetName: sheetName, data: data[source] });
      }
    }
    );
    export_row_to_excel(sheets_data, `${t('devices.exports.line')}${name}.xlsx`);
  };

  return (
    <DetailsShow title={title} resource="devices" tooltip={<ExportButton onClick={handleExport} disabled={!data} />}
      lessMargin={true}
    >
      <LineCardDetails setDataCallback={setDataCallback} />
    </DetailsShow>
  )
};

export default LineDetails;

