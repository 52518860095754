import React, { useEffect, useState } from 'react';

import { Card, Typography } from '@mui/material';
import { useContext } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CustomTable from '../../Table/CustomTable';


const DeviceGeneralInformationDetailsContentBottomSubDevice = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const { record } = useContext(RecordContext);
  const deviceId = record?.id;
  const [rows, setRows] = useState(null);

  const { data: subdevices } = useGetList(
    'subdevices',
    {
      filter: {
        deviceId: deviceId,
      },
    }
  );

  useEffect(() => {
    if (subdevices && !rows) {
      const subdevices_data = subdevices?.sort((a, b) => a?.name?.localeCompare(b.name))?.map(subdevice => ({
        name: subdevice?.name,
        description: subdevice?.description,
        building: subdevice?.building,
        lessor: subdevice?.lessor,
        contract_holder: subdevice?.contract_holder,
      }));
      const rows = [{
        values: [
          t('devices.general_information.subdevices.name'),
          t('devices.general_information.subdevices.description'),
          t('devices.general_information.subdevices.building'),
          t('devices.general_information.subdevices.lessor'),
          t('devices.general_information.subdevices.contract_holder'),
        ]
      }];
      subdevices_data?.forEach((subdevice) => {
        rows.push({
          values: [
            subdevice.name,
            subdevice.description,
            subdevice.building,
            subdevice.lessor,
            subdevice.contract_holder,
          ],
        })
      })
      setRows(rows);
    }
  }, [subdevices, rows]);

  return (
    subdevices && subdevices.length > 0 && rows ?
      <Card className={globalClasses.DetailCard}>
        <Typography display="block" variant="h8">{t('devices.general_information.subdevices.title') + " (" + rows?.length + ")"}</Typography>
        <div className={globalClasses.DetailCardTableSubtitle} />
        {rows?.length === 1 ?
          <CustomTable rows={[
            { name: t('devices.general_information.subdevices.no_data'), value: "", tooltip: 'devices.general_information.subdevices.no_data_tooltip' },
          ]}
          />
          :
          <CustomTable list={true} rows={rows} defaultTooltip={""} />
        }
      </Card>
      : <></>
  );
};

export default DeviceGeneralInformationDetailsContentBottomSubDevice;