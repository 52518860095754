// In theme.js
import { defaultTheme } from "react-admin";
// import { createTheme } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";
import merge from "lodash/merge";
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/open-sans/400.css';

const theme = createTheme(
  merge(defaultTheme, {
    palette: {
      primary: {
        main: "#007f60",
      },
      secondary: {
        main: "#1B232A",
      },
      background: {
        default: '#ebe9e4',
        paper: '#ffffff'
      },
      text: {
        primary: '#37424A',
        secondary: '#37424A'
      },
      aegeon: {
        success: '#4ecca2',
        danger: '#E00707',
        warning: '#D8540B',
        info: '#80C3FF',
      },
      gradation:{
        low: "#4ecca2",
        midLow: "#6c74d8",
        midHigh: "#d6ad56",
        high: "#d95f48",
      }
    },
    typography: {
      fontFamily: 'Open Sans',
      fontWeight: '600',
      color: "#37424A",
      h1: {
        textAlign: "center",
        color: "#044742",
        fontSize: "3em",
        fontWeight: "600",
        padding: "1rem 0 2rem",
        fontFamily: 'Montserrat',
      },
      h2: {
        textAlign: "center",
        fontSize: "1.7em",
        fontWeight: "bold",
        padding: "0.7rem 0",
        fontFamily: 'Montserrat',
      },
      h3: {
        textAlign: "left",
        margin: "0 !important",
        fontWeight: "600",
        fontSize: "20px",
        fontFamily: 'Montserrat',
        color: "#37424A"
      },
      h4: {
        fontWeight: "600",
        fontFamily: 'Montserrat',
        fontSize: '40px',
        color: "#37424A"
      },
      h5: {
        margin: "0 !important",
        fontWeight: "400",
        fontSize: "24px",
        fontFamily: 'Montserrat',
        color: "#37424A"
      },
      h6: {
        fontWeight: "400 !important",
        fontSize: "16px !important",
        fontFamily: 'Montserrat !important',
      },
      h7: {
        fontWeight: "400 !important",
        fontSize: "16px !important",
        fontFamily: 'Montserrat !important',
        color: "#71B9A1 !important",
      },
      h8: {
        fontWeight: "600 !important",
        fontSize: "24px !important",
        fontFamily: 'Montserrat !important',
        color: "#71B9A1 !important",
      },
      h9: {
        fontWeight: "400 !important",
        fontSize: "12px !important",
        fontFamily: 'Montserrat !important',
        color: "#5F6971 !important",
      },
      h10: {
        fontWeight: "600 !important",
        fontSize: "70px !important",
        fontFamily: 'Montserrat !important',
        color: "#5CFFBD !important",
        lineHeight: "80px",
      },
      h11: {
        fontWeight: "600 !important",
        fontSize: "40px !important",
        fontFamily: 'Montserrat !important',
        color: "#1B232A !important",
        lineHeight: "49px",
      },
      h12: {
        fontWeight: "600 !important",
        fontSize: "14px !important",
        fontFamily: 'Montserrat !important',
        color: "#71B9A1 !important",
      },
      bottomSource: {
        fontStyle: "italic",
        fontSize: "12px",
      }
    },
    breakpoints: {
      values: {
        big: 1800,
        medium: 1350,
        small: 0,
      },
    },
    components: {
      ...defaultTheme.components,
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: "#EBE9E4 !important",
            boxShadow: "none !important",
            color: "black !important",
            flexDirection: "row",
          },
        },
      },
      RaLayout: {
        styleOverrides: {
          root: {
            backgroundColor: "#1B232A",
          },
        },
      },
      RaSimpleShowLayout: {
        styleOverrides: {
          root: {
            padding: "0",
          },
        },
      },
      RaMenu: {
        styleOverrides: {
          root: {
            width: "100% !important",
          },
        },
      },
      RaMenuItemLink: {
        styleOverrides: {
          root: {
            color: "#A6ACAF !important",
            fontWeight: "400 !important",
            fontSize: "16px !important",
            fontFamily: 'Montserrat !important',
            height: "70px",
            '& .RaMenuItemLink-icon': {
              marginLeft: "28px !important",
            },
            '& .MuiSvgIcon-root': {
              color: "#A6ACAF !important",
              width: "45px !important",
              height: "45px !important",
              marginLeft: "-8px !important",
              marginRight: "24px !important",
            },
            '&.RaMenuItemLink-active': {
              color: "#71B9A1 !important",
              backgroundColor: "#71B9A116 !important",
              borderRight: "6px solid #71B9A1 !important",
              '& .MuiSvgIcon-root': {
                color: "#71B9A1 !important",
              },
            },
            '&:hover': {
              backgroundColor: "#71B9A116 !important",
            },
          },
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "#EBE9E4",
            color: "black",
            '& .MuiTypography-root': {
              color: "black",
            },
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            margin: "0 !important",
            // minHeight: "64px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiGrid2: {
        styleOverrides: {
          root: {
            margin: "0 !important",
          },
        },
      },
      RaDatagrid: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            borderRadius: "8px",
            '& .RaDatagrid-tbody': {
              boxShadow: "none !important",
              '& .RaDatagrid-row:hover': {
                boxShadow: "0px 0px 12px rgba(55, 66, 74, 0.4)",
              },
            },
            '& .MuiTableRow-root': {
              borderTop: "1px solid #E0E0E0",
            },
            '& .RaDatagrid-headerCell': {
              fontSize: "18px !important",
              fontWeight: "400 !important",
              '&:first-of-type': {
                borderTopLeftRadius: "8px",
              },
              '&:last-child': {
                borderTopRightRadius: "8px",
              },
            },
            '& .MuiTypography-root': {
              fontFamily: 'Montserrat',
              fontWeight: "300",
              fontSize: "16px",
              fontVariantNumeric: "tabular-nums",
            },
            '& .MuiTableCell-root': {
              fontFamily: 'Montserrat',
              fontWeight: "300",
              fontSize: "16px",
            },
            '& .RaDatagrid-row': {
              height: "84px",
            }
          }
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              opacity: "0.4 !important",
            },
            '&.MuiButton-sizeSmall': {
              fontSize: "14px !important",
              fontWeight: "600 !important",
              fontFamily: 'Montserrat !important',
            },
          },
        },
      },
      RaFilterButton: {
        styleOverrides: {
          root: {
            color: "white !important",
            height: "40px",
            display: "flex",
            padding: "16px 32px",
            alignItems: "center",
            borderRadius: "32px",
            flexDirection: "row",
            backgroundColor: "#71B9A1 !important",
            '& .MuiSvgIcon-root': {
              color: "white !important",
            },
          }
        },
      },
      RaButton: {
        styleOverrides: {
          root: {
            color: "white !important",
          }
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "white !important",
            borderTopLeftRadius: "8px !important",
            borderTopRightRadius: "8px !important",
            borderBottom: "1px solid #37424A",
            width: "250px",
          }
        },
      },
      RaAutocompleteInput: {
        styleOverrides: {
          root: {
            '& .MuiFilledInput-root': {
              backgroundColor: "white !important",
              borderTopLeftRadius: "8px !important",
              borderTopRightRadius: "8px !important",
              borderBottom: "1px solid #37424A",
              width: "250px",
              '& .MuiChip-root': {
                '& .MuiSvgIcon-root': {
                  color: "black",
                },
              },
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            marginRight: "4px !important",
            marginBottom: "4px !important",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '& .MuiButton-endIcon>*:nth-of-type(1)': {
              fontSize: "30px !important",
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-cell': {
              fontSize: "13px !important",
            },
            '& .MuiDataGrid-columnHeader': {
              border: "1px solid rgba(224, 224, 224, 1) !important",
              borderBottom: "1px solid #A6ACAF !important",
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: "600 !important",              
            },
            '& .MuiDataGrid-cell--editing:focus-within': {
              outline: "solid 2px #71B9A1 !important",
            },
            '& .MuiDataGrid-cell:focus': {
              outline: "solid 2px #71B9A1 !important",
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            backgroundColor: "#ebe9e470 !important",
          },
        },
      },
    },
  })
);

export { theme };