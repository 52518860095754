import { createContext } from 'react';

import MutationDataProvider from './MutationDataProvider';

import * as queries from "../../..//graphql/queries";
import * as mutations from "../../../graphql/mutations";

const mutationDataProvider = new MutationDataProvider({ queries, mutations, authMode: "AMAZON_COGNITO_USER_POOLS" })

const MutationDataProviderContext = createContext(mutationDataProvider);

MutationDataProviderContext.displayName = 'MutationDataProviderContext';

export default MutationDataProviderContext;
