import '@algolia/autocomplete-theme-classic/dist/theme.css';
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLogout, useTranslate } from "react-admin";
import { useLocation } from "react-router-dom";
// import { Configure, Hits, InstantSearch, Snippet, } from 'react-instantsearch-hooks-web';
import SearchBar from '../components/search/SearchBar';
// import "../styles/algolia.scss";
import globalUseStyles from "../styles/globalCustomStyles";

import { convert_group_to_string } from "../components/aegeon/helper";

import '@algolia/autocomplete-theme-classic';
import { Auth } from "aws-amplify";

const CustomAppBar = (props) => {
  const globalClasses = globalUseStyles();
  const [auth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [username, setUsername] = useState(null);
  const [group, setGroup] = useState(null);
  const t = useTranslate();
  const logout = useLogout();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  const location = useLocation();
  const isHome = location.pathname === '/';
  const isSearch = location.pathname === '/search';

  const formatUsername = (str) => {
    str = str?.split('.');
    str = str[0] + (str[1] ? '.' + str[1] : '')
    return str?.replace('.', ' ').replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  useEffect(() => {
    const fetchUserGroup = async () => {
      const data = await Auth.currentAuthenticatedUser().then(
        (data) => data
      );
      setUsername(formatUsername(data?.username))
      setGroup(convert_group_to_string(data?.signInUserSession?.idToken?.payload?.['cognito:groups']))
    };
    fetchUserGroup().catch((err) => console.log(err));
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} className={globalClasses.appBarContainer}>
      {!isHome && !isSearch && <SearchBar />}
      <div className={globalClasses.appBarTitle}>
        <span className={globalClasses.appBarSpacer} />
        {
          auth && (
            <div className={globalClasses.appBarUserMenu}>
              <div className={globalClasses.appBarText}>
                <Typography variant="h6" noWrap className={globalClasses.appBarUserText}>
                  {username}
                </Typography>
                <Typography variant="h9" noWrap className={globalClasses.appBarGroupText}>
                  {group}
                </Typography>
              </div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <FontAwesomeIcon icon={faCircleUser} className={globalClasses.appBarUserButton} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>{t("general.logout")}</MenuItem>
              </Menu>
            </div>
          )
        }
      </div >
    </Box >
  );
}

export default CustomAppBar;
