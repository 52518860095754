import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import React from "react";
import { useTranslate } from 'react-admin';
import { useNavigate } from "react-router-dom";
import AegeonPlatformIcon from '../assets/AegeonPlatformIcon';
import Menu from '../components/aegeon-rbac/Menu';
import globalUseStyles from '../styles/globalCustomStyles';
import Logo from "./Logo";

const CustomSidebar = (props) => {
    const globalClasses = globalUseStyles();
    const [open, setOpen] = React.useState(window.innerWidth < 1050 ? false : false);
    const t = useTranslate();
    const navigate = useNavigate();

    const handleMenu = () => {
        props.setOpen(!open);
        setOpen(!open);
    };

    const handleLogoClick = () => {
        navigate("/")
    }

    return (
        <div className={`${globalClasses.sidebar} ${open ? '' : globalClasses.sidebarClosed}`}>
            <div className={globalClasses.sidebarTopContainer}>
                <div className={globalClasses.sidebarLogo} onClick={handleLogoClick}>
                    <Logo />
                    {open &&
                        <AegeonPlatformIcon className={globalClasses.sidebarAegeonPlatform} />
                    }
                </div>
                <button onClick={handleMenu} className={`${globalClasses.sidebarButton} ${open ? globalClasses.sidebarButtonOpen : globalClasses.sidebarButtonClosed}`}>
                    <FontAwesomeIcon icon={open ? faChevronLeft : faChevronRight} />
                </button>
            </div>
            <div className={`${globalClasses.sidebarBottomContainer} ${open ? '' : globalClasses.sidebarClosed}`}>
                <div className={`${globalClasses.sidebarMainMenu} ${open ? globalClasses.sidebarMainMenuOpen : globalClasses.sidebarMainMenuClosed}`}>
                    <Typography variant='h7' className={globalClasses.sidebarMainMenuText}>{t("general.mainMenu")}</Typography>
                </div>
                <Menu className={open ? '' : globalClasses.sidebarMenuClosed} />
            </div>
        </div>
    );
};

export default CustomSidebar;
