import { Card, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Auth } from "aws-amplify";
import React, { useContext, useEffect, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import globalUseStyles from '../../../styles/globalCustomStyles';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import ChartProfile from '../../aegeon/charts/ChartProfile';
import QueryField from '../../aegeon/query/QueryField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import QualityCardDetailsStats from './QualityCardDetailsStats';

const moment = require('moment');
const startDateTenyears = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
const filter = { startDate: startDateTenyears, endDate: endDate };
const allowedUserPrefixes = ["marcos.cunha", "clemence.calas", "benjamin.mercuriali", "maxime.capron", "firas.sifaoui", "julien.egreteau", "lucas.belaid", "vaniala.rakotondrasamy"];

const SubtitleCallback = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();

  const record = useRecordContext(props);
  if (!record) {
    return null;
  }

  return (
    <div className={globalClasses.pageSubTitle}>
      <Typography variant="h5">{t('devices.meter')} {record?.name}</Typography>
    </div>
  )
}

const QualityCardDetails = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const [showCard, setShowCard] = useState(false);
  const [username, setUserName] = useState(undefined);
  const [serie, setSerie] = useState(null);
  const [textArray, setTextArray] = useState(null);

  const { data, isLoading } = useQueryContext(props);

  const { record, setRecord } = useContext(RecordContext);
  const isDeviceContract = ["enedisMeter", "grdfMeter"].includes(record?.deviceType);

  useEffect(() => {
    if (data && !isLoading) {
      setRecord(data)
    }
    else {
      setRecord(null)
    }
  }, [data, isLoading]);

  const handleSerie = (childSerie) => {
    if (childSerie && !serie) {
      setSerie(childSerie);
    }
  }

  useEffect(() => {
    if (serie) {
      let text = [];
      serie.forEach(element => {
        let startDate = new Date(element.time).toLocaleDateString(localStorage.getItem("language"))
        let endDate = new Date(new Date(element.time).setDate(new Date(element.time).getDate() + element.value - 1)).toLocaleDateString(localStorage.getItem("language"));
        let reason = element.reason.split("__");
        if (reason.length > 1) {
          var reason_text = reason.slice(0, 2).map((reason) => t('devices.reasons_splitted.' + reason)).join(" ")
          reason_text += reason.length > 2 ? ", " + reason.slice(2).map((reason) => t('devices.reasons_splitted.' + reason)).join(", ") + '.' : ".";
        } else {
          var reason_text = t('devices.reasons.' + element.reason);
        }
        if (reason_text.includes("devices.reasons")) {
          reason_text = t('devices.reasons.default_reason') + element.reason;
        }
        text.push('</br>' + t("devices.quality.from") + " " + startDate + " " + t("devices.quality.to") + " " + endDate + " : <b>" + t('devices.sources.' + element.source) + "</b> : " + reason_text + '</br>');
      });
      setTextArray(text)
    }
  }, [serie, t, props])

  useEffect(() => {
    // Check if user has allowed prefix
    if (allowedUserPrefixes.some((element) => username?.includes(element))) {
      setShowCard(true);
    }
  }, [username]);

  useEffect(() => {
    const fetchUserName = async () => {
      const data = await Auth.currentAuthenticatedUser().then((data) => data);
      const username = data?.username;
      setUserName(username);
    };
    fetchUserName().catch((err) => console.log(err));
  }, []);

  const QualityComponent = () => {
    return (
      <Grid2 container spacing={2}>
        <Grid2 item="true" small={12} medium={12} big={12}>
          <Card className={globalClasses.DetailCard}>
            <Typography display="block" variant="h8">
              {t('devices.quality.graph.title')}
            </Typography>
            <div style={{ width: "100%", height: "550px" }} >
              <ChartProfile isZoom={true} setSerieCallback={handleSerie} />
            </div>
            <div className={globalClasses.DetailQualityText}>
              <Typography display="block" variant="h8">
                {t('devices.quality.explanation.title')}
              </Typography>
              {textArray && textArray.length > 0 && textArray.map((text, index) => (
                <Typography key={index} variant="body1" dangerouslySetInnerHTML={{ __html: text }}></Typography>
              ))}
            </div>

          </Card>
        </Grid2>
        {showCard &&
          <Grid2 item="true" small={12} medium={12} big={12}>
            <div className={globalClasses.DetailCard}>
              <Typography display="block" variant="h8">
                {t('devices.quality.stats.title')}
              </Typography>
              <QueryField query_name='getDeviceQualityReasoning' >
                <QualityCardDetailsStats />
              </QueryField>
            </div>
          </Grid2>
        }
      </Grid2>
    )
  }

  return (
    !record ? null :
      <TimeseriesField filter={filter} source="fusioned_energy">
        {isDeviceContract ?
          <QueryField query_name='getDeviceContract' >
            <QualityComponent />
          </QueryField>
          : <QualityComponent />
        }
      </TimeseriesField>

  )
};

const QualityCardDetailsWithContext = (props) => {
  const t = useTranslate();
  return (
    <DetailsShow title={t('devices.quality.title')} subTitleCallback={SubtitleCallback} resource="devices" lessMargin={true}>
      <QueryField query_name='getDevice'>
        <QualityCardDetails />
      </QueryField>
    </DetailsShow>
  )
}

export default QualityCardDetailsWithContext;

