import SyncIcon from '@mui/icons-material/Sync';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import globalUseStyles from '../../styles/globalCustomStyles';

const SynchronizeButton = ({ resource, onClick, defaultSort }) => {
    const globalClasses = globalUseStyles();
    const [disabled, setDisabled] = React.useState(defaultSort);
    const t = useTranslate();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/users/synchronize")
    }

    useEffect(() => {
        setDisabled(defaultSort)
    }, [defaultSort]);


    return (
        <Tooltip title={t(resource + ".synchronize_button.tooltip")} classes={{ popper: globalClasses.tooltip }} placement="right">
            <span>
                <IconButton onClick={handleClick} disabled={disabled} className={globalClasses.customButton} style={{ marginLeft: "15px" }}>
                    <SyncIcon style={{ marginRight: "8px" }} />
                    <Typography variant="h3" color="white !important">{t(resource + ".synchronize_button.name")}</Typography>
                </IconButton>
            </span>
        </Tooltip>
    )
}

export default SynchronizeButton;