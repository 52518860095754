
// import siteTypeColdIcon from '../../../assets/siteTypeColdIcon';
// import siteTypeElectricityIcon from '../../../assets/siteTypeElecIcon';
// import siteTypeGasIcon from '../../../assets/siteTypeGasIcon';
// import siteTypeHeatIcon from '../../../assets/siteTypeHeatIcon';
// import siteTypeWaterIcon from '../../../assets/siteTypeWaterIcon';

const SiteTypeIcon = (props) => {  

    const siteType = props.siteType;
    if (siteType === "enedisMeter" || siteType === "electricityMeter") {
      return <></>;
    }
    else return <></>;

};


export default SiteTypeIcon;