import { Typography } from '@mui/material';
import React, { useState } from 'react';
import InfoTooltipIcon from "../../../assets/InfoTooltipIcon";
import globalUseStyles from '../../../styles/globalCustomStyles';
import CustomTooltip from '../../aegeon/CustomTooltip';
import ChartTable from '../../aegeon/charts/ChartTable';

const TableDetailsComponent = (props) => {
  const globalClasses = globalUseStyles();
  const { setDataSourcesCallback, groupedSerie, isLoading, unit, title, source, bottomSource } = props;
  const tooltip_text = props.tooltip_text ? props.tooltip_text : "";
  const [subtitle, setSubtitle] = useState("");

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitle(childData);
    }
  };

  const child =
    <ChartTable
      displayButton={false}
      groupBy="month"
      border={true}
      isLoading={isLoading}
      timeseries={groupedSerie}
      groupedSerie={groupedSerie}
      unit={unit}
      className={globalClasses.DetailCardTableCells}
      setSubtitleCallback={handleSetSubtitleCallback}
      setDataCallback={(childData) => setDataSourcesCallback && setDataSourcesCallback(childData, source)}
      disableMissingDataTooltip={props.disableMissingDataTooltip}
      disabledBoldLastValue={props.disabledBoldLastValue}
      disabledTotal={props.disabledTotal}
    />


  return (
    <>
      <div className={globalClasses.DetailCardTableTitle}>
        <Typography variant="h8">{title}</Typography>
        {tooltip_text && <CustomTooltip title={tooltip_text} icon={<InfoTooltipIcon />} />}
      </div>
      <div className={globalClasses.DetailCardTableSubtitle}>
        {subtitle}
      </div>
      <div style={{ minHeight: "200px" }}>
        {child}
        {bottomSource && <Typography variant="bottomSource">{bottomSource}</Typography>}
      </div>
    </>
  )
};


export default TableDetailsComponent;

