import React from "react";
import { Admin } from "react-admin";
import { QueryClient } from "react-query";
import { createContext, useState } from "react";

import CustomLayout from "./customNavigation/CustomLayout";
import CustomLoginPage from "./customNavigation/CustomLoginPage";
import { theme } from "./theme";
import polyglotI18nProvider from "ra-i18n-polyglot";
import customEnglishMessages from "./i18n/en/en";
import customFrenchMessages from "./i18n/fr/fr";

import roles from "./rolePermissions";
import { availableResources } from "./availableResources";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsconfig from "./aws-exports";
import { buildAuthProvider } from "react-admin-amplify";

import { ThemeProvider } from "@mui/material";
import customAmplifyDataProvider from "./CustomAmplifyDataProvider";

import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";

import ErrorBoundary from "./components/aegeon/cards/ErrorBoundary";
import Home from "./components/home/Home";

Amplify.configure(awsconfig);

const messages = {
  fr: { ...customFrenchMessages },
  en: { ...customEnglishMessages },
};

const i18nProvider = polyglotI18nProvider((locale) => (messages[locale] ? messages[locale] : messages.en), "fr");
localStorage.setItem("language", "fr-FR");

export const RecordContext = createContext();

function App() {
  const dataProvider = customAmplifyDataProvider({ queries, mutations, authMode: "AMAZON_COGNITO_USER_POOLS" });
  const authProvider = buildAuthProvider({
    authGroups: ["role_admin", "role_property_owner", "role_property_manager", "role_property_tenant"],
  });

  function AddAllowedResources(permissions) {
    const role =
      roles[
        permissions
          .filter((permission) => permission.startsWith("role_"))
          .map((permission) => permission.replace("role_", ""))
      ];

    // filter the resources that does not have . in the name
    const role_resources = role.map((permission) => permission.resource).filter((resource) => !resource.includes("."));

    // Get in availableResources object only the resources that are in the role_resources array
    const resources = Object.keys(availableResources)
      .filter((resource) => role_resources.includes(resource))
      .reduce((obj, key) => {
        obj.push(availableResources[key]);
        return obj;
      }, []);

    return resources;
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 60 * 1000, // 60 minutes
      },
    },
  });

  const RecordProvider = ({ children }) => {
    const [record, setRecord] = useState(null);
    return (
      <RecordContext.Provider value={{ record, setRecord }}>
        {children}
      </RecordContext.Provider>
    )
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <RecordProvider>
          <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
            queryClient={queryClient}
            theme={theme}
            layout={CustomLayout}
            loginPage={CustomLoginPage}
            i18nProvider={i18nProvider}
            dashboard={Home}>
            {(permissions) => AddAllowedResources(permissions)}
          </Admin>
        </RecordProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
