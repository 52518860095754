import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Show, SimpleShowLayout, useRecordContext, useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import BackButton from '../../Buttons/BackButton';
import DeviceTypeIcon from '../../devices/CustomComponents/DeviceTypeIcon';

const PageTitle = (props) => {
  const { title, titleCallback, resource } = props;
  const globalClasses = globalUseStyles();

  const record = useRecordContext(props);

  const IconComponent = () => {
    switch (resource) {
      case "devices":
        return <DeviceTypeIcon deviceType={record?.deviceType} />
      default:
        return <div></div>
    }
  }

  return (
    <div className={globalClasses.pageTitle} >
      {title ?
        <Typography variant="h4">{title}</Typography>
        :
        titleCallback(props)
      }
      <IconComponent />
    </div>
  );
}

const ChildComponent = (props) => {
  const { children } = props;
  const record = useRecordContext(props);
  return React.cloneElement(children, { record: record });
}

const DefaultSubtitleCallback = (props) => {
  const globalClasses = globalUseStyles();
  const record = useRecordContext(props);
  const t = useTranslate();
  const resource = props.resource;
  var name = record?.name;

  if (resource === "sites") {
    name += ' ' + record?.airtable?.code;
  }
  if (!record) {
    return null;
  }
  return (
    <div className={globalClasses.pageSubTitle}>
      <Typography variant="h5">{t('resources.' + resource + '.prefix_name')} {name}</Typography>
    </div>
  )
}

const PageSubTitle = (props) => {
  const { subTitle, subTitleCallback } = props;
  const globalClasses = globalUseStyles();

  return subTitle ?
    <div className={globalClasses.pageSubTitle} >
      <Typography variant="h5">{subTitle}</Typography>
    </div> :
    subTitleCallback ?
      subTitleCallback(props)
      : DefaultSubtitleCallback(props);
}

const BackButtonCustom = (props) => {
  const record = useRecordContext(props);
  return <BackButton record={record} />
}


const DetailsShow = (props) => {
  const globalClasses = globalUseStyles();
  const {
    children,
    resource,
    title,
    titleCallback,
    subTitle,
    subTitleCallback,
    lessMargin
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Show {...props} className={globalClasses.Show}>
      <SimpleShowLayout className={globalClasses.Show}>
        <BackButtonCustom />
        <div className={globalClasses.DetailTitle}>
          <div>
            <div className={globalClasses.DetailTitleWithIcon}>
              <PageTitle title={title} titleCallback={titleCallback} resource={resource} />
            </div>
            <PageSubTitle subTitle={subTitle} subTitleCallback={subTitleCallback} resource={resource} />
          </div>
          {props.tooltip ? props.tooltip : null}
        </div>
        <div className={lessMargin ? globalClasses.DetailWithLessMargin : globalClasses.Detail}>
          <ChildComponent children={children} />
        </div>
      </SimpleShowLayout>
    </Show>
  )
};

export default DetailsShow;



