const translateContactsFr = {
  contacts: {
    show: {
      title: "Voir le contact",
      general_information: {
        title: "Informations générales",
        name: "Nom du contact",
        email: "Email du contact",
        phonenumber: "Numéro téléphonique du contact",
        company_role: "Fonction dans l'entreprise",
      },
      customers: {
        title: "Clients",
        name: "Nom des clients",
      },
      organizations: {
        title: "Organisations",
        name: "Nom des organisations",
      },
      buildings: {
        title: "Bâtiments",
        name: "Nom des bâtiments",
      },
    },
    create: {
      title: "Ajouter un client",
    },
    edit: {
      title: "Éditer un client",
      general_information: {
        title: "Informations générales",
        name: "Nom du contact",
        email: "Email du contact",
        phonenumber: "Numéro téléphonique du contact",
        company_role: "Fonction dans l'entreprise",
      },
      customers: {
        title: "Clients",
        name: "Nom des Clients",
      },
      organizations: {
        title: "Organisations",
        name: "Nom des Organisations",
      },
    },
  },
};

export default translateContactsFr;
