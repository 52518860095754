import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from 'react';
import { usePermissions } from "react-admin";
import roles from "../../rolePermissions";

export const useRolePermissions = () => {
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return { isLoading, permissions };
    }

    // Keep only the permissions that starts with role_ in the permissions object and discard the rest
    const rolePermissions = permissions
        .filter((permission) => permission.startsWith("role_"))
        .map(permission => permission.replace("role_", ""));

    return { isLoading, permissions: roles[rolePermissions] };
}

const getLocalPermissions = (permission, controlledComponents, action) => {
    return permission
        .filter((perm) => perm.resource.startsWith(controlledComponents.resource + "."))
        .map((perm) => {
            return {
                ...perm,
                resource: perm.resource.replace(controlledComponents.resource + ".", "")
            }
        })
        .filter((perm) => perm.action.includes(action));
}


export const allowedClickComponents = (permission, controlledComponents) => {
    const local_permissions = getLocalPermissions(permission, controlledComponents, "click");

    const elements = local_permissions.reduce((acc, perm) => {
        if (controlledComponents.elements.click[perm.resource]) {
            acc.push(controlledComponents.elements.click[perm.resource])
        }
        return acc;
    }, []);
    return elements;
}


export const allowedShowComponents = (permission, controlledComponents) => {
    const local_permissions = getLocalPermissions(permission, controlledComponents, "show");

    const elements = local_permissions.reduce((acc, perm) => {
        if (controlledComponents.elements.show[perm.resource]) {
            const component = controlledComponents.elements.show[perm.resource];

            if (perm.resource.includes(".")) {
                // If the resource is a subresource, we need to wrap it in a Grid2 component
                acc.push(component);
            }
            else {
                acc.push(
                    <Grid2 small={12} medium={6} big={4} >
                        {component}
                    </Grid2>);
            }
        }
        return acc;
    }, []);

    return elements;
}
