// Libs
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, { useState } from "react";
import { useTranslate, useNotify } from "react-admin";
import { useNavigate } from "react-router-dom";
// Material UI components
import { Typography } from "@mui/material";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { useRef } from "react";
import { useEffect } from "react";

// Custom Style
import globalUseStyles from "../../../styles/globalCustomStyles";
import BackButton from "../../Buttons/BackButton";
import BottomButton from "../../Buttons/BottomButton";
import CardWrapper from "../../aegeon/cards/CardWrapper";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Switch,
  TextField
} from "@mui/material";
import { capitalize } from "lodash";
import { DropzoneArea } from "material-ui-dropzone";

import { faFileExcel } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubDevicesImportPagePreview from "./SubDevicesImportPagePreview";

const SubDevicesImportPage = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const [newSubDevices, setNewSubDevices] = useState(null);
  const [preview, setPreview] = useState(false);
  const dataText = useRef(null)
  const navigate = useNavigate();
  const notify = useNotify();
  const moment = require('moment-timezone');

  const handleCancelClick = () => {
    navigate("/devices");
  };

  const parseSubDevicesData = (data) => {
    let parsedData = Papa.parse(data, { header: true });
    let timeseriesHeaders = parsedData.meta.fields.filter((header) => {
      return header.match(/^[0-9]{2}\/[0-9]{4}$/);
    });
    let subDevicesData = parsedData.data.map((row) => {
      return {
        name: row.name,
        description: row.description,
        device: row.device,
        building: row.building,
        lessor: row.lessor,
        contract_holder: row.contract_holder,
        timeseries: [
          ...timeseriesHeaders.map((header) => {
            return {
              time: moment(new Date(header.split("/")[1], header.split("/")[0] - 1, 1)).tz("Europe/Paris").format(),
              value: row[header] ? parseFloat(row[header]) : null,
              unit: "kWh PCS",
            }
          }).filter((timeserie) => timeserie.value !== null)
        ]
      }
    });
    subDevicesData = subDevicesData.filter(subDevice => subDevice.name && subDevice.device);
    return subDevicesData;
  };

  const handleSyncClick = () => {
    const data = dataText.current;
    if (!data) {
      notify(
        "subdevices.synchronize.error_no_data",
        { type: 'error' }
      );
      return;
    }
    let subDevicesData = parseSubDevicesData(data);
    setNewSubDevices(subDevicesData);
    setPreview(true);
  };

  const onUploadFile = (file) => {
    if (file.length > 0) {
      let f = file[0];
      const reader = new FileReader();
      if (f.name.endsWith(".csv")) {
        reader.readAsText(f);
        reader.onload = (e) => {
          dataText.current = e.target.result;
        };
      }
      else if (f.name.endsWith(".xlsx")) {
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_csv(sheet);
          dataText.current = parsedData;
        };
        reader.readAsArrayBuffer(f);
      }
    }
  }


  const SyncSourceMain = (props) => {
    const globalClasses = globalUseStyles();
    const t = useTranslate();
  
    const onUploadFile = (e) => {
      props.onUploadFile(e);
    }
  
    const SourceExcelFields = () => {
      return (
        <div className={globalClasses.syncExcel}>
          <div className={globalClasses.dropzoneContainer}>
            <DropzoneArea
              onChange={onUploadFile}
              dropzoneText={t("devices.subdevices.import.configuration.dropzone_text")}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewText=""
              showAlerts={false}
              clearOnUnmount={false}
              filesLimit={1}
              acceptedFiles={[".csv", ".xlsx"]}
            />
          </div>
        </div >
      );
    };
  
    const possibleSources = [
      { id: "excel", component: <SourceExcelFields /> }
    ]
  
    const [source, setSource] = useState(possibleSources[0].id);
  
  
    return (
      <div className={globalClasses.UserGeneralInformation}>
        <TextField
          id="source"
          required
          label={t("devices.subdevices.import.configuration.source")}
          variant="outlined"
          select
          onChange={(e) => setSource(e.target.value)}
          value={source}
          className={globalClasses.UserField}>
          {possibleSources.map((source) => (
            <MenuItem key={source.id} value={source.id}>
              {capitalize(source.id)}
            </MenuItem>
          ))}
        </TextField>
        {possibleSources.filter((component) => component.id === source)[0].component}
      </div>
    );
  };

  return (
    <div className={globalClasses.editPageCard + " " + globalClasses.pageMargin}>
      <div className={globalClasses.fullPageCard}>
        <BackButton />
        <div className={globalClasses.pageTitleContainer}>
          <div>
            <div className={globalClasses.pageTitle}>
              <Typography variant="h4">{t("devices.subdevices.import.title")}</Typography>
            </div>
            <div className={globalClasses.pageSubTitle}>
              <Typography variant="h5">{t("devices.subdevices.import.page_subtitle")}</Typography>
            </div>
          </div>
        </div>

        <div className={globalClasses.pageContent + " " + globalClasses.userPageContent}>
            {preview ?
              <SubDevicesImportPagePreview newSubDevices={newSubDevices} />
              :
              <>
              <CardWrapper resource="devices" type="subdevices.import.configuration" unclickable={true}>
                <SyncSourceMain onUploadFile={onUploadFile}/>
              </CardWrapper>
               <div className={globalClasses.bottomButtonBar + " "+ globalClasses.bottomButtonPage}>
               <BottomButton
                 onClick={handleCancelClick}
                 disabled={false}
                 label={t("devices.subdevices.button.cancel")}
                 className={globalClasses.cancelButton}
               />
               <BottomButton 
                 onClick={handleSyncClick}
                 disabled={false} 
                 label={t("devices.subdevices.button.synchronize")}
               />
             </div>
             </>
            }
        </div>
      </div>
    </div>
  );
};

export default SubDevicesImportPage;
