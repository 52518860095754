const translateOrganizationsFr = {
  organizations: {
    show: {
      title: "Voir l'organisation",
      general_information: {
        title: "Informations générales",
        name: "Nom de l'organisation",
      },
      customer: {
        title: "Client",
        name: "Nom du client",
      },
      contacts: {
        title: "Contacts",
        name: "Nom des contacts",
      },
    },
    create: {
      title: "Ajouter une organisation",
      general_information: {
        title: "Informations générales",
        name: "Nom de l'organisation",
        type: "Type d'organisation",
      },
      customer: {
        title: "Client",
        name: "Nom du client",
      },
      contacts: {
        title: "Contacts",
        name: "Nom des contacts",
      },
    },
    edit: {
      title: "Éditer une organisation",
      general_information: {
        title: "Informations générales",
        name: "Nom de l'organisation",
      },
      customer: {
        title: "Client",
        name: "Nom du client",
      },
      contacts: {
        title: "Contacts",
        name: "Nom des contacts",
      },
    },
  },
};

export default translateOrganizationsFr;
