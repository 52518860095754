import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import globalUseStyles from '../../styles/globalCustomStyles';

const ExportButton = (props) => {
    const globalClasses = globalUseStyles();
    const t = useTranslate();

    const record = useRecordContext(props);
    const name = record?.name;

    const handleExport = () => {
        props.onClick(name);
    }

    return (
        <Tooltip title={t('resources.global.export')} classes={{ popper: globalClasses.tooltip }} placement="right">
            {props.disabled ?
                <div className={globalClasses.tooltip}>
                    <IconButton disabled className={globalClasses.customButton} >
                        <Typography variant="h3" color="white !important">{t('devices.dataTable.export')}</Typography>
                        <FontAwesomeIcon icon={faArrowDownToLine} style={{ padding: 5 }} />
                    </IconButton>
                </div>
                :
                <IconButton onClick={handleExport} className={globalClasses.customButton}>
                    <Typography variant="h3" color="white !important">{t('devices.dataTable.export')}</Typography>
                    <FontAwesomeIcon icon={faArrowDownToLine} style={{ padding: 5 }} />
                </IconButton>
            }
        </Tooltip>
    )
}

export default ExportButton;
