// Libs
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React, { useEffect, useRef, useState } from 'react';
import { ListBase, useTranslate, useNotify, Tab } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Datagrid } from 'react-admin';
import { Chip, Tooltip } from '@mui/material';
import useMutationProvider from '../../aegeon/mutation/useMutationProvider';
import {
  useListContext,
  TextField,
  FunctionField,
  FilterForm,
  FilterButton,
  useGetList,
  CreateButton,
} from 'react-admin';
// Material UI components
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Custom Style
import globalUseStyles from '../../../styles/globalCustomStyles';
import BackButton from '../../Buttons/BackButton';
import BottomButton from '../../Buttons/BottomButton';
import CardWrapper from '../../aegeon/cards/CardWrapper';
import SubDeviceTimeseriesComparisonChart from './SubDeviceTimeseriesComparisonChart';

const SubDevicesImportPagePreview = ({newSubDevices}) => {
  const { data: devices } = useGetList('devices');

  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const evaluatedSubDevices = useRef(null);

  const handleCancelClick = () => {
    navigate('/devices');
  };

  const onErrorCallback = (error) => {
    notify(error.message, { type: 'error' });
  };

  const onSuccessCallback = (data, variables) => {
    if (data.data===undefined) {
      notify('users.synchronize.error', { type: 'error' });
      navigate('/devices');
      return;
    }
    if (variables?.input?.operation === 'evaluate') {
      let currentData = data;
      currentData.data.changed.forEach((subDevice) => {
        if (subDevice.sameValues.timeseries) {
          subDevice.sameValues.timeseries = filterTimeseries(subDevice.sameValues.timeseries);
        }
        if (subDevice.changedValues.timeseries) {
          subDevice.changedValues.timeseries.old_value = filterTimeseries(subDevice.changedValues.timeseries.old_value);
          subDevice.changedValues.timeseries.new_value = filterTimeseries(subDevice.changedValues.timeseries.new_value);
        }
      });
      currentData.data.new.forEach((subDevice) => {
        subDevice.timeseries = filterTimeseries(subDevice.timeseries);
      });
      currentData.data.same.forEach((subDevice) => {
        subDevice.timeseries = filterTimeseries(subDevice.timeseries);
      });
      evaluatedSubDevices.current = data;
    } else {
      notify('devices.subdevices.synchronize.success', { type: 'success' });
      navigate('/devices');
    }
  };

  const filterTimeseries = (timeseries) => {
    if (!timeseries) {
      return [];
    }
    return timeseries.map((ts) => {
      return {
        time: ts.time,
        value: ts.value,
      };
    });
  };

  const mutation = useMutationProvider(
    'syncSubdevices',
    {
      input: {
        items: [],
        operation: 'evaluate',
      },
    },
    onErrorCallback,
    onSuccessCallback
  );

  useEffect(() => {
    if (newSubDevices && !evaluatedSubDevices.current) {
      let items = [];
      newSubDevices.forEach((subDevice) => {
        let deviceId = devices.find((device) => device.name === subDevice.device)?.id;
        if (!deviceId) {
          return;
        }
        items.push({
          description: subDevice?.description,
          deviceId: deviceId,
          id: subDevice?.name,
          name: subDevice?.name,
          building: subDevice?.building,
          contract_holder: subDevice?.contract_holder,
          lessor: subDevice?.lessor,
          timeseries: filterTimeseries(subDevice?.timeseries),
        });
      });
      mutation.mutate({
        mutation_name: 'syncSubdevices',
        input: {
          items: items,
          operation: 'evaluate',
        },
      });
    }
  }, [newSubDevices, evaluatedSubDevices]);


  const recursiveDelete = (obj) => {
    for (let key in obj) {
      if (key === '__typename') {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        recursiveDelete(obj[key]);
      }
    }
  };

  const handleApplyClick = () => {
    recursiveDelete(evaluatedSubDevices.current);
    let data = {
      changed: evaluatedSubDevices.current?.data?.changed,
      new: evaluatedSubDevices.current?.data?.new,
      same: evaluatedSubDevices.current?.data?.same,
      operation: 'apply',
    };
    mutation.mutate({ mutation_name: 'syncSubdevices', input: data });
  };

  const CustomTableRow = ({ key, row, className }) => (
    <TableRow key={key} className={className}>
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.description}
      </TableCell>
      <TableCell component="th" scope="row">
        {devices?.find((device) => device.id === row.deviceId)?.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.building}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.lessor}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.contract_holder}
      </TableCell>
      <TableCell component="th" scope="row">
        <SubDeviceTimeseriesComparisonChart new_timeseries={row.timeseries} />
      </TableCell>
    </TableRow>
  );

  const CustomChangesTableRow = ({ index, row, className }) => {
    return (
      <TableRow key={row.name} className={className}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.changedValues?.description ? 
            <Tooltip title={t('devices.subdevices.old_value_tooltip') +
              row.changedValues.description?.old_value}>
              <Chip label={row.changedValues?.description?.new_value} className={globalClasses.addedUserChip} />
            </Tooltip> : row.sameValues?.description}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.changedValues?.deviceId ?
            <Tooltip title={t('devices.subdevices.old_value_tooltip') +
              devices?.find((device) => device.id === row.changedValues?.deviceId?.old_value)?.name}>
              <Chip label={devices?.find((device) => device.id === row.changedValues?.deviceId?.new_value)?.name} className={globalClasses.addedUserChip} />
            </Tooltip> : devices?.find((device) => device.id === row.sameValues.deviceId)?.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.changedValues?.building ?
            <Tooltip title={t('devices.subdevices.old_value_tooltip') +
              row.changedValues?.building?.old_value}>
              <Chip label={row.changedValues?.building?.new_value} className={globalClasses.addedUserChip} />
            </Tooltip> : row.sameValues?.building}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.changedValues?.lessor ?
            <Tooltip title={t('devices.subdevices.old_value_tooltip') +
              row.changedValues?.lessor?.old_value}>
              <Chip label={row.changedValues?.lessor?.new_value} className={globalClasses.addedUserChip} />
            </Tooltip> : row.sameValues?.lessor}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.changedValues?.contract_holder ?
            <Tooltip title={t('devices.subdevices.old_value_tooltip') +
              row.changedValues?.contract_holder?.old_value}>
              <Chip label={row.changedValues?.contract_holder?.new_value} className={globalClasses.addedUserChip} />
            </Tooltip> : row.sameValues?.contract_holder}
        </TableCell>
        <TableCell component="th" scope="row" className={globalClasses.subdevicesTimeseriesCell}>
          {
            row.changedValues?.timeseries ?
              <SubDeviceTimeseriesComparisonChart new_timeseries={row.changedValues?.timeseries?.new_value} previous_timeseries={row.changedValues?.timeseries?.old_value} />
              : <SubDeviceTimeseriesComparisonChart new_timeseries={row.timeseries} />
          }
        </TableCell>
    </TableRow>
    );
  };

  return (
    <>
      <TableContainer component={Paper} className={globalClasses.usersSyncTable + ' ' + globalClasses.customChip}>
        {!devices ? (
          <></>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('devices.general_information.subdevices.name')}</TableCell>
                <TableCell>{t('devices.general_information.subdevices.description')}</TableCell>
                <TableCell>{t('devices.general_information.subdevices.device')}</TableCell>
                <TableCell>{t('devices.general_information.subdevices.building')}</TableCell>
                <TableCell>{t('devices.general_information.subdevices.lessor')}</TableCell>
                <TableCell>{t('devices.general_information.subdevices.contract_holder')}</TableCell>
                <TableCell className={globalClasses.subdevicesTimeseriesCell}>{t('devices.general_information.subdevices.timeseries')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {evaluatedSubDevices.current &&
                evaluatedSubDevices.current?.data?.changed.map((row, index) => (
                  <CustomChangesTableRow key={index} row={row} />
                ))}
              {evaluatedSubDevices.current &&
                evaluatedSubDevices.current?.data?.new.map((row, index) => (
                  <CustomTableRow key={index} row={row} className={globalClasses.newUserRow} />
                ))}
              {evaluatedSubDevices.current &&
                evaluatedSubDevices.current?.data?.same.map((row, index) => <CustomTableRow key={index} row={row} />)}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <div className={globalClasses.bottomButtonBar + ' ' + globalClasses.bottomButtonPage}>
        <BottomButton
          onClick={handleCancelClick}
          disabled={false}
          label={t('devices.subdevices.button.cancel')}
          className={globalClasses.cancelButton}
        />
        <BottomButton onClick={handleApplyClick} disabled={!evaluatedSubDevices.current} label={t('devices.subdevices.button.apply')} />
      </div>
    </>
  );
};

export default SubDevicesImportPagePreview;
