import React from 'react';
import { useTranslate } from 'react-admin';
import DetailsShow from '../../../aegeon/cards/DetailsShow';
import SiteCompositionDetailsContent from './SiteCompositionDetailsContent';

const SiteCompositionDetails = (props) => {
    const t = useTranslate();

    return (
        <DetailsShow title={t('sites.composition.title')} resource="sites" lessMargin={true}>
            <SiteCompositionDetailsContent />
        </DetailsShow>
    )
};

export default SiteCompositionDetails;

