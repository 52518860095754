import ReactECharts from 'echarts-for-react';
import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import ChartLoading from './ChartLoading';
import ChartNoData from './ChartNoData';


const ChartHorizontalBar = (props) => {
  const t = useTranslate();

  const {
    series,
    new_unit,
    isLoading,
    title,
    isData,
    x_data,
    data_total,
    yAxis
  } = props

  const record = useRecordContext();
  const weather_station = record?.airtable?.unified_degree_day_weather_station?.[0]?.city

  const no_data_text = t('devices.comparison.no_data')

  const y_Axis = yAxis ? yAxis : {
    type: 'value',
    name: 'Consommation (' + new_unit + ')',
    nameLocation: 'middle',
    nameGap: 30,
    nameTextStyle: {
      fontSize: 10,
    },
    axisLabel: {
      fontSize: 10,
    },
  }

  const option = {
    title: {
      text: title,
      top: 10,
      left: 'center',
    },
    animation: false,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      textStyle: {
        fontSize: 10,
      },
      position: ['10%', '10%'],
      formatter: function (params) {
        let total = data_total && (!isNaN(data_total[params[0].dataIndex])) ? ' : ' + data_total[params[0].dataIndex] + ' ' + new_unit : ''
        let result = params[0].axisValue + total + '<br>'
        params.forEach((param) => {
          if (param.value !== no_data_text && param.seriesName !== "DJR" && param.seriesName !== "DJU") {
            result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>' + param.seriesName + ' : ' + param.value + ' ' + new_unit + '<br>'
          }
          else {
            result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>' + param.seriesName + ' : ' + param.value + '<br>'
            if (param.seriesName === "DJR" && weather_station) {
              result += t('sites.histogram.meteo_station_tooltip') + weather_station + '<br>'
            }
          }
        })
        return result
      }
    },
    legend: {
      type: 'scroll',
      show: props.hideLegend ? false : true,
      bottom: '1%',
      left: 'center',
      textStyle: {
        fontSize: 10,
      },
    },
    grid: {
      left: '8%',
      right: '8%',
      bottom: '10%',
      top: '5%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: x_data,
      axisLabel: {
        fontSize: 10,
        name: 'Mois'
      },
    },
    yAxis: y_Axis,
    series: series
  };

  return (
    isLoading ?
      <ChartLoading />
      :
      isData ?
        <ReactECharts
          notMerge={true}
          option={option}
          style={{ height: '100%', width: '100%' }} />
        :
        <ChartNoData />
  )
}

export default ChartHorizontalBar
