import { useMemo } from 'react';
import { useQuery } from 'react-query';

import useQueryDataProvider from "./useQueryDataProvider";

const useQueryProvider = (resource, props = {}, options) => {
    const {
        query_name,
        id,
        params
    } = props;
    const queryDataProvider = useQueryDataProvider();
    // const queryClient = useQueryClient();
    const result = useQuery(
        [query_name, { query_name, id }],
        () => {
            return queryDataProvider
                .getQuery(query_name, id, params)
                .then(({ data }) => ({
                    data
                }));
        },
        {
            ...options,
        }
    );

    return useMemo(
        () =>
            result.data
                ? {
                    ...result,
                    data: result.data?.data,
                }
                : result,
        [result]
    )
};

export default useQueryProvider;
