
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const AegeonPlatformIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 183 57"  xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.2258 30.7032L23.5369 24.2949H8.73015L6.04125 30.7032H0.0238037L13.3143 0.3909H19.3318L32.4564 30.7032H26.2258ZM10.8149 19.3198H21.4639L16.1809 6.58603L10.8149 19.3198Z" fill="#1B232A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.3467 0.3909H57.5568V5.46072H41.1509V12.947H55.8747V18.0168H41.1509V25.6334H58.078V30.7032H35.3467V0.3909Z" fill="#1B232A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M94.313 0.3909H116.523V5.46072H100.117V12.947H114.841V18.0168H100.117V25.6334H117.044V30.7032H94.313V0.3909Z" fill="#1B232A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M143.779 2.0374C146.243 3.39962 148.186 5.25935 149.607 7.62842C151.017 9.9975 151.728 12.6272 151.728 15.5056C151.728 18.384 151.017 21.0255 149.607 23.4065C148.198 25.7874 146.255 27.6708 143.779 29.0567C141.315 30.4426 138.579 31.1296 135.57 31.1296C132.562 31.1296 129.837 30.4426 127.362 29.0567C124.898 27.6708 122.955 25.7874 121.534 23.4065C120.124 21.0255 119.413 18.3959 119.413 15.5056C119.413 12.6153 120.124 9.9975 121.534 7.62842C122.943 5.25935 124.886 3.39962 127.362 2.0374C129.825 0.675186 132.562 0 135.57 0C138.579 0 141.304 0.675186 143.779 2.0374ZM130.512 6.56234C128.925 7.47443 127.67 8.71819 126.746 10.2818C125.822 11.8572 125.36 13.5985 125.36 15.4938C125.36 17.389 125.834 19.1421 126.769 20.7294C127.705 22.3167 128.961 23.5723 130.536 24.4962C132.112 25.4202 133.817 25.8821 135.665 25.8821C137.513 25.8821 139.171 25.4202 140.735 24.4962C142.299 23.5723 143.531 22.3167 144.443 20.7294C145.355 19.1421 145.805 17.389 145.805 15.4938C145.805 13.5985 145.355 11.8454 144.443 10.2818C143.531 8.71819 142.299 7.46259 140.735 6.56234C139.171 5.65024 137.489 5.20012 135.665 5.20012C133.841 5.20012 132.1 5.65024 130.512 6.56234Z" fill="#1B232A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M177.325 0.3909H182.952V30.7032H177.456L161.867 9.83166V30.7032H156.193V0.3909H161.689L177.325 21.3098V0.3909Z" fill="#1B232A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M76.4975 15.6241V20.3622H83.4034V23.9869C82.361 24.591 81.2712 25.0648 80.1341 25.3965C78.9969 25.7282 77.9072 25.894 76.8648 25.894C74.934 25.894 73.169 25.432 71.5817 24.5081C69.9944 23.5841 68.727 22.3285 67.7912 20.7413C66.8554 19.154 66.3816 17.4127 66.3816 15.5056C66.3816 13.5985 66.8436 11.8928 67.7675 10.3055C68.6914 8.71819 69.9589 7.47443 71.558 6.56234C73.1572 5.65024 74.9103 5.20012 76.8174 5.20012C78.3454 5.20012 79.8735 5.5081 81.3897 6.11221C82.9059 6.71633 84.2444 7.56919 85.3934 8.67081L88.6864 4.38279C87.1584 3.02057 85.3342 1.95449 83.2257 1.17269C81.1172 0.390897 78.9851 0 76.8174 0C73.785 0 71.025 0.687032 68.5256 2.0611C66.0262 3.43516 64.0599 5.29488 62.6148 7.65211C61.1696 10.0093 60.4471 12.6272 60.4471 15.5056C60.4471 18.384 61.1578 21.0255 62.5674 23.4065C63.977 25.7874 65.9078 27.6708 68.3479 29.0567C70.7881 30.4426 73.4888 31.1296 76.462 31.1296C78.535 31.1296 80.6671 30.7624 82.823 30.028C84.9907 29.2936 86.8623 28.3341 88.4495 27.1496V15.6359H76.4739L76.4975 15.6241Z" fill="#1B232A"/>
        <path d="M26.2258 30.7032L23.5369 24.2949H8.73015L6.04125 30.7032H0.0238037L13.3143 0.3909H19.3318L32.4564 30.7032H26.2258ZM10.8149 19.3198H21.4639L16.1809 6.58603L10.8149 19.3198Z" fill="white"/>
        <path d="M35.3467 0.3909H57.5568V5.46072H41.1509V12.947H55.8747V18.0168H41.1509V25.6334H58.078V30.7032H35.3467V0.3909Z" fill="white"/>
        <path d="M94.313 0.3909H116.523V5.46072H100.117V12.947H114.841V18.0168H100.117V25.6334H117.044V30.7032H94.313V0.3909Z" fill="white"/>
        <path d="M143.779 2.0374C146.243 3.39962 148.186 5.25935 149.607 7.62842C151.017 9.9975 151.728 12.6272 151.728 15.5056C151.728 18.384 151.017 21.0255 149.607 23.4065C148.198 25.7874 146.255 27.6708 143.779 29.0567C141.315 30.4426 138.579 31.1296 135.57 31.1296C132.562 31.1296 129.837 30.4426 127.362 29.0567C124.898 27.6708 122.955 25.7874 121.534 23.4065C120.124 21.0255 119.413 18.3959 119.413 15.5056C119.413 12.6153 120.124 9.9975 121.534 7.62842C122.943 5.25935 124.886 3.39962 127.362 2.0374C129.825 0.675186 132.562 0 135.57 0C138.579 0 141.304 0.675186 143.779 2.0374ZM130.512 6.56234C128.925 7.47443 127.67 8.71819 126.746 10.2818C125.822 11.8572 125.36 13.5985 125.36 15.4938C125.36 17.389 125.834 19.1421 126.769 20.7294C127.705 22.3167 128.961 23.5723 130.536 24.4962C132.112 25.4202 133.817 25.8821 135.665 25.8821C137.513 25.8821 139.171 25.4202 140.735 24.4962C142.299 23.5723 143.531 22.3167 144.443 20.7294C145.355 19.1421 145.805 17.389 145.805 15.4938C145.805 13.5985 145.355 11.8454 144.443 10.2818C143.531 8.71819 142.299 7.46259 140.735 6.56234C139.171 5.65024 137.489 5.20012 135.665 5.20012C133.841 5.20012 132.1 5.65024 130.512 6.56234Z" fill="white"/>
        <path d="M177.325 0.3909H182.952V30.7032H177.456L161.867 9.83166V30.7032H156.193V0.3909H161.689L177.325 21.3098V0.3909Z" fill="white"/>
        <path d="M76.4975 15.6241V20.3622H83.4034V23.9869C82.361 24.591 81.2712 25.0648 80.1341 25.3965C78.9969 25.7282 77.9072 25.894 76.8648 25.894C74.934 25.894 73.169 25.432 71.5817 24.5081C69.9944 23.5841 68.727 22.3285 67.7912 20.7413C66.8554 19.154 66.3816 17.4127 66.3816 15.5056C66.3816 13.5985 66.8436 11.8928 67.7675 10.3055C68.6914 8.71819 69.9589 7.47443 71.558 6.56234C73.1572 5.65024 74.9103 5.20012 76.8174 5.20012C78.3454 5.20012 79.8735 5.5081 81.3897 6.11221C82.9059 6.71633 84.2444 7.56919 85.3934 8.67081L88.6864 4.38279C87.1584 3.02057 85.3342 1.95449 83.2257 1.17269C81.1172 0.390897 78.9851 0 76.8174 0C73.785 0 71.025 0.687032 68.5256 2.0611C66.0262 3.43516 64.0599 5.29488 62.6148 7.65211C61.1696 10.0093 60.4471 12.6272 60.4471 15.5056C60.4471 18.384 61.1578 21.0255 62.5674 23.4065C63.977 25.7874 65.9078 27.6708 68.3479 29.0567C70.7881 30.4426 73.4888 31.1296 76.462 31.1296C78.535 31.1296 80.6671 30.7624 82.823 30.028C84.9907 29.2936 86.8623 28.3341 88.4495 27.1496V15.6359H76.4739L76.4975 15.6241Z" fill="white"/>
        <path d="M10.0804 43.2238C11.0991 44.1004 11.6085 45.3441 11.6085 46.9314C11.6085 48.5187 11.0991 49.8809 10.0686 50.8048C9.04987 51.7169 7.60473 52.1789 5.75685 52.1789H2.04925V56.8223H0V41.8971H5.75685C7.61658 41.8971 9.06171 42.3354 10.0804 43.2238ZM8.62343 49.4782C9.31047 48.9214 9.64214 48.0923 9.64214 46.9906C9.64214 45.889 9.29862 45.1191 8.62343 44.5742C7.9364 44.0293 6.96508 43.7568 5.68578 43.7568L2.0611 43.7805V50.331H5.68578C6.96508 50.331 7.94825 50.0467 8.62343 49.49V49.4782Z" fill="#71B9A1"/>
        <path d="M14.2263 41.0087H16.2045V56.8341H14.2263V41.0087Z" fill="#71B9A1"/>
        <path d="M27.0785 56.8341V55.2587C26.2375 56.3722 24.9227 56.917 23.134 56.917C22.3522 56.917 21.6652 56.7631 21.0729 56.4669C20.4807 56.1708 20.0305 55.7562 19.7107 55.235C19.3909 54.7138 19.2369 54.1215 19.2369 53.47C19.2369 52.4632 19.6041 51.6577 20.3504 51.0773C21.0966 50.4969 22.139 50.1889 23.4776 50.177H27.0549V49.644C27.0549 48.8503 26.8061 48.2344 26.3204 47.7961C25.8348 47.3578 25.1122 47.1446 24.1764 47.1446C23.0393 47.1446 21.8784 47.5474 20.7057 48.3647L19.8766 47.0025C20.682 46.4813 21.4401 46.1022 22.1272 45.8653C22.8142 45.6284 23.6315 45.5099 24.591 45.5099C26.0006 45.5099 27.0904 45.8535 27.8485 46.5405C28.6066 47.2275 29.0093 48.187 29.0212 49.4308L29.0449 56.846H27.0785V56.8341ZM25.894 54.6072C26.5455 54.1571 26.9246 53.5648 27.0549 52.8304V51.7051H23.75C22.8497 51.7051 22.1864 51.8354 21.76 52.1078C21.3335 52.3803 21.1085 52.8067 21.1085 53.3871C21.1085 53.9675 21.3335 54.4295 21.7718 54.773C22.2101 55.1165 22.8024 55.2824 23.5368 55.2824C24.4607 55.2824 25.2425 55.0573 25.894 54.6072Z" fill="#71B9A1"/>
        <path d="M38.6396 56.0879C37.7749 56.6446 36.8865 56.917 35.9981 56.917C35.1097 56.917 34.3753 56.6564 33.8186 56.1234C33.2618 55.5904 32.9894 54.7849 32.9894 53.7069V47.4644H31.3903V45.9482H32.9894V42.9158H34.9913V45.9482H38.4501V47.4644H34.9913V53.3516C34.9913 53.9794 35.1097 54.4295 35.3348 54.6901C35.5598 54.9625 35.9033 55.0929 36.3535 55.0929C36.8984 55.0929 37.4788 54.9152 38.1066 54.5598L38.6396 56.0997V56.0879Z" fill="#71B9A1"/>
        <path d="M43.9227 43.129C43.5791 43.437 43.4133 43.8871 43.4133 44.4676V45.9364H46.5286V47.4526H43.4133V56.8341H41.4351V47.4526H39.9663V45.9364H41.4351V44.7163C41.4351 43.8871 41.6128 43.1883 41.9563 42.6197C42.2998 42.0393 42.7618 41.6128 43.3304 41.3167C43.899 41.0206 44.5149 40.8784 45.1664 40.8784C45.8179 40.8784 46.4457 41.0443 47.1328 41.3878L46.6589 43.0106C46.1496 42.7737 45.6639 42.6434 45.2138 42.6434C44.7045 42.6434 44.278 42.7974 43.9345 43.1172L43.9227 43.129Z" fill="#71B9A1"/>
        <path d="M55.8154 46.2206C56.692 46.7063 57.3672 47.3696 57.8647 48.2344C58.3503 49.0991 58.5991 50.0822 58.5991 51.1839C58.5991 52.2855 58.3503 53.2805 57.8647 54.1452C57.379 55.0099 56.692 55.6851 55.8154 56.1708C54.9389 56.6564 53.9438 56.8933 52.8185 56.8933C51.6932 56.8933 50.6745 56.6564 49.798 56.1708C48.9214 55.6851 48.2462 55.0218 47.7487 54.157C47.2631 53.2923 47.0143 52.3092 47.0143 51.1839C47.0143 50.0586 47.2631 49.0872 47.7487 48.2344C48.2344 47.3696 48.9214 46.7063 49.798 46.2206C50.6745 45.735 51.6814 45.4981 52.8185 45.4981C53.9557 45.4981 54.9389 45.735 55.8154 46.2206ZM50.8522 47.7369C50.2718 48.0804 49.8335 48.5542 49.5137 49.1583C49.1938 49.7624 49.0399 50.4494 49.0399 51.2194C49.0399 51.9893 49.1938 52.7001 49.5137 53.3042C49.8335 53.9201 50.2836 54.394 50.8522 54.7256C51.4326 55.0573 52.0841 55.2231 52.8304 55.2231C53.5766 55.2231 54.2044 55.0573 54.7849 54.7256C55.3653 54.394 55.8036 53.9201 56.1234 53.3042C56.4432 52.6882 56.6091 52.0012 56.6091 51.2194C56.6091 50.4376 56.4432 49.7624 56.1234 49.1583C55.8036 48.5542 55.3534 48.0804 54.7849 47.7369C54.2044 47.3933 53.5529 47.2275 52.8304 47.2275C52.1078 47.2275 51.4326 47.3933 50.8522 47.7369Z" fill="#71B9A1"/>
        <path d="M64.9245 46.1377C65.6115 45.7231 66.417 45.4981 67.3528 45.4862V47.4052C66.2038 47.3578 65.268 47.6658 64.5573 48.3054C63.8466 48.9451 63.4438 49.8098 63.3372 50.8877V56.8341H61.3591V45.5573H63.3372V47.9856C63.7044 47.1801 64.2375 46.5642 64.9127 46.1377H64.9245Z" fill="#71B9A1"/>
        <path d="M87.1227 46.6589C87.8571 47.4407 88.2362 48.5068 88.2362 49.8572V56.8341H86.2343V50.3902C86.2343 49.4426 85.9737 48.6963 85.4525 48.1514C84.9313 47.6066 84.2206 47.346 83.3085 47.346C82.2424 47.3696 81.4014 47.7487 80.7973 48.4713C80.1813 49.1938 79.8852 50.1296 79.8852 51.2668V56.8341H77.8833V50.3902C77.8833 49.4426 77.6227 48.6963 77.1134 48.1514C76.604 47.6066 75.8815 47.346 74.9575 47.346C73.8914 47.3696 73.0504 47.7487 72.4345 48.4713C71.8185 49.1938 71.5105 50.1296 71.5105 51.2668V56.8341H69.5323V45.5573H71.5105V47.9264C72.2449 46.3391 73.6545 45.5218 75.7156 45.4981C76.758 45.4981 77.6109 45.7468 78.3098 46.2443C79.0086 46.7418 79.4706 47.4407 79.7075 48.3291C80.4064 46.4694 81.8634 45.5218 84.1022 45.4981C85.3933 45.4981 86.412 45.889 87.1464 46.6708L87.1227 46.6589Z" fill="#71B9A1"/>
    </SvgIcon>
  );
};

export default AegeonPlatformIcon;