const translateHomeFr = {
  general:{
    logout: "Se déconnecter",
    mainMenu: "Menu principal",
    identification: "Identification",
  },
  home: {
    name: "AEGEON - Assistant Energetique",
    buildings: "bâtiments enregistrés",
    devices: "équipements ajoutés",
    alerts: "alertes configurées",
    dashboards: "tableaux de bord actifs",
  },
  months : {
    0: "Jan",
    1: "Fév",
    2: "Mar",
    3: "Avr",
    4: "Mai",
    5: "Juin",
    6: "Juil",
    7: "Août",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Déc",
  },
  trimesters: {
    0: "T1",
    1: "T2",
    2: "T3",
    3: "T4",
  },
  total: "Total",
};

export default translateHomeFr;
