import * as React from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CardWrapper from './../../aegeon/cards/CardWrapper';

export default function CardAnalysis() {
  const globalClasses = globalUseStyles();

  return (
    <CardWrapper resource="sites" type="analyse" >
      <div className={globalClasses.cardCenter}>
        <img src='AEGEON_ICONES_NIV2-SIMPLE_PERFORMANCE.svg' alt="some file" height='150' width='150' />
      </div>
    </CardWrapper>

  );
}