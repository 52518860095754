import React from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CardWrapper from '../../aegeon/cards/CardWrapper';
import SiteMissingInformation from './SiteMissingInformation';
const CardMissingInformation = () => {
  const globalClasses = globalUseStyles();
  return (
    <CardWrapper resource="sites" type="missing_information">
      <SiteMissingInformation />
    </CardWrapper>
  )
};

export default CardMissingInformation;

