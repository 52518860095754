// Libs
import React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import { Show, SimpleShowLayout } from "react-admin";
// Components
import globalUseStyles from "../../styles/globalCustomStyles";
import DeviceShowDetails from "./DeviceShowDetails";
import QueryField from "../aegeon/query/QueryField";

const DeviceShow = (props) => {
  const globalClasses = globalUseStyles();

  return (
    <Show {...props} className={globalClasses.Show}>
      <SimpleShowLayout className={globalClasses.Show}>
        <QueryField query_name='getDevice' >
          <DeviceShowDetails />
        </QueryField>
      </SimpleShowLayout>
    </Show>
  );
};

export default DeviceShow;
