import React from 'react';

import "./analyseStyle.css";

const AnalyseLoadingAnimation = () => {
  return (
    <div class="Loading">
        <div class="Loading-progress"></div>
    </div>
  )
};

export default AnalyseLoadingAnimation; 
