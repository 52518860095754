
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const NoDataXIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="m96.12,81.72c-1.17-1.17-3.07-1.17-4.24,0l-3.88,3.88-3.88-3.88c-1.17-1.17-3.07-1.17-4.24,0-1.17,1.17-1.17,3.07,0,4.24l3.88,3.88-3.88,3.88c-1.17,1.17-1.17,3.07,0,4.24.59.59,1.35.88,2.12.88s1.54-.29,2.12-.88l3.88-3.88,3.88,3.88c.59.59,1.35.88,2.12.88s1.54-.29,2.12-.88c1.17-1.17,1.17-3.07,0-4.24l-3.88-3.88,3.88-3.88c1.17-1.17,1.17-3.07,0-4.24Z" fill="#71b9a1"/>
        <path d="m72.23,92.45c-2.49.34-5.34.55-8.56.55-13.5,0-20.59-3.66-21-5v-18.95c4.75,2.59,13.04,3.95,21,3.95s16.25-1.36,21-3.95v5.15c1.07-.23,2.19-.35,3.33-.35.91,0,1.8.09,2.67.24v-34.08c0-7.56-14-11-27-11s-27,3.44-27,11v48c0,7.56,14,11,27,11,3.6,0,7.27-.27,10.7-.81-1.06-1.73-1.8-3.67-2.14-5.74Zm-8.56-57.45c13.32,0,20.4,3.57,20.99,5-.58,1.43-7.67,5-20.99,5s-20.4-3.57-20.99-5c.58-1.43,7.66-5,20.99-5Zm-21,12.25c5.28,2.54,13.31,3.75,21,3.75s15.72-1.2,21-3.75v19.44c-3.44,2.35-11.12,4.31-21,4.31s-17.55-1.96-21-4.31v-19.44Z" fill="#71b9a1"/>
    </SvgIcon>
  );
};

export default NoDataXIcon;