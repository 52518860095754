import { useMemo } from 'react';
import {
    useQuery
} from 'react-query';

import useTimeseriesDataProvider from "./useTimeseriesDataProvider";

const useGetTimeseriesProvider = (resource, params = {}, options) => {
    const {
        deviceId,
        propertyId,
        filter,
        aggregation
    } = params;
    const timeseriesDataProvider = useTimeseriesDataProvider();
    // const queryClient = useQueryClient();
    const result = useQuery(
        // [deviceId, propertyId, filter, aggregation],
        ['GetTimeseries', { deviceId, propertyId, filter, aggregation }],
        // [
        //     resource,
        //     'getTimeseries',
        //     { deviceId, propertyId, filter, aggregation }
        // ],
        () => {
            return timeseriesDataProvider
                .getTimeseries(deviceId, propertyId, filter, aggregation)
                .then(({ data, unit, total }) => ({
                    data,
                    unit,
                    total,
                }));
        },
        {
            // onSuccess: ({ data, total }) => {
            //     // optimistically populate the getTimeseries cache
            //     // queryClient.setQueryData(
            //     //         [resource, 'getTimeseries', { deviceId, propertyId, filter, aggregation }],
            //     //         { data, total}
            //     //     );
            // },
            ...options,
            // keepPreviousData : true,
            // staleTime: Infinity,
            // cacheTime: Infinity,
        }
    );
    // console.log(result);
    // if ( result.data ) {
    //     queryClient.setQueryData(
    //         [resource, 'getTimeseries', { deviceId, propertyId, filter, aggregation }],
    //         { data: result.data.data, total: result.data.total});
    // }

    return useMemo(
        () =>
            result.data
                ? {
                    ...result,
                    timeseries: result.data?.data,
                    total: result.data?.total,
                    unit: result.data?.unit,
                    pageInfo: result.data?.pageInfo,
                }
                : result,
        [result]
    )
};

export default useGetTimeseriesProvider;
