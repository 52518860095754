import { Datagrid, ListBase, FilterForm } from "react-admin";
import React from "react";
// Lib
import { Typography } from "@mui/material";
import { FilterButton } from "react-admin";
import globalUseStyles from "../../styles/globalCustomStyles";
import { useNavigate } from "react-router-dom";

// Components
import BackButton from "../Buttons/BackButton";
import SortButton from "../Buttons/SortButton";
import { useTranslate, useListContext } from "react-admin";
import { useRolePermissions } from "../aegeon-rbac/AuthRolePermissions";

const CustomList = ({
  defaultSortFields,
  filters,
  defaultSortData,
  Icon,
  resource,
  fields,
  sortingByDefault,
  CustomListToolbar,
  CustomButton
}) => {
  const globalClasses = globalUseStyles();
  const [defaultSort, setDefaultSort] = React.useState(sortingByDefault);
  const t = useTranslate();

  const { isLoading, permissions } = useRolePermissions();

  if (isLoading) {
    return null;
  }

  const DefaultListToolbar = ({ defaultSort }) => {
    const { setSort } = useListContext();

    const onClick = () => {
      setSort({ field: "id", order: "ASC" });
    };

    const CustomButtons = CustomButton ? CustomButton : () => null;

    return (
      <div className={globalClasses.listActions}>
        <FilterForm filters={filters} />
        <div className={globalClasses.listButtons}>
          <FilterButton filters={filters} />
          {sortingByDefault && <SortButton resource={resource} onClick={onClick} defaultSort={defaultSort} />}
          <CustomButtons />
        </div>
      </div>
    );
  };

  const ListToolbar = CustomListToolbar ? CustomListToolbar : DefaultListToolbar;

  const handleClick = () => {
    setDefaultSort(!defaultSort);
  };

  return (
    <div style={{ marginTop: "1.2em" }}>
      <BackButton />
      <div>
        <div className={globalClasses.pageTitle}>
          <Typography variant="h4">{t("resources." + resource + ".name")}</Typography>
          <Icon />
        </div>
        <div className={`${globalClasses.pageComponent}`}>
          <ListBase
            queryOptions={{
              meta: {
                sortFunction: defaultSortData,
                sortFields: defaultSortFields,
                defaultSort: defaultSort,
                setDefaultSort: handleClick,
              },
            }}>
            <ListToolbar defaultSort={defaultSort} />
            <Datagrid hover={false} isRowSelectable={(record) => false} rowClick="show">
              {fields.map((field) => {
                return field;
              })}
            </Datagrid>
          </ListBase>
        </div>
      </div>
    </div>
  );
};

export default CustomList;
