import ReactECharts from 'echarts-for-react';
import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { convert_data_for_chartline } from '../helper';
import { useQueryContext } from '../query/useQueryContext';
import { useTimeseriesContext } from '../timeseries/useTimeseriesContext';
import ChartLoading from "./ChartLoading";
import ChartNoData from "./ChartNoData";

var formatTooltipLine = function (color, text) {
  return "<span style='display:inline-block;width:10px;height:10px;border-radius:50%;background-color:" + color + ";margin-right:5px;'></span><span><b>" + text + "<b></span>"
}

const getWeekendMarkAreas = (timestamps) => {
  const uniqueTimestamps = [...new Set(timestamps)];
  const MaxTimestamp = new Date(Math.max(...uniqueTimestamps))
  const MinTimestamp = new Date(Math.min(...uniqueTimestamps))

  if (MinTimestamp.getDay() === 0) {
    MinTimestamp.setDate(MinTimestamp.getDate() - 2)
  }
  if (MaxTimestamp.getDay() === 6) {
    MaxTimestamp.setDate(MaxTimestamp.getDate() + 2)
  }

  let weekendMarkArea = [];
  let weekends = [];
  for (let ts = MinTimestamp; ts <= MaxTimestamp; ts.setDate(ts.getDate() + 1)) {
    if (ts.getDay() === 5) {
      weekends.push(new Date(ts.setHours(20, 0, 0, 0)));
    }
    else if (ts.getDay() === 1) {
      weekends.push(new Date(ts.setHours(8, 0, 0, 0)));
      if (weekends.length === 2) {
        weekendMarkArea.push([
          {
            xAxis: weekends[0],
            itemStyle: { color: '#d6ad5640' },
            name: 'week-end',
          }, {
            xAxis: weekends[1],
            itemStyle: { color: '#d6ad5640' },
            name: 'week-end',
          }
        ]);
      }
      else if (weekends.length === 1) {
        weekendMarkArea.push([
          {
            xAxis: new Date(Math.min(...uniqueTimestamps)),
            itemStyle: { color: '#d6ad5640' },
            name: 'week-end',
          }, {
            xAxis: weekends[0],
            itemStyle: { color: '#d6ad5640' },
            name: 'week-end',
          }
        ]);
      }
      weekends = []
    }
  }
  if (weekends.length === 1) {
    weekendMarkArea.push([
      {
        xAxis: weekends[0],
        itemStyle: { color: '#d6ad5640' },
        name: 'week-end',
      }, {
        xAxis: new Date(Math.max(...uniqueTimestamps)),
        itemStyle: { color: '#d6ad5640' },
        name: 'week-end',
      }
    ]);
  }
  return weekendMarkArea
}

const getNightsMarkAreas = (timestamps) => {

  const uniqueTimestamps = [...new Set(timestamps)]; // Get unique timestamps
  const MaxTimestamp = new Date(Math.max(...uniqueTimestamps))
  const MinTimestamp = new Date(Math.min(...uniqueTimestamps))

  let nightsMarkArea = [];
  let nights = [];
  for (let ts = MinTimestamp; ts <= MaxTimestamp; ts.setDate(ts.getDate() + 1)) {

    let ts_tmp = new Date(ts)
    nights.push(new Date(ts_tmp.setHours(20, 0, 0, 0)));
    nights.push(new Date(ts_tmp.setHours(32, 0, 0, 0)));

    nightsMarkArea.push([
      {
        xAxis: nights[0],
        itemStyle: { color: '#5da4d940' },
        name: "Nuit",
      }, {
        xAxis: nights[1],
        itemStyle: { color: '#5da4d940' },
        name: "Nuit",
      }
    ]);
    nights = []
  }
  return nightsMarkArea
}


const ChartActivePower = (props) => {
  const {
    name,
    timeseries,
    unit,
    isLoading,
  } = useTimeseriesContext(props);

  const t = useTranslate();

  const globalClasses = globalUseStyles();

  const {
    data
  } = useQueryContext(props);

  const isAutomatic = props.isAutomatic;
  const needActivation = isAutomatic & data?.donneesGenerales?.segment?.libelle === "C5" & data?.donneesGenerales?.niveauOuvertureServices === "2";
  const customTextNoData = !isAutomatic ? t("devices.power.noDataNotAuto") : needActivation ? t("devices.power.noDataNeedActivation") : t("devices.power.noData");

  const {
    isWeekendsHighlight,
    isNightsHighlight,
    isZoom,
    setLineDataCallback,
    isDetailedCard,
    title
  } = props;

  const max_subscribed_power = data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.valeur
  const max_subscribed_power_unit = data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.unite ? data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.unite : ''

  const convert_to_kW = true

  const getSeries = (timeseries) => {
    let timestamps_arrays = []
    let timestamps = []
    if (isNightsHighlight || isWeekendsHighlight) {
      timestamps_arrays = timeseries.map(c => new Date(c.time));
      timestamps = [].concat.apply([], timestamps_arrays);
    }

    const weekendMarkAreas = isWeekendsHighlight ? getWeekendMarkAreas(timestamps) : [];
    const nightMarkAreas = isNightsHighlight ? getNightsMarkAreas(timestamps) : [];

    let series = [{
      name: name,
      type: 'line',
      smooth: true,
      showSymbol: false,
      itemStyle: {
        color: "#6c74d8"
      },
      data: timeseries.map(element => [new Date(element.time), element.value / (convert_to_kW ? 1000 : 1)]),
      markArea: {
        data: [...weekendMarkAreas, ...nightMarkAreas],
        label: {
          show: false,
        },
      },
    },
    {
      name: 'Puissance souscrite',
      type: 'line',
      showSymbol: false,
      data: timeseries.map(element => [new Date(element.time), max_subscribed_power]),
      itemStyle: {
        color: '#4ecca2'
      },
      lineStyle: {
        type: 'dashed'
      },
      label: {
        show: true,
        position: 'right',
        formatter: function (params) {
          return params.data[1] + ' ' + max_subscribed_power_unit;
        }
      },
    },
    {
      type: 'scatter',
      name: "Nuit (20h - 8h)",
      color: '#5da4d940',
      data: [],
    },
    {
      type: 'scatter',
      name: "Week-end (Vendredi 20h - Lundi 8h)",
      color: '#d6ad5640',
      data: [],
    }
    ];

    if (series && series.length === 0) {
      series = {
        type: 'line',
        data: []
      }
    }

    return series;
  }

  const dataZoom = isZoom ? [
    {
      type: 'slider',
      filterMode: 'weakFilter',
      showDataShadow: false,
      bottom: "10%",
      labelFormatter: ''
    },
    {
      type: 'inside',
      filterMode: 'weakFilter'
    }
  ] : [];

  const getOption = (timeseries) => {
    const max_subscribed_power = data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.valeur
    const max_subscribed_power_unit = data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.unite ? data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.unite : ''

    const option = {
      title: {
        top: 10,
        left: 'center',
        text: title,
        textStyle: {
          fontSize: 18,
          fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
        },
      },
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          return `${new Date(params[0].axisValueLabel).toLocaleDateString(localStorage.getItem("language"))
            } ${new Date(params[0].axisValueLabel).toLocaleTimeString(localStorage.getItem("language"))
            }<br />
                                ${formatTooltipLine(params[0].color, params[0].value[1] + (convert_to_kW ? " k" : " ") + unit)} `
        }
      },
      legend: [{
        show: max_subscribed_power,
        data: ['Puissance souscrite'],
        selectedMode: isDetailedCard,
        orient: 'horizontal',
        bottom: 15,
        left: 0,
        textStyle: {
          fontSize: 10
        },
        formatter: function (name) {
          if (name === 'Puissance souscrite') {
            return name + ' (' + max_subscribed_power + ' ' + max_subscribed_power_unit + ')';
          }
          return name;
        },
        itemWidth: 10,
        itemHeight: 10,
        itemGap: 10,
        tooltip: {
          show: isDetailedCard,
          formatter: function (name) {
            return "Cliquez pour afficher/masquer la puissance souscrite";
          },
          textStyle: {
            fontSize: 10
          },
        }
      },
      {
        show: true,
        data: ['Nuit (20h - 8h)', 'Week-end (Vendredi 20h - Lundi 8h)'],
        selectedMode: false,
        orient: 'horizontal',
        bottom: 0,
        left: 0,
        textStyle: {
          fontSize: 10
        },
        itemWidth: 10,
        itemHeight: 10,
        itemGap: 10,
      }
      ],
      grid: {
        left: '5%',
        right: '3%',
        bottom: '20%',
        top: '5%',
        containLabel: true
      },
      xAxis: {
        type: 'time',
        boundaryGap: false,
        axisLabel: {
          formatter: function (value, index) {
            return new Date(value).toLocaleDateString(localStorage.getItem("language"), { day: "2-digit", month: "2-digit" });
          },
          fontSize: 10,
        },
      },
      yAxis: {
        name: 'Puissance (' + (convert_to_kW ? "k" : "") + unit + ')',
        type: 'value',
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          fontSize: 10,
        },
        axisLabel: {
          fontSize: 10,
        },
      },
      dataZoom: dataZoom,
      dataset: {
        transform: [
          {
            type: 'sort',
            config: { dimension: 1, order: 'desc' }
          }
        ]
      },
      series: getSeries(timeseries)
    };

    return option;
  }

  useEffect(() => {
    if (timeseries && timeseries.length > 0) {
      let newConvertedData = convert_data_for_chartline(timeseries, unit, convert_to_kW)
      setLineDataCallback && setLineDataCallback(newConvertedData, "active_power")
    }
  }, [setLineDataCallback, timeseries, unit, convert_to_kW])

  return (
    isLoading ?
      <ChartLoading />
      :
      timeseries && timeseries.length > 0 ?
        <ReactECharts
          option={getOption(timeseries)}
          notMerge={true}
          style={{ height: props.isButtonBefore ? "calc(100% - 30px)" : "100%", width: "100%" }}
        />
        :
        <ChartNoData customText={customTextNoData} isLocked={needActivation} className={isDetailedCard ? globalClasses.DetailledFullNoData : ""} />
  )
}

export default ChartActivePower
